export const GENERIC = 'GENERIC';

export const GENERIC_FILE = {
  id: 'generic',
  code: GENERIC,
  name: 'generic',
  is_active_by_import: true,
  by_import: true,
  file_meta_data: {
    import: [
      {
        file_name: 'generic file',
        extension: '.csv'
      }
    ]
  }
};
