import React, { FC } from 'react';
import { Navigate, useLocation, matchPath } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ACCOUNT_TYPE, USER_ROLE } from '@constants/common';
import { RootState } from '@store/reducers';
import {
  TAX_PROFESSIONAL_MANAGED_CLIENT_ALLOWED_PATHS,
  TAX_PROFESSIONAL_SHARED_CLIENT_ALLOWED_PATHS
} from './RouteLists';
import RoutesPath from './RoutesPath';

const RouteRedirector: FC = props => {
  const { children } = props;

  const { userDetails, clientProfile } = useSelector((state: RootState) => ({
    ...state.rootReducer.userDetails,
    ...state.rootReducer.app
  }));

  const location = useLocation();

  const clientAllowedPaths =
    clientProfile.type == ACCOUNT_TYPE.MANAGED
      ? TAX_PROFESSIONAL_MANAGED_CLIENT_ALLOWED_PATHS
      : TAX_PROFESSIONAL_SHARED_CLIENT_ALLOWED_PATHS;

  const isAllowed =
    userDetails.userType === USER_ROLE.PROFESSIONAL
      ? clientAllowedPaths.some(path => {
          const match = matchPath({ path }, location.pathname);
          return match !== null;
        })
      : true;

  return (
    <>
      {isAllowed ? (
        children
      ) : (
        <Navigate replace={true} to={RoutesPath.DASHBOARD} />
      )}
    </>
  );
};

export default RouteRedirector;
