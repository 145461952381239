import { authBaseApi } from '@services/api';
import { translate } from '@utils/locale';
import { GenericApiResponse } from 'types/generalTypes';

const AuthBaseAPI = authBaseApi.injectEndpoints({
  endpoints: builder => ({
    verifyEmail: builder.query<GenericApiResponse<string>, string>({
      query: token => ({
        url: '/verify-email',
        method: 'POST',
        body: { token }
      }),
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.verifyError'),
        success: translate('verifySuccess')
      }
    })
  })
});

export const { useVerifyEmailQuery } = AuthBaseAPI;
