import React, { FC } from 'react';

import { getUploadedFileName } from '@utils/generalUtils';
import { FileProcessStatus } from '@constants/mySources';
import { ActionButtons } from '@components';
import { getFileTableDisplayData, hasfileProcessing } from './utils';
import { FileListViewRowProps } from './types';

const ListViewMobileRow: FC<FileListViewRowProps> = props => {
  const {
    ExchangeIcon,
    isGenericFile = false,
    fileUploadStatus,
    lastSync,
    isDeleteDisabled,
    actionItems,
    fileName
  } = props;

  return (
    <div className="flex justify-between py-4 px-6 mb-2 w-full bg-white rounded-md">
      <div className="flex flex-col w-full">
        <div className=" flex justify-between items-center">
          {ExchangeIcon && (
            <div
              className={`${
                isGenericFile ? 'w-[110px] h-[40px]' : 'max-w-[94px] h-[20px]'
              }`}>
              <ExchangeIcon className="w-full h-full" />
            </div>
          )}
          <div className="flex">
            <div
              className={`${
                isDeleteDisabled ||
                ![
                  FileProcessStatus.FILE_PROCESSING_FAILED,
                  FileProcessStatus.UPLOAD_COMPLETED
                ].includes(fileUploadStatus)
                  ? 'cursor-default opacity-30 pointer-events-none'
                  : 'cursor-pointer'
              }`}>
              <ActionButtons
                actionItems={actionItems}
                iconClass="w-[17px] h-[20px] pb-[5px]"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-start mt-3 text-sm text-slateGrey">
          {getFileTableDisplayData(fileUploadStatus, lastSync)}
          {!hasfileProcessing(fileUploadStatus) && (
            <div className="mb-2 w-[95%]">
              <div className="truncate">{getUploadedFileName(fileName)}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ListViewMobileRow;
