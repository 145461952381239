import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { format } from 'date-fns';

import { DeleteIcon, GreenLoadingIcon } from '@assets/icons';
import {
  FileProcessStatus,
  TransactionLinkingStatus
} from '@constants/mySources';
import { DATE_TIME_NO_SEC_FORMAT } from '@constants/common';
import { translate } from '@utils/locale';

export const getFileTableDisplayData = (
  passedStatus: FileProcessStatus,
  fileUploadedTime = ''
) => {
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up('md'));

  let dateString: string = '';
  if (fileUploadedTime) {
    const formattedDate = new Date(fileUploadedTime);
    dateString = format(formattedDate, DATE_TIME_NO_SEC_FORMAT);
  }
  switch (passedStatus) {
    case FileProcessStatus.FILE_PROCESSING_FAILED:
      return (
        <div className="flex items-center mb-2 sm:mb-0 md:mb-0 lg:mb-0 xl:mb-0 2xl:mb-0">
          <div className="flex py-1.5 px-2 mr-3 text-coralRed bg-lightOrange rounded-md">
            {TransactionLinkingStatus.FILE_PROCESSING_FAILED}
          </div>
        </div>
      );
    case FileProcessStatus.READY_TO_PROCESS:
    case FileProcessStatus.FILE_PROCESSING:
      return (
        <div className="flex py-1.5 px-2 text-davyGrey bg-paleGreen rounded-md">
          <GreenLoadingIcon className="mr-2 animate-rotate" />
          {TransactionLinkingStatus.PROCESSING_FILE}
        </div>
      );
    case FileProcessStatus.DELETING:
    case FileProcessStatus.DELETED:
      return (
        <div className="flex items-center py-1.5 px-2 text-black bg-linen rounded-md">
          <DeleteIcon className="mr-2 animate-wiggle" height={12} width={12} />
          {TransactionLinkingStatus.DELETING}
        </div>
      );
    default:
      return match ? (
        <div className="flex items-center">
          <span className="mr-4"> |</span>
          {translate('sourcePage.fileUploadedTime', {
            uploadedTime: dateString
          })}
        </div>
      ) : (
        <div className="flex items-center mr-2 mb-2">
          {translate('sourcePage.fileUploadedTime', {
            uploadedTime: dateString
          })}
        </div>
      );
  }
};

export const hasfileProcessing = (
  fileUploadStatus: FileProcessStatus
): boolean => {
  return [
    FileProcessStatus.FILE_PROCESSING,
    FileProcessStatus.READY_TO_PROCESS
  ].includes(fileUploadStatus);
};
