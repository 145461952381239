import React, { FC, useEffect, useState } from 'react';

import { RefreshBlue } from '@assets/icons';
import { SyncRequestStatus } from '@constants/mySources';
import { ActionButtons } from '@components';
import { getTableDisplayData } from './utils';
import { ExchangeListViewRowProps } from './types';

const ListViewRow: FC<ExchangeListViewRowProps> = props => {
  const {
    ExchangeIcon,
    exchangeId,
    exchangeSyncStatus,
    lastSync,
    handleSyncClick,
    isSyncDisabled,
    actionItems,
    handleTryAgain,
    isActionsDisabled = false
  } = props;

  const [syncButtonEnabled, setSyncButtonEnabled] = useState<boolean>(true);

  useEffect(() => {
    if (exchangeSyncStatus) {
      setSyncButtonEnabled(
        [
          SyncRequestStatus.COMPLETED,
          SyncRequestStatus.DEAD,
          SyncRequestStatus.AUTH_EXPIRED
        ].includes(exchangeSyncStatus)
      );
    }
  }, [exchangeSyncStatus]);

  return (
    <tr className="hover:shadow-md transition duration-300 hover:-translate-y-1">
      <td className="pt-2 ">
        <div className="flex items-center py-2 pl-6 h-14 bg-white rounded-l-md">
          {ExchangeIcon && (
            <div className="max-w-[94px] h-[20px]">
              <ExchangeIcon className="w-full h-full" />
            </div>
          )}
        </div>
      </td>
      <td className="pt-2">
        <div className="flex items-center py-2 h-14 text-sm text-slateGrey bg-white">
          {getTableDisplayData(
            exchangeId,
            exchangeSyncStatus,
            lastSync,
            handleTryAgain,
            isActionsDisabled
          )}
        </div>
      </td>
      <td className="pt-2 lg:w-[25%]">
        <div className="flex items-center py-2 h-14  bg-white" />
      </td>
      <td className="pt-2">
        <div className="flex justify-center items-center py-2 pr-6 h-14 bg-white">
          {
            <button
              className="disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={handleSyncClick}
              disabled={
                !syncButtonEnabled || isSyncDisabled || isActionsDisabled
              }>
              <RefreshBlue />
            </button>
          }
        </div>
      </td>
      <td className="pt-2">
        <div className="flex justify-end items-center py-2 pr-6 h-14 bg-white rounded-r-md">
          <div
            className={` flex ${
              exchangeSyncStatus !== SyncRequestStatus.AUTH_EXPIRED &&
              (!syncButtonEnabled || isSyncDisabled)
                ? 'cursor-default opacity-30 pointer-events-none'
                : 'cursor-pointer'
            }`}>
            <ActionButtons
              actionItems={actionItems}
              isDisabled={isActionsDisabled}
              customStyle={`${
                isActionsDisabled ? 'cursor-not-allowed opacity-50' : ''
              }`}
            />
          </div>
        </div>
      </td>
    </tr>
  );
};

export default ListViewRow;
