import React, { FC } from 'react';

import { USER_ROLE } from '@constants/common';
import { ForgotPasswordProps } from './types';
import ForgotPasswordContainer from '@containers/login-flow/forgot-password/ForgotPasswordContainer';

const ForgotPasswordPage: FC<ForgotPasswordProps> = ({
  userRole = USER_ROLE.PERSONAL
}) => <ForgotPasswordContainer userRole={userRole} />;

export default ForgotPasswordPage;
