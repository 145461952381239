import React, { FC, useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, debounce } from 'lodash';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useNavigate } from 'react-router-dom';

import {
  NotifierTypes,
  NOTIFIER_MESSAGE_TYPES,
  TopBarConstants,
  USER_ROLE
} from '@constants/common';
import { useGetLinkedSourcesQuery } from '@containers/source/api';
import { RootState } from '@store/reducers';
import { translate } from '@utils/locale';
import { TabBar, TopBar } from '@components';
import { TAB_BAR_CONSTANTS, TAB_BAR_ITEMS } from '@constants/tabBar';
import { useQuery } from '@hooks';
import { showNotifier } from '@reducers/appReducer';
import { useAppDispatch } from '@store/store';
import { getNotifierMessage } from '@utils/notifierUtils';
import {
  useAddExchangeMutation,
  useGetConnectedExchangeLinkingDetailsQuery,
  useGetExchangesQuery
} from './api';
import {
  ConnectExchangePopup,
  ExchangeCardListing,
  WalletCardListing
} from './components';
import RoutesPath from '@routes/RoutesPath';

const ConnectExchange: FC = () => {
  const { userDetails, clientId } = useSelector((state: RootState) => ({
    ...state.rootReducer.userDetails,
    ...state.rootReducer.app
  }));

  const [linkedExchangeIds, setLinkedExchangeIds] = useState<string[]>([]);
  const [selectedTab, setSelectedTab] = useState<string>(
    TAB_BAR_CONSTANTS.CONNECT_EXCHANGES
  );
  const [showConnectExchangePopup, setShowConnectExchangePopup] =
    useState(false);
  const [exchangeData, setExchangeData] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [debounceSearchTerm, setDebounceSearchTerm] = useState('');

  const { data: linkedExchanges, isLoading: isFetchingUserExchanges } =
    useGetLinkedSourcesQuery(
      userDetails.isActive || userDetails.userType === USER_ROLE.PROFESSIONAL
        ? {
            searchTerm: '',
            clientId
          }
        : skipToken
    );
  const { isLoading: isLoadingExchanges, data: exchangesList } =
    useGetExchangesQuery({ searchTerm: debounceSearchTerm });
  const [addExchange, addExchangeResponse] = useAddExchangeMutation();
  const { data: exchangeLinkingDetails } =
    useGetConnectedExchangeLinkingDetailsQuery(
      userDetails?.isActive || userDetails.userType === USER_ROLE.PROFESSIONAL
        ? clientId
        : skipToken
    );

  const dispatch = useAppDispatch();
  const query = useQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (query.get('tab')) {
      setSelectedTab(query.get('tab'));
    }
  }, [query]);

  useEffect(() => {
    if (addExchangeResponse.isSuccess) {
      const exchangeId = addExchangeResponse.originalArgs?.payload?.exchange_id;
      const currentExchange = exchangesList?.filter(
        exchange => exchange.id === exchangeId
      )[0];
      triggerNotifier(
        getNotifierMessage(
          NOTIFIER_MESSAGE_TYPES.linkingSuccess,
          currentExchange?.name
        ),
        NotifierTypes.SUCCESS
      );
      setShowConnectExchangePopup(false);
      setTimeout(() => {
        navigate(`${RoutesPath.SOURCE}?tab=${TAB_BAR_CONSTANTS.CONNECTIONS}`);
      }, 700);
      addExchangeResponse.reset();
    }
  }, [addExchangeResponse]);

  useEffect(() => {
    if (!isEmpty(linkedExchanges)) {
      const linkedIds = linkedExchanges.map(
        linkedExchange => linkedExchange.exchange_id
      );
      setLinkedExchangeIds(linkedIds);
    } else {
      setLinkedExchangeIds([]);
    }
    return () => {
      setLinkedExchangeIds([]);
    };
  }, [linkedExchanges]);

  const debounceSearch = useCallback(
    debounce(value => {
      setDebounceSearchTerm(value);
    }, 300),
    [debounce]
  );

  const handleSearchTextChange = useCallback(
    value => {
      debounceSearch(value.trimStart());
      setSearchText(value.trimStart());
    },
    [debounceSearch]
  );

  const triggerNotifier = (message: string, type: NotifierTypes) => {
    dispatch(showNotifier({ message, type }));
  };

  const handleTabSelect = (tabId: string) => {
    setSelectedTab(tabId);
  };

  const handleCardClick = (item?: any) => {
    item && setExchangeData(item);
    setShowConnectExchangePopup(true);
  };
  const handlePopupClose = () => {
    setShowConnectExchangePopup(false);
    setTimeout(() => {
      setExchangeData(null);
    }, 710);
  };

  const handleNewConnection = () => {
    // TODO: handle newly added exchange linking flow
    // setNewlyAddedExchangeData(newExchangeData);
  };

  const handleAddExchange = addPayload => {
    addExchange({ payload: addPayload.payload, clientId });
  };

  const renderContent = () => {
    switch (selectedTab) {
      case TAB_BAR_CONSTANTS.CONNECT_EXCHANGES:
        return (
          <ExchangeCardListing
            exchangesList={exchangesList}
            handleSearchTextChange={handleSearchTextChange}
            searchValue={searchText}
            isLoadingExchanges={isFetchingUserExchanges || isLoadingExchanges}
            handleCardClick={handleCardClick}
            linkedExchangeIds={linkedExchangeIds}
            exchangeLinkingDetails={exchangeLinkingDetails}
            userType={userDetails.userType}
          />
        );
      case TAB_BAR_CONSTANTS.ADD_WALLET:
        return <WalletCardListing />;
      default:
        return (
          <div className="flex justify-center items-center">
            {translate('comingSoon')}
          </div>
        );
    }
  };

  return (
    <>
      <TopBar title={TopBarConstants.CONNECT_EXCHANGE} />
      <TabBar
        selectedTab={selectedTab}
        handleTabSelect={handleTabSelect}
        tabBarItems={TAB_BAR_ITEMS.connectExchange}
        customHeight="h-[48px]"
        wrapperStyle="shadow-shadowBottomTwlightLite sticky top-[130px] z-10 xxs:top-[108px] sm:top-0 "
      />
      {renderContent()}
      {exchangeData && (
        <ConnectExchangePopup
          showModal={showConnectExchangePopup}
          exchangeData={exchangeData}
          exchangeLinkingDetails={exchangeLinkingDetails}
          handleAddExchange={handleAddExchange}
          handleClose={handlePopupClose}
          isSubmittingAddExchange={addExchangeResponse?.isLoading}
          handleNewConnection={handleNewConnection}
          clientId={clientId}
        />
      )}
    </>
  );
};

export default ConnectExchange;
