import React, { FC, useState } from 'react';

import { ImageFetcherProps } from './types';

const ImageFetcher: FC<ImageFetcherProps> = props => {
  const { imageUrl, defaultImage, imageStyle } = props;

  const [isFetchingFailed, setIsFetchingFailed] = useState<boolean>(false);

  return (
    <img
      src={imageUrl || defaultImage}
      onError={(e: any) => {
        if (!isFetchingFailed) {
          e.target.src = defaultImage;
          setIsFetchingFailed(true);
        }
      }}
      className={imageStyle}
      referrerPolicy="no-referrer"
      alt=""
    />
  );
};

export default ImageFetcher;
