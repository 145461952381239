import React from 'react';

import { RectangularSkeleton, TextSkeleton } from '@components';

const PartnerScreenSkeleton = () => {
  return (
    <div className="flex justify-center items-center w-full md:w-[50%]">
      <div className="flex flex-col  w-full md:w-[50%]">
        <span className="pt-6 md:pt-10">
          <TextSkeleton width={150} fontSize="14px" />
        </span>

        <TextSkeleton
          customStyles={{ width: '80%', maxWidth: '350px', marginTop: '12px' }}
          fontSize="20px"
        />
        <TextSkeleton
          fontSize="20px"
          customStyles={{ width: '30%', maxWidth: '300px' }}
        />

        <TextSkeleton
          customStyles={{ width: '80%', maxWidth: '350px', marginTop: '16px' }}
        />
        <TextSkeleton customStyles={{ width: '80%', maxWidth: '350px' }} />
        <TextSkeleton
          customStyles={{
            width: '80%',
            maxWidth: '350px',
            marginBottom: '24px'
          }}
        />

        <RectangularSkeleton
          height={36}
          width={120}
          customStyles={{ borderRadius: '16px' }}
        />
      </div>
    </div>
  );
};

export default PartnerScreenSkeleton;
