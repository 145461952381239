import { authBaseApi } from '@services/api';
import { translate } from '@utils/locale';
import { ForgotPasswordPayload, GenericResponseType } from './types';

const AuthBaseAPI = authBaseApi.injectEndpoints({
  endpoints: builder => ({
    forgotPassword: builder.mutation<
      GenericResponseType,
      ForgotPasswordPayload
    >({
      query: ({ email, user_type }) => ({
        url: '/forgot',
        method: 'POST',
        body: { email, user_type }
      }),
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.forgotPasswordError')
      }
    })
  })
});

export const { useForgotPasswordMutation } = AuthBaseAPI;
