import React, { FC, useEffect } from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';

import {
  Button,
  DatePicker,
  TextField,
  TimePicker,
  AutocompleteDropdown,
  SelectDropdown
} from '@components';
import {
  Currency,
  REGULAR,
  REGULAR_TRADE,
  TDS_TRANSACTION_TYPE,
  TRANSACTION_BUY_SELL_TAGS_DROPDOWN_VALUES,
  TRANSACTION_TAGS,
  TRANSACTION_TRADE_TAGS_DROPDOWN_VALUES,
  TRANSACTION_TRANSFER_IN_TAGS,
  TRANSACTION_TRANSFER_IN_TAGS_DROPDOWN_VALUES,
  TRANSACTION_TRANSFER_OUT_TAGS,
  TRANSACTION_TRANSFER_OUT_TAGS_DROPDOWN_VALUES,
  TRANSACTION_TYPES,
  TRANSACTION_TYPE_DROPDOWN_VALUES
} from '@constants/common';
import {
  gAEventTracker,
  translate,
  checkTDSIsTransactionLevel,
  checkTDSStartDate
} from '@utils';
import { eventKeyMapper } from '@constants/gaEvents';
import { TransactionFormProps } from './types';
import CoinSelector from './components/CoinSelector';
import TransactionTagSelector from './components/TransactionTagSelector';

const TransactionForm: FC<TransactionFormProps> = props => {
  const {
    control,
    submitHandler,
    errors,
    watch,
    getValues,
    setValue,
    handleCancel,
    transactionSourceOptions,
    isEdit = false,
    isManuallyAdded = true,
    isUpdatingTransaction,
    coinOptions,
    reset,
    transactionDetails
  } = props;

  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up('md'));

  const transactionTypeWatchValue = watch('transactionType');
  const transactionTagWatchValue = watch('transactionTag');
  const transactionSourceWatchValue = watch('transactionSource');
  const transactionDateWatchValue = watch('date');

  useEffect(() => {
    gAEventTracker(eventKeyMapper.transactionForm);
    // unmount clean-up
    return () => {
      clearCoinSelectors();
      reset();
    };
  }, []);

  const clearCoinSelectors = () => {
    setValue('coinName', undefined);
    setValue('coinNameReceived', undefined);
    setValue('feeCoinName', undefined);
    setValue('tdsCoinName', undefined);
  };

  const getSelectedTagItem = () => {
    if (isEdit) {
      return getValues('transactionTag');
    }
    switch (transactionTypeWatchValue) {
      case TRANSACTION_TYPES.transferIn:
        return TRANSACTION_TRANSFER_IN_TAGS.income;
      case TRANSACTION_TYPES.transferOut:
        return TRANSACTION_TRANSFER_OUT_TAGS.external;
      case TRANSACTION_TYPES.buy:
      case TRANSACTION_TYPES.sell:
        return REGULAR;
      case TRANSACTION_TYPES.trade:
        return REGULAR_TRADE;
      default:
        return '';
    }
  };

  const transactionTypeChangeHandler = (selectedItem: string) => {
    if (selectedItem === TRANSACTION_TYPES.transferIn) {
      setValue('transactionTag', TRANSACTION_TRANSFER_IN_TAGS.income);
    }
    if (selectedItem === TRANSACTION_TYPES.transferOut) {
      setValue('transactionTag', TRANSACTION_TRANSFER_OUT_TAGS.external);
    }
    if (
      selectedItem === TRANSACTION_TYPES.buy ||
      selectedItem === TRANSACTION_TYPES.sell
    ) {
      // if already a valid tag is choosen, then we don't need to reset tag to default
      if (![REGULAR, TRANSACTION_TAGS.p2p].includes(transactionTagWatchValue)) {
        setValue('transactionTag', REGULAR);
      }
    }
    if (selectedItem === TRANSACTION_TYPES.trade) {
      if (
        ![REGULAR_TRADE, TRANSACTION_TAGS.otc].includes(
          transactionTagWatchValue
        )
      ) {
        setValue('transactionTag', REGULAR_TRADE);
      }
    }
  };

  const transactionTagChangeHandler = (selectedItem: string) => {
    if (isEdit && selectedItem === REGULAR) {
      setValue('paidAmount', transactionDetails.sent_coin_qty);
      setValue('receivedAmount', transactionDetails.recieved_coin_qty);
    }
  };

  const getTagDropdownValues = () => {
    switch (transactionTypeWatchValue) {
      case TRANSACTION_TYPES.transferIn:
        return TRANSACTION_TRANSFER_IN_TAGS_DROPDOWN_VALUES;
      case TRANSACTION_TYPES.transferOut:
        return TRANSACTION_TRANSFER_OUT_TAGS_DROPDOWN_VALUES;
      case TRANSACTION_TYPES.buy:
      case TRANSACTION_TYPES.sell:
        return TRANSACTION_BUY_SELL_TAGS_DROPDOWN_VALUES;
      case TRANSACTION_TYPES.trade:
        return TRANSACTION_TRADE_TAGS_DROPDOWN_VALUES;
      default:
        return [];
    }
  };

  const getSelectedSourceData = () => {
    if (isEdit) {
      if (transactionDetails?.is_wallet) {
        return {
          id: getValues('transactionSource'),
          name: transactionDetails?.nick_name
        };
      }
      return transactionSourceOptions?.find(
        value => value.id === getValues('transactionSource')
      );
    }
    return null;
  };

  return (
    <div className="w-full h-full bg-white rounded-[10px]">
      <form onSubmit={submitHandler()}>
        <Grid container={true}>
          <Grid
            container={true}
            item={true}
            md={12}
            sm={12}
            xs={12}
            columnSpacing={match ? 6 : 1}
            rowSpacing={match ? 6 : 3}>
            <Grid item={true} md={6} sm={12} xs={12}>
              <SelectDropdown
                name="transactionType"
                placeholder={translate('transactionPage.transactionType')}
                control={control}
                errors={errors}
                dropdownLOV={
                  isEdit && transactionDetails?.is_wallet
                    ? TRANSACTION_TYPE_DROPDOWN_VALUES
                    : TRANSACTION_TYPE_DROPDOWN_VALUES.filter(
                        value => value.id !== TRANSACTION_TYPES.cost
                      )
                }
                isDisabled={isEdit && !isManuallyAdded}
                selectedItem={
                  isEdit ? getValues('transactionType') : TRANSACTION_TYPES.buy
                }
                onChangeHandler={transactionTypeChangeHandler}
                dataTestId="TransactionTypeDropdown"
              />
            </Grid>

            <Grid item={true} md={6} sm={12} xs={12}>
              <AutocompleteDropdown
                name="transactionSource"
                placeholder={translate('transactionPage.transactionSource')}
                control={control}
                errors={errors}
                options={transactionSourceOptions || []}
                isDisabled={isEdit}
                selectedItem={getSelectedSourceData()}
                onChangeHandler={clearCoinSelectors}
                dataTestId="transactionSourceDropdown"
              />
            </Grid>

            {[
              TRANSACTION_TYPES.buy,
              TRANSACTION_TYPES.sell,
              TRANSACTION_TYPES.trade,
              TRANSACTION_TYPES.transferIn,
              TRANSACTION_TYPES.transferOut
            ].includes(transactionTypeWatchValue) && (
              <>
                <TransactionTagSelector
                  name="transactionTag"
                  placeholder={translate('transactionPage.transactionTag')}
                  control={control}
                  errors={errors}
                  xs={
                    transactionTagWatchValue === TRANSACTION_TAGS.external
                      ? 10
                      : 12
                  }
                  sm={
                    transactionTagWatchValue === TRANSACTION_TAGS.external
                      ? 11
                      : 12
                  }
                  selectedItem={getSelectedTagItem()}
                  transactionTagChangeHandler={transactionTagChangeHandler}
                  dropDownValues={getTagDropdownValues()}
                  tooltipText={translate('transactionPage.transactionTagInfo')}
                  showTooltip={
                    transactionTagWatchValue === TRANSACTION_TAGS.external
                  }
                />
              </>
            )}

            <Grid item={true} md={6} sm={6} xs={6}>
              <DatePicker
                name="date"
                placeholder={translate('transactionPage.date')}
                control={control}
                errors={errors}
                defaultValue={isEdit && getValues('date')}
                isDisabled={isEdit && !isManuallyAdded}
                dataTestId="datePicker"
              />
            </Grid>

            <Grid item={true} md={6} sm={6} xs={6}>
              <TimePicker
                name="time"
                placeholder={translate('transactionPage.time')}
                control={control}
                errors={errors}
                defaultValue={isEdit && getValues('time')}
                isDisabled={isEdit && !isManuallyAdded}
                dataTestId="timePicker"
              />
            </Grid>

            {[
              TRANSACTION_TYPES.sell,
              TRANSACTION_TYPES.buy,
              TRANSACTION_TYPES.trade,
              TRANSACTION_TYPES.transferOut,
              TRANSACTION_TYPES.realizedLoss,
              TRANSACTION_TYPES.marginFee,
              TRANSACTION_TYPES.cost
            ].includes(transactionTypeWatchValue) && (
              <CoinSelector
                inputField={{
                  name: 'noOfCoins',
                  placeholder: [
                    TRANSACTION_TYPES.trade,
                    TRANSACTION_TYPES.transferOut,
                    TRANSACTION_TYPES.realizedLoss,
                    TRANSACTION_TYPES.marginFee,
                    TRANSACTION_TYPES.cost
                  ].includes(transactionTypeWatchValue)
                    ? translate('transactionPage.sentNoOfAssets')
                    : translate('transactionPage.noOfAssets')
                }}
                selectField={{
                  name: 'coinName',
                  placeholder:
                    isEdit && transactionDetails?.is_wallet
                      ? transactionDetails?.sent_coin_code ||
                        translate('transactionPage.assets')
                      : translate('transactionPage.assets')
                }}
                control={control}
                errors={errors}
                isDisabled={isEdit && !isManuallyAdded}
                selectedItem={
                  coinOptions?.find(value => value.id === watch('coinName')) ||
                  null
                }
                dropDownValues={coinOptions || []}
                componentName="sentCoinSelector"
              />
            )}

            {[
              TRANSACTION_TYPES.trade,
              TRANSACTION_TYPES.transferIn,
              TRANSACTION_TYPES.realizedProfit
            ].includes(transactionTypeWatchValue) && (
              <CoinSelector
                inputField={{
                  name: 'noOfCoinsReceived',
                  placeholder: translate('transactionPage.receivedNoOfAssets')
                }}
                selectField={{
                  name: 'coinNameReceived',
                  placeholder:
                    isEdit && transactionDetails?.is_wallet
                      ? transactionDetails?.received_coin_code ||
                        translate('transactionPage.assets')
                      : translate('transactionPage.assets')
                }}
                control={control}
                errors={errors}
                isDisabled={isEdit && !isManuallyAdded}
                selectedItem={
                  coinOptions?.find(
                    value => value.id === watch('coinNameReceived')
                  ) || null
                }
                dropDownValues={coinOptions || []}
                componentName="receivedCoinSelector"
              />
            )}

            {[TRANSACTION_TYPES.sell].includes(transactionTypeWatchValue) && (
              <Grid item={true} md={6} xs={12}>
                <TextField
                  name="receivedAmount"
                  placeholder={translate('transactionPage.receivedAmount', {
                    currency: Currency.RUPEE
                  })}
                  control={control}
                  errors={errors}
                  testid="receivedAmount"
                  isControlledField={true}
                  type="number"
                  isDisabled={
                    isEdit &&
                    ![TRANSACTION_TAGS.p2p].includes(
                      transactionTagWatchValue
                    ) &&
                    !isManuallyAdded
                  }
                />
              </Grid>
            )}

            {[TRANSACTION_TYPES.buy].includes(transactionTypeWatchValue) && (
              <Grid item={true} md={6} sm={12} xs={12}>
                <TextField
                  name="paidAmount"
                  placeholder={translate('transactionPage.paidAmount', {
                    currency: Currency.RUPEE
                  })}
                  control={control}
                  errors={errors}
                  testid="paidAmount"
                  isControlledField={true}
                  type="number"
                  isDisabled={
                    isEdit &&
                    ![TRANSACTION_TAGS.p2p].includes(
                      transactionTagWatchValue
                    ) &&
                    !isManuallyAdded
                  }
                />
              </Grid>
            )}

            {transactionTypeWatchValue === TRANSACTION_TYPES.transferIn &&
              [
                TRANSACTION_TAGS.giftFromRelative,
                TRANSACTION_TAGS.internalTransfer
              ].includes(transactionTagWatchValue) && (
                <Grid item={true} md={6} sm={12} xs={12}>
                  <TextField
                    name="costBasis"
                    placeholder={translate(
                      'transactionPage.costBasisWithCurrency',
                      {
                        currency: Currency.RUPEE
                      }
                    )}
                    control={control}
                    errors={errors}
                    isControlledField={true}
                    type="number"
                    testid="costBasis"
                    showInfoIcon={true}
                    infoText={translate('transactionPage.costBasisInfo')}
                  />
                </Grid>
              )}

            {([
              TRANSACTION_TYPES.trade,
              TRANSACTION_TYPES.transferOut,
              TRANSACTION_TYPES.marginFee,
              TRANSACTION_TYPES.realizedLoss,
              TRANSACTION_TYPES.cost,
              TRANSACTION_TYPES.realizedProfit
            ].includes(transactionTypeWatchValue) ||
              (transactionTypeWatchValue === TRANSACTION_TYPES.transferIn &&
                [
                  TRANSACTION_TAGS.stakingReward,
                  TRANSACTION_TAGS.gitFromOthers,
                  TRANSACTION_TAGS.income,
                  TRANSACTION_TAGS.airdrop,
                  TRANSACTION_TAGS.fork,
                  TRANSACTION_TAGS.interest,
                  TRANSACTION_TAGS.mining,
                  TRANSACTION_TAGS.rewards,
                  TRANSACTION_TAGS.referralBonus
                ].includes(transactionTagWatchValue))) && (
              <Grid item={true} md={6} sm={12} xs={12}>
                <TextField
                  name="marketValueOfCoin"
                  placeholder={
                    [
                      TRANSACTION_TYPES.transferIn,
                      TRANSACTION_TYPES.realizedProfit
                    ].includes(transactionTypeWatchValue)
                      ? translate('transactionPage.marketValueReceivedAssets', {
                          currency: Currency.RUPEE
                        })
                      : translate('transactionPage.marketValueSentAssets', {
                          currency: Currency.RUPEE
                        })
                  }
                  control={control}
                  errors={errors}
                  isControlledField={true}
                  type="number"
                  testid="marketValueOfCoin"
                  showInfoIcon={true}
                  infoText={
                    [
                      TRANSACTION_TYPES.transferIn,
                      TRANSACTION_TYPES.realizedProfit
                    ].includes(transactionTypeWatchValue)
                      ? translate('transactionPage.marketValueReceivedInfo')
                      : translate('transactionPage.marketValueSentInfo')
                  }
                />
              </Grid>
            )}

            {[TRANSACTION_TYPES.deposit].includes(
              transactionTypeWatchValue
            ) && (
              <Grid item={true} md={6} xs={12}>
                <TextField
                  name="depositAmount"
                  placeholder={translate('transactionPage.depositedAmount', {
                    currency: Currency.RUPEE
                  })}
                  control={control}
                  errors={errors}
                  isControlledField={true}
                  testid="depositAmount"
                  type="number"
                  isDisabled={isEdit && !isManuallyAdded}
                />
              </Grid>
            )}

            {[TRANSACTION_TYPES.withdrawal].includes(
              transactionTypeWatchValue
            ) && (
              <Grid item={true} md={6} xs={12}>
                <TextField
                  name="withdrawalAmount"
                  placeholder={translate('transactionPage.withdrawnAmount', {
                    currency: Currency.RUPEE
                  })}
                  control={control}
                  errors={errors}
                  isControlledField={true}
                  testid="withdrawalAmount"
                  type="number"
                  isDisabled={isEdit && !isManuallyAdded}
                />
              </Grid>
            )}

            {[
              TRANSACTION_TYPES.sell,
              TRANSACTION_TYPES.buy,
              TRANSACTION_TYPES.deposit,
              TRANSACTION_TYPES.withdrawal,
              TRANSACTION_TYPES.transferOut,
              TRANSACTION_TYPES.trade
            ].includes(transactionTypeWatchValue) && (
              <CoinSelector
                inputField={{
                  name: 'fee',
                  placeholder: ![
                    TRANSACTION_TYPES.trade,
                    TRANSACTION_TYPES.transferOut
                  ].includes(transactionTypeWatchValue)
                    ? translate('transactionPage.feeWithCurrency', {
                        currency: Currency.RUPEE
                      })
                    : translate('fee')
                }}
                selectField={{
                  name: 'feeCoinName',
                  placeholder:
                    isEdit && transactionDetails?.is_wallet
                      ? transactionDetails?.received_coin_code ||
                        translate('transactionPage.assets')
                      : translate('transactionPage.assets')
                }}
                control={control}
                errors={errors}
                isDisabled={isEdit && !isManuallyAdded}
                selectedItem={
                  coinOptions?.find(
                    value => value.id === watch('feeCoinName')
                  ) || null
                }
                dropDownValues={coinOptions || []}
                showSelector={[
                  TRANSACTION_TYPES.trade,
                  TRANSACTION_TYPES.transferOut
                ].includes(transactionTypeWatchValue)}
                componentName="feeCoinSelector"
              />
            )}
            {TDS_TRANSACTION_TYPE.filter(
              type =>
                ![
                  TRANSACTION_TYPES.realizedLoss,
                  TRANSACTION_TYPES.marginFee
                ].includes(type)
            ).includes(transactionTypeWatchValue) &&
              !!checkTDSIsTransactionLevel(
                transactionSourceOptions,
                transactionSourceWatchValue
              ) &&
              transactionDateWatchValue &&
              checkTDSStartDate(transactionDateWatchValue) && (
                <>
                  <CoinSelector
                    inputField={{
                      name: 'tdsCharged',
                      placeholder:
                        transactionTypeWatchValue === TRANSACTION_TYPES.sell
                          ? translate('transactionPage.tdsChargedInCurrency', {
                              currency: Currency.RUPEE
                            })
                          : translate('transactionPage.tdsCharged')
                    }}
                    selectField={{
                      name: 'tdsCoinName',
                      placeholder: translate('transactionPage.assets')
                    }}
                    control={control}
                    errors={errors}
                    isDisabled={isEdit && !isManuallyAdded}
                    selectedItem={
                      coinOptions?.find(
                        value => value.id === watch('tdsCoinName')
                      ) || null
                    }
                    dropDownValues={coinOptions || []}
                    showSelector={[
                      TRANSACTION_TYPES.trade,
                      TRANSACTION_TYPES.transferOut
                    ].includes(transactionTypeWatchValue)}
                    componentName="tdsCoinSelector"
                  />
                </>
              )}
            <Grid item={true} md={12} sm={12} xs={12}>
              <TextField
                name="notes"
                placeholder={translate('transactionPage.addNotes')}
                control={control}
                errors={errors}
                rows={4}
                testid="notes"
                multiline={true}
                isControlledField={true}
              />
            </Grid>
          </Grid>

          <Grid item={true} md={4} sm={0} xs={0} />
        </Grid>

        <Grid container={true} item={true} xs={12} sm={12} md={12} mt={5}>
          <Button
            label="Cancel"
            className="py-[9px] px-[17px] mb-2 w-full text-black bg-white border-[1px] border-primaryColor md:w-auto"
            type="button"
            dataTestId="transactionFormCancelButton"
            onClick={handleCancel}
            disabled={isUpdatingTransaction}
          />
          <Button
            label={
              isEdit
                ? translate('transactionPage.updateTransaction')
                : translate('transactionPage.addTransaction')
            }
            className="py-[9px] px-[17px] mb-2 w-full text-white bg-primaryColor md: md:ml-3 md:w-auto"
            type="submit"
            dataTestId={
              isEdit ? 'updateTransactionButton' : 'addTransactionButton'
            }
            disabled={isUpdatingTransaction}
          />
        </Grid>
      </form>
    </div>
  );
};

export default TransactionForm;
