import React, { FC } from 'react';
import { isEmpty } from 'lodash';

import { getFormattedNumbering, translate } from '@utils';
import { CrossFilled, TickFilled } from '@assets/icons';
import { PLAN_TYPES } from '@constants/subscriptionConstants';
import { ContactSupport } from '@assets/images';
import { PlanDescriptionProps } from './types';

const PlanDescription: FC<PlanDescriptionProps> = props => {
  const { transactionCounts, planDetails, planId, isManaged } = props;

  return (
    <div className="flex flex-col max-w-[350px] h-full">
      <div
        className="py-2 px-4 mb-3 font-medium text-blackGreen bg-whiteLilac
             rounded-lg shadow-shadowBottomLightBlack">
        {translate('subscription.yourTransactions')}
        <div className="flex flex-row justify-between pt-2 text-sm font-normal">
          <span className="text-davyGrey">{translate('ceFiTransactions')}</span>
          <span>
            {!isEmpty(transactionCounts) &&
              getFormattedNumbering({
                value: transactionCounts?.cefi_transaction_count?.toString(),
                hideRupeeSymbol: true
              })}
          </span>
        </div>
        {/* Disabled defi count temporarily */}
        {/* <div className="flex flex-row justify-between text-sm font-normal">
          <span className="text-davyGrey">{translate('deFiTransactions')}</span>
          <span>
            {!isEmpty(transactionCounts) &&
              getFormattedNumbering({
                value: transactionCounts?.defi_transaction_count?.toString(),
                hideRupeeSymbol: true
              })}
          </span>
        </div> */}
      </div>
      <div className="p-2 h-full bg-whiteLilac rounded-lg shadow-shadowBottomLightBlack">
        {planId !== PLAN_TYPES.CUSTOM ? (
          planDetails.map(details => (
            <div
              key={details.feature}
              className={`flex flex-row justify-between p-2 text-sm 
                        ${
                          details.is_available
                            ? 'text-blackGreen'
                            : 'text-greyCloud'
                        }
                       ${
                         details.is_highlighted && details.is_available
                           ? 'font-semibold'
                           : ''
                       }`}>
              <span className="mr-4">{details.feature}</span>
              {details.is_available ? (
                <TickFilled className="shrink-0" height={20} width={20} />
              ) : (
                <CrossFilled className="shrink-0" height={20} width={20} />
              )}
            </div>
          ))
        ) : (
          <div className="flex flex-col px-6 pt-6 pb-4 h-full text-sm text-blackGreen">
            <div className="mb-4 w-[30px] rounded-sm border-[2px] border-primaryColor" />
            <div className="flex flex-col justify-between items-center h-full">
              {translate('subscription.contactSupportForCustomPlan', {
                count: getFormattedNumbering({
                  value: isManaged ? '2000' : '10000',
                  hideRupeeSymbol: true
                })
              })}
              <img
                src={ContactSupport}
                alt="contact us"
                className="mt-3 h-[180px]"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlanDescription;
