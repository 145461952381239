/* eslint-disable max-len */
import baseApi, { authBaseApi } from '@services/api';
import {
  appendClientIdInEndpoints,
  restartJobStatusPolling,
  translate
} from '@utils';
import { SubscriptionPayloadType } from '@containers/tax-report/types';
import { clearOfferDetails, updateOfferDetails } from '@reducers/userDetails';
import { GenericResponseType } from '@containers/login-flow/reset-password/types';
import { GenericApiResponse } from 'types/generalTypes';
import {
  EditTaxSettingsPayload,
  GetPlanListPayload,
  GetTaxSettingsPayload,
  HostedPageData,
  HostedPagePayload,
  PlanBasedTransactionCountDetails,
  ProfessionalSettingsData,
  SettingsDetailData,
  TaxSettingsData
} from './types';
import {
  EditProfilePayload,
  ChangePasswordPayloadType,
  SetupPasswordPayloadType
} from './profile-settings/types';
import {
  AvailablePlanDetails,
  CouponDetails,
  UserDiscountDetails
} from './subscription-plan-listing/types';

const SettingsBaseApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getHostedPageDetails: builder.query<HostedPageData, HostedPagePayload>({
      query: ({ payload }) => ({
        url: appendClientIdInEndpoints(
          `/user/subscription/hosted-page/checkout?from_timestamp=${payload.from}&to_timestamp=${payload.to}&current_plan=${payload.currentPlan}&selected_plan=${payload.selectedPlan}`,
          payload.clientId
        ),
        method: 'GET'
      }),
      transformResponse: (response: GenericApiResponse<HostedPageData>) =>
        response.result,
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.subscribePlanFailed')
      }
    }),
    getUpdatePaymentPageDetails: builder.query<HostedPageData, string | void>({
      query: clientId => ({
        url: appendClientIdInEndpoints(
          '/user/subscription/hosted-page/update-payment-method',
          clientId
        ),
        method: 'GET'
      }),
      transformResponse: (response: GenericApiResponse<HostedPageData>) =>
        response.result,
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.somethingWentWrong')
      }
    }),
    getTaxSettingsDetails: builder.query<
      TaxSettingsData,
      GetTaxSettingsPayload
    >({
      query: ({ fromTimestamp, toTimestamp, clientId }) => ({
        url: appendClientIdInEndpoints(
          `/user/profile/settings/tax?from_timestamp=${fromTimestamp}&to_timestamp=${toTimestamp}`,
          clientId
        ),
        method: 'GET'
      }),
      providesTags: ['GetTaxSettings'],
      transformResponse: (response: GenericApiResponse<TaxSettingsData>) =>
        response.result,
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.somethingWentWrong')
      }
    }),
    getSettingsDetails: builder.query<
      SettingsDetailData,
      SubscriptionPayloadType
    >({
      query: ({ from_timestamp, to_timestamp, clientId }) => ({
        url: appendClientIdInEndpoints(
          `/user/profile/settings?from_timestamp=${from_timestamp}&to_timestamp=${to_timestamp}`,
          clientId
        ),
        method: 'GET'
      }),
      providesTags: ['GetSettingsDetails'],
      transformResponse: (response: GenericApiResponse<SettingsDetailData>) =>
        response.result,
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.somethingWentWrong')
      }
    }),
    editTaxSettings: builder.mutation<
      GenericApiResponse<string>,
      EditTaxSettingsPayload
    >({
      query: ({ payload, queryParam, clientId }) => ({
        // eslint-disable-next-line max-len
        url: appendClientIdInEndpoints(
          `/user/profile/settings/tax?from_timestamp=${queryParam.fromTimestamp}&to_timestamp=${queryParam.toTimestamp}`,
          clientId
        ),
        method: 'PUT',
        body: payload
      }),
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.editTaxRulesFailed'),
        success: translate('notifierMessage.editTaxRulesSuccess')
      },
      onQueryStarted: (_, lifeCycleAPI) =>
        restartJobStatusPolling(lifeCycleAPI, ['GetTaxSettings'])
    }),
    editProfile: builder.mutation<GenericResponseType, EditProfilePayload>({
      query: ({ payload }) => ({
        url: '/user/profile/settings',
        method: 'PUT',
        body: payload
      }),
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.editProfileError'),
        success: translate('notifierMessage.editProfileSuccess')
      }
    }),
    getSubscriptionPlanList: builder.query<
      AvailablePlanDetails,
      GetPlanListPayload
    >({
      query: ({ fromTimestamp, toTimestamp, accountType }) => ({
        url: `/subscriptions?from_timestamp=${fromTimestamp}&to_timestamp=${toTimestamp}&account_type=${accountType}`,
        method: 'GET'
      }),
      transformResponse: (response: GenericApiResponse<AvailablePlanDetails>) =>
        response.result
    }),
    generateNewCoupon: builder.mutation<CouponDetails, string>({
      query: couponId => ({
        url: `user/coupon/${couponId}`,
        method: 'POST'
      }),
      invalidatesTags: ['GetUserDiscountDetails'],
      transformResponse: (response: GenericApiResponse<CouponDetails>) =>
        response.result,
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.couponGenerationFailed')
      }
    }),
    getUserDiscountDetails: builder.query<UserDiscountDetails, void>({
      query: () => '/user/coupon',
      providesTags: ['GetUserDiscountDetails'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateOfferDetails(data));
        } catch (err) {
          // `onError` side-effect
          dispatch(clearOfferDetails());
        }
      },
      transformResponse: (response: GenericApiResponse<UserDiscountDetails>) =>
        response.result
    }),
    getPlanBasedTransactionCount: builder.query<
      PlanBasedTransactionCountDetails,
      GetTaxSettingsPayload
    >({
      query: ({ fromTimestamp, toTimestamp, clientId }) =>
        appendClientIdInEndpoints(
          `/user/transaction/limit?from_timestamp=${fromTimestamp}&to_timestamp=${toTimestamp}`,
          clientId
        ),
      transformResponse: (
        response: GenericApiResponse<PlanBasedTransactionCountDetails>
      ) => response.result,
      providesTags: ['GetTransactionLimit']
    })
  })
});

const ChangePasswordBaseApi = authBaseApi.injectEndpoints({
  endpoints: builder => ({
    updatePassword: builder.mutation<
      GenericResponseType,
      ChangePasswordPayloadType
    >({
      query: payload => ({
        url: '/change-password',
        method: 'POST',
        body: payload
      }),
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.resetPasswordError'),
        success: translate('notifierMessage.resetPasswordSuccess')
      }
    }),
    setupPassword: builder.mutation<
      GenericResponseType,
      SetupPasswordPayloadType
    >({
      query: payload => ({
        url: '/setup-password',
        method: 'POST',
        body: payload
      }),
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.setupPasswordError'),
        success: translate('notifierMessage.setupPasswordSuccess')
      }
    }),
    deleteUserAccount: builder.mutation<GenericApiResponse<string>, void>({
      query: () => ({
        url: '/users',
        method: 'DELETE'
      }),
      invalidatesTags: ['GetUserDetails'],
      extraOptions: {
        showNotifier: true,
        success: translate('notifierMessage.accountDeleteSuccess'),
        failure: translate('notifierMessage.accountDeleteError')
      }
    }),
    getProfessionalSettings: builder.query<ProfessionalSettingsData, void>({
      query: () => ({
        url: '/prof/profile/settings',
        method: 'GET'
      }),
      providesTags: ['GetProfessionalSettingsData'],
      transformResponse: (
        response: GenericApiResponse<ProfessionalSettingsData>
      ) => response.result
    }),
    editProfessionalProfile: builder.mutation<
      GenericResponseType,
      EditProfilePayload
    >({
      query: ({ payload }) => ({
        url: '/prof/profile/settings',
        method: 'PUT',
        body: payload
      }),
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.editProfileError'),
        success: translate('notifierMessage.editProfileSuccess')
      }
    })
  })
});

export const {
  useLazyGetHostedPageDetailsQuery,
  useLazyGetUpdatePaymentPageDetailsQuery,
  useGetTaxSettingsDetailsQuery,
  useLazyGetSettingsDetailsQuery,
  useEditTaxSettingsMutation,
  useEditProfileMutation,
  useGetSubscriptionPlanListQuery,
  useGenerateNewCouponMutation,
  useGetUserDiscountDetailsQuery,
  useLazyGetUserDiscountDetailsQuery,
  useGetPlanBasedTransactionCountQuery
} = SettingsBaseApi;

export const {
  useUpdatePasswordMutation,
  useDeleteUserAccountMutation,
  useLazyGetProfessionalSettingsQuery,
  useEditProfessionalProfileMutation,
  useSetupPasswordMutation
} = ChangePasswordBaseApi;
