import React from 'react';

import {
  CircularSkeleton,
  RectangularSkeleton,
  TextSkeleton
} from '@components';

const TransactionDetailsSkeleton = () => {
  return (
    <div className="flex flex-wrap gap-y-4 p-4 mx-auto w-full sm:p-10">
      <div className="flex flex-col gap-4 w-full lg:flex-row">
        {[...Array(3)].map((card, index) => (
          <div
            key={index}
            className=" p-4 mx-auto w-full min-w-[250px] h-[100px] bg-white rounded-md lg:w-1/3">
            <TextSkeleton width={200} fontSize="14px" />
            <div className="flex mt-1">
              <div className="flex-row my-2">
                <CircularSkeleton width={30} height={30} />
              </div>
              <div className="flex-row m-4 mt-5">
                <RectangularSkeleton width={120} height={10} />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="p-4 my-5 mx-auto w-full min-w-[250px] h-[180px] bg-white rounded-md ">
        <div className=" pt-2 h-1/3 border-b">
          <TextSkeleton width={200} fontSize="14px" />
        </div>
        <div className="flex pt-3 h-1/3 border-b">
          <div className="flex-row">
            <TextSkeleton width={200} fontSize="14px" />
          </div>
          <div className="invisible flex-row ml-auto sm:visible">
            <TextSkeleton width={100} fontSize="14px" />
          </div>
        </div>
        <div className="flex pt-4 h-1/3">
          <div className="flex-row">
            <TextSkeleton width={200} fontSize="14px" />
          </div>
          <div className="invisible flex-row ml-auto sm:visible">
            <TextSkeleton width={100} fontSize="14px" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionDetailsSkeleton;
