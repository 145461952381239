import React, { FC, useEffect, useRef, useState } from 'react';

import { ChevronDown } from '@assets/icons';
import { AccordianProps } from './types';
import COLORS from '@constants/colors';

const Accordian: FC<AccordianProps> = props => {
  const {
    styleConfig,
    contentConfig,
    conditionalFlag,
    dataTestId = 'accordianHeading'
  } = props;

  const {
    accordianStyle = '',
    accordianHeadingWrapperStyle = '',
    accordianIconStyle,
    iconColor = COLORS.PRIMARY_COLOR,
    accordianBodyHeightOffset = 30,
    accordianHeadingStyle = ''
  } = styleConfig;
  const { accordianBody, accordianHeading, handleOnClickHeading } =
    contentConfig;
  const {
    chevronDownPresent = true,
    showDetails,
    disableOnClick = false
  } = conditionalFlag;

  const [bodyHeight, setBodyHeight] = useState(accordianBodyHeightOffset);

  const bodyRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const finalHeight = bodyRef.current?.clientHeight
      ? bodyRef.current.clientHeight + accordianBodyHeightOffset
      : accordianBodyHeightOffset;
    setBodyHeight(finalHeight);
  }, [accordianBody]);

  const handleOnClick = () => {
    if (disableOnClick) return;
    handleOnClickHeading();
  };

  return (
    <div className={`${accordianStyle} flex flex-col p-5 w-full rounded-md`}>
      <div
        className={`${accordianHeadingWrapperStyle} flex justify-between items-center w-full cursor-pointer`}
        onClick={handleOnClick}
        data-testid={dataTestId}>
        <div className={`flex w-full ${accordianHeadingStyle}`}>
          {accordianHeading}
        </div>
        {chevronDownPresent && (
          <ChevronDown
            className={`${showDetails ? 'transform  rotate-180 ' : ''}
             ${
               accordianIconStyle ? accordianIconStyle : 'ml-2'
             } opacity-100 duration-1000 `}
            stroke={iconColor}
          />
        )}
      </div>
      <div
        className={`overflow-clip customTransparentScroll duration-1000 transition-height ease opacity-100
       ${showDetails ? 'visible' : 'invisible'}`}
        style={{ maxHeight: showDetails ? `${bodyHeight}px` : '0px' }}>
        <div ref={bodyRef}>{accordianBody}</div>
      </div>
    </div>
  );
};

export default Accordian;
