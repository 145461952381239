import React, { FC, useState } from 'react';
import { Grid, IconButton, useMediaQuery, useTheme } from '@mui/material';

import { Button, TextField } from '@components';
import { translate } from '@utils/locale';
import { EyeCrossedIcon, EyeIcon } from '@assets/icons';
import { ChangePasswordFormProps } from './types';

const ChangePasswordForm: FC<ChangePasswordFormProps> = props => {
  const { control, submitHandler, errors } = props;

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up('md'));

  const handleChange = e => {
    e.preventDefault();
    return false;
  };

  const changeShowOldPasswordState = () => {
    setShowOldPassword(currState => !currState);
  };

  const changeShowPasswordState = () => {
    setShowPassword(currState => !currState);
  };

  const changeShowConfirmPasswordState = () => {
    setShowConfirmPassword(currState => !currState);
  };

  return (
    <div className="px-10 pb-10 w-full h-full text-sm bg-white rounded-[10px] sm:text-base">
      <div
        className="flex flex-row justify-between pt-3 pb-[15px] mb-[23px]
            font-semibold border-b border-harp">
        <div className="ml-2 text-base font-semibold">
          {translate('profile.changePassword')}
        </div>
      </div>
      <form onSubmit={submitHandler()}>
        <Grid container={true}>
          <Grid
            container={true}
            item={true}
            md={8}
            sm={12}
            xs={12}
            columnSpacing={match ? 6 : 1}
            rowSpacing={match ? 4 : 3}>
            <Grid item={true} md={6} sm={12} xs={12}>
              <TextField
                name="oldPassword"
                placeholder={translate('profile.oldPassword')}
                control={control}
                autoComplete="new-password"
                errors={errors}
                isControlledField={true}
                type={showOldPassword ? 'text' : 'password'}
                onCut={handleChange}
                onCopy={handleChange}
                onPaste={handleChange}
                InputLabelProps={{ style: { fontSize: match ? '12' : '14' } }}
                showInfoIcon={true} //for showing eye icon
                endAdornment={
                  <IconButton onClick={changeShowOldPasswordState}>
                    {showOldPassword ? <EyeCrossedIcon /> : <EyeIcon />}
                  </IconButton>
                }
                InputProps={{ style: { height: 54 } }}
                data-testid="oldPassword"
              />
            </Grid>
            <Grid item={true} md={6} sm={12} xs={12}>
              <TextField
                name="newPassword"
                placeholder={translate('profile.newPassword')}
                control={control}
                autoComplete="new-password"
                errors={errors}
                isControlledField={true}
                type={showPassword ? 'text' : 'password'}
                onCut={handleChange}
                onCopy={handleChange}
                onPaste={handleChange}
                InputLabelProps={{ style: { fontSize: match ? '12' : '14' } }}
                showInfoIcon={true} //for showing eye icon
                endAdornment={
                  <IconButton
                    onClick={changeShowPasswordState}
                    data-testid={'eyeIcon'}>
                    {showPassword ? <EyeCrossedIcon /> : <EyeIcon />}
                  </IconButton>
                }
                InputProps={{ style: { height: 54 } }}
                testid="newPassword"
              />
            </Grid>
            <Grid item={true} md={6} sm={12} xs={12}>
              <TextField
                name="confirmNewPassword"
                placeholder={translate('profile.confirmNewPassword')}
                control={control}
                errors={errors}
                autoComplete="new-password"
                isControlledField={true}
                type={showConfirmPassword ? 'text' : 'password'}
                onCut={handleChange}
                onCopy={handleChange}
                onPaste={handleChange}
                InputLabelProps={{ style: { fontSize: match ? '12' : '14' } }}
                showInfoIcon={true} //for showing eye icon
                endAdornment={
                  <IconButton
                    onClick={changeShowConfirmPasswordState}
                    data-testid="confirmEyeIcon">
                    {showConfirmPassword ? <EyeCrossedIcon /> : <EyeIcon />}
                  </IconButton>
                }
                InputProps={{ style: { height: 54 } }}
                testid="confirmPassword"
              />
            </Grid>
          </Grid>
          <Grid container={true} item={true} xs={12} sm={12} md={12} mt={3}>
            <Button
              dataTestId={'changePasswordFormButton'}
              label={translate('resetPasswordPage.updatePassword')}
              className="py-[9px] px-[17px] w-full text-white bg-secondaryColor md:w-auto"
              type="submit"
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default ChangePasswordForm;
