import React, { FC } from 'react';

import { WalletCardProps } from './types';

const WalletCard: FC<WalletCardProps> = props => {
  const {
    handleCardClick,
    name,
    imageUrl,
    iconStyle = 'w-10',
    isWallet = true,
    isDisabled = false
  } = props;

  const handleClick = () => {
    if (isDisabled) {
      return;
    }
    // TODO: Update the event once its available.
    // gAEventTracker(eventKeyMapper.[eventName], name);
    handleCardClick();
  };

  return (
    <div
      className={`${isWallet ? 'p-2.5' : 'justify-center flex-row px-12'} 
      flex items-center relative mr-4 mb-[14px] w-[200px] h-[60px] bg-white
      transition rounded-md shadow-shadowBottomTwlightLite
       ${
         isDisabled
           ? 'cursor-not-allowed opacity-80'
           : 'cursor-pointer sm:hover:shadow-md  sm:duration-300 sm:hover:-translate-y-1'
       } sm:mb-4`}
      onClick={handleClick}
      data-testid="walletCard">
      <img
        className={`${
          isWallet ? 'w-10 h-10 rounded-md border border-harp' : iconStyle
        }`}
        src={imageUrl}
        alt="wallet icon"
      />
      {isWallet ? (
        <div className="overflow-hidden ml-4 font-medium text-davyGrey line-clamp-2">
          {name}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default WalletCard;
