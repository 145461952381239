import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';

import { Button, TransactionForm, SideModal as PopUp } from '@components';
import { useLazyGetCoinsListQuery } from '@containers/source/api';
import {
  TRANSACTION_BUY_SELL_TAGS_DROPDOWN_VALUES,
  TRANSACTION_TYPE_DROPDOWN_VALUES
} from '@constants/common';
import {
  createPayload,
  getFormattedCoinList,
  getFormattedExchangeList,
  translate,
  gAEventTracker
} from '@utils';
import { eventKeyMapper } from '@constants/gaEvents';
import { CloseIcon } from '@assets/icons';
import { transactionFormSchema } from './validate';
import { AddTransactionProps } from './types';
import { FormFieldValues } from '../../containers/source/components/types';
import COLORS from '@constants/colors';

const AddTransaction: FC<AddTransactionProps> = props => {
  const {
    exchangesList,
    addTransaction,
    isUpdatingTransaction,
    handleFormPopUpClose,
    isTransactionFormPopupOpen,
    clientId
  } = props;

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    setValue,
    reset
  } = useForm<FormFieldValues>({
    defaultValues: {
      transactionType: TRANSACTION_TYPE_DROPDOWN_VALUES[0].id,
      transactionSource: '',
      transactionTag: TRANSACTION_BUY_SELL_TAGS_DROPDOWN_VALUES[0].id,
      noOfCoins: '',
      noOfCoinsReceived: '',
      receivedAmount: '',
      paidAmount: '',
      costBasis: '',
      marketValueOfCoin: '',
      depositAmount: '',
      withdrawalAmount: '',
      fee: '',
      notes: '',
      tdsCharged: ''
    },
    context: exchangesList,
    resolver: yupResolver(transactionFormSchema)
  });

  const [getCoinsListById, { data: coinsListByIdResponse }] =
    useLazyGetCoinsListQuery();

  const transactionSourceWatchValue = watch('transactionSource');

  useEffect(() => {
    if (!isEmpty(exchangesList) && transactionSourceWatchValue) {
      getCoinsListById({ exchangeId: transactionSourceWatchValue });
    }
  }, [exchangesList, transactionSourceWatchValue]);

  const handleCancel = () => handleFormPopUpClose(false);

  const handleAddTransaction = (values: FormFieldValues) => {
    const payload = createPayload({
      values: values,
      exchangesList: exchangesList,
      coinsList: getFormattedCoinList(coinsListByIdResponse)
    });
    gAEventTracker(eventKeyMapper.addTransactionClicked);
    addTransaction({ payload: payload, clientId });
  };

  const submitHandler = () => {
    return handleSubmit(handleAddTransaction);
  };

  return (
    <>
      <PopUp
        isOpen={isTransactionFormPopupOpen}
        clickOutSideClose={false}
        handleClose={handleCancel}>
        <div
          className={`flex justify-center items-center self-center
                    w-full h-full bg-transparent
                     ${
                       isTransactionFormPopupOpen
                         ? 'animate-zoom-to-front'
                         : 'animate-zoom-to-back'
                     }`}>
          <div
            className="flex overflow-auto relative flex-col p-3 w-[750px] h-full max-h-full bg-white
           md:rounded-xl lg:h-auto customTransparentScroll"
            data-testid="addTransactionPopup">
            <div className="flex flex-row justify-between px-10 pt-10 w-full">
              <span className="text-base font-medium">
                {translate('transactionPage.addManualTransaction')}
              </span>
              <div
                className="flex absolute top-5 right-5 justify-center items-center w-7 h-7 
              bg-whiteSmoke rounded-full sm:top-10 sm:right-10">
                <Button
                  onClick={handleCancel}
                  iconStroke={COLORS.DAVY_GREY}
                  disableRipple={true}
                  dataTestId="formCloseButton"
                  Icon={CloseIcon}
                />
              </div>
            </div>
            <div className="m-10">
              <TransactionForm
                control={control}
                submitHandler={submitHandler}
                handleCancel={handleCancel}
                errors={errors}
                watch={watch}
                getValues={getValues}
                setValue={setValue}
                transactionSourceOptions={getFormattedExchangeList(
                  exchangesList
                )}
                isUpdatingTransaction={isUpdatingTransaction}
                coinOptions={getFormattedCoinList(coinsListByIdResponse)}
                reset={reset}
              />
            </div>
          </div>
        </div>
      </PopUp>
    </>
  );
};

export default AddTransaction;
