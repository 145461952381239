import baseApi from '@services/api';
import { translate } from '@utils/locale';
import { GenericApiResponse } from 'types/generalTypes';
import { OnboardUserPayload, PartnerDetails } from './types';

const partnerBaseAPI = baseApi.injectEndpoints({
  endpoints: builder => ({
    getPartnerDetails: builder.query<PartnerDetails, string>({
      query: sourceCode => ({
        url: `/partner/${sourceCode}`,
        method: 'GET'
      }),
      transformResponse: (response: GenericApiResponse<PartnerDetails>) =>
        response.result,
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.partnerDetailsFetchFailed')
      }
    }),
    onboardUser: builder.mutation<
      GenericApiResponse<string>,
      OnboardUserPayload
    >({
      query: ({ payload }) => ({
        url: `${process.env.REACT_APP_PARTNERS_API_ENDPOINT}/user/one-click/onboard`,
        body: payload,
        method: 'POST'
      }),
      extraOptions: {
        showNotifier: false
      }
    })
  })
});

export const { useLazyGetPartnerDetailsQuery, useOnboardUserMutation } =
  partnerBaseAPI;
