import React, { FC, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useMediaQuery } from '@mui/material';

import { EmptyData } from '@components';
import { EmptyAsset } from '@assets/images';
import { PortfolioCryptoData, WalletSectionProps } from '../types';
import CryptoPortfolioSection from '../crypto-portfolio-section/CryptoPortfolioSection';
import NftSummary from '../nft-summary/NftSummary';

const WalletSection: FC<WalletSectionProps> = ({
  cryptoData,
  nftData,
  topContentHeight
}) => {
  const [nonEmptyCryptoData, setNonEmptyCryptoData] = useState<
    PortfolioCryptoData[]
  >([]);

  const isMobileView = useMediaQuery('(max-width:1024px)');
  const isSmallScreen = useMediaQuery('(max-width:640px)');

  useEffect(() => {
    setNonEmptyCryptoData(
      cryptoData?.filter(crypto => Number(crypto.quantity) !== 0)
    );
  }, [cryptoData]);

  return (
    <div
      className="p-5 w-full sm:p-8 lg:overflow-auto lg:px-8 lg:pt-0 lg:pb-8 customNormalScroll"
      style={{
        maxHeight: !isMobileView
          ? `calc(100vh - ${topContentHeight + 45}px)`
          : ''
      }}>
      {isEmpty(nonEmptyCryptoData) ? (
        <EmptyData
          styleConfig={{
            height: isSmallScreen
              ? `calc(100vh - ${topContentHeight}px - 28px)`
              : `calc(100vh - ${topContentHeight}px - 130px)`,
            wrapperStyle: 'min-h-[300px] tall:min-h-[500px]',
            imageStyle: 'w-[50%] sm:w-[250px] md:w-[300px] lg:w-[350px]'
          }}
          contentConfig={{
            highlightedImage: EmptyAsset,
            showPrimaryDescription: false,
            showSecondaryDescription: false
          }}
        />
      ) : (
        <CryptoPortfolioSection cryptoData={nonEmptyCryptoData} />
      )}
      {nftData && (
        <NftSummary
          nftData={nftData}
          sortData={true}
          customStyle={'bg-white'}
          accordianTableHeadingStyle="bg-white lg:top-[52px] lg:pt-1 "
          headingStyle="sticky top-0"
        />
      )}
    </div>
  );
};

export default WalletSection;
