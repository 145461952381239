import { TagDescription } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

import { updateJobStatusPolling } from '@reducers/appReducer';
import { LifecycleAPIType } from 'types/generalTypes';
import baseApi from '@services/api';

export const restartJobStatusPolling = async (
  { dispatch, queryFulfilled }: LifecycleAPIType,
  invalidateTagArray: Array<TagDescription<any>> = []
) => {
  await queryFulfilled;
  dispatch(updateJobStatusPolling(false));
  dispatch(baseApi.util.invalidateTags(['GetJobStatuses']));
  if (invalidateTagArray.length > 0) {
    dispatch(baseApi.util.invalidateTags(invalidateTagArray));
  }
};
