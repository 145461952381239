import { GenericApiResponse } from 'types/generalTypes';
import { translate } from '@utils';
import { PromotionDetailsType, RegisterPromotionResponse } from './types';
import baseApi from '@services/api';

const PromotionBaseApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getPromotionStatus: builder.query<RegisterPromotionResponse, string>({
      query: promotionId => `/user/promotion/${promotionId}/status`,
      providesTags: ['GetGiveAwayStatus'],
      transformResponse: (
        response: GenericApiResponse<RegisterPromotionResponse>
      ) => response.result
    }),
    registerPromotion: builder.mutation<GenericApiResponse<string>, string>({
      query: promotionId => ({
        url: `/user/promotion/${promotionId}/register`,
        method: 'POST'
      }),
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.registrationFailed')
      },
      invalidatesTags: ['GetGiveAwayStatus']
    }),
    getPromotionDetails: builder.query<PromotionDetailsType, string>({
      query: promotionId => `/promotion/${promotionId}`,
      transformResponse: (response: GenericApiResponse<PromotionDetailsType>) =>
        response.result
    })
  })
});

export const {
  useGetPromotionStatusQuery,
  useRegisterPromotionMutation,
  useLazyGetPromotionDetailsQuery,
  useGetPromotionDetailsQuery
} = PromotionBaseApi;
