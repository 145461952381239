import app from './appReducer';
import leftNavigation from './leftNavigation';
import subscriptionDetails from './subscription';
import userDetails from './userDetails';
import userAuth from './userAuth';
import professional from './professional';
import transaction from '@containers/transactions/slice';

export default {
  app,
  leftNavigation,
  subscriptionDetails,
  userDetails,
  userAuth,
  professional,
  transaction
};
