import React, { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { debounce, isEmpty } from 'lodash';
import { useMediaQuery, useTheme } from '@mui/material';

import { EXCHANGE_INFO } from '@containers/source/constants';
import {
  AttachmentPinIcon,
  EditIcon,
  DeleteIcon,
  RefreshIconBlack
} from '@assets/icons';
import {
  EmptyData,
  ConfirmationPopup,
  SearchBar,
  Button,
  If
} from '@components';
import { useTopContentHeight } from '@hooks';
import {
  useDisconnectExchangeMutation,
  useEditExchangeMutation,
  useGetConnectedExchangeStatusQuery,
  useSyncTransactionsMutation
} from '@containers/source/api';
import {
  useGetConnectedExchangeLinkingDetailsQuery,
  useGetExchangesQuery
} from '@containers/connect-exchange/api';
import { SyncRequestStatus } from '@constants/mySources';
import {
  NotifierTypes,
  NOTIFIER_MESSAGE_TYPES,
  USER_ROLE
} from '@constants/common';
import { eventKeyMapper } from '@constants/gaEvents';
import { showNotifier } from '@reducers/appReducer';
import { useAppDispatch } from '@store/store';
import { getNotifierMessage } from '@utils/notifierUtils';
import { gAEventTracker } from '@utils/gaUtils';
import { translate } from '@utils/locale';
import { NoConnections, NoSearchResult } from '@assets/images';
import { HandleSyncTransactionArgs } from '@containers/source/types';
import { SourcesSkeleton } from '@containers/skeleton-loader';
import { ExchangeData } from 'types/generalTypes';
import { ExchangeListViewProps } from './types';
import { DeleteExchangeArgs, LinkedExchange } from '../types';
import ConnectExchangePopup from '@containers/connect-exchange/components/connect-exchange-popup/ConnectExchangePopup';
import RoutesPath from '@routes/RoutesPath';
import ExchangeListViewRow from './ExchangeListViewRow';
import ListViewMobileRow from './ExchangeListViewMobileRow';

const ACTION_ITEMS = [
  {
    Icon: EditIcon,
    label: translate('edit'),
    onClick: null
  },
  {
    Icon: DeleteIcon,
    label: translate('delete'),
    onClick: null
  }
];

const ExchangeListView: FC<ExchangeListViewProps> = props => {
  const {
    setIsExchangeListEmpty,
    getLinkedSources,
    linkedExchanges,
    isLoadingUserExchanges,
    userType,
    clientId,
    isManaged
  } = props;

  const [showConnectExchangePopup, setShowConnectExchangePopup] =
    useState(false);
  const [editExchangeData, setEditExchangeData] = useState(null);
  const [selectedExchangeItem, setSelectedExchangeItem] =
    useState<DeleteExchangeArgs>({
      exchangeDetails: null
    });
  const [allExchangeIds, setAllExchangeIds] = useState('');
  const [isSyncAllClicked, setIsSyncAllClicked] = useState(false);
  const [isExchangePopupOpen, setIsExchangePopupOpen] = useState(false);
  const [stopPolling, setStopPolling] = useState(false); // stopPolling= true means polling is active
  const [editingExchangeId, setEditingExchangeId] = useState(null);
  const [isPolling, setIsPolling] = useState(false);

  const [newlyAddedExchangeData, setNewlyAddedExchangeData] =
    useState<ExchangeData>(null);
  const [debounceSearchTerm, setDebounceSearchTerm] = useState('');
  const [searchText, setSearchText] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [showSuccessNotifier, setShowSuccessNotifier] = useState(false);
  const [showUnlinkNotifier, setShowUnlinkNotifier] = useState(false);

  const navigate = useNavigate();
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up('md'));
  const isMobileView = useMediaQuery('(max-width:640px)');
  const dispatch = useAppDispatch();

  const topContentHeight = useTopContentHeight(!!clientId);

  const { data: exchangeLinkingDetails } =
    useGetConnectedExchangeLinkingDetailsQuery(clientId);
  const { data: exchangesList } = useGetExchangesQuery({
    searchTerm: ''
  });
  const [syncTransactions, syncTransactionResponse] =
    useSyncTransactionsMutation();
  const { data: syncStatusData, isFetching: fetchingSyncStatus } =
    useGetConnectedExchangeStatusQuery(
      { exchangeIds: allExchangeIds, clientId },
      { pollingInterval: 5000, skip: !allExchangeIds || stopPolling }
    );
  const [editExchange, editExchangeResponse] = useEditExchangeMutation();
  const [disconnectExchange, disconnectExchangeResponse] =
    useDisconnectExchangeMutation();

  useEffect(() => {
    getLinkedSources({ searchTerm: debounceSearchTerm, clientId });
  }, [debounceSearchTerm]);

  useEffect(() => {
    if (!syncTransactionResponse.isLoading && isSyncAllClicked) {
      setIsSyncAllClicked(false);
    }
  }, [syncTransactionResponse, isSyncAllClicked]);

  useEffect(() => {
    if (editExchangeResponse.isSuccess) {
      const exchangeId = editExchangeResponse.originalArgs?.exchangeId;
      const currentExchange = exchangesList?.filter(
        exchange => exchange.id === exchangeId
      )[0];
      triggerNotifier(
        getNotifierMessage(
          NOTIFIER_MESSAGE_TYPES.linkingSuccess,
          currentExchange?.name
        ),
        NotifierTypes.SUCCESS
      );
      editExchangeResponse.reset();
    }
  }, [editExchangeResponse]);

  useEffect(() => {
    setIsPolling(!stopPolling);
  }, [stopPolling]);

  useEffect(() => {
    // for handling polling
    let pollingRequired = true;
    if (!isEmpty(syncStatusData) && !fetchingSyncStatus) {
      pollingRequired = syncStatusData?.reduce(
        (isPollingRequired, data) =>
          isPollingRequired ||
          ![
            SyncRequestStatus.COMPLETED,
            SyncRequestStatus.DEAD,
            SyncRequestStatus.UNLINKED,
            SyncRequestStatus.AUTH_EXPIRED
          ].includes(data.status),
        false
      );
      const isUnlinkedConnectionsInList = syncStatusData?.reduce(
        (isUnlinked, data) =>
          isUnlinked || data.status === SyncRequestStatus.UNLINKED,
        false
      );
      if (isUnlinkedConnectionsInList) {
        // refetch the linked source api if any of the exchnage is deleted from the list
        getLinkedSources({ searchTerm: debounceSearchTerm, clientId });
      }
    }
    setStopPolling(!pollingRequired);
  }, [syncStatusData, fetchingSyncStatus]);

  useEffect(() => {
    if (!isEmpty(syncStatusData)) {
      const isSyncInprogress = syncStatusData?.reduce(
        (syncStatus, data) =>
          syncStatus ||
          ![
            SyncRequestStatus.COMPLETED,
            SyncRequestStatus.DEAD,
            SyncRequestStatus.UNLINKED,
            SyncRequestStatus.UNLINKING,
            SyncRequestStatus.AUTH_EXPIRED
          ].includes(data.status),
        false
      );

      const isUnlinkInProgress = syncStatusData?.reduce(
        (syncStatus, data) =>
          syncStatus || [SyncRequestStatus.UNLINKING].includes(data.status),
        false
      );
      if (isSyncInprogress) {
        setShowSuccessNotifier(isSyncInprogress);
      }
      if (isUnlinkInProgress) {
        setShowUnlinkNotifier(isUnlinkInProgress);
      }
    }
  }, [syncStatusData]);

  useEffect(() => {
    if (isSyncAllClicked) {
      // retrigger the syncStatus polling api when sync all button is clicked
      setStopPolling(false);
    }
  }, [isSyncAllClicked]);

  useEffect(() => {
    if (disconnectExchangeResponse.isSuccess) {
      setStopPolling(false);
      disconnectExchangeResponse.reset();
    }
  }, [disconnectExchangeResponse]);

  useEffect(() => {
    if (newlyAddedExchangeData || linkedExchanges) {
      setStopPolling(false);
    }
  }, [newlyAddedExchangeData, linkedExchanges]);

  useEffect(() => {
    setIsExchangeListEmpty(!linkedExchanges?.length);
  }, [linkedExchanges]);

  useEffect(() => {
    // computes all available exchange id's
    let ids = [];
    linkedExchanges?.forEach(exchange => ids.push(exchange.exchange_id));
    if (
      newlyAddedExchangeData?.id &&
      !ids.includes(newlyAddedExchangeData?.id)
    ) {
      ids.push(newlyAddedExchangeData.id);
    }
    setAllExchangeIds(ids.join(','));
  }, [newlyAddedExchangeData?.id, linkedExchanges]);

  useEffect(() => {
    // for sync complete toast
    let isAllCompleted = false;
    if (
      !isEmpty(syncStatusData) &&
      !fetchingSyncStatus &&
      showSuccessNotifier
    ) {
      isAllCompleted = syncStatusData?.reduce(
        (isCompleted, data) =>
          isCompleted && data.status === SyncRequestStatus.COMPLETED,
        true
      );
      if (isAllCompleted && stopPolling) {
        triggerNotifier(
          getNotifierMessage(NOTIFIER_MESSAGE_TYPES.syncingTransactionsSuccess),
          NotifierTypes.SUCCESS
        );
        setShowSuccessNotifier(false);
      }
    }
  }, [syncStatusData, fetchingSyncStatus, stopPolling, showSuccessNotifier]);

  useEffect(() => {
    // for unlink complete toast
    let isAllUnlinked = false;
    if (!fetchingSyncStatus && showUnlinkNotifier) {
      if (!isEmpty(syncStatusData)) {
        isAllUnlinked = syncStatusData?.reduce(
          (isCompleted, data) =>
            isCompleted &&
            ![SyncRequestStatus.UNLINKING, SyncRequestStatus.UNLINKED].includes(
              data.status
            ),
          true
        );
      }
      if (isEmpty(linkedExchanges)) {
        isAllUnlinked = true;
      }
      if (isAllUnlinked) {
        triggerNotifier(
          translate('notifierMessage.allExchangesUnlinkedSuccess'),
          NotifierTypes.DELETE
        );
        setShowUnlinkNotifier(false);
      }
    }
  }, [
    syncStatusData,
    fetchingSyncStatus,
    stopPolling,
    showUnlinkNotifier,
    linkedExchanges
  ]);

  const navigateToConnectExchange = () => {
    navigate(RoutesPath.CONNECT_EXCHANGE);
  };

  const handleSyncTransactionClick = async ({
    syncAll = false,
    exchangeId
  }: HandleSyncTransactionArgs) => {
    let payload = {
      exchange_ids: [exchangeId]
    };
    if (syncAll) {
      const exchangeIds = linkedExchanges.map(
        linkedExchange => linkedExchange.exchange_id
      );
      payload = { ...payload, exchange_ids: exchangeIds };
      setNewlyAddedExchangeData(null);
    }
    await syncTransactions({ payload, clientId });
    syncAll && setIsSyncAllClicked(true);
  };

  const handleEditExchange = async editPayload => {
    if (isEditing) await editExchange(editPayload);
  };

  const triggerNotifier = (message: string, type: NotifierTypes) => {
    dispatch(showNotifier({ message, type }));
  };

  const exchangeAlreadyInList = linkedExchanges?.some(
    exchange => exchange?.code === newlyAddedExchangeData?.code
  );

  const getActionItems = (exchange: LinkedExchange) => {
    const selectedExchange = exchangesList?.find(
      exchangeOptions => exchangeOptions.id === exchange.exchange_id
    );

    const showEditButton = !selectedExchange?.is_active_by_oauth;
    //  need to hide the edit icon for oAuth via connected exchange
    return [
      ...(showEditButton
        ? [
            {
              Icon: EditIcon,
              label: translate('edit'),
              onClick: () => {
                setIsEditing(true);
                gAEventTracker(
                  eventKeyMapper.editExchangeButton,
                  `${exchange.name}:`
                );
                setEditExchangeData(
                  exchangesList.find(exc => exc.id === exchange.exchange_id)
                );
                setShowConnectExchangePopup(true);
              }
            }
          ]
        : []),
      {
        Icon: DeleteIcon,
        label: translate('delete'),
        onClick: () => {
          handleDeleteExchangeClick({ exchangeDetails: exchange });
        }
      }
    ];
  };

  const findSyncStatus = (id: string) => {
    if (editingExchangeId === id && editExchangeResponse.isLoading) {
      return SyncRequestStatus.LINKING;
    }
    return syncStatusData?.find(ele => ele?.exchange_id === id)?.status;
  };

  const findLastSync = (id: string) => {
    return syncStatusData?.find(ele => ele?.exchange_id === id)?.last_sync_time;
  };

  const handleSync = async (exchangeId: string, exchangeName: string) => {
    gAEventTracker(eventKeyMapper.individualExchangeSync, `${exchangeName}:`);
    await handleSyncTransactionClick({ exchangeId });
    setStopPolling(false);
  };

  const debounceSearch = useCallback(
    debounce(value => {
      setDebounceSearchTerm(value);
    }, 300),
    [debounce]
  );

  const handleSearchTextChange = useCallback(
    value => {
      if (value) {
        debounceSearch(value);
        setSearchText(value);
      } else {
        setSearchText('');
        debounceSearch('');
      }
      if (newlyAddedExchangeData) {
        setNewlyAddedExchangeData(null);
      }
    },
    [debounceSearch, newlyAddedExchangeData]
  );

  const handleTryAgain = (isByAuth = false, exchangeId: string) => {
    setIsEditing(false);
    if (isByAuth) {
      const selectedExchange = exchangesList?.find(
        exchange => exchange.id === exchangeId
      );
      gAEventTracker(
        eventKeyMapper.oauthReconnectInExchangeListing,
        `${selectedExchange.name}:`
      );
      setEditExchangeData(selectedExchange);
    } else {
      setEditExchangeData(newlyAddedExchangeData);
    }
    setShowConnectExchangePopup(true);
  };

  //TODO: Need to check if its needed or not
  const handleEditConnectedExchange = async data => {
    setEditingExchangeId(data.exchangeId);
    await handleEditExchange(data);
    setShowConnectExchangePopup(false);
    setStopPolling(false);
  };

  const handleDeleteExchangeClick = (selectedItemData: DeleteExchangeArgs) => {
    setSelectedExchangeItem(selectedItemData);
    setIsExchangePopupOpen(true);
  };

  const handleDeleteExchangeConfirm = () => {
    gAEventTracker(
      eventKeyMapper.deleteExchangeButton,
      `${selectedExchangeItem.exchangeDetails?.name}:`
    );
    disconnectExchange({
      exchangeId: selectedExchangeItem.exchangeDetails?.exchange_id,
      clientId
    });
    // clears the state when the current deleting exchange is the newly added one
    if (
      selectedExchangeItem.exchangeDetails?.exchange_id ===
      newlyAddedExchangeData?.id
    ) {
      setNewlyAddedExchangeData(null);
    }
    handleDeleteExchangeClose();
  };

  const handleDeleteExchangeClose = () => {
    setTimeout(() => {
      setSelectedExchangeItem({ exchangeDetails: null });
    }, 700);
    setIsExchangePopupOpen(false);
  };

  const getSyncButtonDisabledStatus = (id: string) => {
    const status = syncStatusData?.find(ele => ele?.exchange_id === id)?.status;
    // need to disable sync button for authexpired exchanges
    if (status === SyncRequestStatus.AUTH_EXPIRED) {
      return true;
    }
    const isStillNotCompleted = ![
      SyncRequestStatus.COMPLETED,
      SyncRequestStatus.DEAD
    ].includes(status);
    return !stopPolling && isStillNotCompleted;
  };

  const handleSyncAllButtonClicked = () => {
    gAEventTracker(eventKeyMapper.syncAllExchangeInSources);
    handleSyncTransactionClick({ syncAll: true });
  };

  return (
    <>
      {isLoadingUserExchanges ? (
        <SourcesSkeleton rowCount={3} />
      ) : (
        <>
          {linkedExchanges?.length > 0 ||
          debounceSearchTerm ||
          (!exchangeAlreadyInList && newlyAddedExchangeData) ? (
            <>
              <div className="flex justify-between p-[14px] pb-0 sm:px-6 sm:pt-6">
                <div className="w-[85%] sm:w-[40%]">
                  <SearchBar
                    searchValue={searchText}
                    handleChange={value => handleSearchTextChange(value)}
                    wrapperStyle="bg-white border-white"
                    searchInputStyle="bg-white py-2"
                  />
                </div>
                <If condition={linkedExchanges?.length > 0}>
                  <Button
                    onClick={handleSyncAllButtonClicked}
                    className="flex items-center py-[9px] text-blackGreen
                     bg-white border border-primaryColor disabled:cursor-not-allowed sm:px-5"
                    disabled={
                      syncTransactionResponse.isLoading ||
                      isEmpty(linkedExchanges) ||
                      isPolling ||
                      (userType === USER_ROLE.PROFESSIONAL && !isManaged)
                    }
                    Icon={RefreshIconBlack}
                    iconClass={`${isPolling ? 'animate-rotate' : ''}`}
                    label={translate('sourcePage.syncAll')}
                  />
                </If>
              </div>
              {match ? (
                <div className="overflow-x-auto px-6 pt-2 w-full h-[calc(100%-210px)] customNormalScroll">
                  {linkedExchanges?.length > 0 ? (
                    <table className="pb-[155px] w-full table-auto">
                      <tbody>
                        {!exchangeAlreadyInList && newlyAddedExchangeData && (
                          <ExchangeListViewRow
                            key={newlyAddedExchangeData.id}
                            ExchangeIcon={
                              EXCHANGE_INFO[newlyAddedExchangeData.code]?.icon
                            }
                            exchangeSyncStatus={SyncRequestStatus.LINKING}
                            lastSync={null}
                            actionItems={ACTION_ITEMS}
                            handleTryAgain={handleTryAgain}
                          />
                        )}
                        {linkedExchanges?.map(exchange => (
                          <ExchangeListViewRow
                            key={exchange.code}
                            ExchangeIcon={EXCHANGE_INFO[exchange.code]?.icon}
                            exchangeId={exchange.exchange_id}
                            exchangeSyncStatus={findSyncStatus(
                              exchange.exchange_id
                            )}
                            lastSync={
                              findLastSync(exchange.exchange_id) ||
                              exchange.last_sync_at
                            }
                            handleSyncClick={() =>
                              handleSync(exchange.exchange_id, exchange.name)
                            }
                            // stopPoling=false and isStillSyncing(exchange?.exchange_id))= true means sync
                            // for that exchange is performing, so need to disable individual sync button
                            isSyncDisabled={
                              syncTransactionResponse.isLoading ||
                              getSyncButtonDisabledStatus(exchange.exchange_id)
                            }
                            actionItems={getActionItems(exchange)}
                            handleTryAgain={handleTryAgain}
                            isActionsDisabled={
                              userType === USER_ROLE.PROFESSIONAL && !isManaged
                            }
                          />
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="flex w-full">
                      <EmptyData
                        styleConfig={{
                          height: isMobileView
                            ? `calc(100vh - ${topContentHeight}px - 80px)`
                            : `calc(100vh - ${topContentHeight}px - 94px)`,
                          wrapperStyle: 'mt-1 min-h-[340px] tall:min-h-[400px]',
                          imageStyle:
                            'w-[50%] sm:w-[250px] md:w-[300px] lg:w-[350px]',
                          secondaryDescriptionStyle: 'max-w-lg text-black'
                        }}
                        contentConfig={{
                          title: translate('emptyResult.title'),
                          highlightedImage: NoSearchResult,
                          showPrimaryDescription: false,
                          showSecondaryDescription: true,
                          secondaryDescription: translate('emptyResult.message')
                        }}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div className="p-[14px] w-full sm:px-6">
                  {linkedExchanges?.length > 0 ||
                  (!exchangeAlreadyInList && newlyAddedExchangeData) ? (
                    <>
                      {!exchangeAlreadyInList && newlyAddedExchangeData && (
                        <ListViewMobileRow
                          ExchangeIcon={
                            EXCHANGE_INFO[newlyAddedExchangeData.code]?.icon
                          }
                          exchangeSyncStatus={SyncRequestStatus.LINKING}
                          lastSync={null}
                          actionItems={ACTION_ITEMS}
                          handleTryAgain={handleTryAgain}
                        />
                      )}
                      {linkedExchanges?.map(exchange => (
                        <ListViewMobileRow
                          key={exchange.code}
                          ExchangeIcon={EXCHANGE_INFO[exchange.code].icon}
                          exchangeId={exchange.exchange_id}
                          exchangeSyncStatus={findSyncStatus(
                            exchange.exchange_id
                          )}
                          lastSync={
                            findLastSync(exchange.exchange_id) ||
                            exchange.last_sync_at
                          }
                          handleSyncClick={() =>
                            handleSync(exchange.exchange_id, exchange.name)
                          }
                          // stopPoling=false and isStillSyncing(exchange?.exchange_id))= true means sync for
                          // that exchange is performing, so need to disable individual sync button
                          isSyncDisabled={
                            syncTransactionResponse.isLoading ||
                            getSyncButtonDisabledStatus(exchange.exchange_id)
                          }
                          actionItems={getActionItems(exchange)}
                          handleTryAgain={handleTryAgain}
                          isActionsDisabled={
                            userType === USER_ROLE.PROFESSIONAL && !isManaged
                          }
                        />
                      ))}
                    </>
                  ) : (
                    <div className="flex p-0">
                      <EmptyData
                        styleConfig={{
                          height: isMobileView
                            ? `calc(100vh - ${topContentHeight}px - 80px)`
                            : `calc(100vh - ${topContentHeight}px - 94px)`,
                          wrapperStyle:
                            'm-0 w-full min-h-[340px] tall:min-h-[400px]',
                          imageStyle: 'w-[50%] sm:w-[250px]',
                          titleStyle: 'text-sm font-semibold mt-2 sm:text-2xl',
                          secondaryDescriptionStyle:
                            'max-w-lg text-black text-[10px] sm:text-sm w-[80%] leading-normal'
                        }}
                        contentConfig={{
                          title: translate('emptyResult.title'),
                          highlightedImage: NoSearchResult,
                          showPrimaryDescription: false,
                          showSecondaryDescription: true,
                          secondaryDescription: translate('emptyResult.message')
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
              <ConfirmationPopup
                visibility={isExchangePopupOpen}
                title={translate('popup.confirmDeletion')}
                onCloseButtonClick={handleDeleteExchangeClose}
                buttonOneText={translate('popup.noKeepIt')}
                buttonTwoText={translate('popup.yesDeleteIt')}
                message={translate('popup.deleteExchangeMessage', {
                  exchangeName: selectedExchangeItem.exchangeDetails?.name
                })}
                onButtonOneClick={handleDeleteExchangeClose}
                onButtonTwoClick={handleDeleteExchangeConfirm}
                disabled={disconnectExchangeResponse.isLoading}
              />
            </>
          ) : (
            <div className="flex w-full">
              <EmptyData
                styleConfig={{
                  height: isMobileView
                    ? `calc(100vh - ${topContentHeight}px - 32px)`
                    : `calc(100vh - ${topContentHeight}px - 64px)`,
                  wrapperStyle:
                    'mx-4 mt-4 w-full m-0 sm:mb-8 sm:mx-8 sm:mt-8 min-h-[400px]',
                  imageStyle: 'w-[50%] sm:w-[250px] md:w-[300px] lg:w-[350px]',
                  secondaryDescriptionStyle:
                    'max-w-lg text-black text-[10px] sm:text-sm w-[80%] leading-normal',
                  titleStyle: 'text-sm font-semibold mt-2 sm:text-2xl'
                }}
                contentConfig={{
                  title: translate('sourcePage.noConnectionsTitle'),
                  highlightedImage: NoConnections,
                  showPrimaryDescription: false,
                  showSecondaryDescription: true,
                  showPrimaryButton: true,
                  secondaryDescription: translate(
                    'sourcePage.noConnectionsMessage'
                  ),
                  primaryButtonConfig: {
                    icon: AttachmentPinIcon,
                    buttonHandler: navigateToConnectExchange,
                    showLabelInSmallScreen: true,
                    buttonLabel: translate('transactionPage.connectExchange')
                  }
                }}
              />
            </div>
          )}
        </>
      )}
      <ConnectExchangePopup
        showModal={showConnectExchangePopup}
        exchangeData={editExchangeData}
        exchangeLinkingDetails={exchangeLinkingDetails}
        handleAddExchange={handleEditConnectedExchange}
        handleClose={() => setShowConnectExchangePopup(false)}
        isSubmittingAddExchange={editExchangeResponse?.isLoading}
        handleNewConnection={() => {}}
        isEdit={isEditing}
        clientId={clientId}
      />
    </>
  );
};

export default ExchangeListView;
