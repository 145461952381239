import React, { FC } from 'react';

import {
  CircularSkeleton,
  TextSkeleton,
  RectangularSkeleton
} from '@components';
import { SourceSkeletonProps } from './types';

const SourcesSkeleton: FC<SourceSkeletonProps> = props => {
  const {
    showSyncSkeleton = true,
    rowCount = 3,
    actionButtonCount = 2
  } = props;
  return (
    <div className="m-[14px] sm:mx-6 sm:mt-6">
      <div className="flex flex-row justify-between">
        <div
          className="p-1 pl-3 mt-[2px] w-[80%] min-w-[120px] h-[38px]
         bg-white rounded-md border-[1px] border-white sm:w-[40%]">
          <TextSkeleton width={60} fontSize="16px" />
        </div>
        {showSyncSkeleton && (
          <div
            className="flex flex-row gap-2 items-center 
        px-5 bg-white rounded-md">
            <CircularSkeleton width={23} height={23} />
            <div className="hidden mui-md:flex">
              <TextSkeleton width={50} fontSize="17px" />
            </div>
          </div>
        )}
      </div>

      <div className="mt-[16px] w-full">
        {[...Array(rowCount)].map((item, index) => (
          <div
            key={index}
            className="py-[2px] mr-4 mb-2 w-full bg-white rounded-md">
            <div className="flex flex-wrap mui-md:flex-row mui-md:flex-nowrap">
              <div className="py-[14px] px-5 w-[75%] min-w-[130px] mui-md:w-[22%]">
                <RectangularSkeleton width={100} height={20} />
              </div>
              <div className="flex flex-row gap-2 py-[14px] px-2 mui-md:hidden">
                <CircularSkeleton width={25} height={25} />
                <CircularSkeleton width={25} height={25} />
              </div>
              <div className="py-[14px] px-5 w-[250px] md:w-[78%] lg:w-[70%]">
                <TextSkeleton width={220} fontSize="14px" />
              </div>
              <div className="hidden flex-row gap-2 justify-end py-[14px] pr-6 w-[10%] mui-md:flex">
                {[...Array(actionButtonCount)].map(
                  (actionButtonCountItem, actionButtonCountIndex) => (
                    <CircularSkeleton
                      key={actionButtonCountIndex}
                      width={25}
                      height={25}
                    />
                  )
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SourcesSkeleton;
