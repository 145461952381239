/* eslint-disable no-unused-vars */
import React from 'react';

import { getPastDateByReducingMonth } from '@utils/generalUtils';
import {
  BinanceIcon,
  BinanceSmallIcon,
  CoindcxIcon,
  CoinDcxSmallIcon,
  WazirxIcon,
  WazirxSmallIcon,
  CoinSwitchIcon,
  BitBnsIcon,
  BitBnsSmallIcon,
  CoinSwitchKuberSmallIcon,
  ZebPayIcon,
  ZebPaySmallIcon,
  KuCoinLogo,
  KuCoinSmallLogo,
  FtxIcon,
  FtxSmallIcon,
  GateIoIcon,
  GateIoSmallIcon,
  HuobiIcon,
  HuobiSmallIcon,
  SuncryptoIcon,
  SuncryptoSmallIcon,
  GiottusIcon,
  GiottusSmallIcon
} from '@assets/icons';

export const GENERIC = 'GENERIC';

const csvTemplateDownloadURL = process.env.REACT_APP_CSV_TEMPLATE_URL;

export const EXCHANGE_INFO = {
  BIN: {
    label: {
      api_key: `The unique ID (a random series of letters and numbers) provided when
      you create a new API within your exchange account`,
      api_secret: `A unique ID in a similar format to key, which acts as a password for the API Key
      you've assigned to TaxCryp and typically provided to you at the end of the API
      creation process within your exchange.`
    },
    name: 'Binance',
    icon: BinanceIcon,
    smallIcon: BinanceSmallIcon,
    instructions: [
      {
        step: `Log in to your Binance account and click <span class="font-bold">
        [Account] <span class="inline-block">-></span> [API Management]</span>`
      },
      { step: 'Click <span class="font-bold">[Create Tax Report API]</span>' },
      {
        step: 'You will receive your unique API and Secret Key for Tax Report. You can copy the keys'
      },
      {
        step: 'Enter the API and secret key here and import the transactions from your binanace account'
      }
    ],
    fileInstructions: [
      {
        step: 'Login to your account and navigate to the "Wallet" dropdown menu'
      },
      {
        step: `<div class="inline-block"> From the dropdown menu, select 
        <a class="text-blue inline" target="_blank" href="https://www.binance.com/en">[Transaction History]</a>`
      },
      { step: 'On the top-right corner click on "Generate all statements"' },
      {
        step: 'Under time, select customized and enter your date range (maximum 12 months)'
      },
      { step: 'Click on "Generate"' },
      { step: 'Repeat until you have generated all your transactions' },
      {
        step: `After downloading the files, you will have a "tar.gz" file downloaded. Extract the "tar.gz" file 
              to get the "CSV" file`
      },
      {
        step: 'Upload all the CSV files here to upload your Binance transactions with TaxCryp'
      }
    ],
    notes: [
      {
        note: `<div class="inline-block whitespace-normal">Not clear ? Click on the
          <a class="text-blue inline" target="_blank"
          href="https://www.binance.com/en/support/faq/538e05e2fd394c489b4cf89e92c55f70">
          link</a> to view details/ screenshots of each step .</div>`,
        hideIcon: true
      },
      {
        note: 'NOTE: Currently, we are only handling transactions related to spot trades.'
      }
    ],
    fileNotes: [],
    isOutOfOrder: false
  },
  WRX: {
    label: {
      api_key: `The unique ID (a random series of letters and numbers) provided when
      you create a new API within your exchange account`,
      api_secret: `A unique ID in a similar format to key, which acts as a password for the API Key
      you've assigned to TaxCryp and typically provided to you at the end of the API
      creation process within your exchange.`
    },
    name: 'WazirX',
    icon: WazirxIcon,
    smallIcon: WazirxSmallIcon,
    instructions: [
      {
        step: `<div class="inline-block">Open the Wazirx
          <a class="text-blue inline" target="_blank" href="https://wazirx.com/">
          <div class="inline-flex items-center">
           &nbsp;API page&nbsp; <img src="/assets/icons/go-to-link.svg" alt="" class="h-3 w-3 ml-1" />
          </div></a></div>`
      },
      {
        step: `Create a new API key by entering a label, such as 'CoinTracker',
           and clicking the Create New Key button`
      },
      { step: 'If applicable, enter your two-factor authentication code' },
      { step: 'Copy the API Key and Secret' },
      {
        step: 'RECOMMENDED Disable trading access for this API key:',
        subStep: [
          'Click the Edit button',
          'Disable the Enable Trading permission',
          'Click Save',
          'If applicable, enter your two-factor authentication code'
        ]
      }
    ],
    fileInstructions: [
      {
        step: `<div class="inline-block">Login to your account and navigate to the
          <a class="text-blue inline" target="_blank" href="https://wazirx.com/settings/reports">
          <div class="inline-flex items-center">
           &nbsp;download reports page&nbsp; <img src="/assets/icons/go-to-link.svg" alt="" class="h-3 w-3 ml-1" />
          </div></a></div>`
      },
      {
        step: `Choose 'trading report' and check all the 
        checkboxes`
      },
      {
        step: `Wazirx only lets you download the data for 12 months at a time. So you have to download all
      your historic transaction data as multiple files`
      },
      {
        step: 'Upload the transactions files here to import all your transactions'
      }
    ],
    notes: [],
    fileNotes: [
      {
        note: 'Please upload the complete list of transactions carried out in the exchange.'
      }
    ],
    isOutOfOrder: false
  },
  DCX: {
    label: {
      api_key: `The unique ID (a random series of letters and numbers) provided when
      you create a new API within your exchange account`,
      api_secret: `A unique ID in a similar format to key, which acts as a password for the API Key
      you've assigned to TaxCryp and typically provided to you at the end of the API
      creation process within your exchange.`
    },
    name: 'CoinDCX',
    icon: CoindcxIcon,
    smallIcon: CoinDcxSmallIcon,
    instructions: [
      {
        step: `<div class="inline-block">Login to your account and navigate to the CoinDCX
          <a class="text-blue inline" target="_blank" href="https://coindcx.com/api-dashboard">
          <div class="inline-flex items-center">
           &nbsp;API dashboard&nbsp; <img src="/assets/icons/go-to-link.svg" alt="" class="h-3 w-3 ml-1" />
          </div></a></div>`
      },
      {
        step: `Create your API Key by clicking on the 'create a new one'
         button`
      },
      { step: 'Continue with the authentication by providing the OTP' },
      { step: 'Copy your API credentials over to your taxcryp account' }
    ],
    fileInstructions: [
      {
        step: `<div class="inline-block">Login to your account and navigate to the
          <a class="text-blue inline" target="_blank" href="https://coindcx.com/orders">
          <div class="inline-flex items-center">
           &nbsp;order history page&nbsp; <img src="/assets/icons/go-to-link.svg" alt="" class="h-3 w-3 ml-1" />
          </div></a></div>`
      },
      {
        step: `Choose 'Download CSV' to export your
         transactions`
      },
      {
        step: 'Upload the transactions files here to import all your transactions'
      }
    ],
    notes: [],
    fileNotes: [
      {
        note: 'Please upload the complete list of transactions carried out in the exchange.'
      },
      {
        note: `Please upload all the below files from CoinDCX
      a.Spot trades
      b.Insta trades
      c.Transfers`
      }
    ],
    isOutOfOrder: false
  },
  CSK: {
    label: {
      api_key: `The unique ID (a random series of letters and numbers) provided when
      you create a new API within your exchange account`,
      api_secret: `A unique ID in a similar format to key, which acts as a password for the API Key
      you've assigned to TaxCryp and typically provided to you at the end of the API
      creation process within your exchange.`
    },
    name: 'CoinSwitch',
    icon: CoinSwitchIcon,
    smallIcon: CoinSwitchKuberSmallIcon,
    instructions: [
      {
        step: `<div class="inline-block">Login to your account and navigate to the CoinDCX
          <a class="text-blue inline" target="_blank" href="https://coindcx.com/api-dashboard">
          <div class="inline-flex items-center">
           &nbsp;API dashboard&nbsp; <img src="/assets/icons/go-to-link.svg" alt="" class="h-3 w-3 ml-1" />
          </div></a></div>`
      },
      {
        step: `Create your API Key by clicking on the 'create a new one'
         button`
      },
      { step: 'Continue with the authentication by providing the OTP' },
      { step: 'Copy your API credentials over to your taxcryp account' }
    ],
    fileInstructions: [
      {
        step: 'Login to your account in the CoinSwitch App and navigate to the profile tab'
      },
      {
        step: `Choose 'history' and click the download statement
         button`
      },
      {
        step: `Choose 'custom date range' and make sure to download 
         all your transactions.`
      },
      {
        step: 'Upload the transactions files here to import all your transactions'
      }
    ],
    notes: [],
    fileNotes: [
      {
        note: 'Please upload the complete list of transactions carried out in the exchange.'
      }
    ],
    isOutOfOrder: false
  },
  BNS: {
    label: {
      api_key: `The unique ID (a random series of letters and numbers) provided when
      you create a new API within your exchange account`,
      api_secret: `A unique ID in a similar format to key, which acts as a password for the API Key
      you've assigned to TaxCryp and typically provided to you at the end of the API
      creation process within your exchange.`
    },
    name: 'Bitbns',
    icon: BitBnsIcon,
    smallIcon: BitBnsSmallIcon,
    instructions: [
      {
        step: 'Click on Connect exchange button. We will redirect you to Bitbns Login'
      },
      {
        step: 'Sign In to your Bitbns account by providing the required credentials'
      },
      {
        step: `Authorise Taxcryp to pull the transactions from Bitbns  by Clicking  on 'Allow'  
      in the permission pop up that appears after you login`
      }
    ],
    fileInstructions: [
      {
        step: `<div class="inline-block">Login to your account and navigate to the CoinDCX
          <a class="text-blue inline" target="_blank" href="https://coindcx.com/api-dashboard">
          <div class="inline-flex items-center">
           &nbsp;API dashboard&nbsp; <img src="/assets/icons/go-to-link.svg" alt="" class="h-3 w-3 ml-1" />
          </div></a></div>`
      },
      {
        step: `Create your CSV Key by clicking on the 'create a new one'
         button`
      },
      { step: 'Continue with the authentication by providing the OTP' },
      { step: 'Copy your API credentials over to your taxcryp account' }
    ],
    notes: [],
    fileNotes: [],
    isOutOfOrder: false
  },
  ZEB: {
    label: {
      api_key: `The unique ID (a random series of letters and numbers) provided when
      you create a new API within your exchange account`,
      api_secret: `A unique ID in a similar format to key, which acts as a password for the API Key
      you've assigned to TaxCryp and typically provided to you at the end of the API
      creation process within your exchange.`
    },
    name: 'Zebpay',
    icon: ZebPayIcon,
    smallIcon: ZebPaySmallIcon,
    instructions: [
      {
        step: `<div class="inline-block">Login to your account and navigate to the CoinDCX
          <a class="text-blue inline" target="_blank" href="https://coindcx.com/api-dashboard">
          <div class="inline-flex items-center">
           &nbsp;API dashboard&nbsp; <img src="/assets/icons/go-to-link.svg" alt="" class="h-3 w-3 ml-1" />
          </div></a></div>`
      },
      {
        step: `Create your API Key by clicking on the 'create a new one'
         button`
      },
      { step: 'Continue with the authentication by providing the OTP' },
      { step: 'Copy your API credentials over to your taxcryp account' }
    ],
    fileInstructions: [
      {
        step: `<div class="inline-block">Login to your Zebpay account and click on the "
        <img src="/assets/icons/hamburger_zebpay.svg" alt="" class="inline h-3 w-3 ml-1" />
         " icon </div>`
      },
      {
        step: 'Select the option "Statement" - Import  the following CSVs',
        subStep: ['Trade CSV', 'Fiat CSV', 'Crypto Wallet CSV']
      },
      {
        step: 'Upload the transactions files here to import all your transactions'
      }
    ],
    notes: [],
    fileNotes: [
      {
        note: 'Please upload the complete list of transactions carried out in the exchange.'
      }
    ],
    isOutOfOrder: false
  },
  KCN: {
    label: {
      api_key: `The unique ID (a random series of letters and numbers) provided when
      you create a new API within your exchange account`,
      api_secret: `A unique ID in a similar format to key, which acts as a password for the API Key
      you've assigned to TaxCryp and typically provided to you at the end of the API
      creation process within your exchange.`,
      api_pass_phrase: `For Additional security, KuCoin needs you to create a Passphrase for all the 
      interface calls using the API. Please provide the same to connect your account.`
    },
    name: 'KuCoin',
    icon: KuCoinLogo,
    smallIcon: KuCoinSmallLogo,
    instructions: [
      {
        step: `Log in to your KuCoin Account and click <span class="font-bold">
        [User Profile] <span class="inline-block">-></span>
        <a class="text-blue inline" target="_blank" href="https://www.kucoin.com/account/api">
         [API Management] </a></span>`
      },
      {
        step: 'Under "Spot/Margin API" Click on <span class="font-bold">[Create API]</span>'
      },
      {
        step: 'Provide an <span class="font-bold">API Name and Passphrase</span> of your choice'
      },
      {
        step: `For additional security, ensure the <span class="font-bold">Trade</span>
         and <span class="font-bold">Withdraw</span> permissions are disabled for this API key`
      },
      {
        step: `Select <span class="font-bold">No</span> for IP restrictions, and then click
        <span class="font-bold">Next</span>`
      },
      {
        step: 'Enter your Trading Password, Email Verification Code and 2FA code'
      },
      {
        step: 'Click Activate API in the email you receive API key and secret in your KuCoin account'
      },
      {
        step: 'Provide the <span class="font-bold">API Key, API Secret and passphrase</span> to TaxCryp'
      }
    ],
    fileInstructions: [
      {
        step: 'Login to your KuCoin account and Navigate to the following pages',
        subStep: [
          `Trade History - [Orders]->[Spot Trade History]->
          <a class="text-blue inline" target="_blank" href="https://www.kucoin.com/order/trade/detail">
          [Trade History]</a> -> Export CSV`,
          `Deposit and withdrawal history- [Assets] -> [Asset Overview] -> 
          <a class="text-blue inline" target="_blank" href="https://www.kucoin.com/assets/record">
          [Deposit & Withdrawal History]</a> -> Export CSV`
        ]
      },
      {
        step: `KuCoin only allows for 100 days to be exported at a time,
        so you may need to download multiple CSV files.`
      },
      {
        step: `To download the files to your system, navigate to 
        <a class="text-blue inline" target="_blank" href="https://www.kucoin.com/account/download">
        [Download CSV]</a>`
      },
      {
        step: 'Upload all the CSV files here to connect your Kucoin account with Taxcryp'
      }
    ],
    notes: [
      {
        note: `<div class="inline-block whitespace-normal">
        Please note that Kucoin API will only allow to pull transactions from past 9 months .
        Make sure to upload the CSV if you have performed any transactions before
        <span class="font-bold">${getPastDateByReducingMonth(
          9
        )}</span> in Kucoin.`
      }
    ],
    fileNotes: [
      //   {note: `<div class="inline-block whitespace-normal">
      //   Please make sure that CSV only contains transactions upto  <span class="font-bold">
      //   ${getPastDateByReducingMonth(9)}</span>.
      //   Connect API for Syncing any transactions made post  <span class="font-bold">${getPastDateByReducingMonth(
      //     9
      //   )}</span>
      //    and to sync any future transaction.
      //   </div>`
      // }
    ],
    isOutOfOrder: false
  },
  FTX: {
    label: {
      api_key: `The unique ID (a random series of letters and numbers) provided when
      you create a new API within your exchange account`,
      api_secret: `A unique ID in a similar format to key, which acts as a password for the API Key
      you've assigned to TaxCryp and typically provided to you at the end of the API
      creation process within your exchange.`
    },
    name: 'FTX',
    icon: FtxIcon,
    smallIcon: FtxSmallIcon,
    instructions: [
      {
        step: `Log in to your FTX Account and click <span class="font-bold">
        [User Profile] <span class="inline-block">-></span>
        <a class="text-blue inline" target="_blank" href="https://ftx.com/settings/api">
         [API]</a></span>`
      },
      {
        step: 'Under API Click on <span class="font-bold">[Create Read-only API Key]</span>'
      },
      {
        step: 'Do a Two - factor authentication to view API Key  and API Secret'
      },
      {
        step: `Copy both  <span class="font-bold">API Key</span> and  <span class="font-bold">API Secret</span>
         and paste the same here and connect your FTX account to TaxCryp`
      }
    ],
    fileInstructions: [],
    notes: [],
    fileNotes: [],
    isOutOfOrder: true
  },
  GTE: {
    label: {
      api_key: `The unique ID (a random series of letters and numbers) provided when
      you create a new API within your exchange account`,
      api_secret: `A unique ID in a similar format to key, which acts as a password for the API Key
      you've assigned to TaxCryp and typically provided to you at the end of the API
      creation process within your exchange.`
    },
    name: 'Gate IO',
    icon: GateIoIcon,
    smallIcon: GateIoSmallIcon,
    instructions: [
      {
        step: `Log in to your gate.io  account and click <span class="font-bold">
        [Profile Icon] <span class="inline-block">-></span> [API Management]</span>`
      },
      { step: 'Click <span class="font-bold">[Create API Key]</span>' },
      {
        step: 'Provide an API key remark eg: API for Taxcryp'
      },
      {
        step: 'Set',
        subStep: [
          'IP permission as "Later"',
          'API Key Type  as APIV4 key',
          'Account type as  Classic Account'
        ]
      },
      {
        step: 'Enable permissions for',
        subStep: ['Read only  for Spot / Margin trade', 'Read only  for wallet']
      },
      {
        step: 'Press on Submit button, Enter your password and verification code to receive the API key and Secret.'
      },
      {
        step: 'Provide the <span class="font-bold">API Key, API Secret</span> to TaxCryp'
      }
    ],
    fileInstructions: [
      {
        step: `<div class="inline-block">Open the Binance
          <a class="text-blue inline" target="_blank" href="https://www.binance.com/en">
          <div class="inline-flex items-center">
           &nbsp;API page&nbsp; <img src="/assets/icons/go-to-link.svg" alt="" class="h-3 w-3 ml-1" />
          </div></a></div>`
      },
      {
        step: `Create a new API key by entering a label, such as 'CoinTracker',
           and clicking the Create New Key button`
      },
      { step: 'If applicable, enter your two-factor authentication code' },
      { step: 'Copy the API Key and Secret' },
      {
        step: 'RECOMMENDED Disable trading access for this API key:',
        subStep: [
          'Click the Edit button',
          'Disable the Enable Trading permission',
          'Click Save',
          'If applicable, enter your two-factor authentication code'
        ]
      }
    ],
    notes: [],
    fileNotes: [],
    isOutOfOrder: false
  },
  HBI: {
    label: {
      api_key: `The unique ID (a random series of letters and numbers) provided when
      you create a new API within your exchange account`,
      api_secret: `A unique ID in a similar format to key, which acts as a password for the API Key
      you've assigned to TaxCryp and typically provided to you at the end of the API
      creation process within your exchange.`
    },
    name: 'Huobi',
    icon: HuobiIcon,
    smallIcon: HuobiSmallIcon,
    instructions: [
      {
        step: `Log in to your Huobi  account and click <span class="font-bold">
        [Profile Icon] <span class="inline-block">-></span> [API Management]</span>`
      },
      { step: 'Provide a note eg: API for TaxCryp' },
      {
        step: 'Make sure that the permission for the API is set as "Read Only"'
      },
      { step: 'Click on "Create"' },
      {
        step: 'Provide the <span class="font-bold">API Key, API Secret</span> to TaxCryp'
      }
    ],
    fileInstructions: [
      {
        step: `Log in to your Huobi  account and click <span class="font-bold">
        [Assets] <span class="inline-block">-></span> [Spots]</span>`
      },
      { step: 'Click on the <span class="font-bold">[History]</span>' },
      {
        step: `Click on the <span class="font-bold">[Click here]</span>
         button present in the footer to get historic transactions`
      },
      {
        step: 'Submit the form requesting to get  the excel with historic data.'
      },
      { step: 'Upload the file received here to connect to TaxCryp.' }
    ],
    notes: [],
    fileNotes: [],
    isOutOfOrder: false
  },
  SCR: {
    label: {
      api_key: `The unique ID (a random series of letters and numbers) provided when
      you create a new API within your exchange account`,
      api_secret: `A unique ID in a similar format to key, which acts as a password for the API Key
      you've assigned to TaxCryp and typically provided to you at the end of the API
      creation process within your exchange.`
    },
    name: 'SunCrypto',
    icon: SuncryptoIcon,
    smallIcon: SuncryptoSmallIcon,
    instructions: [
      {
        step: `Log in to your SunCrypto account and select <span class="font-bold">[PROFILE]</span>, this will 
        take you to Settings`
      },
      {
        step: 'Under Settings, select <span class="font-bold">[Tax Api Key] -> [GET KEYS]</span>'
      },
      {
        step: 'Then enter the required OTPs for your registered email and phone'
      },
      {
        step: 'Once both OTP has been entered, select <span class="font-bold">[GET KEY]</span> again'
      },
      {
        step: 'You will find both your API key and API Secret have been generated for you'
      },
      {
        step: 'Copy the API key and API Secret from the application'
      },
      {
        step: 'Provide the API Key and API Secret to TaxCryp'
      }
    ],
    fileInstructions: [],
    notes: [],
    fileNotes: [],
    isOutOfOrder: false
  },
  GTS: {
    label: {
      api_key: `The unique ID (a random series of letters and numbers) provided when
      you create a new API within your exchange account`,
      api_secret: `A unique ID in a similar format to key, which acts as a password for the API Key
      you've assigned to TaxCryp and typically provided to you at the end of the API
      creation process within your exchange.`
    },
    name: 'Giottus',
    icon: GiottusIcon,
    smallIcon: GiottusSmallIcon,
    instructions: [],
    fileInstructions: [
      {
        step: `<div class="inline-block">Login to your Giottus account and navigate to
        <a class="text-blue inline" target="_blank" href="https://www.giottus.com/dashboard">
        <div class="inline-flex items-center">
         &nbsp;dashboard&nbsp; <img src="/assets/icons/go-to-link.svg" alt="" class="h-3 w-3 ml-1" />
        </div></a></div>`
      },
      {
        step: 'Click on "INR Deposit / Withdraw Details" tab and Select the option "Download"'
      },
      {
        step: 'Click on "Trade History" tab and Select the option "Download"'
      },
      {
        step: 'Click on "Crypto Deposit / Withdraw Details" tab and Select the option "Download"'
      },
      {
        step: 'Choose "Select Duration" and make sure to download all your transactions.'
      },
      {
        step: 'Upload the transactions files here to import all your transactions'
      }
    ],
    notes: [],
    fileNotes: [
      {
        note: 'Please upload the complete list of transactions carried out in the exchange.'
      }
    ],
    isOutOfOrder: false
  },
  [GENERIC]: {
    name: 'generic',
    fileInstructions: [
      {
        step: `<div class="inline-block">Download the Taxcryp formatted CSV file
        <a class="text-blue inline" download
        href=${csvTemplateDownloadURL}>
        <div class="inline-flex items-center">
           &nbsp;here&nbsp; <img src="/assets/icons/go-to-link.svg" alt="" class="h-3 w-3 ml-1" />
          </div></a></div>`
      },
      {
        step: 'Copy your transaction data into the downloaded file'
      },
      { step: 'Save and upload that file to import your transactions' }
    ],
    notes: [],
    fileNotes: [
      {
        note: 'Please upload the complete list of transactions carried out in the exchange.'
      }
    ]
  }
};

export const GENERIC_FILE = {
  id: 'generic',
  code: GENERIC,
  name: 'generic',
  is_active_by_import: true,
  by_import: true,
  file_upload_config: ['generic file']
};
