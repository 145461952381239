import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Pagination } from '@mui/material';
import { styled } from '@mui/material/styles';

import {
  KEYBOARD_KEYS,
  PAGINATOR_ALIGNMENT_BREAKPOINT,
  ROWS_PER_PAGE
} from '@constants/common';
import { getFormattedNumbering, translate } from '@utils';
import { RootState } from '@store/reducers';
import { PaginatorProps } from './types';
import COLORS from '@constants/colors';

const CustomPagination = styled(Pagination)({
  '& button.MuiButtonBase-root': {
    color: COLORS.BLACK_GREEN,
    fontWeight: 500,
    backgroundColor: COLORS.WHITE,
    marginLeft: '3px',
    marginRight: '3px',
    marginBottom: '8px',
    borderRadius: '5px'
  },
  '&& .MuiTouchRipple-child': {
    backgroundColor: COLORS.PRIMARY_COLOR,
    opacity: 0.3,
    color: COLORS.BLACK_GREEN
  },
  '& .MuiPaginationItem-root.Mui-disabled': {
    color: COLORS.GREY_CLOUD,
    backgroundColor: COLORS.WHITE,
    opacity: '100'
  },
  '& .MuiPaginationItem-ellipsis': {
    color: COLORS.GREY_CLOUD,
    fontWeight: '400',
    marginBottom: '6px',
    width: '19px'
  },
  '& .MuiPaginationItem-root.Mui-selected': {
    borderColor: COLORS.PRIMARY_COLOR,
    backgroundColor: COLORS.WHITE,
    color: COLORS.PRIMARY_COLOR
  },
  '& button.MuiButtonBase-root:hover': {
    backgroundColor: COLORS.WHITE
  }
});

const Paginator: FC<PaginatorProps> = props => {
  const {
    totalCount,
    page,
    limit = ROWS_PER_PAGE,
    className,
    handlePageChangeEvent
  } = props;

  const [containerWidth, setContainerWidth] = useState<number>(0);

  const inputRef = useRef(null);
  const containerRef = useRef(null);

  const { isNavOpen } = useSelector(
    (state: RootState) => state.rootReducer.leftNavigation
  );

  useEffect(() => {
    //look for changes to the element's size
    resizeObserver.observe(containerRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const noOfPages = Math.ceil(totalCount / limit);

  const handlePageChange = (event, value: number) => {
    handlePageChangeEvent(value);
  };

  const getPageNumber = (pageNumber: number) => {
    if (pageNumber < 1) return 1;
    if (pageNumber > noOfPages) return noOfPages;
    return pageNumber;
  };

  const handleKeyDown = e => {
    if (e.key === KEYBOARD_KEYS.enter) {
      const pageNumber = getPageNumber(Number(e.target.value));
      handlePageChangeEvent(pageNumber);
      e.preventDefault();
      inputRef.current.value = '';
    }
  };

  const handleElementResized = () => {
    if (
      containerRef.current &&
      containerRef.current.offsetWidth !== containerWidth
    ) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
  };

  const handleKeyPress = e => {
    if (e.code === KEYBOARD_KEYS.minus) e.preventDefault();
  };

  //instantiating the resizeObserver and passing the event handler to the constructor
  const resizeObserver = new ResizeObserver(handleElementResized);

  return (
    <div
      ref={containerRef}
      className={`flex text-sm font-medium pb-16 sm:pb-[70px] ${className}`}>
      {noOfPages > 1 && (
        <>
          <div className="hidden flex-row mt-[6px] text-blackGreen lg:flex lg:visible">
            <span className="mr-1">{translate('total')}</span>
            <span className="mr-1">
              {`${getFormattedNumbering({
                value: String(totalCount),
                hideRupeeSymbol: true
              })}`}
            </span>
            <span>{translate('items')}</span>
          </div>
          <div className="md:ml-4 ">
            <CustomPagination
              variant="outlined"
              shape="rounded"
              count={noOfPages}
              page={page}
              onChange={handlePageChange}
              siblingCount={
                containerWidth < PAGINATOR_ALIGNMENT_BREAKPOINT && isNavOpen
                  ? 0
                  : 1
              }
              size={
                containerWidth < PAGINATOR_ALIGNMENT_BREAKPOINT
                  ? 'small'
                  : 'medium'
              }
            />
          </div>
          <div className="hidden ml-4 text-blackGreen lg:flex lg:visible">
            <span className="flex mt-[6px] mr-2 ">{translate('goTo')}</span>
            <div>
              <input
                ref={inputRef}
                type="number"
                min="0"
                className="w-[50px] h-8 text-center
           rounded-[5px] border border-greyCloud focus:outline-none"
                onKeyPress={handleKeyPress}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Paginator;
