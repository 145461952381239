import React, { FC } from 'react';

import { SearchIcon } from '@assets/icons';
import { SearchBarProps } from './types';

const SearchBar: FC<SearchBarProps> = props => {
  const {
    searchValue,
    handleChange,
    placeholder = 'Search',
    customClass = 'p-2 h-[38px]',
    wrapperStyle = 'bg-[#F3F5F7] border-[#F3F5F7]',
    searchInputStyle = 'bg-[#F3F5F7]',
    dataTestId
  } = props;
  return (
    <div
      className={`flex items-center min-w-[120px] ${customClass}
                  rounded-md border-[1px] ${wrapperStyle}`}>
      <SearchIcon />
      <input
        className={`overflow-hidden pl-2 w-full h-full text-sm font-medium
                    placeholder:text-slateGrey rounded-md outline-none ${searchInputStyle}`}
        type="text"
        data-testid={dataTestId}
        placeholder={placeholder}
        value={searchValue}
        onChange={e => handleChange(e.target.value)}
      />
    </div>
  );
};

export default SearchBar;
