import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import { CircularSkeleton, TextSkeleton } from '@components';

const MyTransactionsSkeleton = () => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  const arrayLength = isMobileView ? 2 : 4;

  return (
    <div className="overflow-hidden pt-[20px] w-full sm:pt-[70px] ">
      <div className="flex overflow-hidden flex-col w-full">
        {[...Array(arrayLength)].map((card, index) => (
          <div
            key={index}
            className="p-2 mx-[14px] mb-[12px] bg-white rounded-md sm:mx-6">
            <div className="flex flex-wrap mui-md:flex-row mui-md:flex-nowrap">
              <div
                className="flex flex-col py-4 px-2 
                          w-[83%] lg:px-5 lg:w-[19%] mui-md:w-[15%]">
                <div className="flex flex-row gap-1">
                  <div className="flex items-center">
                    <CircularSkeleton width={17} height={17} />
                  </div>
                  <TextSkeleton width={60} fontSize="16px" />
                </div>
                <div className="flex flex-row gap-1">
                  <div className="flex items-center">
                    <CircularSkeleton width={18} height={18} />
                  </div>
                  <TextSkeleton width={80} fontSize="12px" />
                </div>
              </div>
              <div className="flex flex-row gap-2 py-5  px-1 mui-md:hidden mui-md:gap-0">
                <CircularSkeleton width={18} height={18} />
                <CircularSkeleton width={18} height={18} />
              </div>
              <div
                className="flex flex-row  gap-8 py-4 px-2 w-[90%] lg:px-5 
              lg:w-[25%] mui-md:gap-2 mui-md:w-[33%]">
                <div className="flex items-center">
                  <CircularSkeleton width={26} height={26} />
                </div>
                <div className="flex flex-col">
                  <TextSkeleton width={70} fontSize="16px" />
                  <TextSkeleton width={110} fontSize="12px" />
                </div>
              </div>
              <div
                className="flex flex-row  gap-8 items-center py-3 px-2 w-[90%]
                lg:px-5 lg:w-[24%] mui-md:gap-2 mui-md:w-[30%]">
                <CircularSkeleton width={26} height={26} />
                <TextSkeleton width={120} fontSize="14px" />
              </div>
              <div className="flex flex-row gap-2 py-4 px-2 sm:flex-col  lg:px-5 lg:w-[13%]">
                <TextSkeleton width={50} fontSize="12px" />
                <TextSkeleton width={90} fontSize="16px" />
              </div>
              <div className="hidden flex-row gap-2 items-center py-5 px-3 lg:flex lg:px-5">
                <CircularSkeleton width={25} height={25} />
                <CircularSkeleton width={25} height={25} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyTransactionsSkeleton;
