import React, { FC, useState, memo } from 'react';
import { uniq } from 'lodash';

import { Button } from '@components';
import { translate } from '@utils';
import {
  checkIsEqualArrays,
  checkIsSequentialNumbers,
  findMissingNumberInSequence,
  generateArray
} from '@utils/generalUtils';
import {
  MONTHS,
  QUARTERS,
  MONTH_FILTER_IDENTIFIERS,
  TDS_DISABLED_MONTHS_FOR_FY_22,
  TDS_DISABLED_QUATER_FOR_FY_22,
  TDS_DISABLED_FY
} from './constants';
import { MonthlyFilterProps } from './types';

const MonthFilter: FC<MonthlyFilterProps> = props => {
  const {
    activeMonthList,
    setActiveMonthList,
    setShowMonthFilter,
    financialYear
  } = props;

  const [selectedMonths, setSelectedMonth] = useState(activeMonthList);

  const handleCancelClick = () => {
    setSelectedMonth([]);
    setShowMonthFilter(false);
  };

  const handleFilterApply = () => {
    setActiveMonthList(selectedMonths);
    setShowMonthFilter(false);
  };

  const handleQuarterClick = quarter => {
    if (
      !(
        financialYear === TDS_DISABLED_FY &&
        checkIsEqualArrays(quarter, TDS_DISABLED_MONTHS_FOR_FY_22)
      )
    ) {
      let activeMonths = uniq([...quarter, ...selectedMonths]).sort(
        (a, b) => a - b
      );
      if (quarter.every(val => selectedMonths.includes(val))) {
        let filteredMonths = activeMonths.filter(item => {
          return quarter.includes(item);
        });
        setSelectedMonth(uniq(filteredMonths));
      } else {
        if (!checkIsSequentialNumbers(activeMonths)) {
          activeMonths = [
            ...activeMonths,
            ...findMissingNumberInSequence(activeMonths)
          ];
        }
        setSelectedMonth(uniq(activeMonths.sort((a, b) => a - b)));
      }
    }
  };

  const handleMonthClick = month => {
    if (
      !(
        financialYear === TDS_DISABLED_FY &&
        TDS_DISABLED_MONTHS_FOR_FY_22.includes(month)
      )
    ) {
      if (selectedMonths.includes(month)) {
        let filteredMonthArray = [];
        if (selectedMonths[0] === month) {
          filteredMonthArray = [month];
        } else {
          filteredMonthArray = selectedMonths?.filter(item => item !== month);
          const start = Math.min(...filteredMonthArray);
          filteredMonthArray = generateArray(start, month + 1, 1);
        }
        setSelectedMonth(uniq(filteredMonthArray));
      } else {
        const minMonth = Math.min(...selectedMonths);
        let activeMonths = uniq([month, ...selectedMonths]).sort(
          (a, b) => a - b
        );
        const isMonthBeforeSelected = month < minMonth;
        if (isMonthBeforeSelected) {
          activeMonths = [month];
        }
        if (!checkIsSequentialNumbers(activeMonths)) {
          activeMonths = [
            ...activeMonths,
            ...findMissingNumberInSequence(activeMonths)
          ];
        }
        setSelectedMonth(uniq([...activeMonths]));
      }
    }
  };

  const getSelectedItemStyle = (item, type) => {
    if (
      !(
        financialYear === TDS_DISABLED_FY &&
        (TDS_DISABLED_MONTHS_FOR_FY_22.includes(item) || item === '1')
      )
    ) {
      let selectedItemStyle = '';
      if (type === MONTH_FILTER_IDENTIFIERS.QUARTER) {
        if (QUARTERS[item].every(val => selectedMonths.includes(val))) {
          selectedItemStyle = 'bg-secondaryColor   text-white';
        } else selectedItemStyle = 'bg-whiteSmoke text-davyGrey';
      }
      if (type === MONTH_FILTER_IDENTIFIERS.MONTHS) {
        if (selectedMonths.includes(MONTHS[item])) {
          const minMonth = Math.min(...selectedMonths);
          const maxMonth = Math.max(...selectedMonths);
          if ([minMonth, maxMonth].includes(MONTHS[item])) {
            //checking for end array elements
            selectedItemStyle = 'bg-primaryColor text-white';
          } else selectedItemStyle = 'bg-lavenderMist text-davyGrey';
        } else selectedItemStyle = 'bg-white text-davyGrey';
      }
      return selectedItemStyle;
    }
  };

  return (
    <div
      className="flex absolute top-[50px] left-[-88px] flex-col w-[238px] min-h-[293px]
        bg-white rounded-[5px] border-[1px] border-greyCloud animate-zoom-to-front-fast sm:top-[45px] sm:left-10">
      <div className="flex h-full">
        <div className="grid grid-cols-1">
          {Object.keys(QUARTERS).map(quarter => (
            <div
              key={Math.random()}
              className={`flex justify-center items-center w-[59px]
                                h-[59px] text-base font-semibold border-[1px] border-harp
                                ${
                                  financialYear <= TDS_DISABLED_FY &&
                                  quarter === TDS_DISABLED_QUATER_FOR_FY_22
                                    ? 'cursor-not-allowed bg-whiteSmoke/40 text-davyGrey/30 opacity-1'
                                    : ' cursor-pointer'
                                }
                                ${getSelectedItemStyle(
                                  quarter,
                                  MONTH_FILTER_IDENTIFIERS.QUARTER
                                )} cursor-pointer`}
              onClick={() => {
                handleQuarterClick(QUARTERS[quarter]);
              }}>
              {`Q${quarter}`}
            </div>
          ))}
        </div>
        <div className="grid grid-cols-3">
          {Object.keys(MONTHS).map(month => (
            <div
              key={Math.random()}
              className={`flex justify-center items-center w-[59px]
                                h-[59px] text-base font-normal border-[1px] border-harp 
                                ${
                                  financialYear <= TDS_DISABLED_FY &&
                                  TDS_DISABLED_MONTHS_FOR_FY_22.includes(
                                    MONTHS[month]
                                  )
                                    ? 'text-davyGrey/30 cursor-not-allowed'
                                    : ' cursor-pointer'
                                }
                                ${getSelectedItemStyle(
                                  month,
                                  MONTH_FILTER_IDENTIFIERS.MONTHS
                                )}`}
              onClick={() => {
                handleMonthClick(MONTHS[month]);
              }}>
              {month}
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-between items-center p-[10px] w-full h-full">
        <Button
          className="py-[6px] px-7 min-w-[102px] min-h-[31px] text-primaryColor border border-primaryColor"
          label={translate('cancel')}
          onClick={handleCancelClick}
        />
        <Button
          className="py-[6px] px-7 min-w-[102px] min-h-[33px] text-white whitespace-nowrap bg-primaryColor"
          label="Apply"
          disabled={selectedMonths.length === 0}
          onClick={handleFilterApply}
        />
      </div>
    </div>
  );
};

export default memo(MonthFilter);
