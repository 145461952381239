import React, { FC } from 'react';

import { USER_ROLE } from '@constants/common';
import { ResetPasswordProps } from './types';
import ResetPasswordContainer from '@containers/login-flow/reset-password/ResetPasswordContainer';

const ResetPasswordPage: FC<ResetPasswordProps> = ({
  userRole = USER_ROLE.PERSONAL
}) => <ResetPasswordContainer userRole={userRole} />;

export default ResetPasswordPage;
