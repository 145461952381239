import React, { FC } from 'react';
import { Grid, Tooltip } from '@mui/material';

import { SelectDropdown } from '@components';
import { InfoIconGrey } from '@assets/icons';
import { TransactionTagSelectorProps } from './types';

const TransactionTagSelector: FC<TransactionTagSelectorProps> = props => {
  const {
    name,
    placeholder,
    control,
    errors,
    dropDownValues,
    selectedItem,
    tooltipText,
    xs = 12,
    sm = 12,
    showTooltip = false,
    transactionTagChangeHandler
  } = props;

  return (
    <Grid container={true} item={true} spacing={6}>
      <Grid
        container={true}
        item={true}
        direction="row"
        alignItems="center"
        spacing={6}>
        <Grid item={true} md={6} xs={xs} sm={sm}>
          <SelectDropdown
            name={name}
            placeholder={placeholder}
            control={control}
            errors={errors}
            dropdownLOV={dropDownValues}
            selectedItem={selectedItem}
            onChangeHandler={transactionTagChangeHandler}
            dataTestId="transactionTagDropdown"
          />
        </Grid>
        {showTooltip && (
          <div className="mt-[55px] ml-1">
            <Tooltip title={tooltipText} arrow={true} enterTouchDelay={0}>
              <InfoIconGrey className="cursor-default" />
            </Tooltip>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default TransactionTagSelector;
