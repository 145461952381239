import { translate } from './locale';
import { ExchangeLinkingDetails } from '@containers/source/types';

export const getLinkedViaDetails = (
  exchangeId: string,
  exchangeLinkingDetails: ExchangeLinkingDetails[]
) => {
  const currentExchange = exchangeLinkingDetails?.find(
    exchange => exchange.exchange_id === exchangeId
  );
  return {
    linkedViaAPI: currentExchange?.via_api,
    linkedViaFile: currentExchange?.via_import
  };
};

export const computeLinkedViaLabel = (
  exchangeId: string,
  exchangeLinkingDetails: ExchangeLinkingDetails[]
) => {
  const { linkedViaAPI, linkedViaFile } = getLinkedViaDetails(
    exchangeId,
    exchangeLinkingDetails
  );
  if (linkedViaAPI && linkedViaFile) {
    return translate('sourcePage.multipleSources');
  } else if (linkedViaFile) {
    return translate('sourcePage.fileUploaded');
  } else if (linkedViaAPI) {
    return translate('sourcePage.linkedViaAPI');
  }
  return '';
};
