import {
  HomeIcon,
  ReportIcon,
  SettingsIcon,
  SourcesIcon,
  TransactionsIcon,
  UpgradePlanIcon,
  TdsTrackerIcon,
  ConnectExchangeIcon,
  SunIcon,
  MoonIcon,
  PersonIcon,
  SummaryIcon
  // ItrIcon
} from '@assets/icons';
import COLORS from './colors';

export const TAX_PROFESSIONAL_INVITES_PATH =
  '/tax-summary/tax-professional-invites';
export const TAX_SUMMARY_PATH = '/tax-summary';
export const ITR_FILING_PATH = '/itr-filing';

export const NAV_BAR_ACCORDIAN_CONSTANTS = {
  [TAX_SUMMARY_PATH]: [
    {
      navText: 'Tax Report',
      icon: ReportIcon,
      path: '/tax-report',
      iconDefaultFill: 'transparent',
      iconSelectedFill: COLORS.PRIMARY_COLOR,
      iconDefaultStroke: COLORS.SLATE_GREY,
      iconSelectedStroke: COLORS.WHITE,
      dataTestId: 'taxReport',
      hideOnProfessionalView: false
    },
    {
      navText: 'TDS Tracker',
      icon: TdsTrackerIcon,
      path: '/tds-tracker',
      iconDefaultFill: COLORS.SLATE_GREY,
      iconSelectedFill: COLORS.PRIMARY_COLOR,
      iconDefaultStroke: 'transparent',
      iconSelectedStroke: 'transparent',
      dataTestId: 'tdsTracker',
      hideOnProfessionalView: false
    },
    {
      navText: 'Tax Professionals',
      icon: PersonIcon,
      path: '/tax-professional-invites',
      iconDefaultFill: 'transparent',
      iconSelectedFill: COLORS.PRIMARY_COLOR,
      iconDefaultStroke: COLORS.SLATE_GREY,
      iconSelectedStroke: 'transparent',
      dataTestId: 'taxProfessionalInvites',
      hideOnProfessionalView: true
    }
    /* Disabled ITR Filing temporarily */
    // {
    //   navText: 'ITR Filing',
    //   icon: ItrIcon,
    //   path: '/itr-filing',
    //   iconDefaultFill: 'transparent',
    //   iconSelectedFill: COLORS.PRIMARY_COLOR,
    //   iconDefaultStroke: COLORS.SLATE_GREY,
    //   iconSelectedStroke: 'transparent',
    //   dataTestId: 'itrFiling',
    //   hideOnProfessionalView: true
    // }
  ]
};

export const NAV_BAR_CONSTANTS = [
  {
    navText: 'Dashboard',
    icon: HomeIcon,
    path: '/dashboard',
    iconDefaultFill: 'transparent',
    iconSelectedFill: COLORS.PRIMARY_COLOR,
    iconDefaultStroke: COLORS.SLATE_GREY,
    iconSelectedStroke: 'transparent',
    dataTestId: 'dashboard',
    hideOnProfessionalView: false
  },
  {
    navText: 'My Transactions',
    icon: TransactionsIcon,
    path: '/transaction',
    iconDefaultFill: COLORS.SLATE_GREY,
    iconSelectedFill: COLORS.PRIMARY_COLOR,
    iconDefaultStroke: 'transparent',
    iconSelectedStroke: 'transparent',
    dataTestId: 'myTransactions',
    hideOnProfessionalView: false
  },
  {
    navText: 'My Sources',
    icon: SourcesIcon,
    path: '/source',
    iconDefaultFill: 'transparent',
    iconSelectedFill: COLORS.PRIMARY_COLOR,
    iconDefaultStroke: COLORS.SLATE_GREY,
    iconSelectedStroke: 'transparent',
    dataTestId: 'mySources',
    hideOnProfessionalView: false
  },
  {
    navText: 'Connect Exchange',
    icon: ConnectExchangeIcon,
    path: '/connect-exchange',
    iconDefaultFill: 'transparent',
    iconSelectedFill: 'transparent',
    iconDefaultStroke: COLORS.SLATE_GREY,
    iconSelectedStroke: COLORS.PRIMARY_COLOR,
    dataTestId: 'connectExchange',
    hideOnProfessionalView: false
  },
  {
    navText: 'Tax Summary',
    icon: SummaryIcon,
    path: TAX_SUMMARY_PATH,
    iconDefaultFill: 'transparent',
    iconSelectedFill: 'transparent',
    iconDefaultStroke: COLORS.SLATE_GREY,
    iconSelectedStroke: COLORS.PRIMARY_COLOR,
    dataTestId: 'taxSummary',
    hideOnProfessionalView: false,
    child: NAV_BAR_ACCORDIAN_CONSTANTS[TAX_SUMMARY_PATH]
  }
];

export const NAV_BAR_CONSTANTS_FOR_PROFESSIONALS = [
  {
    navText: 'Clients',
    icon: PersonIcon,
    path: '/professional/clients',
    iconDefaultFill: 'transparent',
    iconSelectedFill: COLORS.PRIMARY_COLOR,
    iconDefaultStroke: COLORS.SLATE_GREY,
    iconSelectedStroke: 'transparent',
    dataTestId: 'clients'
  }
];

export const NAV_BAR_BOTTOM_ID = {
  settings: 'settings',
  plan: 'plan'
};

export const NAV_BAR_BOTTOM_CONSTANTS_FOR_PROFESSIONALS = [
  {
    navText: 'Settings',
    path: '/professional/settings',
    icon: SettingsIcon,
    id: NAV_BAR_BOTTOM_ID.settings,
    defaultTextColor: 'text-slateGrey',
    iconDefaultFill: 'transparent',
    iconSelectedFill: 'transparent',
    iconDefaultStroke: COLORS.SLATE_GREY,
    iconSelectedStroke: COLORS.PRIMARY_COLOR,
    style: 'hover:bg-hawksBlue/30 mb-2',
    dataTestId: 'professionalSettings'
  }
];

export const NAV_BAR_BOTTOM_CONSTANTS_FOR_MANAGED_CLIENTS = [
  {
    navText: '',
    icon: UpgradePlanIcon,
    id: NAV_BAR_BOTTOM_ID.plan,
    iconDefaultStroke: COLORS.BLACK_GREEN,
    iconDefaultFill: 'transparent',
    defaultTextColor: 'text-black',
    style: 'mb-4 mt-1 bg-lightOrange rounded-[5px] font-medium',
    dataTestId: 'subscription'
  },
  {
    navText: 'Settings',
    path: '/settings',
    icon: SettingsIcon,
    id: NAV_BAR_BOTTOM_ID.settings,
    defaultTextColor: 'text-slateGrey',
    iconDefaultFill: 'transparent',
    iconSelectedFill: 'transparent',
    iconDefaultStroke: COLORS.SLATE_GREY,
    iconSelectedStroke: COLORS.PRIMARY_COLOR,
    style: 'hover:bg-hawksBlue/30',
    dataTestId: 'settings'
  }
];

export const NAV_BAR_BOTTOM_CONSTANTS = [
  {
    navText: 'Settings',
    path: '/settings',
    icon: SettingsIcon,
    id: NAV_BAR_BOTTOM_ID.settings,
    defaultTextColor: 'text-slateGrey',
    iconDefaultFill: 'transparent',
    iconSelectedFill: 'transparent',
    iconDefaultStroke: COLORS.SLATE_GREY,
    iconSelectedStroke: COLORS.PRIMARY_COLOR,
    style: 'hover:bg-hawksBlue/30',
    dataTestId: 'settings'
  },
  {
    navText: '',
    icon: UpgradePlanIcon,
    id: NAV_BAR_BOTTOM_ID.plan,
    iconDefaultStroke: COLORS.BLACK_GREEN,
    iconDefaultFill: 'transparent',
    defaultTextColor: 'text-black',
    style: 'mb-4 mt-1 bg-lightOrange rounded-[5px] font-medium',
    dataTestId: 'subscription'
  }
];

export const DISPLAY_MODE = [
  {
    id: 'light',
    icon: SunIcon,
    isActive: true
  },
  {
    id: 'dark',
    icon: MoonIcon,
    isActive: false
  }
];
