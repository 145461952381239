import React, { FC, useEffect, useState } from 'react';

import { translate } from '@utils/locale';
import { InvestmentSummaryProps } from '../types';

import { getGainLossStyle } from '@utils/transactionUtils';
import { KpiCard } from '@components';
import { getDecimalValue } from '@utils/generalUtils';

const InvestmentSummary: FC<InvestmentSummaryProps> = ({ summary }) => {
  const [summaryData, setSummaryData] = useState([]);

  useEffect(() => {
    const kpiData = [
      {
        key: 'invested_amount',
        title: translate('dashboard.investedAmount'),
        value: getDecimalValue(summary.totalInvestedAmount, 2),
        info: translate('dashboard.investedAmountDesc')
      },
      {
        key: 'market_value',
        title: translate('dashboard.marketValue'),
        value: getDecimalValue(summary.totalMarketValue, 2),
        info: translate('dashboard.marketValueDesc')
      },
      {
        key: 'gain_or_loss',
        title: translate('dashboard.unrealisedGainOrLoss'),
        value: getDecimalValue(summary.totalGainOrLoss, 2),
        info: translate('dashboard.gainOrLossDesc'),
        titleStyle: getGainLossStyle(
          parseFloat(summary.totalGainOrLoss),
          'text-primaryColor'
        ),
        subValue: parseFloat(summary.totalGainOrLossPercent)
      }
    ];
    setSummaryData(kpiData);
  }, [summary]);

  return (
    <div
      id="investmentSummary"
      className="flex flex-col justify-between px-[14px] pb-[14px] md:p-6 md:pt-2 lg:sticky
       lg:top-[80px] lg:z-20 lg:flex-row lg:pt-6 lg:pr-0">
      {summaryData.map(item => (
        <KpiCard
          key={item.key}
          title={item.value}
          subTitle={item.subValue}
          infoText={item.info}
          description={item.title}
          cardStyle="
            sm:py-6 sm:px-6 sm:h-auto py-[16px] px-[14px] h-[100px] transition sm:duration-500 sm:hover:scale-[102%]"
          titleStyle={`sm:text-[38px] font-semibold leading-10 text-sm ${
            item?.titleStyle || 'text-primaryColor'
          }`}
          subTitleStyle={item.titleStyle || 'text-primaryColor'}
          descriptionStyle="text-secondaryColor text-sm"
          enableTitleBlur={false}
        />
      ))}
    </div>
  );
};

export default InvestmentSummary;
