/* eslint-disable no-useless-escape */
import { object, string } from 'yup';

import { translate } from '@utils/locale';
import { REGEX } from '@constants/common';

export const forgotPasswordSchema = object({
  email: string()
    .email(translate('signUpPage.invalidFormat'))
    .required(translate('resetPasswordPage.requiredField'))
    .matches(REGEX.email, translate('signUpPage.invalidFormat'))
});
