import React from 'react';
import { Divider } from '@mui/material';

import { RectangularSkeleton, TextSkeleton } from '@components';
import COLORS from '@constants/colors';

const TaxationSettingsSkeleton = () => {
  return (
    <div
      className="px-[15px] pt-8 m-5 mb-[35px] min-h-[calc(100vh-175px)] bg-white rounded-[10px]
        sm:py-[50px] sm:px-[20px] sm:mx-9 sm:mt-[43px]">
      <div
        className="py-[21px] px-[20px] min-h-[calc(100vh-240px)] rounded-[10px] border-[1px] border-harp
           sm:pr-[34px] sm:pl-[18px]">
        <TextSkeleton width={200} fontSize="14px" />
        <div className="pt-[11px] pb-[25px]">
          <Divider color={COLORS.MERCURY} />
        </div>
        {[...Array(4)].map((item, index) => (
          <div key={index} className="flex flex-row justify-between mb-[15px]">
            <TextSkeleton width={160} fontSize="12px" />
            <TextSkeleton width={60} fontSize="12px" />
          </div>
        ))}
        <div className="mt-[60px]">
          <TextSkeleton width={200} fontSize="14px" />
        </div>
        <div className="pt-[11px] pb-[25px]">
          <Divider color={COLORS.MERCURY} />
        </div>
        {[...Array(4)].map((item, index) => (
          <div key={index} className="flex flex-row justify-between mt-[15px]">
            <TextSkeleton width={160} fontSize="12px" />
            <TextSkeleton width={30} fontSize="12px" />
          </div>
        ))}
        <div className="flex flex-row gap-[17px] mt-[19px] mb-[65px] sm:mt-[49px] sm:mb-auto">
          <RectangularSkeleton width={128} height={38} />
          <RectangularSkeleton width={128} height={38} />
        </div>
      </div>
    </div>
  );
};

export default TaxationSettingsSkeleton;
