import React, { FC } from 'react';
import { Tooltip } from '@mui/material';

import { Button } from '@components';
import { VoidFn } from 'types/generalTypes';
import { ActionButtonProps } from './types';

const ActionButtons: FC<ActionButtonProps> = props => {
  const {
    actionItems,
    iconClass,
    wrapperStyle,
    dataTestId,
    isDisabled = false,
    customStyle = ''
  } = props;

  const handleActionButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    onClick: VoidFn
  ) => {
    e.stopPropagation();
    if (isDisabled) {
      return;
    }
    onClick();
  };

  return (
    <>
      {actionItems?.map(item => (
        <div key={item.label}>
          <Tooltip title={item.label} arrow={true} enterTouchDelay={0}>
            <div className={wrapperStyle}>
              <Button
                Icon={item.Icon}
                iconClass={iconClass}
                className={customStyle}
                disableRipple={true}
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                  handleActionButtonClick(e, item.onClick)
                }
                dataTestId={`${item.label}${dataTestId}`}
              />
            </div>
          </Tooltip>
        </div>
      ))}
    </>
  );
};

export default ActionButtons;
