import React, { FC } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import { CircularSkeleton, TextSkeleton } from '@components';

const SourceSectionSkeleton: FC = () => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('lg'));

  const arrayLength = isMobileView ? 2 : 4;

  return (
    <div className="p-5 w-full sm:p-8">
      <div className="px-6 pt-3 pb-2 w-full rounded-lg border border-harp">
        <div
          className={`flex w-[100%] ${isMobileView ? 'border-0' : 'border-b'}`}>
          <div className="flex flex-row justify-between pb-2 w-full">
            {[...Array(arrayLength)].map((item, index) => (
              <div
                key={index}
                className={`${isMobileView ? 'w-[25%]' : 'w-[16%]'}`}>
                <TextSkeleton customStyles={{ width: '80%' }} fontSize="12px" />
              </div>
            ))}
          </div>
        </div>
        {[...Array(5)].map((item, index) => (
          <div key={index} className="flex flex-row justify-between w-full">
            <div className="flex my-2 w-[25%]">
              <CircularSkeleton height={30} width={30} />
              <div className="mt-2 ml-2 w-[40%]">
                <TextSkeleton customStyles={{ width: '50%' }} fontSize="12px" />
              </div>
            </div>
            <div
              className={`mr-28 mt-4 w-[10%] ${
                isMobileView ? 'hidden' : 'block'
              }`}>
              <TextSkeleton customStyles={{ width: '50%' }} fontSize="12px" />
            </div>
            <div
              className={`mt-4 mr-[80px] w-[10%] ${
                isMobileView ? 'hidden' : 'block'
              }`}>
              <TextSkeleton customStyles={{ width: '50%' }} fontSize="12px" />
            </div>
            <div className={`mt-4 w-[10%] ${isMobileView ? 'mr-0' : 'mr-8'}`}>
              <TextSkeleton customStyles={{ width: '50%' }} fontSize="12px" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SourceSectionSkeleton;
