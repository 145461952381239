import React, { FC } from 'react';

import { CircularSkeleton, TextSkeleton } from '@components';
import { ProfileComponentSkeletonProps } from './types';

const ProfileComponentSkeleton: FC<ProfileComponentSkeletonProps> = ({
  type
}) => {
  return (
    <div
      className="flex absolute bottom-1 flex-row p-4 w-full h-[76px] border-t-2 border-gray-200 
            sm:static sm:bottom-auto">
      <CircularSkeleton width={40} height={40} />
      <div
        className={`flex overflow-hidden flex-col ${type} justify-start ml-2`}>
        <TextSkeleton width={150} fontSize="16px" />
        <TextSkeleton width={70} fontSize="10px" />
      </div>
    </div>
  );
};

export default ProfileComponentSkeleton;
