import { object, string } from 'yup';

import { translate } from '@utils/locale';
import { REGEX } from '@constants/common';

export const signInFormSchema = object({
  email: string()
    .email(translate('signUpPage.invalidFormat'))
    .required(translate('resetPasswordPage.requiredField'))
    .matches(REGEX.email, translate('signUpPage.invalidFormat')),
  password: string().required(translate('resetPasswordPage.requiredField'))
});
