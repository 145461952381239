import React, { FC } from 'react';

import { StringToTSX } from '@components';
import { WarningIcon, CloseIcon } from '@assets/icons';
import { BannerProps } from './types';
import COLORS from '@constants/colors';

const Banner: FC<BannerProps> = props => {
  const {
    content,
    handleClose,
    contentStyle,
    isClosable = true,
    icon: Icon = WarningIcon
  } = props;

  return (
    <div
      className={`flex text-[12px] justify-between items-center py-2 px-3 ${contentStyle} sm:px-8 sm:text-sm`}>
      <div className="flex flex-row items-center mr-2">
        <Icon className="shrink-0 w-6 h-6 sm:w-7 sm:h-7" />
        <span className="ml-2">
          <StringToTSX domString={content} />
        </span>
      </div>
      {isClosable && (
        <CloseIcon
          stroke={COLORS.DAVY_GREY}
          className="shrink-0 ml-auto w-3 h-3 cursor-pointer"
          onClick={handleClose}
        />
      )}
    </div>
  );
};

export default Banner;
