import React, { FC } from 'react';

import {
  CircularSkeleton,
  RectangularSkeleton,
  TextSkeleton
} from '@components';
import { CardSkeletonProps } from '../types';

const CardSkeleton: FC<CardSkeletonProps> = props => {
  const { iconPresent, customStyle = 'h-[100px] sm:h-32 ' } = props;

  return (
    <div
      className={`p-4 mx-auto w-full min-w-[200px] ${customStyle} bg-white rounded-md xl:w-1/3`}>
      <TextSkeleton width={180} fontSize="14px" />
      <div className="flex mt-1">
        <div className={`flex-row my-2 ${iconPresent ? 'block' : 'hidden'}`}>
          <CircularSkeleton width={30} height={30} />
        </div>
        <div className={`flex-row mt-5 ${iconPresent ? 'm-4' : 'm-[1px]'}`}>
          <RectangularSkeleton width={120} height={10} />
        </div>
      </div>
    </div>
  );
};

export default CardSkeleton;
