import React, { FC } from 'react';

import { TabBar } from '@components';
import { PortfolioSummaryProps } from '../types';
import { translate } from '@utils/locale';
import { TAB_BAR_CONSTANTS, TAB_BAR_ITEMS } from '@constants/tabBar';
import WalletSection from '../wallet-section/WalletSection';
import SourcesSection from '../sources-section/SourcesSection';

const PortfolioSummary: FC<PortfolioSummaryProps> = props => {
  const {
    selectedTab,
    handleTabSelect,
    cryptoData,
    nftData,
    topContentHeight
  } = props;

  const renderContent = () => {
    switch (selectedTab) {
      case TAB_BAR_CONSTANTS.ASSET:
        return (
          <WalletSection
            cryptoData={cryptoData}
            nftData={nftData}
            topContentHeight={topContentHeight}
          />
        );
      case TAB_BAR_CONSTANTS.SOURCE:
        return <SourcesSection topContentHeight={topContentHeight} />;
      default:
        return (
          <div className="flex justify-center items-center">
            {translate('dashboard.comingSoon')}
          </div>
        );
    }
  };

  return (
    <>
      <div className="border-b border-gray-200 lg:sticky lg:top-[255px]">
        <TabBar
          selectedTab={selectedTab}
          handleTabSelect={handleTabSelect}
          tabBarItems={TAB_BAR_ITEMS.dashboardPage}
          wrapperStyle="rounded-t-[10px] px-5 "
          tabStyle="ml-0 justify-center"
          customHeight="h-[40px]"
        />
      </div>
      {renderContent()}
    </>
  );
};

export default PortfolioSummary;
