import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';

import {
  ConfirmationPopup,
  EmptyData,
  ExchangeCard,
  SearchBar
} from '@components';
import { useTopContentHeight } from '@hooks';
import { translate } from '@utils/locale';
import { getMobileScreenCardAlignment } from '@utils/generalUtils';
import { gAEventTracker } from '@utils/gaUtils';
import { eventKeyMapper } from '@constants/gaEvents';
import { ACCOUNT_TYPE, USER_ROLE } from '@constants/common';
import { computeLinkedViaLabel, getLinkedViaDetails } from '@utils/sourceUtils';
import { GENERIC_FILE } from '@constants/connectExchangeConstants';
import { CloudUploadIcon } from '@assets/icons';
import { NoSearchResult } from '@assets/images';
import { RootState } from '@store/reducers';
import { ConnectExchangeSkeleton } from '@containers/skeleton-loader';
import { ExchangeCardListingProps, SourceListType } from '../types';
import COLORS from '@constants/colors';

const ExchangeCardListing: FC<ExchangeCardListingProps> = props => {
  const {
    exchangesList,
    handleSearchTextChange,
    searchValue,
    isLoadingExchanges,
    linkedExchangeIds,
    handleCardClick,
    exchangeLinkingDetails,
    userType
  } = props;

  const containerRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedExchange, setSelectedExchange] =
    useState<SourceListType>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);

  const { clientId, clientProfile } = useSelector((state: RootState) => ({
    ...state.rootReducer.app
  }));

  const topContentHeight = useTopContentHeight(!!clientId);
  const isMobileView = useMediaQuery('(max-width:640px)');

  useEffect(() => {
    //look for changes to the element's size
    if (containerRef.current) resizeObserver.observe(containerRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef.current]);

  const handleExchangeClick = (data: SourceListType) => {
    const { linkedViaFile, linkedViaAPI } = getLinkedViaDetails(
      data.id,
      exchangeLinkingDetails
    );
    if (linkedViaFile) {
      setSelectedExchange(data);
      setIsOpen(true);
      return;
    }
    if (!linkedViaAPI) handleCardClick(data);
  };

  const handleConfirmClick = () => {
    gAEventTracker(
      eventKeyMapper.transactionDuplicateValueConfirm,
      `${selectedExchange.name}:`
    );
    handleCardClick(selectedExchange);
    handleClose();
  };

  const handleClose = () => setIsOpen(false);

  const handleElementResized = () => {
    if (
      containerRef.current &&
      containerRef.current.offsetWidth !== containerWidth
    ) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
  };

  const genericCardClicked = () => {
    if (
      userType === USER_ROLE.PROFESSIONAL &&
      clientProfile.type !== ACCOUNT_TYPE.MANAGED
    ) {
      return;
    }
    gAEventTracker(eventKeyMapper.genericFileUpload);
    handleCardClick(GENERIC_FILE);
  };

  //instantiating the resizeObserver and passing the event handler to the constructor
  const resizeObserver = new ResizeObserver(handleElementResized);

  return (
    <>
      {isLoadingExchanges ? (
        <ConnectExchangeSkeleton cardCount={11} isWallet={false} />
      ) : (
        <>
          <div className="px-[14px] pt-[14px] w-full sm:px-6 sm:pt-6 md:w-[60%] lg:w-[40%] xl:w-[35%]">
            <SearchBar
              searchValue={searchValue}
              handleChange={handleSearchTextChange}
              wrapperStyle="bg-white border-white"
              searchInputStyle="bg-white py-2"
            />
          </div>
          <div
            ref={containerRef}
            className="flex flex-wrap justify-center items-start px-6 pt-[14px] w-full sm:justify-start sm:pt-4">
            <div
              className={`flex flex-wrap w-full ${getMobileScreenCardAlignment(
                containerWidth
              )}`}>
              {exchangesList?.length > 0 ? (
                exchangesList?.map(item => (
                  <div key={item.id}>
                    <ExchangeCard
                      data={item}
                      isLinkedViaApi={
                        getLinkedViaDetails(item.id, exchangeLinkingDetails)
                          .linkedViaAPI
                      }
                      handleCardClick={() => handleExchangeClick(item)}
                      isAlreadyLinked={
                        linkedExchangeIds.includes(item.id) &&
                        !(item.is_active_by_api && item.is_active_by_import)
                      }
                      linkedVia={computeLinkedViaLabel(
                        item.id,
                        exchangeLinkingDetails
                      )}
                      isComingSoon={
                        !item.is_active_by_api &&
                        !item.is_active_by_oauth &&
                        !item.is_active_by_import
                      }
                      isDisabled={
                        userType === USER_ROLE.PROFESSIONAL &&
                        clientProfile.type !== ACCOUNT_TYPE.MANAGED
                      }
                    />
                  </div>
                ))
              ) : (
                <div className="flex w-full">
                  <EmptyData
                    styleConfig={{
                      height: isMobileView
                        ? `calc(100vh - ${topContentHeight}px - 76px)`
                        : `calc(100vh - ${topContentHeight}px - 102px)`,
                      wrapperStyle:
                        'mb-2 sm:mb-6 min-h-[350px] tall:min-h-[400px]',
                      imageStyle:
                        'w-[50%] sm:w-[250px] md:w-[300px] lg:w-[350px]',
                      titleStyle:
                        'text-primaryColor text-sm mt-1 sm:text-base font-semibold'
                    }}
                    contentConfig={{
                      title: translate('sourcePage.noSearchResult'),
                      highlightedImage: NoSearchResult,
                      showPrimaryDescription: false,
                      showSecondaryDescription: false
                    }}
                  />
                </div>
              )}
              {/* Generic csv card section */}
              {!searchValue && (
                <div
                  className={`flex flex-row justify-center items-center px-5 mr-4 mb-4 w-[200px] h-[60px] text-sm
                    font-medium whitespace-nowrap bg-white rounded-md shadow-shadowBottomTwlightLite 
                   transition ${
                     userType === USER_ROLE.PROFESSIONAL &&
                     clientProfile.type !== ACCOUNT_TYPE.MANAGED
                       ? 'cursor-not-allowed opacity-80'
                       : 'cursor-pointer  sm:hover:shadow-md sm:duration-300 sm:hover:-translate-y-1'
                   } 
                  `}
                  onClick={genericCardClicked}
                  data-testid="uploadGenericCsvCard">
                  <CloudUploadIcon
                    stroke={COLORS.BLACK_GREEN}
                    fill="none"
                    className="shrink-0 mr-2"
                  />
                  <div className="w-36">
                    {translate('sourcePage.uploadGenericCsv')}
                  </div>
                </div>
              )}

              <ConfirmationPopup
                title={translate('popup.areYouSure')}
                message={translate('popup.connectExchangeWarning')}
                buttonOneText={translate('cancel')}
                buttonTwoText={translate('resetSuccessfulPage.continue')}
                onButtonOneClick={handleClose}
                onButtonTwoClick={handleConfirmClick}
                onCloseButtonClick={handleClose}
                visibility={isOpen}
                messageStyle="px-5 whitespace-pre-line"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ExchangeCardListing;
