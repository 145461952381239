import ReactGA from 'react-ga4';

import { eventObjMapper } from '@constants/gaEvents';

export const gAEventTracker = (key: string, val?: string) => {
  let eventObj = { ...eventObjMapper[key] };
  if (val) {
    let actionVal = eventObj.action;
    actionVal = val + ' ' + actionVal;
    eventObj.action = actionVal;
  }

  ReactGA.event(eventObj);
};

export const gAPageView = path =>
  ReactGA.send({ hitType: 'pageview', page: path });
