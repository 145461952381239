/* eslint-disable no-unused-vars */
import React, { FC } from 'react';
import { Tooltip } from '@mui/material';

import { Button, If } from '@components';
import { DeleteIcon, RefreshBlue } from '@assets/icons';
import { translate } from '@utils/locale';
import { shortenString } from '@utils/generalUtils';
import { SyncRequestStatus } from '@constants/mySources';
import { getTableDisplayData } from '../exchange-list-view/utils';
import { WalletTableType } from './type';

const WalletsTable: FC<WalletTableType> = props => {
  const {
    handleDeletePopUp,
    handleSync,
    img,
    walletId,
    publicAddress,
    findSyncStatus,
    findLastSyncStatus,
    transaction,
    name,
    blockChainId,
    isActionDisabled = false
  } = props;

  return (
    <tr
      key={walletId}
      className="flex items-center px-[10px] h-14 bg-white rounded-md 
      hover:shadow-md transition duration-300 hover:-translate-y-1">
      <td className="md:w-[48%] lg:w-[42%] xl:w-[35%]">
        <div className="flex flex-row items-center">
          <img
            src={img}
            className="min-w-[40px] h-[40px] rounded-md border border-harp"
          />
          <div className="flex flex-col">
            <div className="overflow-hidden pl-4 w-36 text-[15px] font-medium text-ellipsis md:w-56">
              {name}
            </div>
            <Tooltip title={publicAddress} arrow={true} enterTouchDelay={0}>
              <div className="pl-4 text-sm text-slateGrey">
                {shortenString(publicAddress, 4, 4)}
              </div>
            </Tooltip>
          </div>
        </div>
      </td>

      <td className="flex text-sm text-slateGrey md:w-[60%] xl:w-[52%]">
        <div className="flex mr-1 lg:mr-4">
          {getTableDisplayData(
            walletId,
            findSyncStatus(walletId),
            findLastSyncStatus(walletId),
            () => {},
            isActionDisabled
          )}
          {/* TODO: Add Transactions when it is ready from BE */}
          {/* <If
            condition={
              findSyncStatus(walletId) === SyncRequestStatus.COMPLETED
            }>
            <div className="flex ml-1 lg:ml-4">
              <span>|</span>
              <div className=" ml-4">
                {transaction} {translate('transactions')}
              </div>
            </div>
          </If> */}
        </div>
      </td>

      <td className="xl:w-[23%]"> </td>

      <td className="lg:mr-4 xl:w-[5%]">
        <Button
          className="disabled:opacity-50 disabled:cursor-not-allowed"
          Icon={RefreshBlue}
          disableRipple={true}
          disabled={
            [
              SyncRequestStatus.PENDING,
              SyncRequestStatus.PROCESSING,
              SyncRequestStatus.PAUSED,
              SyncRequestStatus.UNLINKING
            ].includes(findSyncStatus(walletId)) || isActionDisabled
          }
          onClick={() => handleSync(walletId, blockChainId)}
        />
      </td>

      <td className="lg:mr-1">
        <Button
          className="disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={() => handleDeletePopUp(walletId)}
          Icon={DeleteIcon}
          disableRipple={true}
          disabled={
            [
              SyncRequestStatus.PENDING,
              SyncRequestStatus.PROCESSING,
              SyncRequestStatus.PAUSED,
              SyncRequestStatus.UNLINKING
            ].includes(findSyncStatus(walletId)) || isActionDisabled
          }
        />
      </td>
    </tr>
  );
};

export default WalletsTable;
