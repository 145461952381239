import React, { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { debounce, isEmpty } from 'lodash';
import { useMediaQuery, useTheme } from '@mui/material';

import { EXCHANGE_INFO } from '@containers/source/constants';
import { AttachmentPinIcon, DeleteIcon, GenericFileIcon } from '@assets/icons';
import {
  EmptyData,
  CircularLoader,
  ConfirmationPopup,
  SearchBar,
  Paginator
} from '@components';
import { useTopContentHeight } from '@hooks';
import {
  useDeleteFileMutation,
  useGetConnectedFileStatusQuery
} from '@containers/source/api';
import { FileProcessStatus, TEMPLATE } from '@constants/mySources';
import { NotifierTypes, USER_ROLE } from '@constants/common';
import { showNotifier } from '@reducers/appReducer';
import { useAppDispatch } from '@store/store';
import { gAEventTracker } from '@utils/gaUtils';
import { eventKeyMapper } from '@constants/gaEvents';
import { translate } from '@utils/locale';
import { getUploadedFileName } from '@utils/generalUtils';
import { NoConnections, NoSearchResult } from '@assets/images';
import { FileResult } from '@containers/source/types';
import { SourcesSkeleton } from '@containers/skeleton-loader';
import { DeleteFileArgs, FileListViewProps } from './types';
import FileListViewRow from './FileListViewRow';
import FileListViewMobileRow from './FileListViewMobileRow';
import RoutesPath from '@routes/RoutesPath';

const FileListView: FC<FileListViewProps> = props => {
  const {
    getConnectedFiles,
    getConnectedFilesResponse,
    debounceSearchTerm,
    updateSearchTerm,
    isFetchingConnectedFiles,
    pageNumber,
    noOfRecordsDisplayed,
    totalCount,
    limit,
    isLoadingConnectedFiles,
    paginationData,
    setCurrentPage,
    userType,
    clientId,
    isManaged
  } = props;

  const [skipFileStatusPolling, setSkipFileStatusPolling] = useState(false);
  const [availableFileIds, setAvailableFileIds] = useState('');
  const [searchText, setSearchText] = useState('');
  const [showDeleteNotifier, setShowDeleteNotifier] = useState(false);
  const [selectedFileItem, setSelectedFileItem] = useState<DeleteFileArgs>({
    fileDetails: null
  });
  const [isFilePopupOpen, setIsFilePopupOpen] = useState(false);
  // state value :0 for not started, 1 for in progress, 2 for completion
  const [isLoadingIntialAPI, setIsLoadingIntialAPI] = useState(0);

  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up('md'));
  const isMobileView = useMediaQuery('(max-width:640px)');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const topContentHeight = useTopContentHeight(!!clientId);

  const {
    data: connectedFileStatusData,
    isFetching: isFetchingConnectedFileStatus,
    isLoading: isLoadingConnectedFileStatus
  } = useGetConnectedFileStatusQuery(
    { fileIds: availableFileIds, clientId },
    { pollingInterval: 5000, skip: !availableFileIds || skipFileStatusPolling }
  );
  const [deleteFile, deleteFileResponse] = useDeleteFileMutation();

  useEffect(() => {
    setSkipFileStatusPolling(false);
  }, [debounceSearchTerm]);

  useEffect(() => {
    if (isLoadingIntialAPI === 0) {
      // initial fetch loading scenario
      setIsLoadingIntialAPI(1);
    }
    getConnectedFiles({
      searchTerm: debounceSearchTerm,
      page: pageNumber - 1,
      limit,
      clientId
    });
  }, [getConnectedFiles, debounceSearchTerm, pageNumber]);

  useEffect(() => {
    // initial fetch completion scenario
    if (isLoadingIntialAPI === 1 && !isFetchingConnectedFiles) {
      setIsLoadingIntialAPI(2);
    }
  }, [getConnectedFilesResponse, isFetchingConnectedFiles]);

  useEffect(() => {
    // computes all available file id's
    let ids = [];
    getConnectedFilesResponse?.forEach(file => {
      ids.push(file.id);
    });
    setAvailableFileIds(ids.join(','));
    setSkipFileStatusPolling(false);
    // pagination reset to last page logic
    if (isCurrentPageExceedsPageLimit()) {
      setCurrentPage(Math.ceil(paginationData.total_rows / limit));
    }
  }, [getConnectedFilesResponse]);

  useEffect(() => {
    // for handling polling
    let pollingRequired = true;

    if (connectedFileStatusData?.length > 0 && !isFetchingConnectedFileStatus) {
      pollingRequired = connectedFileStatusData?.reduce(
        (isPollingRequired, file) =>
          isPollingRequired ||
          ![
            FileProcessStatus.UPLOAD_COMPLETED,
            FileProcessStatus.FILE_PROCESSING_FAILED,
            FileProcessStatus.DELETED
          ].includes(file.status),
        false
      );
      const isDeletedFilesInList = connectedFileStatusData.reduce(
        (isDeleted, data) =>
          isDeleted || data.status === FileProcessStatus.DELETED,
        false
      );
      if (isDeletedFilesInList) {
        // refetch the uploaded files api if any of the exchnage file is deleted from the list
        getConnectedFiles({
          searchTerm: debounceSearchTerm,
          page: pageNumber - 1,
          limit,
          clientId
        });
      }
    }
    // To stop polling for empty string case
    setSkipFileStatusPolling(!pollingRequired);
  }, [connectedFileStatusData, isFetchingConnectedFileStatus]);

  useEffect(() => {
    if (!isEmpty(connectedFileStatusData)) {
      const isDeleteInProgress = connectedFileStatusData?.reduce(
        (syncStatus, data) =>
          syncStatus || [FileProcessStatus.DELETING].includes(data.status),
        false
      );
      if (isDeleteInProgress) {
        setShowDeleteNotifier(isDeleteInProgress);
      }
    }
  }, [connectedFileStatusData]);

  useEffect(() => {
    if (deleteFileResponse.isSuccess) {
      setSkipFileStatusPolling(false);
      deleteFileResponse.reset();
    }
  }, [deleteFileResponse]);

  useEffect(() => {
    // for delete complete toast
    let isAllFilesDeleted = false;
    if (!isFetchingConnectedFileStatus && showDeleteNotifier) {
      if (!isEmpty(connectedFileStatusData)) {
        isAllFilesDeleted = connectedFileStatusData?.reduce(
          (isCompleted, data) =>
            isCompleted &&
            ![FileProcessStatus.DELETING, FileProcessStatus.DELETED].includes(
              data.status
            ),
          true
        );
      }
      if (paginationData.total_rows === 0) {
        isAllFilesDeleted = true;
      }
      if (isAllFilesDeleted) {
        triggerNotifier(
          translate('notifierMessage.allFilesDeleteSuccess'),
          NotifierTypes.DELETE
        );
        setShowDeleteNotifier(false);
      }
    }
  }, [
    connectedFileStatusData,
    isFetchingConnectedFileStatus,
    skipFileStatusPolling,
    showDeleteNotifier,
    paginationData
  ]);

  const navigateToConnectExchange = () => {
    navigate(RoutesPath.CONNECT_EXCHANGE);
  };

  const isCurrentPageExceedsPageLimit = () => {
    if (paginationData?.page > 0) {
      return (
        paginationData.page + 1 > Math.ceil(paginationData.total_rows / limit)
      );
    } else if (paginationData?.page === 0) {
      return false;
    }
  };

  const triggerNotifier = (message: string, type: NotifierTypes) => {
    dispatch(showNotifier({ message, type }));
  };

  const debounceSearch = useCallback(
    debounce(value => {
      updateSearchTerm(value);
      setCurrentPage(1);
    }, 300),
    [debounce]
  );

  const handleSearchTextChange = useCallback(
    value => {
      if (value) {
        debounceSearch(value);
        setSearchText(value);
      } else {
        setSearchText('');
        debounceSearch('');
      }
      setShowDeleteNotifier(false);
    },
    [debounceSearch]
  );

  const getFileActionItems = (sourceData: FileResult) => {
    return [
      {
        Icon: DeleteIcon,
        label: translate('delete'),
        onClick: () => {
          handleDeleteFileClick({ fileDetails: sourceData });
        }
      }
    ];
  };

  const handleDeleteFileConfirm = async () => {
    gAEventTracker(
      eventKeyMapper.deleteExchangeFileButton,
      `${selectedFileItem.fileDetails.template}:`
    );
    await deleteFile({
      upload_id: selectedFileItem.fileDetails?.id,
      clientId: clientId
    });
    handleDeleteFileClose();
  };

  const handleDeleteFileClick = (selectedItemData: DeleteFileArgs) => {
    setSelectedFileItem(selectedItemData);
    setIsFilePopupOpen(true);
  };

  const handleDeleteFileClose = () => {
    setTimeout(() => {
      setSelectedFileItem({ fileDetails: null });
    }, 700);
    setIsFilePopupOpen(false);
  };

  const handlePageChangeEvent = (value: number) => {
    setShowDeleteNotifier(false);
    setCurrentPage(value);
  };

  const findFileUploadStatus = (id: string) => {
    return connectedFileStatusData?.find(ele => ele?.upload_id === id)?.status;
  };

  return (
    <>
      {isLoadingConnectedFiles ||
      isLoadingConnectedFileStatus ||
      isLoadingIntialAPI === 1 ? (
        <SourcesSkeleton
          showSyncSkeleton={false}
          rowCount={6}
          actionButtonCount={1}
        />
      ) : (
        <>
          {(debounceSearchTerm || !isEmpty(getConnectedFilesResponse)) && (
            <div className="flex justify-between p-[14px] sm:px-6 sm:pt-6 sm:pb-0 sm:mb-2">
              <div className="w-full sm:w-[40%]">
                <SearchBar
                  searchValue={searchText}
                  handleChange={value => handleSearchTextChange(value)}
                  wrapperStyle="bg-white border-white"
                  searchInputStyle="bg-white py-2"
                />
              </div>
            </div>
          )}
          {!isEmpty(getConnectedFilesResponse) && (
            <div>
              {match ? (
                <div
                  className="overflow-x-auto items-start px-6 pb-3 w-full h-[calc(100%-210px)]
            customNormalScroll">
                  <table className="pb-[155px] w-full table-auto">
                    <tbody>
                      {getConnectedFilesResponse?.map(file => (
                        <FileListViewRow
                          key={file.id}
                          ExchangeIcon={
                            EXCHANGE_INFO[file.exchange_code]?.icon ||
                            GenericFileIcon
                          }
                          isGenericFile={file.template === TEMPLATE.GENERIC}
                          fileUploadStatus={findFileUploadStatus(file.id)}
                          lastSync={file?.created_at}
                          actionItems={getFileActionItems(file)}
                          fileName={file.filename}
                          isDeleteDisabled={
                            userType === USER_ROLE.PROFESSIONAL && !isManaged
                          }
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="px-[14px] w-full sm:px-6">
                  {!isEmpty(getConnectedFilesResponse) && (
                    <>
                      {getConnectedFilesResponse?.map(file => (
                        <FileListViewMobileRow
                          key={file.id}
                          ExchangeIcon={
                            EXCHANGE_INFO[file.exchange_code]?.icon ||
                            GenericFileIcon
                          }
                          isGenericFile={file.template === TEMPLATE.GENERIC}
                          fileUploadStatus={findFileUploadStatus(file.id)}
                          lastSync={file?.created_at}
                          actionItems={getFileActionItems(file)}
                          fileName={file.filename}
                          isDeleteDisabled={
                            userType === USER_ROLE.PROFESSIONAL && !isManaged
                          }
                        />
                      ))}
                    </>
                  )}
                </div>
              )}
              <Paginator
                handlePageChangeEvent={handlePageChangeEvent}
                limit={limit}
                page={pageNumber}
                noOfRecordsDisplayed={noOfRecordsDisplayed}
                totalCount={totalCount}
                className="justify-center mx-6 mt-4 mb-1 lg:justify-end"
              />
            </div>
          )}
          {isEmpty(getConnectedFilesResponse) &&
            debounceSearchTerm &&
            !isCurrentPageExceedsPageLimit() && (
              <div className="px-4 pt-0 sm:px-6 sm:pt-2">
                <EmptyData
                  styleConfig={{
                    height: isMobileView
                      ? `calc(100vh - ${topContentHeight}px - 76px)`
                      : `calc(100vh - ${topContentHeight}px - 94px)`,
                    wrapperStyle:
                      'mb-2 sm:mb-4 min-h-[350px] tall:min-h-[400px]',
                    imageStyle:
                      'w-[50%] sm:w-[250px] md:w-[300px] lg:w-[350px]',
                    titleStyle:
                      'text-sm font-semibold mt-2 md:mt-0 md:text-2xl',
                    secondaryDescriptionStyle:
                      'max-w-lg text-black text-[10px] w-[80%] md:w-3/4 md:text-sm'
                  }}
                  contentConfig={{
                    title: translate('emptyResult.title'),
                    highlightedImage: NoSearchResult,
                    showPrimaryDescription: false,
                    showSecondaryDescription: true,
                    secondaryDescription: translate('emptyResult.message')
                  }}
                />
              </div>
            )}
          {isEmpty(getConnectedFilesResponse) &&
            !debounceSearchTerm &&
            !isCurrentPageExceedsPageLimit() && (
              <>
                {isFetchingConnectedFiles ? (
                  <CircularLoader />
                ) : (
                  <div className="flex w-full">
                    <EmptyData
                      styleConfig={{
                        height: isMobileView
                          ? `calc(100vh - ${topContentHeight}px - 32px)`
                          : `calc(100vh - ${topContentHeight}px - 64px)`,
                        wrapperStyle:
                          'mx-4 mt-4 sm:mx-8 sm:mt-8 w-full m-0 sm:mb-8 min-h-[400px]',
                        imageStyle:
                          'w-[50%] sm:w-[250px] md:w-[300px] lg:w-[350px]',
                        secondaryDescriptionStyle:
                          'max-w-lg text-black text-[10px] sm:text-sm w-[80%] leading-normal',
                        titleStyle: 'text-sm font-semibold mt-2 sm:text-2xl'
                      }}
                      contentConfig={{
                        title: translate('sourcePage.noConnectionsTitle'),
                        highlightedImage: NoConnections,
                        showPrimaryDescription: false,
                        showSecondaryDescription: true,
                        showPrimaryButton: true,
                        secondaryDescription: translate(
                          'sourcePage.noConnectionsMessage'
                        ),
                        primaryButtonConfig: {
                          icon: AttachmentPinIcon,
                          buttonHandler: navigateToConnectExchange,
                          showLabelInSmallScreen: true,
                          buttonLabel: translate(
                            'transactionPage.connectExchange'
                          )
                        }
                      }}
                    />
                  </div>
                )}
              </>
            )}
        </>
      )}
      <ConfirmationPopup
        visibility={isFilePopupOpen}
        title={translate('popup.confirmDeletion')}
        onCloseButtonClick={handleDeleteFileClose}
        buttonOneText={translate('popup.noKeepIt')}
        buttonTwoText={translate('popup.yesDeleteIt')}
        message={translate('popup.deleteExchangeMessage', {
          exchangeName: getUploadedFileName(
            selectedFileItem?.fileDetails?.filename
          )
        })}
        onButtonOneClick={handleDeleteFileClose}
        onButtonTwoClick={handleDeleteFileConfirm}
        disabled={deleteFileResponse.isLoading || !isFilePopupOpen}
      />
    </>
  );
};

export default FileListView;
