import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  CSRF_TOKEN_FOR_EXCHANGE_AUTH,
  IS_AUTHENTICATED,
  USER_ROLE
} from '@constants/common';
import { useQuery } from '@hooks';
import { CircularLoader } from '@components';
import { useAppDispatch } from '@store/store';
import { updateNavigateTo } from '@reducers/appReducer';
import { updateIsUserAuthenticated } from '@reducers/userAuth';
import { RootState } from '@store/reducers';
import { clearOfferDetails, clearUserDetails } from '@reducers/userDetails';
import { clearClientDetails } from '@utils';
import baseApi, { authBaseApi } from '@services/api';
import { useUserSignInMutation } from './api';
import { SignInFieldValues, SignInProps } from './types';
import routesPath from '@routes/RoutesPath';
import EmailPasswordSignIn from './components/EmailPasswordSignIn';

const SignIn: FC<SignInProps> = props => {
  const { userRole } = props;

  const [signInUser, signInResponse] = useUserSignInMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const query = useQuery();

  const [redirectionPathName] = useState(
    ((location.state as any) || { from: null })?.from
  );

  const userToken = query.get('user_token');
  const sourceCode = query.get('source');
  const referralCode = query.get('referral_code');

  useEffect(() => {
    navigate(location.pathname + location.search, { replace: true }); // redirect to current path w/o state
  }, [navigate]);

  const { navigateTo } = useSelector(
    (state: RootState) => state.rootReducer.app
  );

  useEffect(() => {
    dispatch(baseApi.util.resetApiState());
    dispatch(authBaseApi.util.resetApiState());
    if (navigateTo) {
      navigate(navigateTo);
      dispatch(updateNavigateTo(''));
    }
    dispatch(clearUserDetails());
    dispatch(clearOfferDetails());
    localStorage.removeItem(CSRF_TOKEN_FOR_EXCHANGE_AUTH);
  }, []); // Clearing API cache data on mount.

  useEffect(() => {
    if (signInResponse.isSuccess) {
      localStorage.setItem(IS_AUTHENTICATED, 'true');
      if (redirectionPathName) {
        navigate('.', { state: { from: redirectionPathName } });
      }
      dispatch(updateIsUserAuthenticated('true'));
      clearClientDetails();
    }
  }, [signInResponse]);

  const handleUserSignIn = (values: SignInFieldValues) => {
    if (values !== null)
      signInUser({
        ...values,
        user_type: userRole,
        user_token: userToken,
        source: sourceCode
      });
  };

  const navigateToSignUp = () => {
    let additionalParams = '';
    if (userToken) additionalParams = `user_token=${userToken}`;
    if (sourceCode) additionalParams += `&source=${sourceCode}`;
    if (referralCode) additionalParams += `&referral_code=${referralCode}`;
    const route =
      userRole === USER_ROLE.PROFESSIONAL
        ? `${routesPath.TAX_PROFESSIONALS}/signup`
        : `${routesPath.SIGNUP}?${additionalParams}`;
    navigate(route);
  };

  const navigateToForgotPassword = () => {
    const route =
      userRole === USER_ROLE.PROFESSIONAL
        ? `${routesPath.TAX_PROFESSIONALS}/forgot`
        : routesPath.FORGOT;
    navigate(route);
  };

  return (
    <div className="flex w-full h-screen bg-harp">
      {signInResponse.isLoading && <CircularLoader isFullScreen={true} />}
      <div className="hidden relative justify-start items-end w-3/5 bg-alabaster rounded-l-lg sm:flex">
        <img
          src="/images/curvedBg.png"
          className="absolute w-full h-full"
          alt=""
        />
        <img
          src="images/SignIn.png"
          className="absolute inset-0 m-auto w-[90%]"
          alt="Sign In"
        />
      </div>
      <div
        className="flex overflow-y-auto justify-center w-full min-h-[-webkit-fill-available] 
 max-h-[-webkit-fill-available] bg-white rounded-r-lg sm:w-2/5 customNormalScroll">
        <div className="flex flex-col justify-center items-center w-[71%]   h-full sm:w-[60%]">
          <EmailPasswordSignIn
            handleValues={handleUserSignIn}
            disabled={signInResponse.isLoading}
            backToSignUpClick={navigateToSignUp}
            forgotPasswordClick={navigateToForgotPassword}
            role={userRole}
            redirectionPathName={redirectionPathName}
            extraParams={{
              userToken,
              sourceCode,
              referralCode
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
