import React, { FC, useState } from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';

import { Button, TextField } from '@components';
import { translate } from '@utils/locale';
import { ProfileFormProps } from './types';
import defaultAvatar from '@assets/icons/defaultAvatar.svg';

const startAdornment = (
  <span className="text-sm font-medium leading-5 text-black opacity-[0.38]">
    {translate('welcomePopup.91')}
  </span>
);

const ProfileForm: FC<ProfileFormProps> = props => {
  const { control, submitHandler, errors, userDetails, watch, isProfessional } =
    props;

  const { firstName, lastName, photo } = userDetails;

  const [isFetchingFailed, setIsFetchingFailed] = useState<boolean>(false);

  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up('md'));

  const firstNameWatchValue = watch('firstName');
  const lastNameWatchValue = watch('lastName');

  const isButtonDisabled = () => {
    return firstName === firstNameWatchValue && lastName === lastNameWatchValue;
  };

  return (
    <div className="p-[30px] w-full h-full text-sm bg-white rounded-[10px] sm:p-10 sm:text-base">
      <div
        className="flex flex-row justify-between pt-3 pb-[15px] 
            text-base font-semibold border-b border-harp">
        <div className="ml-2 text-base font-semibold">
          {translate('profile.publicProfile')}
        </div>
      </div>
      <form onSubmit={submitHandler()}>
        <Grid container={true}>
          <Grid
            container={true}
            item={true}
            md={8}
            sm={12}
            xs={12}
            alignItems="center"
            spacing={0}
            justifyContent={match ? 'flexStart' : 'center'}>
            <img
              src={photo || defaultAvatar}
              onError={(e: any) => {
                if (!isFetchingFailed) {
                  e.target.src = defaultAvatar;
                  setIsFetchingFailed(true);
                }
              }}
              className="mx-4 mt-[40px] mb-[55px] w-[100px] h-[100px] rounded-[50%]"
              referrerPolicy="no-referrer"
              alt="Profile Photo"
            />
          </Grid>
          <Grid
            container={true}
            item={true}
            md={8}
            sm={12}
            xs={12}
            columnSpacing={match ? 6 : 1}
            rowSpacing={match ? 4 : 3}>
            {isProfessional && (
              <Grid
                container={true}
                item={true}
                md={12}
                sm={12}
                xs={12}
                columnSpacing={match ? 6 : 1}
                rowSpacing={match ? 4 : 3}>
                <Grid item={true} md={6} sm={12} xs={12}>
                  <TextField
                    name="companyName"
                    className="mb-4"
                    placeholder={translate('profile.companyName')}
                    control={control}
                    errors={errors}
                    isControlledField={true}
                    isDisabled={true}
                  />
                </Grid>
              </Grid>
            )}

            <Grid item={true} md={6} sm={12} xs={12}>
              <TextField
                name="firstName"
                placeholder={translate('profile.firstName')}
                control={control}
                errors={errors}
                isControlledField={true}
              />
            </Grid>
            <Grid item={true} md={6} sm={12} xs={12}>
              <TextField
                name="lastName"
                placeholder={translate('profile.lastName')}
                control={control}
                errors={errors}
                isControlledField={true}
              />
            </Grid>
            <Grid item={true} md={6} sm={12} xs={12}>
              <TextField
                name="email"
                placeholder={translate('profile.email')}
                control={control}
                errors={errors}
                isControlledField={true}
                isDisabled={true}
              />
            </Grid>
            <Grid item={true} md={6} sm={12} xs={12}>
              <TextField
                name="mobileNumber"
                placeholder={translate('profile.mobileNumber')}
                control={control}
                errors={errors}
                isControlledField={true}
                isDisabled={true}
                showStartAdornment={true}
                startAdornment={startAdornment}
              />
            </Grid>
          </Grid>
          <Grid container={true} item={true} xs={12} sm={12} md={12} mt={3}>
            <Button
              label={translate('saveChanges')}
              className={`py-[9px] px-[17px] w-full text-white 
              ${
                isButtonDisabled() ? 'bg-gray-500' : 'bg-primaryColor'
              } md:w-auto`}
              type="submit"
              disabled={isButtonDisabled()}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default ProfileForm;
