/* eslint-disable no-unused-vars */
import React, { FC } from 'react';
import { Tooltip } from '@mui/material';

import { Button, If } from '@components';
import { DeleteIcon, RefreshBlue } from '@assets/icons';
import { SyncRequestStatus } from '@constants/mySources';
import { shortenString } from '@utils/generalUtils';
import { translate } from '@utils/locale';
import { getTableDisplayData } from '../exchange-list-view/utils';
import { WalletTableType } from './type';

const WalletsTableMobile: FC<WalletTableType> = props => {
  const {
    handleDeletePopUp,
    img,
    walletId,
    findSyncStatus,
    publicAddress,
    findLastSyncStatus,
    transaction,
    handleSync,
    name,
    blockChainId,
    isActionDisabled = false
  } = props;

  return (
    <div>
      <div className="flex justify-between items-center pb-3 w-full">
        <div className="flex flex-row items-center">
          <img
            src={img}
            className={'min-w-[35px] h-[35px] rounded-md border border-harp'}
          />
          <div className="flex flex-col">
            <div
              className="pl-2 text-[15px] font-medium sm:overflow-hidden sm:w-48 
            sm:text-ellipsis md:overflow-visible">
              {name}
            </div>
            <Tooltip title={publicAddress} arrow={true} enterTouchDelay={0}>
              <div className="pl-2 text-sm text-slateGrey">
                {shortenString(publicAddress, 4, 4)}
              </div>
            </Tooltip>
          </div>
        </div>
        <div className="flex">
          <Button
            iconClass="w-4 h-4"
            Icon={RefreshBlue}
            disableRipple={true}
            className="disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={
              [
                SyncRequestStatus.PENDING,
                SyncRequestStatus.PROCESSING,
                SyncRequestStatus.PAUSED,
                SyncRequestStatus.UNLINKING
              ].includes(findSyncStatus(walletId)) || isActionDisabled
            }
            onClick={() => handleSync(walletId, blockChainId)}
          />
          <Button
            iconClass="w-4 h-4"
            onClick={() => handleDeletePopUp(walletId)}
            disableRipple={true}
            Icon={DeleteIcon}
            className="disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={
              [
                SyncRequestStatus.PENDING,
                SyncRequestStatus.PROCESSING,
                SyncRequestStatus.PAUSED,
                SyncRequestStatus.UNLINKING
              ].includes(findSyncStatus(walletId)) || isActionDisabled
            }
          />
        </div>
      </div>

      <div className="flex flex-col px-4 w-full text-sm text-slateGrey">
        <div className="flex ">
          {getTableDisplayData(
            walletId,
            findSyncStatus(walletId),
            findLastSyncStatus(walletId),
            () => {},
            isActionDisabled
          )}
        </div>
        {/* TODO: Add Transactions when it is ready from BE */}
        {/* <If
          condition={findSyncStatus(walletId) === SyncRequestStatus.COMPLETED}>
          <div>
            {transaction} {translate('transactions')}
          </div>
        </If> */}
      </div>
    </div>
  );
};

export default WalletsTableMobile;
