import {
  appendClientIdInEndpoints,
  restartJobStatusPolling,
  getNotifierMessage,
  translate
} from '@utils';
import { NOTIFIER_MESSAGE_TYPES } from '@constants/common';
import {
  GenericApiResponse,
  GenericPaginatedAPiResponse
} from 'types/generalTypes';
import {
  SyncTransactionsPayload,
  SyncTransactionsResponse,
  LinkedSource,
  LinkedSourcesPayload,
  EditExchangePayload,
  EditExchangeResponse,
  DisconnectExchangePayload,
  ConnectedExchangeStatusPayload,
  ConnectedExchangeStatusResponse,
  StatusResult,
  AddTransactionPayload,
  AddTransactionResponse,
  GetCoinsListPayload,
  CoinsListResponseType,
  CoinListType,
  GeneratePreSignedURLPayload,
  UploadFilePayload,
  GeneratePreSignedURLResponse,
  CancelFileUploadPayload,
  CancelFileUploadResponseType,
  SubmitFilesPayload,
  GeneralResponse,
  FileStatusResult,
  DeleteFilePayload,
  FilesUploadPayload,
  DistinctCoinsListResponseType,
  DistinctCoinListType,
  GetAuthRedirectionURLResponse,
  GetAuthRedirectionURLPayload,
  RedirectionData,
  FileResult,
  ConnectedFilesPayload,
  Wallet,
  WalletsPayload,
  DisconnectWalletPayload,
  SyncWalletsResponse,
  SyncWalletsPayload,
  StatusResultWallet,
  ConnectedWalletStatusPayload,
  UserSpecificCoinListType
} from './types';
import baseApi from '@services/api';

const SourceBaseApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    syncTransactions: builder.mutation<
      SyncTransactionsResponse,
      SyncTransactionsPayload
    >({
      query: ({ payload, clientId }) => ({
        url: appendClientIdInEndpoints('/user/exchange/sync', clientId),
        method: 'POST',
        body: payload
      }),
      extraOptions: {
        showNotifier: true,
        failure: getNotifierMessage(
          NOTIFIER_MESSAGE_TYPES.syncingTransactionsFailed
        ),
        showCustomMessage: true
      },
      onQueryStarted: (_, lifeCycleAPI) =>
        restartJobStatusPolling(lifeCycleAPI, [
          'GetSyncStatus',
          'GetTransactionCount',
          'GetTransactionLimit'
        ])
    }),

    getLinkedSources: builder.query<LinkedSource[], LinkedSourcesPayload>({
      query: ({ searchTerm, clientId }) =>
        searchTerm
          ? appendClientIdInEndpoints(
              `/user/exchange?name=${encodeURIComponent(searchTerm)}`,
              clientId
            )
          : appendClientIdInEndpoints('/user/exchange', clientId),
      transformResponse: (response: GenericApiResponse<LinkedSource[]>) =>
        response.result,
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.getLinkedSourceError')
      },
      providesTags: ['GetLinkedExchange']
    }),

    editExchange: builder.mutation<EditExchangeResponse, EditExchangePayload>({
      query: ({ payload, exchangeId, clientId }) => ({
        url: appendClientIdInEndpoints(
          `/user/exchange/${exchangeId}/conf`,
          clientId
        ),
        method: 'PUT',
        body: payload
      }),
      extraOptions: {
        showNotifier: true,
        failure: getNotifierMessage(NOTIFIER_MESSAGE_TYPES.linkingFailed),
        showCustomMessage: true
      },
      onQueryStarted: (_, lifeCycleAPI) =>
        restartJobStatusPolling(lifeCycleAPI, [
          'GetLinkedExchange',
          'GetSyncStatus'
        ])
    }),

    disconnectExchange: builder.mutation<
      EditExchangeResponse,
      DisconnectExchangePayload
    >({
      query: ({ exchangeId, clientId }) => ({
        url: appendClientIdInEndpoints(
          `/user/exchange/${exchangeId}/disconnect`,
          clientId
        ),
        method: 'DELETE'
      }),
      extraOptions: {
        showNotifier: true,
        failure: getNotifierMessage(NOTIFIER_MESSAGE_TYPES.deleteFailed),
        showCustomMessage: true
      },
      onQueryStarted: (_, lifeCycleAPI) =>
        restartJobStatusPolling(lifeCycleAPI, [
          'GetLinkedExchange',
          'GetSyncStatus',
          'GetTransactionCount',
          'GetTransactionLimit'
        ])
    }),

    getConnectedExchangeStatus: builder.query<
      StatusResult[],
      ConnectedExchangeStatusPayload
    >({
      query: ({ exchangeIds, clientId }) =>
        appendClientIdInEndpoints(
          `/user/exchange/sync/status?exchange_ids=${exchangeIds}`,
          clientId
        ),
      transformResponse: (response: ConnectedExchangeStatusResponse) =>
        response.result,
      providesTags: ['GetSyncStatus']
    }),

    addTransaction: builder.mutation<
      AddTransactionResponse,
      AddTransactionPayload
    >({
      query: ({ payload, clientId }) => ({
        url: appendClientIdInEndpoints('/user/transaction', clientId),
        method: 'POST',
        body: payload
      }),
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.addTransactionError'),
        success: translate('notifierMessage.addTransactionSuccess')
      },
      onQueryStarted: (_, lifeCycleAPI) =>
        restartJobStatusPolling(lifeCycleAPI, [
          'GetTransactionErrorCount',
          'GetPortfolioAsset',
          'GetUserSpecificCoins',
          'GetUserSpecificExchanges',
          'GetTransactionList',
          'GetTransactionTypeFilterCount',
          'GetTransactionCount',
          'GetTransactionLimit'
        ])
    }),

    getDistinctCoinsList: builder.query<DistinctCoinListType[], void>({
      query: () => '/coins',
      transformResponse: (response: DistinctCoinsListResponseType) =>
        response.result
    }),

    getUserSpecificCoinsList: builder.query<
      UserSpecificCoinListType[],
      string | void
    >({
      query: clientId => appendClientIdInEndpoints('/user/coins', clientId),
      transformResponse: (
        response: GenericApiResponse<UserSpecificCoinListType[]>
      ) => response.result,
      providesTags: ['GetUserSpecificCoins']
    }),

    getCoinsList: builder.query<CoinListType[], GetCoinsListPayload>({
      query: ({ exchangeId }) => `/exchange/${exchangeId}/coins`,
      transformResponse: (response: CoinsListResponseType) => response.result
    }),

    generatePresignedURL: builder.mutation<
      GeneratePreSignedURLResponse,
      GeneratePreSignedURLPayload
    >({
      query: ({ payload, clientId }) => ({
        url: appendClientIdInEndpoints('/user/transaction/upload', clientId),
        method: 'POST',
        body: payload
      })
    }),

    uploadFileToS3: builder.mutation<void, UploadFilePayload>({
      query: ({ url, payload, contentType }) => ({
        url: url,
        method: 'PUT',
        body: payload,
        headers: {
          'Content-Type': contentType
        },
        credentials: 'omit'
      })
    }),

    cancelFileUpload: builder.mutation<
      CancelFileUploadResponseType,
      CancelFileUploadPayload
    >({
      query: ({ payload, clientId }) => ({
        url: appendClientIdInEndpoints(
          '/user/transaction/upload/cancel',
          clientId
        ),
        method: 'PATCH',
        body: payload
      })
    }),

    submitFiles: builder.mutation<GeneralResponse, SubmitFilesPayload>({
      query: ({ payload, clientId }) => ({
        url: appendClientIdInEndpoints(
          '/user/transaction/upload/submit',
          clientId
        ),
        method: 'PATCH',
        body: payload
      }),
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.fileImportFailed'),
        success: translate('notifierMessage.fileImportSuccess')
      },
      onQueryStarted: (_, lifeCycleAPI) =>
        restartJobStatusPolling(lifeCycleAPI, [
          'GetFileStatus',
          'GetTransactionCount',
          'GetTransactionLimit'
        ])
    }),

    getConnectedFiles: builder.query<
      GenericPaginatedAPiResponse<FileResult[]>,
      FilesUploadPayload
    >({
      query: ({ searchTerm, page = 0, limit = 10, clientId }) =>
        searchTerm && searchTerm.length > 0
          ? appendClientIdInEndpoints(
              `/user/transaction/uploads?search=${encodeURIComponent(
                searchTerm
              )}&page=${page}&limit=${limit}`,
              clientId
            )
          : appendClientIdInEndpoints(
              `/user/transaction/uploads?page=${page}&limit=${limit}`,
              clientId
            ),
      providesTags: ['GetFileStatus']
    }),

    getConnectedFileStatus: builder.query<
      FileStatusResult[],
      ConnectedFilesPayload
    >({
      query: ({ fileIds, clientId }) =>
        appendClientIdInEndpoints(
          `/user/transaction/uploads/status?id=${fileIds}`,
          clientId
        ),
      transformResponse: (response: GenericApiResponse<FileStatusResult[]>) =>
        response.result
    }),

    deleteFile: builder.mutation<EditExchangeResponse, DeleteFilePayload>({
      query: ({ upload_id, clientId }) => ({
        url: appendClientIdInEndpoints(
          `/user/transaction/upload/${upload_id}`,
          clientId
        ),
        method: 'DELETE'
      }),
      extraOptions: {
        showNotifier: true,
        failure: getNotifierMessage(NOTIFIER_MESSAGE_TYPES.deleteFailed),
        showCustomMessage: true
      },
      onQueryStarted: (_, lifeCycleAPI) =>
        restartJobStatusPolling(lifeCycleAPI, [
          'GetFileStatus',
          'GetTransactionCount',
          'GetTransactionLimit'
        ])
    }),

    getAuthRedirectionURL: builder.query<
      RedirectionData,
      GetAuthRedirectionURLPayload
    >({
      query: ({ exchangeId, clientId }) =>
        appendClientIdInEndpoints(
          `/user/exchange/${exchangeId}/oauth`,
          clientId
        ),
      transformResponse: (response: GetAuthRedirectionURLResponse) =>
        response.result,
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.somethingWentWrong')
      }
    }),
    getWallets: builder.query<
      GenericPaginatedAPiResponse<Wallet[]>,
      WalletsPayload
    >({
      query: ({ searchTerm, page = 0, limit = 10, clientId }) =>
        searchTerm
          ? appendClientIdInEndpoints(
              `/user/wallet?search=${encodeURIComponent(
                searchTerm
              )}&page=${page}&limit=${limit}`,
              clientId
            )
          : appendClientIdInEndpoints(
              `/user/wallet?page=${page}&limit=${limit}`,
              clientId
            ),
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.getWalletSourceError')
      },
      providesTags: ['GetWallets']
    }),
    disconnectWallet: builder.mutation<
      GenericApiResponse<string>,
      DisconnectWalletPayload
    >({
      query: ({ walletId, clientId }) => ({
        url: appendClientIdInEndpoints(
          `/user/wallet/${walletId}/disconnect`,
          clientId
        ),
        method: 'DELETE'
      }),
      extraOptions: {
        showNotifier: true,
        failure: getNotifierMessage(NOTIFIER_MESSAGE_TYPES.deleteFailed),
        showCustomMessage: true
      },
      onQueryStarted: (_, lifeCycleAPI) =>
        restartJobStatusPolling(lifeCycleAPI, [
          'GetWallets',
          'GetTransactionCount',
          'GetTransactionLimit'
        ])
    }),

    syncWallet: builder.mutation<SyncWalletsResponse, SyncWalletsPayload>({
      query: ({ walletPayload, clientId }) => ({
        url: appendClientIdInEndpoints('/user/wallet/sync', clientId),
        method: 'POST',
        body: walletPayload
      }),
      extraOptions: {
        showNotifier: true,
        failure: getNotifierMessage(
          NOTIFIER_MESSAGE_TYPES.syncingTransactionsFailed
        ),
        showCustomMessage: true
      },
      onQueryStarted: (_, lifeCycleAPI) =>
        restartJobStatusPolling(lifeCycleAPI, [
          'GetSyncStatusWallet',
          'GetTransactionCount',
          'GetTransactionLimit'
        ])
    }),

    getConnectedWalletStatus: builder.query<
      StatusResultWallet[],
      ConnectedWalletStatusPayload
    >({
      query: ({ walletIds, clientId }) =>
        appendClientIdInEndpoints(
          `/user/wallet/sync/status?wallet_config_ids=${walletIds}`,
          clientId
        ),
      transformResponse: (response: GenericApiResponse<StatusResultWallet[]>) =>
        response.result,
      providesTags: ['GetSyncStatusWallet']
    })
  })
});

export const {
  useSyncTransactionsMutation,
  useGetLinkedSourcesQuery,
  useLazyGetLinkedSourcesQuery,
  useEditExchangeMutation,
  useDisconnectExchangeMutation,
  useGetConnectedExchangeStatusQuery,
  useLazyGetConnectedExchangeStatusQuery,
  useLazyGetConnectedFilesQuery,
  useGetConnectedFileStatusQuery,
  useAddTransactionMutation,
  useGetDistinctCoinsListQuery,
  useGetUserSpecificCoinsListQuery,
  useLazyGetCoinsListQuery,
  useGeneratePresignedURLMutation,
  useUploadFileToS3Mutation,
  useCancelFileUploadMutation,
  useSubmitFilesMutation,
  useDeleteFileMutation,
  useLazyGetAuthRedirectionURLQuery,
  useGetWalletsQuery,
  useLazyGetWalletsQuery,
  useDisconnectWalletMutation,
  useSyncWalletMutation,
  useGetConnectedWalletStatusQuery
} = SourceBaseApi;
