import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import {
  CircularSkeleton,
  RectangularSkeleton,
  TextSkeleton
} from '@components';
import { CardSkeleton } from './components';

const TaxReportSkeleton = () => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const arrayLength = isMobileView ? 2 : 3;

  return (
    <div className="justify-center py-[2px] px-[6px] pt-2 w-full sm:py-3 sm:px-4">
      <div className="flex flex-col gap-2 justify-between px-2 lg:px-0 xl:flex-row xl:pb-4">
        {[...Array(arrayLength)].map((item, index) => (
          <CardSkeleton iconPresent={true} key={index} />
        ))}
      </div>
      <div className="flex flex-col justify-between lg:flex-row">
        <div className="flex-row grow px-2 pt-2 w-full lg:pl-0 lg:w-9/12 ">
          <div className="flex flex-col bg-white rounded-[10px]">
            <div className="p-5">
              <div>
                <TextSkeleton width={200} fontSize="24px" />
              </div>
              {[...Array(arrayLength * 2)].map((item, index) => (
                <div
                  key={index}
                  className="flex flex-row justify-between 
                                         items-center pt-[18px] pr-[1px] w-full">
                  <span>
                    <TextSkeleton width={170} fontSize="16px" />
                  </span>
                  <span>
                    <TextSkeleton width={80} fontSize="16px" />
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="hidden p-5 mt-3 bg-white rounded-[10px] lg:block">
            <div className="flex justify-between">
              <div className="flex flex-wrap">
                <div className="pr-3">
                  <TextSkeleton width={200} fontSize="24px" />
                </div>
              </div>
              <div className="flex flex-row items-center pt-[3px]">
                <CircularSkeleton width={16} height={16} />
                <div className="pl-[2px]">
                  <RectangularSkeleton width={60} height={16} />
                </div>
              </div>
            </div>
            <div className="pt-3">
              <TextSkeleton width={150} fontSize="16px" />
            </div>
          </div>
        </div>
        <div className="hidden gap-y-[14px] px-2 pt-2 sm:flex sm:flex-col lg:pr-0">
          {[...Array(3)].map((item, index) => (
            <CardSkeleton iconPresent={true} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TaxReportSkeleton;
