import React, { FC, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';

import {
  Button,
  CircularLoader,
  TransactionForm,
  SideModal as PopUp
} from '@components';
import { TRANSACTION_MODES } from '@constants/common';
import {
  createPayload,
  generateDefaultValues,
  getFormattedCoinList,
  getFormattedExchangeList,
  gAEventTracker,
  translate
} from '@utils';
import { eventKeyMapper } from '@constants/gaEvents';
import { useLazyGetCoinsListQuery } from '@containers/source/api';
import { transactionFormSchema } from '@components/add-transaction/validate';
import { FormFieldValues } from '@containers/source/components/types';
import { CloseIcon } from '@assets/icons';
import { schedulerTransactionFormSchema } from './validate';
import { DefaultValue, EditTransactionProps } from './types';
import COLORS from '@constants/colors';

const EditTransaction: FC<EditTransactionProps> = props => {
  const {
    transactionId,
    isLoadingExchanges,
    isFetchingTransactionDetails,
    exchangesList,
    transactionDetailsData,
    editTransaction,
    handleFormPopUpClose,
    isUpdatingTransaction,
    isTransactionFormPopupOpen,
    clientId
  } = props;

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    setValue,
    reset
  } = useForm<FormFieldValues>({
    defaultValues: {
      transactionType: '',
      transactionSource: '',
      noOfCoins: '',
      noOfCoinsReceived: '',
      receivedAmount: '',
      paidAmount: '',
      costBasis: '',
      marketValueOfCoin: '',
      depositAmount: '',
      withdrawalAmount: '',
      fee: '',
      notes: '',
      tdsCharged: ''
    },
    context: exchangesList,
    resolver: yupResolver(
      transactionDetailsData?.mode === TRANSACTION_MODES.manual
        ? transactionFormSchema
        : schedulerTransactionFormSchema
    )
  });

  const [getCoinsListById, { data: coinsListByIdResponse }] =
    useLazyGetCoinsListQuery();

  const transactionSourceWatchValue = watch('transactionSource');

  useEffect(() => {
    if (!isEmpty(exchangesList) && transactionSourceWatchValue) {
      getCoinsListById({ exchangeId: transactionSourceWatchValue });
    }
  }, [exchangesList, transactionSourceWatchValue]);

  useEffect(() => {
    if (transactionDetailsData && !isFetchingTransactionDetails) {
      const defaultValues = generateDefaultValues(transactionDetailsData);
      defaultValues.forEach(({ name, value }: DefaultValue) => {
        setValue(name, value);
      });
    }
  }, [
    transactionDetailsData,
    isFetchingTransactionDetails,
    isTransactionFormPopupOpen
  ]);

  const handleEditTransaction = (values: FormFieldValues) => {
    const payload = createPayload({
      values: values,
      exchangesList: exchangesList,
      coinsList: getFormattedCoinList(coinsListByIdResponse),
      exchangeCode: transactionDetailsData?.exchange_code,
      isWallet: transactionDetailsData?.is_wallet,
      isManual: transactionDetailsData?.mode === TRANSACTION_MODES.manual
    });
    gAEventTracker(eventKeyMapper.editTransactionClicked);
    editTransaction({ payload, transactionId, clientId });
  };

  const submitHandler = () => {
    return handleSubmit(handleEditTransaction);
  };

  const handleCancel = () => handleFormPopUpClose(false);

  return (
    <>
      <PopUp
        isOpen={isTransactionFormPopupOpen}
        clickOutSideClose={false}
        handleClose={handleCancel}>
        <div
          className={`flex justify-center items-center self-center
                    w-full h-full bg-transparent
                     ${
                       isTransactionFormPopupOpen
                         ? 'animate-zoom-to-front'
                         : 'animate-zoom-to-back'
                     }`}>
          <div
            className="flex overflow-auto relative flex-col p-3 w-[750px] h-full max-h-full bg-white
           md:rounded-xl lg:h-auto customTransparentScroll"
            data-testid="editTransactionPopup">
            <div className="flex flex-row justify-between px-10 pt-10 w-full">
              <span className="text-base font-medium">
                {translate('transactionPage.editTransaction')}
              </span>
              <div
                className="flex absolute top-5 right-5 justify-center items-center w-7 h-7 
              bg-whiteSmoke rounded-full sm:top-10 sm:right-10">
                <Button
                  onClick={handleCancel}
                  disableRipple={true}
                  iconStroke={COLORS.DAVY_GREY}
                  Icon={CloseIcon}
                />
              </div>
            </div>
            <div className="m-10">
              {isFetchingTransactionDetails || isLoadingExchanges ? (
                <CircularLoader />
              ) : (
                <TransactionForm
                  control={control}
                  submitHandler={submitHandler}
                  errors={errors}
                  watch={watch}
                  getValues={getValues}
                  handleCancel={handleCancel}
                  setValue={setValue}
                  transactionSourceOptions={getFormattedExchangeList(
                    exchangesList
                  )}
                  isEdit={true}
                  isManuallyAdded={
                    transactionDetailsData?.mode === TRANSACTION_MODES.manual
                  }
                  isUpdatingTransaction={isUpdatingTransaction}
                  coinOptions={getFormattedCoinList(coinsListByIdResponse)}
                  reset={reset}
                  transactionDetails={transactionDetailsData}
                />
              )}
            </div>
          </div>
        </div>
      </PopUp>
    </>
  );
};

export default EditTransaction;
