import React, { FC, useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Grid, Paper } from '@mui/material';
import { isEmpty } from 'lodash';
import OutsideClickHandler from 'react-outside-click-handler';

import { SearchBar, Tooltip, ListBoxComponent } from '@components';
import { CloseIcon, DownArrowWhite } from '@assets/icons';
import { getValueFromArray } from '@utils/generalUtils';
import { translate } from '@utils/locale';
import { OptionsLOVType } from 'types/generalTypes';
import { CoinDropdownProps } from './types';
import COLORS from '@constants/colors';

const CoinDropdown: FC<CoinDropdownProps> = props => {
  const {
    options,
    name,
    control,
    errors,
    placeholder,
    selectedItem,
    isDisabled,
    componentName
  } = props;

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([...options]);
  const [visibility, setVisibility] = useState(false);
  const [hideTooltip, setHideTooltip] = useState(false);
  const [selectedOption, setSelectedOption] = useState(selectedItem?.id);

  const outsideClickHandlerRef = useRef(null);

  useEffect(() => {
    const availableOptions = getFilteredValues(options, searchTerm);
    setFilteredOptions([...availableOptions]);
  }, [options]);

  useEffect(() => {
    setSelectedOption(selectedItem?.id);
  }, [selectedItem]);

  useEffect(() => {
    if (!visibility) {
      setSearchTerm('');
      setFilteredOptions([...options]);
    }
    if (visibility) {
      setHideTooltip(false);
    }
  }, [visibility]);

  const getFilteredValues = (list: OptionsLOVType[], filterTerm: string) => {
    const availableOptions = list?.filter(
      option =>
        option.name.toLowerCase().indexOf(filterTerm.toLowerCase()) === 0 ||
        option.id.toLowerCase().indexOf(filterTerm.toLowerCase()) === 0
    );
    return availableOptions;
  };

  const handleSearchValueChange = (value: string) => {
    setSearchTerm(value);
    const availableOptions = getFilteredValues(options, value);
    setFilteredOptions([...availableOptions]);
  };

  const handleClickOut = () => {
    setVisibility(false);
  };

  const handleClick = (e, onChange) => {
    if (e.target.id) {
      onChange(e.target.value);
      setSelectedOption(e.target.value);
      setHideTooltip(true);
      // added a timeout for 'visibity' inorder to hide tooltip before unmounting the dropdown
      setTimeout(() => {
        setVisibility(false);
      }, 300);
    }
  };

  const handleCloseClick = (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>,
    onChange
  ) => {
    e.stopPropagation();
    if (!isDisabled) {
      onChange('');
      setSelectedOption('');
    }
  };

  const renderBody = onChange => (
    <Paper
      style={{ width: '100%', minWidth: '216px', marginTop: 10 }}
      elevation={0}>
      <div className="mx-4 mt-4 mb-3">
        <SearchBar
          handleChange={handleSearchValueChange}
          searchValue={searchTerm}
          dataTestId={`Form${name}DropdownSearch`}
          customClass="px-2 py-[2px] h-6"
        />
      </div>
      {options && (
        <ListBoxComponent
          componentName={componentName}
          handleOptionClick={e => handleClick(e, onChange)}
          showId={true}
          showCheckBox={false}
          hidden={hideTooltip}>
          {filteredOptions}
        </ListBoxComponent>
      )}
      {isEmpty(filteredOptions) && (
        <div className="mx-4 mb-2 text-sm font-medium text-davyGrey truncate">
          {translate('noOptions')}
        </div>
      )}
    </Paper>
  );

  const getSelectedCoinName = (id: string) => {
    const coinName = getValueFromArray(id, [...options], 'id', 'id');
    return coinName || placeholder;
  };

  if (
    outsideClickHandlerRef.current &&
    outsideClickHandlerRef.current.childNode
  ) {
    outsideClickHandlerRef.current.childNode.style.width = '100%';
  }

  const toggleVisibility = () => {
    if (!isDisabled) {
      setVisibility(currentState => !currentState);
    }
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Grid
            container={true}
            data-testid={name}
            className={`flex relative items-center p-2 pr-[19px] h-[54px] 
            text-white bg-secondaryColor rounded-r-md ${
              isDisabled ? 'opacity-90' : ''
            }`}
            onClick={toggleVisibility}>
            <OutsideClickHandler
              onOutsideClick={handleClickOut}
              display="inline"
              ref={outsideClickHandlerRef}>
              <Tooltip
                body={renderBody(onChange)}
                onHover={false}
                visibility={visibility}
                outerClass="w-full"
                innerClass="mt-9 -right-[69px]">
                <Grid
                  container={true}
                  item={true}
                  xs={12}
                  className="flex justify-between  items-center"
                  onClick={toggleVisibility}>
                  <Grid
                    item={true}
                    xs={8}
                    md={8}
                    className={`text-sm font-medium truncate ${
                      isDisabled ? 'opacity-50' : ''
                    }`}>
                    {getSelectedCoinName(value)}
                  </Grid>
                  <Grid
                    item={true}
                    container={true}
                    className="flex absolute flex-row justify-end items-center min-w-[30px]">
                    {selectedOption && !isDisabled && (
                      <CloseIcon
                        stroke={COLORS.WHITE}
                        className="mr-2 cursor-pointer"
                        onClick={e => handleCloseClick(e, onChange)}
                      />
                    )}
                    <DownArrowWhite
                      className={`${
                        isDisabled ? 'cursor-auto' : 'cursor-pointer'
                      } 
                      ${visibility ? 'rotate-180' : 'rotate-0'}`}
                    />
                  </Grid>
                </Grid>
              </Tooltip>
            </OutsideClickHandler>
          </Grid>
        )}
      />
      {!isEmpty(errors) && errors[name]?.message && (
        <p className="mt-[4px] text-xs text-red-500">{errors[name]?.message}</p>
      )}
    </>
  );
};

export default CoinDropdown;
