import React, { FC } from 'react';

import { KEYBOARD_KEYS, REGEX } from '@constants/common';
import { SplitOTPInputProps } from './types';

const SplitOTPInput: FC<SplitOTPInputProps> = props => {
  const { fieldValues, handleValueChange, hasError = false } = props;

  const focusToNextInput = (target: HTMLElement) => {
    const nextElementSibling =
      target.nextElementSibling as HTMLInputElement | null;
    if (nextElementSibling) {
      // moves to next input
      nextElementSibling.focus();
    } else {
      // this means, current target is the last sibling, so making it blur
      target.blur();
    }
  };

  const focusToPrevInput = (target: HTMLElement) => {
    const previousElementSibling =
      target.previousElementSibling as HTMLInputElement | null;
    if (previousElementSibling) {
      // moves to previous input
      previousElementSibling.focus();
    }
  };

  const onChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { target } = event;
    let targetValue = target.value;
    const isTargetValueDigit = REGEX.digits.test(targetValue);
    // invalid digit check
    if (!isTargetValueDigit && targetValue !== '') {
      return;
    }
    let updatedValue = [...fieldValues];
    updatedValue[index] = targetValue;
    handleValueChange(updatedValue);
    // if invalid digit is entered, no need to move to next sibling (case: backspace)
    if (!isTargetValueDigit) {
      return;
    }
    // moves the focus to next sibling
    focusToNextInput(target);
  };

  const inputOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = event;
    const target = event.target as HTMLInputElement;
    if (key === KEYBOARD_KEYS.arrowRight || key === KEYBOARD_KEYS.arrowDown) {
      event.preventDefault();
      return focusToNextInput(target);
    }
    if (key === KEYBOARD_KEYS.arrrowLeft || key === KEYBOARD_KEYS.arrowUp) {
      event.preventDefault();
      return focusToPrevInput(target);
    }
    // keep the selection range position
    // if the same digit was typed
    target.setSelectionRange(0, target.value.length);

    if (event.key !== KEYBOARD_KEYS.backspace || target.value !== '') {
      return;
    }
    focusToPrevInput(target);
  };

  const inputOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    const { target } = event;
    target.setSelectionRange(0, target.value.length);
  };

  const onPasteHandler = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const clipboardData: DataTransfer =
      event.clipboardData || (window as any).clipboardData;
    if (clipboardData) {
      const pastedData = clipboardData.getData('text/plain');
      if (REGEX.digits.test(pastedData)) {
        const splittedOTP = pastedData
          .substring(0, fieldValues.length)
          .split('');
        if (splittedOTP.length < fieldValues.length) {
          // fill the remaining array values with empty string make length same as fieldValues.length
          while (splittedOTP.length < fieldValues.length) {
            splittedOTP.push('');
          }
        }
        handleValueChange(splittedOTP);
        const target = event.target as HTMLInputElement;
        target.blur();
      }
    }
  };

  const getBorderColor = (value: string) => {
    if (hasError) {
      return 'border-coralRed';
    } else if (value) {
      return 'border-blackGreen';
    }
    return 'border-greyCloud';
  };

  return (
    <div>
      {fieldValues.map((_, index) => (
        <input
          key={index}
          autoFocus={index === 0}
          inputMode="numeric"
          className={`py-[9px] mb-1 px-3 mr-4 last:mr-0 w-10 h-10 text-2xl 
       font-medium text-blackGreen bg-white selection:bg-primaryColor/20
        rounded-[5px] border ${getBorderColor(fieldValues[index])} 
        outline-none caret-greyCloud`}
          maxLength={1}
          type="text"
          value={fieldValues[index]}
          onChange={event => onChangeHandler(event, index)}
          onKeyDown={inputOnKeyDown}
          onFocus={inputOnFocus}
          onPaste={onPasteHandler}
        />
      ))}
    </div>
  );
};

export default SplitOTPInput;
