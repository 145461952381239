import { useEffect, useRef, useState } from 'react';

export default function useTimeout(timerDuration: number) {
  const [count, setCount] = useState(timerDuration);
  const [retryCount, setRetryCount] = useState(0);

  const interval = useRef(null);

  useEffect(() => {
    interval.current = setInterval(() => {
      setCount(currentState => currentState - 1);
    }, 1000);
    return () => clearInterval(interval.current);
  }, [retryCount]);

  useEffect(() => {
    if (count <= 0) clearInterval(interval.current);
  }, [count]);

  const resetTimer = () => {
    setRetryCount(currentState => currentState + 1);
    setCount(timerDuration);
  };

  return { count, resetTimer };
}
