import { object, string, ref } from 'yup';

import { translate } from '@utils/locale';
import { REGEX } from '@constants/common';

export const signUpFormSchema = object({
  firstName: string()
    .required(translate('resetPasswordPage.requiredField'))
    .matches(REGEX.name, translate('signUpPage.invalidCharacters'))
    .trim()
    .max(50, 'Maximum 50 characters allowed'),
  lastName: string()
    .required(translate('resetPasswordPage.requiredField'))
    .matches(REGEX.name, translate('signUpPage.invalidCharacters'))
    .trim()
    .max(50, 'Maximum 50 characters allowed'),
  email: string()
    .email(translate('signUpPage.invalidFormat'))
    .required(translate('resetPasswordPage.requiredField'))
    .matches(REGEX.email, translate('signUpPage.invalidFormat')),
  password: string()
    .matches(REGEX.password, translate('resetPasswordPage.passwordWarning'))
    .required(translate('resetPasswordPage.requiredField')),
  confirmPassword: string()
    .required(translate('resetPasswordPage.requiredField'))
    .oneOf(
      [ref('password')],
      translate('resetPasswordPage.passwordsDoNotMatch')
    )
});

export const signUpFormSchemaForCA = object({
  companyName: string()
    .required(translate('resetPasswordPage.requiredField'))
    .trim()
    .max(100, 'Maximum 100 characters allowed')
}).concat(signUpFormSchema);
