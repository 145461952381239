import React, { FC } from 'react';

import { USER_ROLE } from '@constants/common';
import { VerifyEmailProps } from './types';
import Verify from '@containers/login-flow/verify/Verify';

const VerifyPage: FC<VerifyEmailProps> = ({
  userRole = USER_ROLE.PERSONAL
}) => <Verify userRole={userRole} />;

export default VerifyPage;
