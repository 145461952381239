import React, { useState, useEffect, useCallback, FC, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Tooltip, useMediaQuery } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';

import { RippleEffect, ProfileComponent, Accordian } from '@components';
import {
  BetaLogo,
  CloseIconBlue,
  GiftIcon,
  HamburgerMenu
} from '@assets/icons';
import {
  DISPLAY_MODE,
  NAV_BAR_ACCORDIAN_CONSTANTS,
  NAV_BAR_BOTTOM_CONSTANTS,
  NAV_BAR_BOTTOM_CONSTANTS_FOR_MANAGED_CLIENTS,
  NAV_BAR_BOTTOM_CONSTANTS_FOR_PROFESSIONALS,
  NAV_BAR_BOTTOM_ID,
  NAV_BAR_CONSTANTS,
  NAV_BAR_CONSTANTS_FOR_PROFESSIONALS,
  TAX_PROFESSIONAL_INVITES_PATH,
  TAX_SUMMARY_PATH
} from '@constants/navBarConstants';
import { useClientBannerHeight } from '@hooks';
import { ACCOUNT_TYPE, NotifierTypes, USER_ROLE } from '@constants/common';
import { updateValue } from '@reducers/leftNavigation';
import { useAppDispatch } from '@store/store';
import { RootState } from '@store/reducers';
import { useLogoutUserMutation } from '@containers/login-flow/sign-in/api';
import { useGetPendingInviteCountQuery } from '@containers/tax-professionals/api';
import { gAEventTracker, translate, setUserLogout } from '@utils';
import { CircularSkeleton, TextSkeleton } from '@components';
import { eventKeyMapper } from '@constants/gaEvents';
import { PLAN_TYPES } from '@constants/subscriptionConstants';
import { ImperativeRefType } from '@components/ripple-effect/types';
import { showNotifier } from '@reducers/appReducer';
import routesPath, { TAX_PROFESSIONALS } from '@routes/RoutesPath';
import { LeftNavProps, NavBarItemType } from './types';
import COLORS from '@constants/colors';

const tooltipStyle = {
  arrow: {
    sx: {
      color: COLORS.WHITE,
      '&:before': {
        border: '1px solid',
        borderColor: COLORS.GREY_CLOUD
      }
    }
  },
  tooltip: {
    sx: {
      color: COLORS.DAVY_GREY,
      backgroundColor: COLORS.WHITE,
      fontSize: '16px',
      border: '1px solid',
      borderColor: COLORS.GREY_CLOUD,
      borderRadius: '6px'
    }
  }
};

const COUNT_THRESHOLD = 9;

const SideMenu: FC<LeftNavProps> = props => {
  const { isClientViewBannerActive } = props;

  const [userNavBarItems, setUserNavBarItems] = useState<NavBarItemType[]>([]);
  const [userNavBarBotttomItems, setUserNavBarBottomItems] = useState([]);
  const [selectedTab, setSelectedTab] = useState('');
  const [animation, setAnimation] = useState(false);
  const [selectedChildTab, setSelectedChildTab] = useState('');

  const [clientViewBannerHeight] = useClientBannerHeight(
    isClientViewBannerActive
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const ref = useRef<{ [x: string]: ImperativeRefType }>({});

  const isMobileView = useMediaQuery('(max-width:640px)');

  const {
    subscriptionDetails: userSubscription,
    userDetails,
    isNavOpen,
    disableGiveAway,
    clientProfile
  } = useSelector((state: RootState) => ({
    ...state.rootReducer.subscriptionDetails,
    ...state.rootReducer.userDetails,
    ...state.rootReducer.leftNavigation,
    ...state.rootReducer.app
  }));

  const [userLogout, logoutResponse] = useLogoutUserMutation();
  const { data: pendingInviteCount } = useGetPendingInviteCountQuery(
    !(
      userDetails.isActive &&
      userDetails.phone?.number &&
      !userDetails.isDeleted &&
      userDetails.userType === USER_ROLE.PERSONAL
    ) && skipToken,
    { refetchOnFocus: true }
  );

  useEffect(() => {
    if (userDetails.userType === USER_ROLE.PROFESSIONAL) {
      if (isClientViewBannerActive) {
        const filteredItems = NAV_BAR_CONSTANTS.filter(
          item => !item.hideOnProfessionalView
        );
        setUserNavBarItems(filteredItems);
        setUserNavBarBottomItems(
          clientProfile.type === ACCOUNT_TYPE.MANAGED
            ? NAV_BAR_BOTTOM_CONSTANTS_FOR_MANAGED_CLIENTS
            : []
        );
        return;
      }
      setUserNavBarItems(NAV_BAR_CONSTANTS_FOR_PROFESSIONALS);
      setUserNavBarBottomItems(NAV_BAR_BOTTOM_CONSTANTS_FOR_PROFESSIONALS);
      return;
    }
    setUserNavBarItems(NAV_BAR_CONSTANTS);
    setUserNavBarBottomItems(NAV_BAR_BOTTOM_CONSTANTS);
  }, [userDetails.userType, isClientViewBannerActive, clientProfile.type]);

  const { hash } = window.location;

  useEffect(() => {
    const hashSplit = hash?.split('/');
    const baseHash = hash?.split('/')[1];
    let activeTab = '';
    if (baseHash === TAX_PROFESSIONALS) {
      activeTab = `${TAX_PROFESSIONALS}/${hashSplit[2]?.split('?')[0]}`;
    } else {
      activeTab = baseHash?.split('?')[0];
    }
    if (
      NAV_BAR_ACCORDIAN_CONSTANTS[`/${baseHash}`] &&
      NAV_BAR_ACCORDIAN_CONSTANTS[`/${baseHash}`].find(
        item => item.path === `/${hashSplit[2]}`
      )
    ) {
      setSelectedTab(`/${baseHash}`);
      setSelectedChildTab(`/${hashSplit[2]}`);
    } else {
      setSelectedTab(activeTab ? `/${activeTab}` : userNavBarItems[0]?.path);
      setSelectedChildTab('');
    }
  }, [hash]);

  useEffect(() => {
    // for auto closing the left nav if its a mobile-view
    if (isMobileView) {
      dispatch(updateValue(false));
    }
    setAnimation(false);
  }, [isMobileView]);

  useEffect(() => {
    if (selectedTab && selectedChildTab) {
      navigate(`${selectedTab}${selectedChildTab}`);
    }
  }, [selectedChildTab]);

  useEffect(() => {
    logoutResponse.isSuccess && setUserLogout();
    if (logoutResponse.isError) {
      dispatch(
        showNotifier({
          message: translate('notifierMessage.logoutError'),
          type: NotifierTypes.ERROR
        })
      );
    }
  }, [logoutResponse]);

  const handleViewProfileClick = () => {
    userLogout();
  };

  const handleNavBottomBarClick = (event, id: string) => {
    ref.current[id]?.getMouseClickPosition(event);
    if (isMobileView) {
      dispatch(updateValue(!isNavOpen));
      setAnimation(true);
    }
    setSelectedChildTab('');
    switch (id) {
      case NAV_BAR_BOTTOM_ID.settings:
        if (
          userDetails.userType === USER_ROLE.PERSONAL ||
          clientProfile.type === ACCOUNT_TYPE.MANAGED
        )
          navigate(routesPath.SETTINGS);
        else navigate(`${routesPath.TAX_PROFESSIONALS}/settings`);
        break;
      case NAV_BAR_BOTTOM_ID.plan: {
        gAEventTracker(eventKeyMapper.upgradePlanFromNav);
        navigate(`${routesPath.SETTINGS}/plans`);
        break;
      }
    }
  };

  const handleSelectedTab = (event, tab: string) => {
    ref.current[tab]?.getMouseClickPosition(event);
    gAEventTracker(eventKeyMapper.leftMainMenu, `LeftMainMenu-${tab}`);
    if (isMobileView) {
      dispatch(updateValue(!isNavOpen));
      setAnimation(true);
    }
    navigate(tab);
    setSelectedChildTab('');
    setSelectedTab(tab);
  };

  const handleMenuClick = () => {
    dispatch(updateValue(!isNavOpen));
    setAnimation(true);
  };

  const handleAccordionToggle = (path: string) => {
    if (path !== selectedTab) {
      setSelectedTab(path);
      if (!selectedChildTab) {
        const selectedTabDetails = NAV_BAR_CONSTANTS.find(
          tabBar => tabBar.path === path
        );
        setSelectedChildTab(selectedTabDetails.child[0].path);
      }
      return;
    }
    setSelectedTab('');
  };

  const handleSelectedChildTab = (event, childPath: string) => {
    ref.current[childPath]?.getMouseClickPosition(event);
    gAEventTracker(eventKeyMapper.leftMainMenu, `LeftMainMenu-${childPath}`);
    if (isMobileView) {
      dispatch(updateValue(!isNavOpen));
      setAnimation(true);
    }
    setSelectedChildTab(childPath);
  };

  const handleGiveawayClick = () => {
    if (isMobileView) dispatch(updateValue(!isNavOpen));
    navigate(routesPath.GIVE_AWAY);
  };

  const getVisibility = useCallback(() => {
    if (isNavOpen) return 'visible';
    return 'hidden';
  }, [isNavOpen]);

  const navigationBarPlan = navBottomBarItems => {
    if (navBottomBarItems.id == NAV_BAR_BOTTOM_ID.plan) {
      if (userSubscription.subscriptionType !== '') {
        if (userSubscription.subscriptionType === PLAN_TYPES.FREE) {
          navBottomBarItems.navText = translate('myPlans');
        } else {
          navBottomBarItems.navText = translate('myPlans');
        }
      } else {
        navBottomBarItems.navText = translate('myPlans');
      }
    }
  };

  const giveAway = () => {
    return (
      <div
        className={`flex flex-row items-center ${
          isNavOpen || isMobileView ? 'p-[12px]' : 'p-[6px]'
        }
        m-2 bg-primaryColor/10 rounded-[5px] cursor-pointer`}
        onClick={handleGiveawayClick}>
        <div
          className={`flex shrink-0 justify-center items-center
         bg-hawksBlue rounded-full  ${
           isNavOpen || isMobileView
             ? ' w-[60px] h-[60px]'
             : 'w-[50px] h-[50px]'
         }`}>
          <GiftIcon />
        </div>
        <div className={`flex flex-col ml-[13px] ${getVisibility()}`}>
          <div className="text-base font-medium text-primaryColor">
            {translate('giveaway.header')}
          </div>
          <div className="text-xs text-secondaryColor break-words">
            {translate('giveaway.info')}
          </div>
        </div>
      </div>
    );
  };

  const displayMode = () => {
    return (
      <div
        className={`flex items-center py-[3px] px-1 mb-4 ${
          isNavOpen ? 'ml-2' : 'mx-2'
        } max-w-[110px] transition duration-300 hover:scale-[103%] mt-2  
  h-[55px] bg-whiteLilac rounded-[6px] cursor-pointer`}>
        {DISPLAY_MODE.map(mode => {
          const { id, icon: ModeIcon, isActive } = mode;
          return (
            <Tooltip
              key={id}
              title={translate('comingSoon!')}
              placement="right"
              enterTouchDelay={0}
              disableHoverListener={isActive}
              disableFocusListener={isActive}
              disableTouchListener={isActive}
              arrow={true}
              componentsProps={tooltipStyle}>
              <div
                className={`flex justify-center items-center w-1/2 h-full 
        ${
          isActive ? 'bg-white' : 'bg-whiteLilac cursor-not-allowed'
        }  rounded-[6px]`}>
                <ModeIcon className={`${!isNavOpen ? 'w-5 h-5' : 'w-6 h-6'}`} />
              </div>
            </Tooltip>
          );
        })}
      </div>
    );
  };

  const notificationDot = () => {
    return (
      <Tooltip
        title={translate('ca.pendingCountTooltip', {
          count: pendingInviteCount.pending
        })}
        enterTouchDelay={0}
        arrow={true}
        className="fixed top-0 left-0">
        <span className="flex relative ml-2 w-[18px] h-[18px]">
          <span
            className="inline-flex absolute w-full h-full bg-primaryColor
             rounded-full opacity-75 animate-ping"
          />
          <span
            className="inline-flex relative justify-center items-center w-[18px] h-[18px] 
          text-[9px] text-white bg-primaryColor rounded-full">
            {pendingInviteCount.pending > COUNT_THRESHOLD
              ? `${COUNT_THRESHOLD}+`
              : pendingInviteCount.pending}
          </span>
        </span>
      </Tooltip>
    );
  };

  const accordianHeading = (item: NavBarItemType) => {
    const {
      path = '',
      navText = '',
      dataTestId = '',
      icon: NavbarIcon,
      iconDefaultFill,
      iconDefaultStroke
    } = item;
    return (
      <div
        data-testid={dataTestId}
        className={`flex relative flex-row items-center h-10
    ${isNavOpen || isMobileView ? 'justify-start' : 'justify-center'}`}>
        <NavbarIcon fill={iconDefaultFill} stroke={iconDefaultStroke} />
        <span
          className={`overflow-hidden ml-3 text-base font-medium text-slateGrey  whitespace-nowrap
         ${!isMobileView ? getVisibility() : ''}`}>
          {navText}
        </span>
        {selectedTab !== path && showNotificationDot(path) && notificationDot()}
      </div>
    );
  };

  const accordianBody = (data = [], parentPath: string) => (
    <div className="flex flex-col mb-1 w-full bg-whiteSmoke">
      {data.map(item => {
        const { icon: NavbarIcon } = item;
        if (
          !(
            userDetails.userType === USER_ROLE.PROFESSIONAL &&
            item.hideOnProfessionalView
          )
        )
          return (
            <div
              key={item.path}
              className={`flex relative flex-row items-center hover:bg-hawksBlue/30 
                  ${
                    isNavOpen || isMobileView
                      ? 'justify-start pl-5'
                      : 'justify-center'
                  } 
                    mx-2 my-2 h-10 cursor-pointer ${
                      selectedChildTab === item.path &&
                      'bg-titanWhite rounded-md'
                    } 
         overflow-hidden`}
              onClick={event => handleSelectedChildTab(event, item.path)}
              data-testid={item.dataTestId}>
              <NavbarIcon
                fill={`${
                  selectedChildTab === item.path
                    ? item.iconSelectedFill
                    : item.iconDefaultFill
                }`}
                stroke={`${
                  selectedChildTab === item.path
                    ? item.iconSelectedStroke
                    : item.iconDefaultStroke
                }`}
              />
              <span
                className={`ml-3 text-base font-medium ${
                  !isMobileView ? getVisibility() : ''
                }
                    ${
                      selectedChildTab === item.path
                        ? 'text-primaryColor'
                        : 'text-slateGrey'
                    } hover:scale-100 
           whitespace-nowrap overflow-hidden`}>
                {item.navText}
              </span>
              {showNotificationDot(parentPath, item.path) && notificationDot()}
              <RippleEffect ref={el => (ref.current[item.path] = el)} />
            </div>
          );
      })}
    </div>
  );

  const getRippleColor = (id: string) => {
    if (id === NAV_BAR_BOTTOM_ID.plan) {
      return 'bg-almond';
    }
    return 'bg-tealishBlue';
  };

  const getLeftNavAnimation = () => {
    if (animation) {
      if (isNavOpen) return 'animate-slide-out-from-right w-full';
      return 'animate-slide-out-to-left';
    }
    return 'w-0';
  };

  const showNotificationDot = (parentPath: string, childPath?: string) => {
    if (!childPath) {
      switch (`${parentPath}`) {
        case TAX_SUMMARY_PATH:
          if (pendingInviteCount?.pending > 0) {
            return true;
          }
          return false;
        default:
          return false;
      }
    } else {
      switch (`${parentPath}${childPath}`) {
        case TAX_PROFESSIONAL_INVITES_PATH:
          if (pendingInviteCount?.pending > 0) {
            return true;
          }
          return false;
        default:
          return false;
      }
    }
  };

  return (
    <>
      {isMobileView ? (
        <>
          <div
            id="mobile-header"
            className={`flex flex-row justify-center h-[75px] z-[100] 
          items-center bg-white border-b-2 border-gray-200 w-full 
          p-6`}>
            <HamburgerMenu
              className="absolute left-5 cursor-pointer"
              onClick={handleMenuClick}
            />
            <BetaLogo />
          </div>
          <div
            className={`flex flex-col absolute 
             bg-white left-0 ${getLeftNavAnimation()} h-[-webkit-fill-available]
              overflow-auto z-[100] overflow-x-hidden`}
            style={{
              maxHeight: !clientViewBannerHeight
                ? '100vh'
                : `calc(100vh - ${clientViewBannerHeight}px)`
            }}>
            <div className="flex flex-row justify-between items-center mx-6 mt-2 mb-11">
              <BetaLogo />
              <CloseIconBlue
                className="cursor-pointer"
                onClick={handleMenuClick}
              />
            </div>
            <div className="flex flex-col justify-between h-full bg-white">
              <div>
                {userNavBarItems.map(navBarItem => {
                  const { icon: NavbarIcon } = navBarItem;
                  return navBarItem.child ? (
                    <div key={navBarItem.path}>
                      <Accordian
                        dataTestId="accordianHeading"
                        styleConfig={{
                          accordianHeadingWrapperStyle:
                            'px-5 hover:bg-hawksBlue/30',
                          accordianStyle: 'p-0 mb-3',
                          iconColor: selectedChildTab
                            ? COLORS.PRIMARY_COLOR
                            : COLORS.BLACK_GREEN
                        }}
                        contentConfig={{
                          accordianHeading: accordianHeading(navBarItem),
                          accordianBody: accordianBody(
                            navBarItem.child,
                            navBarItem.path
                          ),
                          handleOnClickHeading: () =>
                            handleAccordionToggle(navBarItem.path)
                        }}
                        conditionalFlag={{
                          chevronDownPresent: true,
                          showDetails: selectedTab === navBarItem.path,
                          disableOnClick: false
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      key={navBarItem.path}
                      className={`flex flex-row items-center hover:bg-hawksBlue/30 
                      ${
                        isNavOpen
                          ? 'justify-start pl-3 hover:scale-[103%]'
                          : 'hover:scale-[110%]'
                      }
         mx-2 mb-4 h-10 cursor-pointer ${
           selectedTab === navBarItem.path && 'bg-whiteSmoke rounded-md'
         } 
         overflow-hidden`}
                      onClick={event =>
                        handleSelectedTab(event, navBarItem.path)
                      }
                      data-testid={navBarItem.dataTestId}>
                      <NavbarIcon
                        fill={`${
                          selectedTab === navBarItem.path
                            ? navBarItem.iconSelectedFill
                            : navBarItem.iconDefaultFill
                        }`}
                        stroke={`${
                          selectedTab === navBarItem.path
                            ? navBarItem.iconSelectedStroke
                            : navBarItem.iconDefaultStroke
                        }`}
                      />
                      <span
                        className={`overflow-hidden ml-3 text-base font-medium whitespace-nowrap
                     ${
                       selectedTab === navBarItem.path
                         ? 'text-primaryColor'
                         : 'text-slateGrey'
                     } `}>
                        {navBarItem.navText}
                      </span>
                      {showNotificationDot(navBarItem.path) &&
                        notificationDot()}
                    </div>
                  );
                })}
                {userDetails.userType === USER_ROLE.PERSONAL &&
                  !disableGiveAway &&
                  giveAway()}
              </div>
              <div className="flex flex-col justify-end w-full">
                {userDetails.userType === USER_ROLE.PERSONAL && displayMode()}
                <>
                  {userNavBarBotttomItems.map(navBottomBarItem => {
                    const { icon: NavbarBottomIcon } = navBottomBarItem;
                    navigationBarPlan(navBottomBarItem);
                    return (
                      <div
                        key={navBottomBarItem.id}
                        className={`flex flex-row  px-3 py-[7px] cursor-pointer mx-2
                        ${
                          clientProfile.type === ACCOUNT_TYPE.MANAGED
                            ? 'mb-4'
                            : ''
                        }
                    ${
                      selectedTab === navBottomBarItem.path
                        ? 'text-primaryColor bg-whiteSmoke rounded-md'
                        : navBottomBarItem.defaultTextColor
                    }
                      ${navBottomBarItem.style}`}
                        onClick={event =>
                          handleNavBottomBarClick(event, navBottomBarItem.id)
                        }
                        data-testid={navBottomBarItem.dataTestId}>
                        <NavbarBottomIcon
                          fill={`${
                            selectedTab === navBottomBarItem.path
                              ? navBottomBarItem.iconSelectedFill
                              : navBottomBarItem.iconDefaultFill
                          }`}
                          stroke={`${
                            selectedTab === navBottomBarItem.path
                              ? navBottomBarItem.iconSelectedStroke
                              : navBottomBarItem.iconDefaultStroke
                          }`}
                        />
                        <span className="overflow-hidden ml-[9px]">
                          {translate(navBottomBarItem.navText)}
                        </span>
                      </div>
                    );
                  })}
                </>
                {!isClientViewBannerActive && (
                  <ProfileComponent
                    handleViewProfileClick={handleViewProfileClick}
                    type={getVisibility()}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div
          className={`flex fixed flex-col justify-between  
          overflow-auto z-[100] overflow-x-hidden customNormalScroll
        bg-white border-r-2 border-gray-200 ${isNavOpen ? 'w-80' : 'w-[5rem]'} 
          transition-[width] duration-[725ms] ease-out delay-0`}
          style={{
            height: clientViewBannerHeight
              ? `calc(100vh - ${clientViewBannerHeight}px)`
              : '100vh'
          }}>
          <div className="flex flex-col mt-5">
            <div
              className={`flex flex-row ${
                isNavOpen ? 'justify-between' : 'justify-center'
              } items-center mx-4 mb-8`}>
              <div className={getVisibility()}>
                <BetaLogo />
              </div>
              <HamburgerMenu
                className="cursor-pointer"
                onClick={handleMenuClick}
              />
            </div>
            {userNavBarItems.map(navBarItem => {
              const { icon: NavbarIcon } = navBarItem;
              return navBarItem.child ? (
                <div key={navBarItem.path}>
                  <Accordian
                    dataTestId="accordianHeading"
                    styleConfig={{
                      accordianHeadingWrapperStyle: `sm:px-5 ${
                        selectedTab !== navBarItem.path
                          ? 'hover:bg-hawksBlue/30'
                          : ''
                      }`,
                      accordianStyle: 'p-0',
                      accordianHeadingStyle: `${
                        !isNavOpen || isMobileView ? 'justify-center' : ''
                      }`,
                      iconColor: selectedChildTab
                        ? COLORS.PRIMARY_COLOR
                        : COLORS.BLACK_GREEN
                    }}
                    contentConfig={{
                      accordianHeading: accordianHeading(navBarItem),
                      accordianBody: accordianBody(
                        navBarItem.child,
                        navBarItem.path
                      ),
                      handleOnClickHeading: () =>
                        handleAccordionToggle(navBarItem.path)
                    }}
                    conditionalFlag={{
                      chevronDownPresent: isNavOpen || isMobileView,
                      showDetails: navBarItem.path === selectedTab
                    }}
                  />
                </div>
              ) : (
                <div
                  key={navBarItem.path}
                  className={`flex relative flex-row items-center hover:bg-hawksBlue/30 
                  ${isNavOpen ? 'justify-start pl-3 ' : 'justify-center'} 
                    mx-2 mb-4 h-10 cursor-pointer ${
                      selectedTab === navBarItem.path &&
                      'bg-whiteSmoke rounded-md'
                    } 
         overflow-hidden transition duration-150`}
                  onClick={event => handleSelectedTab(event, navBarItem.path)}
                  data-testid={navBarItem.dataTestId}>
                  <NavbarIcon
                    fill={`${
                      selectedTab === navBarItem.path
                        ? navBarItem.iconSelectedFill
                        : navBarItem.iconDefaultFill
                    }`}
                    stroke={`${
                      selectedTab === navBarItem.path
                        ? navBarItem.iconSelectedStroke
                        : navBarItem.iconDefaultStroke
                    }`}
                  />
                  <span
                    className={`ml-3 text-base font-medium ${getVisibility()}
                    ${
                      selectedTab === navBarItem.path
                        ? 'text-primaryColor'
                        : 'text-slateGrey'
                    } hover:scale-100 
           whitespace-nowrap overflow-hidden`}>
                    {navBarItem.navText}
                  </span>
                  {showNotificationDot(navBarItem.path) && notificationDot()}
                  <RippleEffect
                    ref={el => (ref.current[navBarItem.path] = el)}
                  />
                </div>
              );
            })}
            {userDetails.userType === USER_ROLE.PERSONAL &&
              !disableGiveAway &&
              giveAway()}
          </div>
          <div>
            {userDetails.userType === USER_ROLE.PERSONAL && displayMode()}
            <>
              {userDetails.email === '' ||
              (userSubscription.subscriptionType === '' &&
                userDetails.userType === USER_ROLE.PERSONAL &&
                userDetails.isActive) ? (
                [...Array(userNavBarBotttomItems.length)].map((item, index) => (
                  <div key={index} className="flex flex-row pb-3 pl-4">
                    <CircularSkeleton width={25} height={25} />
                    <div className="pl-3">
                      <TextSkeleton width={150} fontSize="14px" />
                    </div>
                  </div>
                ))
              ) : (
                <>
                  {userNavBarBotttomItems.map(navBottomBarItem => {
                    const { icon: NavbarBottomIcon } = navBottomBarItem;
                    navigationBarPlan(navBottomBarItem);
                    return (
                      <div key={navBottomBarItem.id}>
                        <div
                          className={`flex flex-row  py-[7px] relative overflow-hidden cursor-pointer mx-2 
                        ${navBottomBarItem.style}
                        ${
                          clientProfile.type === ACCOUNT_TYPE.MANAGED
                            ? 'mb-4'
                            : ''
                        }
                      ${
                        selectedTab === navBottomBarItem.path
                          ? 'text-primaryColor bg-whiteSmoke rounded-md'
                          : navBottomBarItem.defaultTextColor
                      } 
                    ${!isNavOpen ? 'justify-center' : ' px-3'}`}
                          onClick={event =>
                            handleNavBottomBarClick(event, navBottomBarItem.id)
                          }
                          data-testid={navBottomBarItem.dataTestId}>
                          <NavbarBottomIcon
                            fill={`${
                              selectedTab === navBottomBarItem.path
                                ? navBottomBarItem.iconSelectedFill
                                : navBottomBarItem.iconDefaultFill
                            }`}
                            stroke={`${
                              selectedTab === navBottomBarItem.path
                                ? navBottomBarItem.iconSelectedStroke
                                : navBottomBarItem.iconDefaultStroke
                            }`}
                          />
                          <span
                            className={`ml-[9px] whitespace-nowrap hover:scale-100
                               overflow-hidden ${getVisibility()}`}>
                            {translate(navBottomBarItem.navText)}
                          </span>
                          <RippleEffect
                            ref={el => (ref.current[navBottomBarItem.id] = el)}
                            rippleClass={getRippleColor(navBottomBarItem.id)}
                          />
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </>
            {!isClientViewBannerActive && (
              <ProfileComponent
                handleViewProfileClick={handleViewProfileClick}
                type={getVisibility()}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SideMenu;
