import React, { FC } from 'react';

import { TextSkeleton } from '@components';
import { SourceSettingsProps } from './types';

const SettingsSkeleton: FC<SourceSettingsProps> = props => {
  const { isProfessional } = props;

  const arrayLength = isProfessional ? 1 : 3;

  return (
    <div className=" p-5 w-full sm:p-[32px]">
      {[...Array(arrayLength)].map((item, index) => (
        <div
          key={index}
          className="py-[25px] px-[19px] mb-10 bg-white rounded-md ">
          <div className="p-7 pt-3 w-full rounded-md border border-harp">
            <div className="pb-3  border-b border-harp">
              <TextSkeleton width={170} fontSize="14px" />
            </div>
            {[...Array(5)].map((row, ind) => (
              <div
                key={ind}
                className="flex flex-row justify-between pt-4 mt-2 ">
                <TextSkeleton width={100} fontSize="14px" />
                <TextSkeleton width={50} fontSize="14px" />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SettingsSkeleton;
