import React, { FC } from 'react';

import { EXCHANGE_INFO } from '@containers/source/constants';
import { Bulb } from '@assets/icons';
import { NoteDetails, NotesSectionProps } from './types';

const NotesSection: FC<NotesSectionProps> = props => {
  const { exchangeCode, showBorder = true, noteType = 'notes' } = props;
  return (
    <div>
      {showBorder && (
        <div className="mb-3 w-full border-[1px] border-greyCloud border-dashed" />
      )}
      {EXCHANGE_INFO[exchangeCode][noteType].map((noteDetails: NoteDetails) => (
        <div className="inline-flex items-start  mt-2" key={noteDetails.note}>
          {!noteDetails?.hideIcon && (
            <Bulb className="inline shrink-0 mr-2" width="20px" />
          )}
          <div className="pt-[3px] text-base font-normal list-decimal text-comet whitespace-pre-line">
            <p dangerouslySetInnerHTML={{ __html: noteDetails.note }} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default NotesSection;
