import React from 'react';

import { CircularSkeleton, TextSkeleton } from '@components';

const VerifyPageSkeleton = () => (
  <div className="flex flex-col items-center w-full">
    <CircularSkeleton width={50} height={50} />
    <div className="flex flex-col items-center my-7 w-full">
      <TextSkeleton
        fontSize="24px"
        customStyles={{ width: '80%', marginBottom: '13px' }}
      />
      <TextSkeleton fontSize="14px" customStyles={{ width: '75%' }} />
      <TextSkeleton fontSize="14px" customStyles={{ width: '40%' }} />
    </div>
    <TextSkeleton
      fontSize="14px"
      customStyles={{ width: '30%', marginTop: '11px' }}
    />
    <TextSkeleton
      fontSize="14px"
      customStyles={{ width: '26%', marginTop: '11px' }}
    />
  </div>
);

export default VerifyPageSkeleton;
