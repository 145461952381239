import { createSlice } from '@reduxjs/toolkit';

import { UserDetails } from '@containers/login-flow/sign-in/types';
import { OfferDetailsPayloadType } from './types';

const initialState = {
  userDetails: {
    id: '',
    email: '',
    firstName: '',
    isActive: false,
    lastName: '',
    phone: {
      countryCode: '',
      number: ''
    },
    photo: '',
    hasPassword: false,
    twoFaEnabled: false,
    isDeleted: false,
    hardDeletedAt: '',
    userType: '',
    companyName: '',
    origin: ''
  },
  couponDetails: {
    discountValue: '',
    discountType: '',
    partnerName: ''
  },
  disableGiveAway: true
};

export const userDetailsSlice = createSlice({
  name: 'homePage',
  initialState,
  reducers: {
    updateUserDetails: (state, { payload }: { payload: UserDetails }) => {
      state.userDetails = {
        id: payload.id,
        email: payload.email,
        firstName: payload.first_name,
        isActive: payload.is_active,
        lastName: payload.last_name,
        phone: {
          countryCode: payload.phone_country_code,
          number: payload.phone
        },
        photo: payload.photo,
        hasPassword: payload.has_password,
        twoFaEnabled: payload.two_fa_enabled,
        isDeleted: payload.is_deleted,
        hardDeletedAt: payload.hard_deleted_at,
        userType: payload.user_type,
        companyName: payload.company_name,
        origin: payload.origin
      };
    },
    clearUserDetails: state => {
      state.userDetails = initialState.userDetails;
    },
    updateOfferDetails: (
      state,
      { payload }: { payload: OfferDetailsPayloadType }
    ) => {
      state.couponDetails = {
        discountType: payload.discount_type,
        discountValue: payload.discount_value,
        partnerName: payload.promotion_name
      };
    },
    clearOfferDetails: state => {
      state.couponDetails = initialState.couponDetails;
    },
    updateGiveAwayStatus: (state, { payload }: { payload: boolean }) => {
      state.disableGiveAway = payload;
    }
  }
});

export const {
  updateUserDetails,
  clearUserDetails,
  updateOfferDetails,
  clearOfferDetails,
  updateGiveAwayStatus
} = userDetailsSlice.actions;

export default userDetailsSlice.reducer;
