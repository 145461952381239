import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { isEmpty } from 'lodash';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material';

import { RadioButtonProps } from './types';
import COLORS from '@constants/colors';

const checkedRadioButtonStyle = {
  '&, &.Mui-checked': {
    color: COLORS.PRIMARY_COLOR
  }
};

const RadioButton: FC<RadioButtonProps> = props => {
  const { handleValueChange, name, radioValues, control, errors, className } =
    props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }) => (
        <FormControl>
          <RadioGroup
            row={true}
            name={name}
            onChange={event => {
              onChange(event);
              handleValueChange(event);
            }}>
            {radioValues?.map(item => (
              <div key={item.id} className={className}>
                <FormControlLabel
                  value={item.id}
                  control={<Radio sx={checkedRadioButtonStyle} />}
                  label={item.label}
                />
              </div>
            ))}
          </RadioGroup>
          {!isEmpty(errors) && (
            <div className="text-xs text-coralRed">{errors[name]?.message}</div>
          )}
        </FormControl>
      )}
    />
  );
};

export default RadioButton;
