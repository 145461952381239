import React, { FC } from 'react';
import { Tooltip } from '@mui/material';

import { ExternalLinkIcon } from '@assets/icons';
import { INDIAN_RUPEE } from '@constants/common';
import {
  getFormattedNumbering,
  getNumberInIndianFormat,
  translate
} from '@utils';
import { NftCardProps } from '../types';

const NftCard: FC<NftCardProps> = props => {
  const { nftCard, customStyles } = props;
  const { name, purchase_value, url } = nftCard;

  return (
    <div
      className={`py-2 px-2 pt-3.5 min-w-[280px] text-xs font-normal bg-white 
            sm:px-6 sm:text-base ${customStyles} mb-3`}>
      <div className="flex justify-between mb-1">
        <div className="mr-2">{translate('dashboard.name')}</div>
        <Tooltip title={name} arrow={true} enterTouchDelay={0}>
          <div className="font-semibold text-davyGrey truncate">{name}</div>
        </Tooltip>
      </div>
      <div className="flex justify-between mb-1">
        <div className="mr-2">{translate('dashboard.purchaseValue')}</div>
        <div className="font-semibold text-davyGrey">
          <Tooltip
            title={getNumberInIndianFormat(
              Number(purchase_value),
              INDIAN_RUPEE.code,
              true
            )}
            arrow={true}
            enterTouchDelay={0}
            placement="bottom-start">
            <div className="w-fit">
              {getFormattedNumbering({
                value: purchase_value,
                showZeroValue: true
              })}
            </div>
          </Tooltip>
        </div>
      </div>
      <div className="flex justify-between mb-1">
        <div className="mr-2">{translate('dashboard.link')}</div>
        <div className="font-semibold text-davyGrey ">
          <ExternalLinkIcon
            className="cursor-pointer"
            onClick={() => window.open(url, '_blank')}
          />
        </div>
      </div>
    </div>
  );
};

export default NftCard;
