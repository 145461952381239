import React, { FC } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import {
  CircularSkeleton,
  RectangularSkeleton,
  TextSkeleton
} from '@components';
import { ClientListingSkeletonProps } from './types';

const ClientListingSkeleton: FC<ClientListingSkeletonProps> = props => {
  const { isFilterRequired = true } = props;
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  const arrayLength = isMobileView ? 3 : 5;

  return (
    <div className="overflow-hidden p-6 w-full mui-md:pt-6">
      {isFilterRequired && (
        <div className="flex mb-5">
          <div
            className="p-1 pl-3 mt-[2px] mr-10 w-[80%] min-w-[120px]
         h-[38px] bg-white rounded-md border-[1px] border-white sm:w-[40%]">
            <TextSkeleton width={60} fontSize="16px" />
          </div>
          <div
            className="flex items-center p-1 px-5 mt-[2px] min-w-[60px]
         h-[38px] bg-white rounded-3xl border-[1px] border-white sm:w-[10%]">
            <TextSkeleton width={50} fontSize="16px" />
          </div>
        </div>
      )}
      <div className="flex overflow-hidden flex-col w-full md:pt-[50px]">
        {[...Array(arrayLength)].map((card, index) => (
          <div key={index}>
            <div
              className="hidden items-center p-2 mb-[12px] 
                h-[62px] bg-white rounded-md mui-md:flex">
              <div className="flex flex-row items-center mr-2 w-[230px] lg:w-[33%]">
                <CircularSkeleton height={30} width={30} />
                <TextSkeleton
                  width={150}
                  customStyles={{ marginLeft: '8px' }}
                />
              </div>
              <div className="flex flex-col w-[450px] lg:w-[33%]">
                <TextSkeleton fontSize="14px" width={150} />
                <TextSkeleton fontSize="14px" width={110} />
              </div>
              <div className="flex flex-col mr-2 w-[180px] lg:w-[18%] xl:w-[24%]">
                <TextSkeleton fontSize="14px" width={50} />
              </div>
              <div className="flex flex-row items-center w-[180px] lg:w-[16%] xl:w-[10%]">
                <RectangularSkeleton
                  height={24}
                  width={56}
                  customStyles={{ marginRight: '16px', borderRadius: '5px' }}
                />
                <CircularSkeleton height={24} width={24} />
              </div>
            </div>
            <div className="flex flex-col p-[22px] mb-[10px] bg-white  rounded-md mui-md:hidden">
              <div className="flex flex-row justify-between mb-4">
                <TextSkeleton fontSize="12px" width={80} />
                <TextSkeleton fontSize="12px" width={110} />
              </div>
              <div className="flex flex-row justify-between mb-4">
                <TextSkeleton
                  fontSize="12px"
                  customStyles={{ height: '18px' }}
                  width={80}
                />
                <div className="flex flex-col items-end">
                  <TextSkeleton fontSize="12px" width={110} />
                  <TextSkeleton fontSize="10px" width={90} />
                </div>
              </div>
              <div className="flex flex-row justify-between mb-4">
                <TextSkeleton fontSize="12px" width={80} />
                <TextSkeleton fontSize="12px" width={50} />
              </div>
              <div className="flex flex-row justify-between">
                <TextSkeleton fontSize="12px" width={80} />
                <div className="flex flex-row">
                  <RectangularSkeleton
                    height={24}
                    width={56}
                    customStyles={{ marginRight: '16px', borderRadius: '5px' }}
                  />
                  <CircularSkeleton height={24} width={24} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ClientListingSkeleton;
