import React, { useState, useEffect, FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { TopBar, TabBar } from '@components';
import {
  ACCOUNT_TYPE,
  ROWS_PER_PAGE,
  TopBarConstants
} from '@constants/common';
import { RootState } from '@store/reducers';
import { AttachmentPinIcon } from '@assets/icons';
import { useQuery } from '@hooks';
import { translate } from '@utils/locale';
import { gAEventTracker } from '@utils/gaUtils';
import { eventKeyMapper } from '@constants/gaEvents';
import { TAB_BAR_CONSTANTS, TAB_BAR_ITEMS } from '@constants/tabBar';
import { SourcesSkeleton } from '@containers/skeleton-loader';
import { ExchangeListView, FileListView, WalletsListView } from './components';
import {
  useLazyGetConnectedFilesQuery,
  useLazyGetLinkedSourcesQuery,
  useLazyGetWalletsQuery
} from './api';
import RoutesPath from '@routes/RoutesPath';

const Source: FC = () => {
  const [selectedTab, setSelectedTab] = useState<string>('');
  const [isExchangeListEmpty, setIsExchangeListEmpty] = useState<boolean>(true);
  const [isUploadedFilesEmpty, setIsUploadedFilesEmpty] =
    useState<boolean>(true);
  const [isWalletEmpty, setIsWalletEmpty] = useState<boolean>(true);
  const [debounceSearchTerm, setDebounceSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [navigateToDefaultTab, setNavigateToDefaultTab] = useState(false);

  const navigate = useNavigate();
  const query = useQuery();

  const { clientId, userDetails, clientProfile } = useSelector(
    (state: RootState) => ({
      ...state.rootReducer.app,
      ...state.rootReducer.userDetails
    })
  );

  const [
    getConnectedFiles,
    {
      data: getConnectedFilesResponse,
      isFetching: isFetchingConnectedFiles,
      isLoading: isLoadingConnectedFiles
    }
  ] = useLazyGetConnectedFilesQuery();
  const [
    getLinkedSources,
    { data: linkedSources, isLoading: isLoadingLinkedSources }
  ] = useLazyGetLinkedSourcesQuery();
  const [
    getWallets,
    {
      data: walletsDetails,
      isLoading: isLoadingWallets,
      isFetching: isFetchingWallets
    }
  ] = useLazyGetWalletsQuery();

  useEffect(() => {
    getLinkedSources({ searchTerm: '', clientId });
    getConnectedFiles({
      searchTerm: '',
      page: 0,
      limit: ROWS_PER_PAGE,
      clientId
    });
    getWallets({
      searchTerm: '',
      page: 0,
      limit: ROWS_PER_PAGE,
      clientId
    });
    setNavigateToDefaultTab(true);
  }, []);

  useEffect(() => {
    if (query.get('tab')) {
      setSelectedTab(query.get('tab'));
    }
  }, [query]);

  useEffect(() => {
    setIsUploadedFilesEmpty(isEmpty(getConnectedFilesResponse?.result));
  }, [getConnectedFilesResponse]);

  useEffect(() => {
    // logic for redirecting to a tab which contains data
    if (
      navigateToDefaultTab &&
      !isLoadingConnectedFiles &&
      !isLoadingLinkedSources &&
      !isLoadingWallets
    ) {
      if (!query.get('tab')) {
        if (linkedSources?.length > 0) {
          setSelectedTab(TAB_BAR_CONSTANTS.CONNECTIONS);
        } else if (getConnectedFilesResponse?.result?.length > 0) {
          setSelectedTab(TAB_BAR_CONSTANTS.UPLOADED_FILES);
        } else if (walletsDetails?.result?.length > 0) {
          setSelectedTab(TAB_BAR_CONSTANTS.WALLET);
        } else {
          setSelectedTab(TAB_BAR_CONSTANTS.CONNECTIONS);
        }
      }
      setNavigateToDefaultTab(false);
    }
  }, [
    isLoadingLinkedSources,
    isLoadingConnectedFiles,
    isLoadingWallets,
    navigateToDefaultTab
  ]);

  useEffect(() => {
    setDebounceSearchTerm('');
  }, [selectedTab]);

  const handleTabSelect = (tabId: string) => {
    setSelectedTab(tabId);
    setCurrentPage(1);
  };

  const getTotalCount = () => {
    if (selectedTab === TAB_BAR_CONSTANTS.UPLOADED_FILES)
      return getConnectedFilesResponse?.pagination?.total_rows;
    if (selectedTab === TAB_BAR_CONSTANTS.WALLET)
      return walletsDetails?.pagination?.total_rows;
  };

  const getNoOfRecordsDisplayed = () => {
    if (selectedTab === TAB_BAR_CONSTANTS.UPLOADED_FILES)
      return getConnectedFilesResponse?.result?.length || 0;
    if (selectedTab === TAB_BAR_CONSTANTS.WALLET)
      return walletsDetails?.result?.length || 0;
  };

  const handleConnectExchangeOnclick = () => {
    gAEventTracker(eventKeyMapper.connectExchangesBtnInSources);
    navigate(`${RoutesPath.CONNECT_EXCHANGE}`);
  };

  // TODO: For futute use - to get label for connected exchanges and upload files
  // const getPaginationLabel = () => {
  //   const currentTab = TAB_BAR_ITEMS.sourcePage.find(
  //     data => data.id === selectedTab
  //   );
  //   return currentTab?.label;
  // };

  const showPrimaryButton = () => {
    if (
      (isUploadedFilesEmpty &&
        selectedTab === TAB_BAR_CONSTANTS.UPLOADED_FILES) ||
      (isExchangeListEmpty && selectedTab === TAB_BAR_CONSTANTS.CONNECTIONS) ||
      (isWalletEmpty && selectedTab === TAB_BAR_CONSTANTS.WALLET)
    )
      return false;
    return true;
  };

  const primaryButtonConfig = {
    showButton: showPrimaryButton(),
    buttonLabel: translate('sourcePage.connectExchanges'),
    buttonHandler: handleConnectExchangeOnclick,
    icon: AttachmentPinIcon,
    customClass: 'text-white bg-primaryColor'
  };

  const renderContent = () => {
    switch (selectedTab) {
      case TAB_BAR_CONSTANTS.CONNECTIONS:
        return (
          <ExchangeListView
            getLinkedSources={getLinkedSources}
            linkedExchanges={linkedSources}
            isLoadingUserExchanges={isLoadingLinkedSources}
            setIsExchangeListEmpty={setIsExchangeListEmpty}
            userType={userDetails.userType}
            clientId={clientId}
            isManaged={clientProfile.type === ACCOUNT_TYPE.MANAGED}
          />
        );
      case TAB_BAR_CONSTANTS.UPLOADED_FILES:
        return (
          <FileListView
            getConnectedFiles={getConnectedFiles}
            isFetchingConnectedFiles={isFetchingConnectedFiles}
            isLoadingConnectedFiles={isLoadingConnectedFiles}
            getConnectedFilesResponse={getConnectedFilesResponse?.result}
            debounceSearchTerm={debounceSearchTerm}
            pageNumber={currentPage}
            limit={ROWS_PER_PAGE}
            noOfRecordsDisplayed={getNoOfRecordsDisplayed()}
            totalCount={getTotalCount()}
            updateSearchTerm={setDebounceSearchTerm}
            paginationData={getConnectedFilesResponse?.pagination}
            setCurrentPage={setCurrentPage}
            userType={userDetails.userType}
            clientId={clientId}
            isManaged={clientProfile.type === ACCOUNT_TYPE.MANAGED}
          />
        );
      case TAB_BAR_CONSTANTS.WALLET:
        return (
          <WalletsListView
            setIsWalletEmpty={setIsWalletEmpty}
            currentPage={currentPage}
            linkedWallets={walletsDetails?.result}
            paginationData={walletsDetails?.pagination}
            setDebounceSearchTerm={setDebounceSearchTerm}
            debounceSearchTerm={debounceSearchTerm}
            isLoadingWallets={isLoadingWallets}
            getWallets={getWallets}
            setCurrentPage={setCurrentPage}
            limit={ROWS_PER_PAGE}
            noOfRecordsDisplayed={getNoOfRecordsDisplayed()}
            totalCount={getTotalCount()}
            isFetchingWallets={isFetchingWallets}
            userType={userDetails.userType}
            clientId={clientId}
            isSharedAccount={clientProfile.type === ACCOUNT_TYPE.SHARED}
          />
        );
      case '':
        return (
          <div className="flex justify-center items-center">
            <div className="w-full">
              <SourcesSkeleton />
            </div>
          </div>
        );
      default:
        return (
          <div className="flex justify-center items-center m-[14px] md:m-6">
            {translate('oopsNotFound')}
          </div>
        );
    }
  };

  return (
    <div>
      <TopBar
        title={TopBarConstants.MY_SOURCES}
        primaryButtonConfig={primaryButtonConfig}
      />
      <div
        className="flex sticky top-[130px] z-10 flex-col justify-between w-full bg-white 
      shadow-shadowBottomTwlightLite xxs:top-[108px] sm:top-0 sm:flex-row">
        <TabBar
          selectedTab={selectedTab}
          handleTabSelect={handleTabSelect}
          tabBarItems={TAB_BAR_ITEMS.sourcePage}
          customHeight="h-[48px]"
          wrapperStyle="pr-4"
        />
      </div>
      {renderContent()}
    </div>
  );
};

export default Source;
