import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { USER_ROLE } from '@constants/common';
import LoginPage from '@pages/SignInPage';
import useScreenDimensions from '@hooks/useScreenDimensions';
import ForgotPasswordPage from '@pages/ForgotPasswordPage';
import PartnerLandingPage from '@pages/PartnerLandingPage';
import SignUpPage from '@pages/SignUpPage';
import RoutesPath from './RoutesPath';

const PublicLayout = () => {
  const { isMobileView } = useScreenDimensions();

  const location = useLocation();

  return (
    <div
      className={`flex ${
        isMobileView ? 'flex-col' : 'flex-row'
      } w-screen h-full selection:bg-primaryColor/20`}>
      <Routes>
        <Route path={RoutesPath.SIGNUP} element={<SignUpPage />} />
        <Route path={RoutesPath.DEFAULT} element={<LoginPage />} />
        <Route path={RoutesPath.SIGNIN} element={<LoginPage />} />
        <Route path={RoutesPath.FORGOT} element={<ForgotPasswordPage />} />
        <Route
          path={RoutesPath.ACCOUNT_SETUP}
          element={<PartnerLandingPage />}
        />

        {/* CA dashboard */}
        <Route
          path={`${RoutesPath.TAX_PROFESSIONALS}/signup`}
          element={<SignUpPage userRole={USER_ROLE.PROFESSIONAL} />}
        />
        <Route
          path={RoutesPath.TAX_PROFESSIONALS}
          element={<LoginPage userRole={USER_ROLE.PROFESSIONAL} />}
        />
        <Route
          path={`${RoutesPath.TAX_PROFESSIONALS}/signin`}
          element={<LoginPage userRole={USER_ROLE.PROFESSIONAL} />}
        />
        <Route
          path={`${RoutesPath.TAX_PROFESSIONALS}/forgot`}
          element={<ForgotPasswordPage userRole={USER_ROLE.PROFESSIONAL} />}
        />
        <Route
          path={`${RoutesPath.TAX_PROFESSIONALS}/*`}
          element={
            <Navigate to={`${RoutesPath.TAX_PROFESSIONALS}/signin`} replace />
          }
        />
        <Route
          path={RoutesPath.ALL}
          element={
            <Navigate
              to={RoutesPath.SIGNIN}
              state={{ from: location.pathname }}
              replace
            />
          }
        />
      </Routes>
    </div>
  );
};

export default PublicLayout;
