export const QUARTERS = {
  1: [4, 5, 6],
  2: [7, 8, 9],
  3: [10, 11, 12],
  4: [13, 14, 15] // 13 - 15 instead of 1 - 3 because the months from Jan to Mar are of the next financial year
};

export const MONTHS = {
  Apr: 4,
  May: 5,
  Jun: 6,
  July: 7,
  Aug: 8,
  Sep: 9,
  Oct: 10,
  Nov: 11,
  Dec: 12,
  Jan: 13,
  Feb: 14,
  Mar: 15
};

export const MONTH_FILTER_IDENTIFIERS = {
  MONTHS: 'MONTHS',
  QUARTER: 'QUARTER'
};

export const TDS_DISABLED_FY = 2022;

export const TDS_DISABLED_MONTHS_FOR_FY_22 = [4, 5, 6];

export const TDS_DISABLED_QUATER_FOR_FY_22 = '1';
