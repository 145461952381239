import { authBaseApi } from '@services/api';

import { translate } from '@utils/locale';
import { ResetPasswordPayload, ValidateTokenPayload } from './types';
import { GenericApiResponse } from 'types/generalTypes';

const AuthBaseAPI = authBaseApi.injectEndpoints({
  endpoints: builder => ({
    validateForgotToken: builder.query<
      GenericApiResponse<string>,
      ValidateTokenPayload
    >({
      query: ({ token }) => ({
        url: '/forgot/verify',
        method: 'POST',
        body: { token }
      }),
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.somethingWentWrong')
      }
    }),
    resetPassword: builder.query<
      GenericApiResponse<string>,
      ResetPasswordPayload
    >({
      query: ({ token, password }) => ({
        url: '/forgot/update',
        method: 'POST',
        body: { token, password }
      }),
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.resetPasswordError')
      }
    })
  })
});

export const { useValidateForgotTokenQuery, useLazyResetPasswordQuery } =
  AuthBaseAPI;
