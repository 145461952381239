import React from 'react';

import { CircularSkeleton, TextSkeleton } from '@components';
import { CardSkeleton } from './components';
import SourceSectionSkeleton from './SourceSectionSkeleton';

const DashboardSkeleton = () => {
  return (
    <>
      <div className="justify-center p-[14px] w-full  md:px-6 md:pt-6">
        <div className="flex flex-col gap-5 justify-between lg:flex-row lg:px-0 lg:pb-2">
          {[...Array(3)].map((item, index) => (
            <CardSkeleton key={index} iconPresent={false} />
          ))}
        </div>
      </div>
      <div className="mx-4 bg-white rounded-lg md:mx-6">
        <div className="flex gap-8 py-2 border-b border-gray-200">
          <div className="ml-7">
            <TextSkeleton fontSize="16px" width={100} />
          </div>
          <TextSkeleton fontSize="16px" width={100} />
        </div>
        <div className="mx-5 mt-11 mb-3 sm:mx-8">
          <TextSkeleton fontSize="16px" width={200} />
          <div className="px-4 pt-3 pb-2 my-3 rounded-lg border border-harp sm:px-6">
            <div className="flex justify-between pb-1  border-b">
              <TextSkeleton fontSize="16px" width={100} />
              <div className="hidden md:block">
                <TextSkeleton fontSize="16px" width={100} />
              </div>
            </div>

            <div className="flex justify-between py-2">
              <div className="flex gap-2">
                <CircularSkeleton height={24} width={24} />
                <TextSkeleton fontSize="16px" width={100} />
              </div>
              <div className="hidden md:block">
                <TextSkeleton fontSize="16px" width={100} />
              </div>
            </div>
            <div className="flex justify-between py-2">
              <div className="flex gap-2">
                <CircularSkeleton height={24} width={24} />
                <TextSkeleton fontSize="16px" width={100} />
              </div>
              <div className="hidden md:block">
                <TextSkeleton fontSize="16px" width={100} />
              </div>
            </div>
            <div className="flex justify-between py-2">
              <div className="flex gap-2">
                <CircularSkeleton height={24} width={24} />
                <TextSkeleton fontSize="16px" width={100} />
              </div>
              <div className="hidden md:block">
                <TextSkeleton fontSize="16px" width={100} />
              </div>
            </div>
          </div>
        </div>
        <SourceSectionSkeleton />
      </div>
    </>
  );
};
export default DashboardSkeleton;
