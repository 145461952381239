import React, { FC } from 'react';
// import { useSelector } from 'react-redux';

import { Button } from '@components';
import { LeftArrow } from '@assets/icons';
// import { PartyPopper } from '@assets/images';
// import { RootState } from '@store/reducers';
// import { translate } from '@utils/locale';
// import {
//   USER_ROLE,
//   DISCOUNT_TYPE,
//   DISCOUNT_TYPE_MAPPER
// } from '@constants/common';
import { TopBarProps } from './types';

const TopBar: FC<TopBarProps> = props => {
  const {
    showNavigateBack = false,
    handleBackClick = () => null,
    title = '',
    primaryButtonConfig = {},
    middleChild = {
      left: <></>,
      right: <></>
    }
  } = props;
  // const { userDetails, couponDetails } = useSelector(
  //   (state: RootState) => state.rootReducer.userDetails
  // );

  return (
    <div id="topBar" className="flex sticky top-0 z-50 flex-col w-full">
      {/* {userDetails.userType === USER_ROLE.PERSONAL && (
        <div
          className="flex flex-row justify-center items-center p-1 w-full min-h-[24px] text-xs font-medium text-center
       text-white bg-primaryColor md:text-base">
          <img
            src={PartyPopper}
            width={20}
            height={20}
            className="shrink-0 mr-1 ml-2 md:mx-2"
          />
          {couponDetails.discountValue ? (
            translate('subscription.partnerOfferBannerText', {
              percentage:
                couponDetails.discountType === DISCOUNT_TYPE.percentage
                  ? couponDetails.discountValue +
                    DISCOUNT_TYPE_MAPPER[couponDetails.discountType]
                  : DISCOUNT_TYPE_MAPPER[couponDetails.discountType] +
                    couponDetails.discountValue,
              partnerName: couponDetails.partnerName
            })
          ) : (
            <div>
              <StringToTSX
                domString={translate('subscription.offerBannerText', {
                  percentage: process.env.REACT_APP_WELCOME_COUPON_PERCENTAGE
                })}
              />
              <span className="mx-1 font-bold">
                {process.env.REACT_APP_WELCOME_COUPON}
              </span>
              {translate('subscription.rushText')}
            </div>
          )}
          <img
            src={PartyPopper}
            width={20}
            height={20}
            className="shrink-0 mr-2 ml-1 -rotate-90 md:mx-2"
          />
        </div>
      )} */}
      <div
        className="flex flex-row justify-between py-[15px] px-4 
      w-full min-h-[80px] font-inter bg-white border-b-[1px] border-mercury sm:px-6">
        <div className="flex items-center text-base font-semibold text-blackGreen sm:text-2xl">
          {showNavigateBack && (
            <LeftArrow
              className="mr-2 cursor-pointer"
              onClick={handleBackClick}
            />
          )}
          {title}
          {middleChild.left}
        </div>
        <div className="flex items-center">
          {middleChild.right}
          {primaryButtonConfig.showButton && (
            <Button
              className={`ml-2 sm:py-[10px] sm:px-5 sm:ml-3 ${primaryButtonConfig.customClass}`}
              onClick={primaryButtonConfig.buttonHandler}
              Icon={primaryButtonConfig.icon}
              iconClass={primaryButtonConfig.iconClass}
              disabled={primaryButtonConfig.isDisabled}
              label={primaryButtonConfig.buttonLabel}
              showMainViewButton={primaryButtonConfig.showMainViewButton}
              breakpoint={850}
              rippleClass="bg-tealishBlue"
              dataTestId={primaryButtonConfig.dataTestId}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TopBar;
