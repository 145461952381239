import React, { FC } from 'react';
import { Paper, List } from '@mui/material';
import OutsideClickHandler from 'react-outside-click-handler';

import { Tooltip } from '@components';
import { getFormattedNumbering, translate } from '@utils';
import { CheckIcon } from '@assets/icons';
import { CheckboxMenuProps } from './types';

const CheckboxMenu: FC<CheckboxMenuProps> = props => {
  const {
    children,
    actionItems,
    visibilty,
    ellipsisRef,
    filterProperty,
    currentFilter,
    handleClickOut,
    handleValueChange,
    tooltipStyle,
    showCount,
    filterSpecificCount,
    dropdownMinWidth = 200
  } = props;

  const handleCheck = event => {
    let updatedList = [...currentFilter];
    if (event.target.checked) {
      updatedList = [...currentFilter, event.target.value];
    } else {
      updatedList.splice(currentFilter.indexOf(event.target.value), 1);
    }
    handleValueChange({ [filterProperty]: updatedList });
  };

  const handleClearAll = () => {
    handleValueChange({ [filterProperty]: [] });
  };

  const getFilterCount = item => {
    let count;
    if (showCount) {
      if (filterSpecificCount && filterSpecificCount[item?.id])
        count = filterSpecificCount[item?.id];
      else if (item && item.count) count = item.count;
    }
    return count
      ? `(${getFormattedNumbering({
          value: count,
          hideRupeeSymbol: true
        })})`
      : '';
  };

  const renderMenu = () => (
    <>
      <Paper
        className="customDavyGreyScroll"
        style={{ maxHeight: 320, minWidth: dropdownMinWidth, overflow: 'auto' }}
        elevation={0}>
        <List style={{ paddingTop: 8 }}>
          <div className="flex flex-col">
            {actionItems?.map(item => {
              const ActionItemIcon = item.icon || <></>;
              return (
                <label
                  key={item.id}
                  htmlFor={item.id}
                  className="text-sm text-davyGrey cursor-pointer">
                  <div className="flex justify-between items-center px-4 h-[38px] hover:bg-hawksBlue/20 cursor-pointer">
                    <div
                      className={`flex flex-row items-center mr-1 ${
                        currentFilter.includes(item.id)
                          ? 'font-semibold'
                          : 'font-medium'
                      }`}>
                      {ActionItemIcon}
                      <span className="focus-within:mr-1 text-sm  text-davyGrey">
                        {item.label}
                      </span>
                      <span className="mx-1 text-secondaryColor">
                        {getFilterCount(item)}
                      </span>
                    </div>
                    <input
                      type="checkbox"
                      id={item.id}
                      name={item.label}
                      value={item.id}
                      className="absolute opacity-0 cursor-pointer customInput"
                      onChange={handleCheck}
                      checked={currentFilter.includes(item.id)}
                    />
                    <CheckIcon className="hidden justify-center checkedIcon" />
                  </div>
                </label>
              );
            })}
          </div>
        </List>
      </Paper>
      <div className="mb-2 border-b border-softPeach" />
      <div
        role="presentation"
        className="pr-4 pb-2 text-sm font-normal text-right text-slateGrey">
        <span onClick={handleClearAll} className="cursor-pointer">
          {translate('transactionPage.clear')}
        </span>
      </div>
    </>
  );

  return (
    <OutsideClickHandler onOutsideClick={handleClickOut}>
      <div onClick={e => e.stopPropagation()}>
        <Tooltip
          body={renderMenu()}
          onHover={false}
          visibility={visibilty}
          onTop={false}
          innerClass={tooltipStyle}
          showPointer={false}
          tooltipRef={ellipsisRef}
          bodyClass="p-0">
          {children}
        </Tooltip>
      </div>
    </OutsideClickHandler>
  );
};

export default CheckboxMenu;
