import React, { FC } from 'react';

import { ErrorRedIcon } from '@assets/icons';
import { translate } from '@utils/locale';
import { LinkExpiredProps } from '../types';

const LinkExpired: FC<LinkExpiredProps> = props => {
  const {
    handleResendClick,
    handleBacktoSignInClick,
    isResetPage = false
  } = props;

  return (
    <>
      <ErrorRedIcon />
      <div className="flex overflow-hidden flex-col justify-center items-center my-7 w-full">
        <div className="text-2xl font-semibold text-center text-blackGreen">
          {translate('linkExpiredPage.linkExpired')}
        </div>
        <div className="mt-[13px] text-sm text-center text-davyGrey">
          {translate(
            isResetPage
              ? 'linkExpiredPage.expiredDescriptionReset'
              : 'linkExpiredPage.expiredDescription'
          )}
        </div>
      </div>
      <div
        className="mt-[11px] text-sm text-center text-primaryColor cursor-pointer"
        onClick={handleResendClick}
        data-testid="resend-link">
        {translate('linkExpiredPage.tryAgain')}
      </div>
      <div
        className="mt-[11px] text-sm text-davyGrey underline cursor-pointer"
        onClick={handleBacktoSignInClick}
        data-testid="back-to-sign-in">
        {translate('linkExpiredPage.backToLogIn')}
      </div>
    </>
  );
};

export default LinkExpired;
