import React, {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  useCallback,
  useEffect,
  useState
} from 'react';
import { useNavigate } from 'react-router-dom';

import { CircularLoader } from '@components';
import { USER_ROLE } from '@constants/common';
import { useForgotPasswordMutation } from './api';
import { ForgotPasswordFieldValues, ForgotPasswordProps } from './types';
import CheckEmail from '@containers/login-flow/sign-in/components/CheckEmail';
import ForgotPassword from './components/ForgotPassword';
import routesPath from '@routes/RoutesPath';

const ForgotPasswordContainer: FC<ForgotPasswordProps> = props => {
  const { userRole } = props;

  const [forgotPassword, forgotPasswordResponse] = useForgotPasswordMutation();
  const navigate = useNavigate();

  const [forgotPasswordSuccess, setForgotPasswordSuccess] =
    useState<boolean>(false);
  const [forgotPasswordValues, setForgotPasswordValues] =
    useState<ForgotPasswordFieldValues | null>(null);

  useEffect(() => {
    if (forgotPasswordResponse.isSuccess) {
      setForgotPasswordSuccess(true);
    }
  }, [forgotPasswordResponse]);

  const handleForgotPassword = (values: ForgotPasswordFieldValues) => {
    setForgotPasswordValues(values);
    if (values !== null)
      forgotPassword({ email: values.email, user_type: userRole });
  };

  const backToSignInClick = () => {
    setForgotPasswordSuccess(false);
    const route =
      userRole === USER_ROLE.PROFESSIONAL
        ? `${routesPath.TAX_PROFESSIONALS}/signin`
        : routesPath.SIGNIN;
    navigate(route);
  };

  const renderChildComponent = useCallback((): DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > => {
    if (forgotPasswordSuccess) {
      return (
        <CheckEmail
          email={forgotPasswordValues.email}
          handleResendClick={() =>
            forgotPassword({
              email: forgotPasswordValues.email,
              user_type: userRole
            })
          }
          handleBackClick={backToSignInClick}
          isConfirmEmail={false}
        />
      );
    }
    return (
      <ForgotPassword
        handleValues={handleForgotPassword}
        disabled={forgotPasswordResponse.isLoading}
        handleBackClick={backToSignInClick}
      />
    );
  }, [forgotPasswordSuccess, location.pathname]);
  return (
    <div className="flex w-full min-h-screen bg-harp">
      {forgotPasswordResponse.isLoading && (
        <CircularLoader isFullScreen={true} />
      )}
      <div className="hidden relative justify-start items-end w-3/5 bg-alabaster rounded-l-lg sm:flex">
        <img
          src="/images/curvedBg.png"
          className="absolute w-full h-full"
          alt=""
        />
        <img
          src="images/SignIn.png"
          className="absolute inset-0 m-auto w-[90%]"
          alt="Sign In"
        />
      </div>
      <div
        className={'flex justify-center w-full bg-white rounded-r-lg sm:w-2/5'}>
        <div className="flex flex-col justify-center items-center w-[70%] h-full sm:w-[60%]">
          {renderChildComponent()}
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordContainer;
