import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: [],
  debounceSearchTerm: '',
  currentPage: 1,
  navigatedFrom: ''
};

export const professionalSlice = createSlice({
  name: 'professional',
  initialState,
  reducers: {
    updateFilterValue: (state, { payload }) => ({
      ...state,
      ...payload
    }),
    resetFilter: state => ({
      ...state,
      status: [],
      debounceSearchTerm: ''
    }),
    updatePage: (state, { payload }) => ({
      ...state,
      currentPage: payload.page
    }),
    resetPage: state => ({
      ...state,
      currentPage: 1
    }),
    updateNavigation: (state, { payload }) => ({
      ...state,
      navigatedFrom: payload
    })
  }
});

export const {
  updateFilterValue,
  resetFilter,
  updatePage,
  resetPage,
  updateNavigation
} = professionalSlice.actions;

export default professionalSlice.reducer;
