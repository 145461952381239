import React, { FC, useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import { debounce, isEmpty } from 'lodash';

import {
  Button,
  ConfirmationPopup,
  EmptyData,
  Paginator,
  SearchBar
} from '@components';
import { useTopContentHeight } from '@hooks';
import { AttachmentPinIcon, RefreshIconBlack } from '@assets/icons';
import { SyncRequestStatus } from '@constants/mySources';
import { useAppDispatch } from '@store/store';
import { showNotifier } from '@reducers/appReducer';
import { getNotifierMessage } from '@utils/notifierUtils';
import { NoConnections, NoSearchResult } from '@assets/images';
import { HandleSyncWalletArgs } from '@containers/source/types';
import { TAB_BAR_CONSTANTS } from '@constants/tabBar';
import {
  NotifierTypes,
  NOTIFIER_MESSAGE_TYPES,
  ROWS_PER_PAGE,
  USER_ROLE
} from '@constants/common';
import { SourcesSkeleton } from '@containers/skeleton-loader';
import {
  useSyncWalletMutation,
  useDisconnectWalletMutation,
  useGetConnectedWalletStatusQuery
} from '@containers/source/api';
import { eventKeyMapper } from '@constants/gaEvents';
import { gAEventTracker, translate } from '@utils';
import { WalletsProps } from './type';
import RoutesPath from '@routes/RoutesPath';
import WalletsTable from './WalletsTable';
import WalletsTableMobile from './WalletsTableMobile';

const WalletsListView: FC<WalletsProps> = props => {
  const {
    setIsWalletEmpty,
    currentPage,
    linkedWallets,
    setDebounceSearchTerm,
    debounceSearchTerm,
    isLoadingWallets,
    getWallets,
    setCurrentPage,
    limit,
    noOfRecordsDisplayed,
    paginationData,
    totalCount,
    isFetchingWallets,
    userType,
    clientId,
    isSharedAccount
  } = props;

  const [searchText, setSearchText] = useState('');
  const [isDeletePopUpOpen, setDeletePopUp] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [isSyncAllClicked, setIsSyncAllClicked] = useState(false);
  const [stopPolling, setStopPolling] = useState(false);
  const [allWallets, setAllWallets] = useState('');
  const [showSuccessNotifier, setShowSuccessNotifier] = useState(false);
  const [isLoadingIntialAPI, setIsLoadingIntialAPI] = useState(0);
  const [showUnlinkNotifier, setShowUnlinkNotifier] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { data: syncStatusData, isFetching: fetchingSyncStatus } =
    useGetConnectedWalletStatusQuery(
      {
        walletIds: allWallets,
        clientId
      },
      { pollingInterval: 5000, skip: !allWallets || stopPolling }
    );
  const [syncTransactions, syncTransactionResponse] = useSyncWalletMutation();
  const [disconnectExchange, disconnectExchangeResponse] =
    useDisconnectWalletMutation();

  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up('md'));
  const isMobileView = useMediaQuery('(max-width:640px)');

  const topContentHeight = useTopContentHeight(!!clientId);

  useEffect(() => {
    if (isLoadingIntialAPI === 0) {
      setIsLoadingIntialAPI(1);
    }
    getWallets({
      searchTerm: debounceSearchTerm,
      page: currentPage - 1,
      limit: ROWS_PER_PAGE,
      clientId
    });
  }, [getWallets, debounceSearchTerm, currentPage]);

  useEffect(() => {
    if (isLoadingIntialAPI === 1 && !isFetchingWallets) {
      setIsLoadingIntialAPI(2);
    }
  }, [linkedWallets, isFetchingWallets]);

  useEffect(() => {
    setIsWalletEmpty(!linkedWallets?.length);
    if (isCurrentPageExceedsPageLimit()) {
      setCurrentPage(Math.ceil(paginationData.total_rows / limit));
    }
  }, [linkedWallets]);

  useEffect(() => {
    let pollingRequired = true;
    if (!isEmpty(syncStatusData) && !fetchingSyncStatus) {
      pollingRequired = syncStatusData?.reduce(
        (isPollingRequired, data) =>
          isPollingRequired ||
          ![
            SyncRequestStatus.COMPLETED,
            SyncRequestStatus.DEAD,
            SyncRequestStatus.UNLINKED,
            SyncRequestStatus.AUTH_EXPIRED
          ].includes(data.status),
        false
      );
      const isUnlinkedConnectionsInList = syncStatusData?.reduce(
        (isUnlinked, data) =>
          isUnlinked || data.status === SyncRequestStatus.UNLINKED,
        false
      );
      if (isUnlinkedConnectionsInList) {
        // refetch the linked source api if any of the exchnage is deleted from the list
        getWallets({
          searchTerm: debounceSearchTerm,
          page: currentPage - 1,
          limit: ROWS_PER_PAGE,
          clientId
        });
      }
    }
    setStopPolling(!pollingRequired);
  }, [syncStatusData, fetchingSyncStatus]);

  useEffect(() => {
    if (!isEmpty(syncStatusData)) {
      const isSyncInprogress = syncStatusData?.reduce(
        (syncStatus, data) =>
          syncStatus ||
          ![
            SyncRequestStatus.COMPLETED,
            SyncRequestStatus.DEAD,
            SyncRequestStatus.UNLINKED,
            SyncRequestStatus.UNLINKING,
            SyncRequestStatus.AUTH_EXPIRED
          ].includes(data.status),
        false
      );
      const isUnlinkInProgress = syncStatusData?.reduce(
        (syncStatus, data) =>
          syncStatus || [SyncRequestStatus.UNLINKING].includes(data.status),
        false
      );
      if (isSyncInprogress) {
        setShowSuccessNotifier(isSyncInprogress);
      }
      if (isUnlinkInProgress) {
        setShowUnlinkNotifier(isUnlinkInProgress);
      }
    }
  }, [syncStatusData]);

  useEffect(() => {
    if (isSyncAllClicked) {
      setStopPolling(false);
    }
  }, [isSyncAllClicked]);

  useEffect(() => {
    const ids = [];
    linkedWallets?.forEach(wallet => ids.push(wallet.id));
    setAllWallets(ids.join(','));
    setStopPolling(false);
  }, [linkedWallets]);

  useEffect(() => {
    if (!syncTransactionResponse.isLoading && isSyncAllClicked) {
      setIsSyncAllClicked(false);
    }
  }, [syncTransactionResponse, isSyncAllClicked]);

  useEffect(() => {
    if (disconnectExchangeResponse.isSuccess) {
      setStopPolling(false);
      disconnectExchangeResponse.reset();
    }
  }, [disconnectExchangeResponse]);

  useEffect(() => {
    let isAllCompleted = false;
    if (
      !isEmpty(syncStatusData) &&
      !fetchingSyncStatus &&
      showSuccessNotifier
    ) {
      isAllCompleted = syncStatusData?.reduce(
        (isCompleted, data) =>
          isCompleted && data.status === SyncRequestStatus.COMPLETED,
        true
      );
      if (isAllCompleted && stopPolling && showSuccessNotifier) {
        triggerNotifier(
          getNotifierMessage(NOTIFIER_MESSAGE_TYPES.syncingTransactionsSuccess),
          NotifierTypes.SUCCESS
        );
        setShowSuccessNotifier(false);
      }
    }
  }, [syncStatusData, fetchingSyncStatus, stopPolling, showSuccessNotifier]);

  useEffect(() => {
    // for unlink complete toast
    let isAllUnlinked = false;
    if (!fetchingSyncStatus && showUnlinkNotifier) {
      if (!isEmpty(syncStatusData)) {
        isAllUnlinked = syncStatusData?.reduce(
          (isCompleted, data) =>
            isCompleted &&
            ![SyncRequestStatus.UNLINKING, SyncRequestStatus.UNLINKED].includes(
              data.status
            ),
          true
        );
      }
      if (isEmpty(linkedWallets)) {
        isAllUnlinked = true;
      }
      if (isAllUnlinked) {
        triggerNotifier(
          translate('notifierMessage.allWalletsUnlinkedSuccess'),
          NotifierTypes.DELETE
        );
        setShowUnlinkNotifier(false);
      }
    }
  }, [
    syncStatusData,
    fetchingSyncStatus,
    stopPolling,
    showUnlinkNotifier,
    linkedWallets
  ]);

  const isCurrentPageExceedsPageLimit = () => {
    if (paginationData?.page > 0) {
      return (
        paginationData.page + 1 > Math.ceil(paginationData.total_rows / limit)
      );
    } else if (paginationData?.page === 0) {
      return false;
    }
  };

  const debounceSearch = useCallback(
    debounce(value => {
      setDebounceSearchTerm(value);
      setCurrentPage(1);
    }, 300),
    [debounce]
  );

  const handleSearchTextChange = useCallback(
    value => {
      debounceSearch(value);
      setSearchText(value);
      setShowUnlinkNotifier(false);
      setShowSuccessNotifier(false);
    },
    [debounceSearch]
  );

  const handleDeletePopUp = (id: string) => {
    setDeletePopUp(true);
    setDeleteId(id);
  };

  const handleDeleteWalletConfirm = () => {
    gAEventTracker(eventKeyMapper.walletDeleteBtnClicked);
    disconnectExchange({ walletId: deleteId, clientId });
    setDeletePopUp(false);
  };

  const handleCancelDeleteWallet = () => {
    setDeletePopUp(false);
  };

  const handleSyncTransactionClick = async ({
    syncAll = false,
    walletId,
    blockChainId
  }: HandleSyncWalletArgs) => {
    let walletPayload = {
      sync_request: syncAll
        ? []
        : [
            {
              exchange_id: blockChainId,
              wallet_config_id: walletId
            }
          ],
      sync_all: syncAll
    };
    gAEventTracker(eventKeyMapper.walletSyncBtnClicked);
    await syncTransactions({ walletPayload, clientId });
    syncAll && setIsSyncAllClicked(true);
  };

  const findSyncStatus = (id: string) => {
    const status = syncStatusData?.find(
      syncStatus => syncStatus?.wallet_config_id === id
    );
    return status?.status;
  };

  const findLastSync = (id: string) => {
    const status = syncStatusData?.find(
      syncStatus => syncStatus?.wallet_config_id === id
    );
    return status?.last_sync_time;
  };

  const triggerNotifier = (message: string, type: NotifierTypes) => {
    dispatch(showNotifier({ message, type }));
  };

  const handleSyncAllClick = () => {
    handleSyncTransactionClick({ syncAll: true });
  };

  const handleSync = async (id: string, blockChainId: string) => {
    await handleSyncTransactionClick({
      walletId: id,
      blockChainId: blockChainId
    });
    setStopPolling(false);
  };

  const navigateToConnectExchange = () => {
    navigate(
      `${RoutesPath.CONNECT_EXCHANGE}?tab=${TAB_BAR_CONSTANTS.ADD_WALLET}`
    );
  };

  const handlePageChangeEvent = (value: number) => {
    setShowUnlinkNotifier(false);
    setShowSuccessNotifier(false);
    setCurrentPage(value);
  };

  return (
    <>
      {isLoadingWallets || isLoadingIntialAPI === 1 ? (
        <SourcesSkeleton rowCount={3} actionButtonCount={1} />
      ) : (
        <div>
          {isEmpty(linkedWallets) &&
          debounceSearchTerm === '' &&
          !isFetchingWallets ? (
            <div className="flex w-full">
              <EmptyData
                styleConfig={{
                  height: isMobileView
                    ? `calc(100vh - ${topContentHeight}px - 32px)`
                    : `calc(100vh - ${topContentHeight}px - 64px)`,
                  wrapperStyle:
                    'mx-4 mt-4 sm:mx-8 sm:mt-8 w-full m-0 sm:mb-8 min-h-[400px]',
                  imageStyle: 'w-[50%] sm:w-[250px] md:w-[300px] lg:w-[350px]',
                  secondaryDescriptionStyle:
                    'max-w-lg text-black text-[10px] sm:text-sm w-[80%] leading-normal',
                  titleStyle: 'text-sm font-semibold mt-2 sm:text-2xl'
                }}
                contentConfig={{
                  title: translate('sourcePage.noWalletsTitle'),
                  highlightedImage: NoConnections,
                  showPrimaryDescription: false,
                  showSecondaryDescription: true,
                  showPrimaryButton: true,
                  secondaryDescription: translate(
                    'sourcePage.noWalletsMessage'
                  ),
                  primaryButtonConfig: {
                    icon: AttachmentPinIcon,
                    buttonHandler: navigateToConnectExchange,
                    showLabelInSmallScreen: true,
                    buttonLabel: translate('transactionPage.connectExchange')
                  }
                }}
              />
            </div>
          ) : (
            <div className="flex flex-col">
              <div className="flex flex-row justify-between w-full">
                <div className="pt-6 pl-4 w-[85%] sm:mb-4 sm:w-[40%] md:pl-6">
                  <SearchBar
                    searchValue={searchText}
                    handleChange={handleSearchTextChange}
                    wrapperStyle="bg-white border-white"
                    searchInputStyle="bg-white py-2"
                  />
                </div>
                {!isEmpty(linkedWallets) && (
                  <div className="pt-6 pr-4 pl-5 md:pr-6">
                    <Button
                      onClick={handleSyncAllClick}
                      className="p-2  h-[38px] bg-white hover:bg-slate-50 
                      rounded-md border-[1px] border-primaryColor disabled:cursor-not-allowed"
                      Icon={RefreshIconBlack}
                      iconClass={`${!stopPolling ? 'animate-rotate' : ''}`}
                      label={translate('sourcePage.syncAll')}
                      disabled={
                        syncTransactionResponse.isLoading ||
                        !stopPolling ||
                        (userType === USER_ROLE.PROFESSIONAL && isSharedAccount)
                      }
                    />
                  </div>
                )}
              </div>
              {linkedWallets?.length > 0 && !isCurrentPageExceedsPageLimit() ? (
                <div className="pt-[14px] sm:pt-0">
                  {linkedWallets?.map(wallet => (
                    <div key={wallet?.id} className="w-full md:px-6">
                      {match ? (
                        <table className="mb-2 w-full table-auto">
                          <tbody>
                            <WalletsTable
                              handleDeletePopUp={handleDeletePopUp}
                              handleSyncTransactionClick={
                                handleSyncTransactionClick
                              }
                              img={wallet?.wallet_icons.lg}
                              walletId={wallet.id}
                              findSyncStatus={findSyncStatus}
                              lastSync={wallet.last_sync_at}
                              findLastSyncStatus={findLastSync}
                              transaction={wallet.transaction_no}
                              handleSync={handleSync}
                              name={wallet.nick_name}
                              publicAddress={wallet.public_address}
                              blockChainId={wallet.exchange_id}
                              isActionDisabled={
                                userType === USER_ROLE.PROFESSIONAL &&
                                isSharedAccount
                              }
                            />
                          </tbody>
                        </table>
                      ) : (
                        <div className="flex flex-wrap px-4 w-full md:px-0">
                          <div
                            className="flex flex-col py-5 pr-2 pl-3 mb-1.5 w-full 
                            min-h-[116px] bg-white rounded-md">
                            <WalletsTableMobile
                              handleDeletePopUp={handleDeletePopUp}
                              handleSyncTransactionClick={
                                handleSyncTransactionClick
                              }
                              img={wallet?.wallet_icons.lg}
                              walletId={wallet.id}
                              findSyncStatus={findSyncStatus}
                              lastSync={wallet.last_sync_at}
                              findLastSyncStatus={findLastSync}
                              transaction={wallet.transaction_no}
                              handleSync={handleSync}
                              publicAddress={wallet.public_address}
                              name={wallet.nick_name}
                              blockChainId={wallet.exchange_id}
                              isActionDisabled={
                                userType === USER_ROLE.PROFESSIONAL &&
                                isSharedAccount
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                  <Paginator
                    handlePageChangeEvent={handlePageChangeEvent}
                    limit={limit}
                    page={currentPage}
                    noOfRecordsDisplayed={noOfRecordsDisplayed}
                    totalCount={totalCount}
                    className="justify-center mx-6 mt-6 mb-1 lg:justify-end"
                  />
                </div>
              ) : (
                !isFetchingWallets && (
                  <div className="flex px-4 pt-2 w-full sm:px-6 sm:pt-0">
                    <EmptyData
                      styleConfig={{
                        height: isMobileView
                          ? `calc(100vh - ${topContentHeight}px - 80px)`
                          : `calc(100vh - ${topContentHeight}px - 94px)`,
                        wrapperStyle:
                          'text-xs sm:text-sm mb-2 sm:mb-4 min-h-[340px] tall:min-h-[400px]',
                        imageStyle:
                          'w-[50%] sm:w-[250px] md:w-[300px] lg:w-[350px]',
                        titleStyle: 'text-sm font-semibold sm:text-2xl',
                        secondaryDescriptionStyle:
                          'max-w-lg text-black text-[10px] sm:text-sm w-[80%] leading-normal'
                      }}
                      contentConfig={{
                        title: translate('emptyResult.title'),
                        highlightedImage: NoSearchResult,
                        showPrimaryDescription: false,
                        showSecondaryDescription: true,
                        secondaryDescription: translate('emptyResult.message')
                      }}
                    />
                  </div>
                )
              )}

              <ConfirmationPopup
                visibility={isDeletePopUpOpen}
                title={translate('popup.confirmDeletion')}
                onCloseButtonClick={handleCancelDeleteWallet}
                buttonOneText={translate('popup.noKeepIt')}
                buttonTwoText={translate('popup.yesDeleteIt')}
                message={translate('popup.deleteWalletMessage')}
                onButtonOneClick={handleCancelDeleteWallet}
                onButtonTwoClick={handleDeleteWalletConfirm}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default WalletsListView;
