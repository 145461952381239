import { clearClientProfile, updateClientProfile } from '@reducers/appReducer';
import { authBaseApi } from '@services/api';
import { translate } from '@utils';
import { GenericApiResponse } from 'types/generalTypes';
import {
  ClientProfileDetails,
  CreateClientPayload,
  InviteClientPayload,
  ListClientPayload,
  ManagedClientListing,
  RemoveClientPayload,
  SharedClientListing
} from './types';

const ClientListingBaseApi = authBaseApi.injectEndpoints({
  endpoints: builder => ({
    getSharedClientDetails: builder.query<
      SharedClientListing,
      ListClientPayload
    >({
      query: ({ queryParams, page, limit }) => {
        let url = `/prof/users/connection/shared?page=${page}&limit=${limit}`;
        Object.keys(queryParams).forEach(item => {
          if (queryParams[item]) {
            url = `${url}&${item}=${encodeURIComponent(queryParams[item])}`;
          }
        });
        return url;
      },
      transformResponse: (response: GenericApiResponse<SharedClientListing>) =>
        response.result,
      providesTags: ['GetSharedClientDetails']
    }),
    getManagedClientDetails: builder.query<
      ManagedClientListing,
      ListClientPayload
    >({
      query: ({ queryParams, page, limit }) => {
        let url = `/prof/users/connection/managed?page=${page}&limit=${limit}`;
        Object.keys(queryParams).forEach(item => {
          if (queryParams[item]) {
            url = `${url}&${item}=${encodeURIComponent(queryParams[item])}`;
          }
        });
        return url;
      },
      transformResponse: (response: GenericApiResponse<ManagedClientListing>) =>
        response.result,
      providesTags: ['GetManagedClientDetails']
    }),
    inviteClient: builder.mutation<
      GenericApiResponse<string>,
      InviteClientPayload
    >({
      query: ({ payload }) => ({
        url: '/prof/users/invite',
        method: 'POST',
        body: payload
      }),
      extraOptions: {
        showNotifier: true,
        success: translate('notifierMessage.clientInviteSuccess'),
        failure: translate('notifierMessage.clientInviteFailed')
      },
      invalidatesTags: ['GetSharedClientDetails']
    }),
    createManagedAccount: builder.mutation<
      GenericApiResponse<string>,
      CreateClientPayload
    >({
      query: ({ payload }) => ({
        url: '/prof/users/connection/managed',
        method: 'POST',
        body: payload
      }),
      extraOptions: {
        showNotifier: true,
        success: translate('notifierMessage.createClientSuccess'),
        failure: translate('notifierMessage.createClientFailed')
      },
      invalidatesTags: ['GetManagedClientDetails']
    }),
    removeClient: builder.mutation<
      GenericApiResponse<string>,
      RemoveClientPayload
    >({
      query: ({ connectionId }) => ({
        url: `/prof/users/connection/${connectionId}`,
        method: 'DELETE'
      }),
      extraOptions: {
        showNotifier: true,
        success: translate('notifierMessage.clientRemoveSuccess'),
        failure: translate('notifierMessage.clientRemoveFailed')
      },
      invalidatesTags: ['GetSharedClientDetails']
    }),
    deleteManagedAccount: builder.mutation<
      GenericApiResponse<string>,
      RemoveClientPayload
    >({
      query: ({ connectionId }) => ({
        url: `/prof/users/managed/${connectionId}`,
        method: 'DELETE'
      }),
      extraOptions: {
        showNotifier: true,
        success: translate('notifierMessage.clientRemoveSuccess'),
        failure: translate('notifierMessage.clientRemoveFailed')
      },
      invalidatesTags: ['GetManagedClientDetails']
    }),
    getClientProfile: builder.query<
      GenericApiResponse<ClientProfileDetails>,
      string
    >({
      query: clientId => `/prof/users/client/${clientId}`,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateClientProfile(data.result));
        } catch (err) {
          // `onError` side-effect
          dispatch(clearClientProfile());
        }
      }
    })
  })
});

export const {
  useLazyGetManagedClientDetailsQuery,
  useLazyGetSharedClientDetailsQuery,
  useInviteClientMutation,
  useRemoveClientMutation,
  useDeleteManagedAccountMutation,
  useLazyGetClientProfileQuery,
  useCreateManagedAccountMutation
} = ClientListingBaseApi;
