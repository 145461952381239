import React, {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  useCallback,
  useEffect,
  useState
} from 'react';
import { useNavigate } from 'react-router-dom';

import { useQuery } from '@hooks';
import { Button, CircularLoader } from '@components';
import { USER_ROLE } from '@constants/common';
import { translate } from '@utils/locale';
import { useLazyResetPasswordQuery, useValidateForgotTokenQuery } from './api';
import { ResetPasswordContainerProps, ResetPasswordFieldValues } from './types';
import routesPath from '@routes/RoutesPath';
import PasswordResetSuccessful from './components/PasswordResetSuccessful';
import LinkExpired from './components/LinkExpired';
import ResetPassword from './components/ResetPassword';

const ResetPasswordContainer: FC<ResetPasswordContainerProps> = props => {
  const { userRole } = props;

  const [resetPasswordSuccess, setResetPasswordSuccess] =
    useState<boolean>(false);
  const [validateTokenSuccess, setValidateTokenSuccess] =
    useState<boolean>(false);
  const [validateTokenError, setValidateTokenError] = useState<boolean>(false);

  const navigate = useNavigate();
  const query = useQuery();
  const token = query.get('token');

  const [resetPassword, resetPasswordResponse] = useLazyResetPasswordQuery();
  const {
    isSuccess: isValidateTokenSuccess,
    isError: isValidateTokenError,
    isLoading
  } = useValidateForgotTokenQuery({ token });

  useEffect(() => {
    if (isValidateTokenSuccess) {
      setValidateTokenSuccess(true);
    } else if (isValidateTokenError) {
      setValidateTokenError(true);
    }
  }, [isValidateTokenSuccess, isValidateTokenError]);

  useEffect(() => {
    if (resetPasswordResponse.isSuccess) {
      setResetPasswordSuccess(true);
    } else if (resetPasswordResponse.isError) {
      const resetPasswordError: any = resetPasswordResponse?.error;
      // if the error is 'password same as old password ' do not invalidate
      resetPasswordError.data?.error?.code !== 142 &&
        setValidateTokenError(true);
    }
  }, [resetPasswordResponse]);

  const handleResetPassword = (values: ResetPasswordFieldValues) => {
    if (values !== null) {
      const password = values.confirmPassword;
      resetPassword({ token, password });
    }
  };

  const navigateToSignIn = () => {
    const route =
      userRole === USER_ROLE.PROFESSIONAL
        ? `${routesPath.TAX_PROFESSIONALS}/signin`
        : routesPath.SIGNIN;
    navigate(route);
  };

  const navigateToForgotPassword = () => {
    const route =
      userRole === USER_ROLE.PROFESSIONAL
        ? `${routesPath.TAX_PROFESSIONALS}/forgot`
        : routesPath.FORGOT;
    navigate(route);
  };

  const renderChildComponent = useCallback((): DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > => {
    if (resetPasswordSuccess) {
      return <PasswordResetSuccessful handleContinueClick={navigateToSignIn} />;
    }
    if (validateTokenError) {
      return (
        <LinkExpired
          handleResendClick={navigateToForgotPassword}
          handleBacktoSignInClick={navigateToSignIn}
          isResetPage={true}
        />
      );
    }
    if (validateTokenSuccess) {
      return (
        <ResetPassword
          handleValues={handleResetPassword}
          disabled={resetPasswordResponse.isLoading}
          handleBackClick={navigateToSignIn}
        />
      );
    }
    if (isLoading) return <CircularLoader isFullScreen={true} />;

    return (
      <div className="flex justify-center mt-3 w-full text-davyGrey">
        <Button
          className="p-4 text-white bg-primaryColor cursor-pointer"
          onClick={navigateToSignIn}
          label={translate('resetPasswordPage.backToLogin')}
        />
      </div>
    );
  }, [
    validateTokenSuccess,
    validateTokenError,
    resetPasswordSuccess,
    location.pathname,
    isLoading
  ]);

  return (
    <div className="flex w-full min-h-screen bg-harp">
      {resetPasswordResponse.isLoading && (
        <CircularLoader isFullScreen={true} />
      )}
      <div className="hidden relative justify-start items-end w-3/5 bg-alabaster rounded-l-lg sm:flex">
        <img
          src="/images/curvedBg.png"
          className="absolute w-full h-full"
          alt=""
        />
        <img
          src="images/SignIn.png"
          className="absolute inset-0 m-auto w-[90%]"
          alt="Sign In"
        />
      </div>
      <div
        className={'flex justify-center w-full bg-white rounded-r-lg sm:w-2/5'}>
        <div className="flex flex-col justify-center items-center w-[70%] h-full sm:w-[60%]">
          {renderChildComponent()}
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordContainer;
