import React, { FC, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQuery } from '@hooks';
import { IS_AUTHENTICATED, USER_ROLE } from '@constants/common';
import { VerifyPageSkeleton } from '@containers/skeleton-loader';
import { updateIsUserAuthenticated } from '@reducers/userAuth';
import { clearClientDetails } from '@utils';
import { useAppDispatch } from '@store/store';
import { useVerifyEmailQuery } from './api';
import { VerifyEmailProps } from './types';
import useScreenDimensions from '@hooks/useScreenDimensions';
import routesPath from '@routes/RoutesPath';
import LinkExpired from '../reset-password/components/LinkExpired';

const Verify: FC<VerifyEmailProps> = props => {
  const { userRole } = props;

  const { isMobileView } = useScreenDimensions();
  const query = useQuery();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isSuccess, isError, error } = useVerifyEmailQuery(query.get('token'));

  useEffect(() => {
    if (isSuccess) {
      localStorage.setItem(IS_AUTHENTICATED, 'true');
      dispatch(updateIsUserAuthenticated('true'));
      clearClientDetails();
      const route =
        userRole === USER_ROLE.PROFESSIONAL
          ? `${routesPath.TAX_PROFESSIONALS}/clients`
          : routesPath.SOURCE;
      navigate(route);
    }
  }, [isSuccess]);

  const handleNavigateBack = () => {
    const route =
      userRole === USER_ROLE.PROFESSIONAL
        ? `${routesPath.TAX_PROFESSIONALS}/signin`
        : routesPath.SIGNIN;
    navigate(route);
  };

  const renderChildComponent = useCallback(() => {
    if (isError) {
      return (
        <LinkExpired
          handleResendClick={handleNavigateBack}
          handleBacktoSignInClick={handleNavigateBack}
        />
      );
    }
    return <VerifyPageSkeleton />;
  }, [isError, error, userRole]);

  return (
    <div className="flex w-full min-h-screen bg-harp">
      {!isMobileView && (
        <div className="hidden relative justify-start items-end w-3/5 bg-alabaster rounded-l-lg sm:flex">
          <img
            src="/images/curvedBg.png"
            className="absolute w-full h-full"
            alt=""
          />
          <img
            src="images/SignIn.png"
            className="absolute inset-0 m-auto w-[90%]"
            alt="Sign In"
          />
        </div>
      )}
      <div
        className={`flex justify-center ${isMobileView ? 'w-full' : 'w-2/5'} 
        bg-white rounded-r-lg`}>
        <div className="flex flex-col justify-center items-center w-[80%] h-full sm:w-[60%]">
          {renderChildComponent()}
        </div>
      </div>
    </div>
  );
};

export default Verify;
