/* eslint-disable tailwindcss/no-custom-classname */
import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  useMediaQuery
} from '@mui/material';
import {
  ClassNames,
  DateRangePicker,
  Range,
  RangeKeyDict
} from 'react-date-range';
import { isSameDay, format } from 'date-fns';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { convertFinancialYearToUTC, translate } from '@utils';
import { CalenderIcon, DownArrowBlack } from '@assets/icons';
import { PICKER_ID, staticDateSelectors } from './constants';
import { DateRangeSelectorProps, RenderMonthAndYearTypes } from './types';
import COLORS from '@constants/colors';

import './styles.css';

const selectSX = {
  width: '100%',
  height: '32px',
  fontSize: '14px',
  backgroundColor: COLORS.WHITE,
  '& .MuiSelect-icon': {
    top: '40%',
    marginRight: '10px',
    transitionDuration: '500ms'
  },
  '& .MuiSelect-select': {
    transitionDuration: '900ms'
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: COLORS.GREY_CLOUD,
    borderRadius: '6px',
    borderWidth: '1px'
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: COLORS.PRIMARY_COLOR,
    borderRadius: '6px',
    borderWidth: '1px'
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: COLORS.GREY_CLOUD
  },
  '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
    borderColor: COLORS.GREY_CLOUD
  }
};

const inputLabelSX = {
  top: '-10px',
  fontSize: '14px',
  '&.MuiInputLabel-shrink': {
    fontSize: '13px',
    top: '0px',
    color: COLORS.SLATE_GREY
  },
  '&.Mui-focused': {
    color: COLORS.PRIMARY_COLOR,
    top: '0px'
  }
};

const focussedPickerStyle = {
  '.MuiOutlinedInput-input': {
    backgroundColor: COLORS.HAWKS_BLUE,
    borderRadius: '6px',
    padding: '1px 6px !important',
    marginRight: '10px',
    zIndex: '10'
  }
};

const yearPickerSX = {
  height: '32px',
  fontSize: '16px',
  fontWeight: '500',
  color: COLORS.PRIMARY_COLOR,
  backgroundColor: COLORS.WHITE,
  '.MuiOutlinedInput-input': {
    padding: '1px 6px !important',
    marginRight: '10px',
    zIndex: '10'
  },
  '&:hover .MuiOutlinedInput-input': {
    backgroundColor: COLORS.HAWKS_BLUE,
    borderRadius: '6px',
    padding: '1px 6px !important',
    marginRight: '10px',
    zIndex: '10'
  },
  '& .MuiSelect-icon': {
    top: '45%',
    marginRight: '0px',
    right: '5px',
    height: '5px'
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '.MuiPaper-root': {
    height: '200px !important'
  }
};

const menuPropSx = {
  height: '260px',
  '& .MuiMenuItem-root': {
    color: COLORS.DAVY_GREY,
    fontSize: '14px',
    marginLeft: '4px',
    height: '38px'
  },
  '& .MuiMenuItem-root:hover': {
    backgroundColor: '#d4ddfc33'
  },
  '.MuiMenuItem-root.Mui-selected': {
    backgroundColor: '#d4ddfc66 !important'
  },
  '*::-webkit-scrollbar': {
    height: '8px'
  },
  '*::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 10px 10px white',
    border: 'solid 4px transparent'
  },
  '*::-webkit-scrollbar-thumb': {
    borderRadius: '10px',
    boxShadow: ' inset 0 0 10px 10px #4B5563',
    border: 'solid 5px transparent',
    minHeight: '60px'
  }
};

const SELECTION = 'selection';

const classNames: ClassNames = {
  dayNumber: 'dayNumber',
  startEdge: 'startEdge',
  endEdge: 'endEdge',
  dayHovered: 'dayHovered',
  dayStartPreview: 'dayStartPreview',
  dayToday: 'dayToday',
  dayEndPreview: 'dayEndPreview',
  weekDay: 'weekDay',
  monthName: 'monthName',
  nextPrevButton: 'nextPrevButton'
};

const DateRangeSelector: FC<DateRangeSelectorProps> = props => {
  const {
    onOpen,
    onClose,
    financialYears,
    setSelectedFY,
    selectedFY,
    handleDateChange,
    resetPagination,
    startDateString,
    endDateString
  } = props;

  const [selectionRange, setSelectionRange] = useState<Range[]>([
    {
      endDate: new Date(),
      key: SELECTION
    }
  ]);
  const [activeDropdownId, setActiveDropdownId] = useState('');

  const matches = useMediaQuery('(min-width:1430px)');

  useEffect(() => {
    setSelectionRange([
      {
        key: SELECTION,
        ...(startDateString && { startDate: new Date(startDateString) }),
        endDate: endDateString ? new Date(endDateString) : new Date()
      }
    ]);
  }, [startDateString, endDateString]);

  const handleSelect = (ranges: RangeKeyDict) => {
    setSelectionRange([ranges[SELECTION]]);
    handleDateChange(ranges[SELECTION].startDate, ranges[SELECTION].endDate);
    setSelectedFY('');
    resetPagination();
  };

  const handleClearAll = () => {
    setSelectionRange([{ key: SELECTION, endDate: new Date() }]);
    handleDateChange();
    setSelectedFY('');
    resetPagination();
  };

  const handleFYSelect = (event: SelectChangeEvent<string>) => {
    const selectedOption = event.target.value;
    setSelectedFY(selectedOption);
    const startDate = new Date(
      convertFinancialYearToUTC(selectedOption).startDate
    );
    const fyEndDate = new Date(
      convertFinancialYearToUTC(selectedOption).endDate
    );
    const currentDate = new Date();
    const endDate = currentDate > fyEndDate ? fyEndDate : currentDate;
    handleDateChange(startDate, endDate);
    setSelectionRange([
      {
        key: SELECTION,
        startDate,
        endDate
      }
    ]);
    resetPagination();
  };

  const getStaticRanges = useCallback(() => {
    return staticDateSelectors.map(staticSelector => ({
      label: staticSelector.label,
      range: () => staticSelector.range,
      isSelected: (range: Range) => {
        return (
          isSameDay(range.startDate, staticSelector.range.startDate) &&
          isSameDay(range.endDate, staticSelector.range.endDate)
        );
      }
    }));
  }, [staticDateSelectors]);

  const onOpenClick = (pickerId: string) => {
    setActiveDropdownId(pickerId);
    onOpen();
  };

  const onCloseClick = () => {
    setActiveDropdownId('');
    onClose();
  };

  const renderMonthAndYear: RenderMonthAndYearTypes = (
    focusedDate,
    changeShownDate,
    calendarProps
  ) => {
    const {
      showMonthArrow = true,
      locale,
      maxDate,
      showMonthAndYearPickers
    } = calendarProps;
    const upperYearLimit = maxDate.getFullYear();
    const lowerYearLimit = 2015;

    const getSelectedMonth = (e: SelectChangeEvent<number>) => {
      let monthValue = Number(e.target.value);
      // if month is not January , then we need to decrease a month from the selected month
      if (monthValue !== 0) {
        monthValue -= 1;
      } else {
        // if month is January , then we need to set month as December
        changeShownDate(new Date(focusedDate.getFullYear() - 1, 11), 'set');
        return;
      }
      if (focusedDate.getMonth() === 11) {
        // if left side shown calender is December then, need to increase year by one
        changeShownDate(
          new Date(focusedDate.getFullYear() + 1, monthValue),
          'set'
        );
      } else {
        changeShownDate(monthValue, 'setMonth');
      }
    };

    const getSelectedYear = (e: SelectChangeEvent<number>) => {
      if (focusedDate.getMonth() < 11)
        changeShownDate(e.target.value, 'setYear');
      else changeShownDate(Number(e.target.value) - 1, 'setYear');
    };

    return (
      <div
        onMouseUp={e => e.stopPropagation()}
        className="relative monthAndYearWrapper rdrMonthAndYearWrapper">
        {showMonthArrow ? (
          <button
            type="button"
            className="nextPrevButton rdrnextPrevButton rdrPprevButton"
            onClick={() => changeShownDate(-1, 'monthOffset')}>
            <i />
          </button>
        ) : null}
        {showMonthAndYearPickers ? (
          <>
            <span className="left-[95px] rdrMonthAndYearPickers monthAndYearPickers">
              <span className="monthPicker">
                <FormControl>
                  <Select
                    labelId={PICKER_ID.monthPicker1}
                    value={focusedDate.getMonth()}
                    onOpen={() => onOpenClick(PICKER_ID.monthPicker1)}
                    onClose={onCloseClick}
                    sx={{
                      ...yearPickerSX,
                      ...(activeDropdownId === PICKER_ID.monthPicker1 &&
                        focussedPickerStyle)
                    }}
                    MenuProps={{ sx: menuPropSx }}
                    IconComponent={() => <div className="hidden" />}
                    onChange={e => {
                      changeShownDate(e.target.value, 'setMonth');
                    }}>
                    {Array.from(Array(12).keys())
                      .map(i => locale.localize.month(i))
                      ?.map((month, i) => (
                        <MenuItem key={i} value={i}>
                          {month}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </span>
              <span className="monthAndYearDivider" />
              <span className="yearPicker">
                <FormControl sx={{ left: '-14px' }}>
                  <Select
                    labelId={PICKER_ID.yearPicker1}
                    value={focusedDate.getFullYear()}
                    onOpen={() => onOpenClick(PICKER_ID.yearPicker1)}
                    onClose={onCloseClick}
                    sx={{
                      ...yearPickerSX,
                      ...(activeDropdownId === PICKER_ID.yearPicker1 &&
                        focussedPickerStyle)
                    }}
                    MenuProps={{ sx: menuPropSx }}
                    IconComponent={() => <div className="hidden" />}
                    onChange={e => changeShownDate(e.target.value, 'setYear')}>
                    {new Array(upperYearLimit - lowerYearLimit + 1)
                      .fill(upperYearLimit)
                      .map((val, i) => {
                        const year = val - i;
                        return (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </span>
            </span>
            <span
              className={`left-[450px] ${matches ? 'flex' : 'hidden'} top-[45px]
             rdrMonthAndYearPickers monthAndYearPickers`}>
              <span className="monthPicker">
                <FormControl>
                  <Select
                    labelId={PICKER_ID.monthPicker2}
                    value={
                      focusedDate.getMonth() < 11
                        ? focusedDate.getMonth() + 1
                        : 0
                    }
                    onOpen={() => onOpenClick(PICKER_ID.monthPicker2)}
                    onClose={onCloseClick}
                    sx={{
                      ...yearPickerSX,
                      ...(activeDropdownId === PICKER_ID.monthPicker2 &&
                        focussedPickerStyle)
                    }}
                    MenuProps={{ sx: menuPropSx }}
                    IconComponent={() => <div className="hidden" />}
                    onChange={e => getSelectedMonth(e)}>
                    {Array.from(Array(12).keys())
                      .map(i => locale.localize.month(i))
                      ?.map((month, i) => (
                        <MenuItem key={i} value={i}>
                          {month}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </span>
              <span className="monthAndYearDivider" />
              <span className="yearPicker">
                <FormControl sx={{ left: '-14px' }}>
                  <Select
                    labelId={PICKER_ID.yearPicker2}
                    value={
                      focusedDate.getMonth() < 11
                        ? focusedDate.getFullYear()
                        : focusedDate.getFullYear() + 1
                    }
                    onOpen={() => onOpenClick(PICKER_ID.yearPicker2)}
                    onClose={onCloseClick}
                    sx={{
                      ...yearPickerSX,
                      ...(activeDropdownId === PICKER_ID.yearPicker2 &&
                        focussedPickerStyle)
                    }}
                    MenuProps={{ sx: menuPropSx }}
                    IconComponent={() => <div className="hidden" />}
                    onChange={e => getSelectedYear(e)}>
                    {new Array(upperYearLimit - lowerYearLimit + 2)
                      .fill(upperYearLimit + 1)
                      .map((val, i) => {
                        const year = val - i;
                        return (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </span>
            </span>
          </>
        ) : (
          <span className="monthAndYearPickers">
            {locale?.localize?.month()[focusedDate.getMonth()]}{' '}
            {focusedDate.getFullYear()}
          </span>
        )}
        {showMonthArrow ? (
          <button
            type="button"
            className={
              'nextPrevButton rdrNextPrevButton nextButton rdrNextButton'
            }
            onClick={() => changeShownDate(+1, 'monthOffset')}>
            <i />
          </button>
        ) : null}
      </div>
    );
  };

  return (
    <div
      className="relative text-davyGrey bg-white rounded-md 
    shadow-shadowBottomTwlightLite animate-zoom-to-front-fast">
      <DateRangePicker
        ranges={selectionRange}
        navigatorRenderer={renderMonthAndYear}
        onChange={handleSelect}
        monthDisplayFormat="MMMM yyy"
        direction="horizontal"
        months={matches ? 2 : 1}
        maxDate={new Date()}
        rangeColors={[COLORS.ARTYCLICK_DEEP_SKY_BLUE]}
        inputRanges={[]}
        showDateDisplay={false}
        moveRangeOnFirstSelection={false}
        classNames={classNames}
        calendarFocus="forwards"
        staticRanges={getStaticRanges()}
      />
      <div id="actionControls" className="absolute top-[18px] pl-[18px]">
        <FormControl sx={{ width: 220 }}>
          <InputLabel id="fyDropDown" sx={inputLabelSX}>
            {translate('transactionPage.selectFY')}
          </InputLabel>
          <Select
            labelId="fyDropDown"
            label={translate('transactionPage.selectFY')}
            value={selectedFY}
            onOpen={onOpen}
            onClose={onClose}
            sx={selectSX}
            MenuProps={{ sx: menuPropSx }}
            IconComponent={DownArrowBlack}
            onChange={handleFYSelect}>
            {financialYears.map(financialYear => (
              <MenuItem value={financialYear.name} key={financialYear.name}>
                {financialYear.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div
          className={`flex flex-row items-center py-[10px] px-3 mt-[14px]
            max-w-[220px] text-sm  font-medium ${
              selectionRange[0].startDate && selectionRange[0].endDate
                ? 'text-blackGreen border-primaryColor'
                : 'text-slateGrey border-harp'
            } 
            whitespace-nowrap rounded-[5px] border`}>
          <CalenderIcon
            stroke={
              selectionRange[0].startDate && selectionRange[0].endDate
                ? COLORS.BLACK_GREEN
                : COLORS.SLATE_GREY
            }
            className="shrink-0 mr-[10px]"
          />
          {selectionRange[0].startDate && selectionRange[0].endDate
            ? format(selectionRange[0].startDate, 'dd/MM/yy')
            : translate('transactionPage.startDate')}
          <span className="mx-[10px]">{'->'}</span>
          {selectionRange[0].startDate && selectionRange[0].endDate
            ? format(selectionRange[0].endDate, 'dd/MM/yy')
            : translate('transactionPage.endDate')}
        </div>
      </div>
      <div className="border-b border-softPeach"></div>
      <div
        role="presentation"
        className="flex justify-end p-2 text-sm font-normal text-slateGrey">
        <span onClick={handleClearAll} className="cursor-pointer">
          {translate('transactionPage.clear')}
        </span>
      </div>
    </div>
  );
};

export default DateRangeSelector;
