import { MISSING_TAG_IDS, TRANSACTION_TYPES } from '@constants/common';

export const TRANSACTION_TYPE_LABEL = {
  [TRANSACTION_TYPES.buy]: 'Buy',
  [TRANSACTION_TYPES.sell]: 'Sell',
  [TRANSACTION_TYPES.trade]: 'Trade',
  [TRANSACTION_TYPES.transferIn]: 'Transfer In',
  [TRANSACTION_TYPES.transferOut]: 'Transfer Out',
  [TRANSACTION_TYPES.deposit]: 'Deposit',
  [TRANSACTION_TYPES.withdrawal]: 'Withdrawal',
  [TRANSACTION_TYPES.cost]: 'Cost',
  [TRANSACTION_TYPES.realizedProfit]: 'Realized P&L (Profit)',
  [TRANSACTION_TYPES.realizedLoss]: 'Realized P&L (Loss)',
  [TRANSACTION_TYPES.marginFee]: 'Margin Fee'
};

export const TRANSFER_IN_TEXT = 'Transferred from others';
export const TRANSFER_IN_TEXT_INTERNAL = 'Transferred from self';

export const TRANSFER_OUT_TEXT = 'Transferred to others';
export const TRANSFER_OUT_TEXT_INTERNAL = 'Transferred to self';

export const TDS_VALID_AFTER_DATE = '2022-07-01';

export const TAG_ID_TO_FILTER_VALUE_MAPPER = {
  missing_asset_count: { transactionTag: MISSING_TAG_IDS.missingAsset },
  missing_cost_basis_count: { transactionTag: MISSING_TAG_IDS.missingCostBasis }
};

export const ERROR_TAG_ID = {
  missing_asset_count: MISSING_TAG_IDS.missingAsset,
  missing_cost_basis_count: MISSING_TAG_IDS.missingCostBasis
};
