import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

import { translate } from '@utils';
import { useTopContentHeight } from '@hooks';
import { Button, EmptyData, RadioButton, TopBar } from '@components';
import {
  convertFinancialYearToUTC,
  generateFinancialYears
} from '@utils/generalUtils';
import { TopBarConstants, YES_OR_NO_RADIO_VALUES } from '@constants/common';
import { UpgradeIcon } from '@assets/icons';
import { ItrSubmissionSuccess, UpgradeYourPlan } from '@assets/images';
import { ItrFillingPageSkeleton } from '@containers/skeleton-loader';
import { ERR_ACCESS_DENIED } from '@constants/errorCodeMapper';
import { itrFormSchema } from './validate';
import { useGetItrRequestCountQuery, useSubmitItrMutation } from './api';
import { ITR_FORM } from './itrFilingConstants';
import routesPath from '@routes/RoutesPath';

const assesmentFY = generateFinancialYears(2)[1];

const ItrFiling: FC = () => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(itrFormSchema)
  });

  const topContentHeight = useTopContentHeight(false);
  const isSmallScreen = useMediaQuery('(max-width:640px)');

  const navigate = useNavigate();

  const convertToBoolean = (value: string) => {
    return value === YES_OR_NO_RADIO_VALUES[0].id;
  };

  const assessmentYear = convertFinancialYearToUTC(assesmentFY.name);
  const queryParam = {
    fromTimestamp: assessmentYear.startDate,
    toTimestamp: assessmentYear.endDate
  };

  const [submitItr, { isSuccess: itrSubmissionSuccess }] =
    useSubmitItrMutation();

  const {
    data: itrRequestCount,
    isFetching: isFetchingRequestCount,
    error: itrRequestCountError
  } = useGetItrRequestCountQuery(queryParam);

  const handleItrSubmission = values => {
    const payload = {
      has_agricultural_income: convertToBoolean(values.hasAgriculturalIncome),
      has_income_from_business: convertToBoolean(values.hasIncomeFromBusiness),
      has_income_from_house_property: convertToBoolean(
        values.hasIncomeFromHouseProperty
      ),
      has_income_from_other_sources: convertToBoolean(
        values.hasIncomeFromOtherSources
      ),
      has_income_from_salaries: convertToBoolean(values.hasIncomeFromSalaries),
      has_income_from_sale: convertToBoolean(values.hasIncomeFromSale)
    };
    submitItr({ payload, queryParam });
  };

  const handleRedirectToSubscription = () => {
    navigate(
      `${routesPath.SETTINGS}/plans?financialYear=${assesmentFY.name.replace(
        '_',
        '-'
      )}`
    );
  };

  const topBarMiddleLeftChild = (
    <div className="flex items-center ml-8 text-xs font-semibold text-primaryColor sm:text-base">
      {assesmentFY.label}
    </div>
  );
  const middleChild = {
    left: topBarMiddleLeftChild
  };

  const getContentConfig = () => {
    return (itrRequestCountError as any)?.data?.error?.message ===
      ERR_ACCESS_DENIED
      ? {
          highlightedImage: UpgradeYourPlan,
          showPrimaryDescription: false,
          showSecondaryDescription: true,
          title: translate('itr.notAllowed'),
          secondaryDescription: translate('itr.notAllowedSubtext', {
            year: assesmentFY.label
          }),
          showPrimaryButton: true,
          primaryButtonConfig: {
            buttonHandler: handleRedirectToSubscription,
            showLabelInSmallScreen: true,
            icon: UpgradeIcon,
            buttonLabel: translate('itr.upgradeButton')
          }
        }
      : {
          highlightedImage: ItrSubmissionSuccess,
          showPrimaryDescription: false,
          showSecondaryDescription: true,
          title: translate('itr.success'),
          secondaryDescription: translate('itr.successSubtext')
        };
  };

  const renderItr = () => {
    return itrSubmissionSuccess ||
      (itrRequestCountError as any)?.data?.error?.message ===
        ERR_ACCESS_DENIED ||
      itrRequestCount?.request_count > 0 ? (
      <div className="flex w-full">
        <EmptyData
          styleConfig={{
            height: isSmallScreen
              ? `calc(100vh - ${topContentHeight}px - 32px)`
              : `calc(100vh - ${topContentHeight}px - 48px)`,
            wrapperStyle: `m-[14px] md:m-6 min-h-[300px] ${
              (itrRequestCountError as any)?.data?.error?.message ===
              ERR_ACCESS_DENIED
                ? 'tall:min-h-[730px]'
                : 'tall:min-h-[600px]'
            }`,
            imageStyle: 'w-[50%] sm:w-[250px] md:w-[300px] lg:w-[550px]',
            titleStyle: 'mt-[26px] text-sm sm:text-2xl font-semibold',
            secondaryDescriptionStyle:
              'text-[10px] w-[80%] leading-normal sm:text-sm'
          }}
          contentConfig={getContentConfig()}
        />
      </div>
    ) : (
      <div
        className="p-8 m-[14px] min-h-[calc(100vh-176px)] bg-white rounded-[10px] sm:my-8 
    sm:mx-[27px] lg:px-28 xl:px-56">
        <div className="flex justify-center text-2xl font-semibold text-center text-blackGreen">
          {translate('itr.heading')}
        </div>
        <div className="flex justify-center py-2 mt-2 mb-4 text-sm font-normal text-center text-davyGrey">
          {translate('itr.subHeading')}
        </div>
        <form onSubmit={handleSubmit(handleItrSubmission)}>
          {ITR_FORM.map(question => (
            <div
              key={question.questionId}
              className="flex flex-col md:flex-row">
              <div className="pt-6 text-[16px] font-medium md:w-1/2">
                {question.question}
              </div>
              <div className="flex justify-start items-center pt-3 ml-8 md:justify-center md:w-1/2">
                <RadioButton
                  className="lg:pl-4"
                  name={question.questionId}
                  control={control}
                  errors={errors}
                  radioValues={question.radioValues}
                />
              </div>
            </div>
          ))}
          <div className="flex justify-start pt-6">
            <Button
              label={translate('itr.submit')}
              className="py-[10px] px-[30px] mb-2 w-full text-white
                         bg-primaryColor md:w-auto"
              type="submit"
              dataTestId={'itrSubmitButton'}
            />
          </div>
        </form>
      </div>
    );
  };

  return (
    <>
      <TopBar title={TopBarConstants.ITR_FILING} middleChild={middleChild} />
      {isFetchingRequestCount ? <ItrFillingPageSkeleton /> : renderItr()}
    </>
  );
};

export default ItrFiling;
