import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useMediaQuery, useTheme } from '@mui/material';

import {
  AddTransaction,
  Banner,
  EmptyData,
  If,
  Tooltip,
  TopBar
} from '@components';
import { useTopContentHeight } from '@hooks';
import {
  ACCOUNT_TYPE,
  NotifierTypes,
  TopBarConstants,
  USER_ROLE
} from '@constants/common';
import { AttachmentPinIcon, InfoIconGrey, RefreshIcon } from '@assets/icons';
import { translate } from '@utils/locale';
import { eventKeyMapper } from '@constants/gaEvents';
import { gAEventTracker } from '@utils/gaUtils';
import { SyncRequestStatus } from '@constants/mySources';
import { EmptyPortfolio as EmptyPortfolioImage } from '@assets/images';
import { RootState } from '@store/reducers';
import { TAB_BAR_CONSTANTS, TAB_BAR_ITEMS } from '@constants/tabBar';
import { showNotifier } from '@reducers/appReducer';
import { useAppDispatch } from '@store/store';
import {
  useAddTransactionMutation,
  useGetConnectedExchangeStatusQuery,
  useGetConnectedWalletStatusQuery,
  useGetLinkedSourcesQuery,
  useGetWalletsQuery,
  useSyncTransactionsMutation,
  useSyncWalletMutation
} from '@containers/source/api';
import { useGetExchangesQuery } from '@containers/connect-exchange/api';
import { DashboardSkeleton } from '@containers/skeleton-loader';
import { useGetPortfolioAssetQuery, useGetPortfolioNftQuery } from './api';
import { InvestmentSummaryType } from './types';
import RoutesPath from '@routes/RoutesPath';
import PortfolioSummary from './components/portfolio-summary/PortfolioSummary';
import InvestmentSummary from './components/investment-summary/InvestmentSummary';

const Dashboard: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(
    TAB_BAR_ITEMS.dashboardPage[0].id
  );
  const [investmentSummary, setInvestmentSummary] =
    useState<InvestmentSummaryType>(null);
  const [allExchangeIds, setAllExchangeIds] = useState('');
  const [showSuccessNotifier, setShowSuccessNotifier] = useState(false);
  const [allWallets, setAllWallets] = useState('');
  const [stopPolling, setStopPolling] = useState(false); // stopPolling= true means polling is active
  const [stopWalletsPolling, setStopWalletsPolling] = useState(false);
  const [showMissingCostBasisWarning, setShowMissingCostBasisWarning] =
    useState<boolean>(false);
  const [isTransactionFormPopupOpen, setIsTransactionFormPopupOpen] =
    useState(false);
  const [investmentSummaryHeight, setInvestmentSummaryHeight] = useState(0);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const navigateToPage = tabName => {
    navigate(`${RoutesPath.CONNECT_EXCHANGE}?tab=${tabName}`);
  };
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up('md'));
  const isMobileView = useMediaQuery('(max-width:640px)');

  const { userDetails, clientId, clientProfile } = useSelector(
    (state: RootState) => ({
      ...state.rootReducer.userDetails,
      ...state.rootReducer.app
    })
  );

  const topContentHeight = useTopContentHeight(!!clientId);

  const { data: linkedExchanges } = useGetLinkedSourcesQuery({
    searchTerm: '',
    clientId
  });
  const { isFetching: isLoadingPortfolioAsset, data: portfolioAsset } =
    useGetPortfolioAssetQuery(clientId);
  const { isFetching: isLoadingPortfolioNft, data: portfolioNft } =
    useGetPortfolioNftQuery(clientId);
  const [syncTransactions, syncTransactionResponse] =
    useSyncTransactionsMutation();
  const { data: syncStatusData, isFetching: fetchingSyncStatus } =
    useGetConnectedExchangeStatusQuery(
      { exchangeIds: allExchangeIds, clientId },
      { pollingInterval: 5000, skip: !allExchangeIds || stopPolling }
    );
  const { data: exchangesList } = useGetExchangesQuery({ searchTerm: '' });
  const [addTransaction, addTransactionResponse] = useAddTransactionMutation();
  const { data: walletsDetails } = useGetWalletsQuery({
    searchTerm: '',
    clientId
  });
  const { data: syncStatusWalletData, isFetching: fetchingSyncWalletStatus } =
    useGetConnectedWalletStatusQuery(
      {
        walletIds: allWallets,
        clientId
      },
      { pollingInterval: 5000, skip: !allWallets || stopWalletsPolling }
    );
  const [syncWalletTransactions, syncWalletTransactionResponse] =
    useSyncWalletMutation();

  useEffect(() => {
    const {
      total_cost_basis,
      total_market_value,
      total_gain_or_loss,
      total_gain_or_loss_percent,
      currency_code,
      is_missing_asset
    } = portfolioAsset || {};
    setShowMissingCostBasisWarning(is_missing_asset);

    if (portfolioAsset) {
      setInvestmentSummary({
        totalInvestedAmount: total_cost_basis,
        totalMarketValue: total_market_value,
        totalGainOrLoss: total_gain_or_loss,
        totalGainOrLossPercent: total_gain_or_loss_percent,
        currencyCode: currency_code
      });
    }
  }, [portfolioAsset]);

  useEffect(() => {
    const investmentSummaryElement =
      document.getElementById('investmentSummary');
    setInvestmentSummaryHeight(investmentSummaryElement?.offsetHeight || 0);
  });

  useEffect(() => {
    // computes all available exchange id's
    let ids = [];
    linkedExchanges?.forEach(exchange => ids.push(exchange.exchange_id));
    setAllExchangeIds(ids.join(','));
  }, [linkedExchanges]);

  useEffect(() => {
    let walletIds = [];
    walletsDetails?.result?.forEach(wallets => walletIds.push(wallets.id));
    setAllWallets(walletIds.join(','));
  }, [walletsDetails]);

  useEffect(() => {
    // for handling polling
    let pollingRequired = true;
    if (!isEmpty(syncStatusData) && !fetchingSyncStatus) {
      pollingRequired = syncStatusData?.reduce(
        (isPollingRequired, data) =>
          isPollingRequired ||
          ![
            SyncRequestStatus.COMPLETED,
            SyncRequestStatus.DEAD,
            SyncRequestStatus.UNLINKED,
            SyncRequestStatus.AUTH_EXPIRED
          ].includes(data.status),
        false
      );
    }
    setStopPolling(!pollingRequired);
  }, [syncStatusData, fetchingSyncStatus]);

  useEffect(() => {
    let walletsPollingRequired = true;
    if (!isEmpty(syncStatusWalletData) && !fetchingSyncWalletStatus) {
      walletsPollingRequired = syncStatusWalletData?.reduce(
        (isWalletsPollingRequired, data) =>
          isWalletsPollingRequired ||
          ![
            SyncRequestStatus.COMPLETED,
            SyncRequestStatus.DEAD,
            SyncRequestStatus.UNLINKED,
            SyncRequestStatus.AUTH_EXPIRED
          ].includes(data.status),
        false
      );
    }
    setStopWalletsPolling(!walletsPollingRequired);
  }, [syncStatusWalletData, fetchingSyncWalletStatus]);

  useEffect(() => {
    let isExchangeSyncInProgress = false;
    let isWalletSyncInProgress = false;
    if (!isEmpty(syncStatusData)) {
      isExchangeSyncInProgress = syncStatusData?.reduce(
        (syncStatus, data) =>
          syncStatus ||
          ![
            SyncRequestStatus.COMPLETED,
            SyncRequestStatus.DEAD,
            SyncRequestStatus.UNLINKED,
            SyncRequestStatus.UNLINKING,
            SyncRequestStatus.AUTH_EXPIRED
          ].includes(data.status),
        false
      );
    }
    if (!isEmpty(syncStatusWalletData)) {
      isWalletSyncInProgress = syncStatusWalletData?.reduce(
        (syncStatus, data) =>
          syncStatus ||
          ![
            SyncRequestStatus.COMPLETED,
            SyncRequestStatus.DEAD,
            SyncRequestStatus.UNLINKED,
            SyncRequestStatus.UNLINKING,
            SyncRequestStatus.AUTH_EXPIRED
          ].includes(data.status),
        false
      );
    }
    if (isWalletSyncInProgress || isExchangeSyncInProgress)
      setShowSuccessNotifier(true);
  }, [syncStatusData, syncStatusWalletData]);

  useEffect(() => {
    if (
      !(
        (!stopPolling && allExchangeIds) ||
        (!stopWalletsPolling && allWallets)
      ) &&
      showSuccessNotifier
    ) {
      dispatch(
        showNotifier({
          message: translate('notifierMessage.syncSuccess'),
          type: NotifierTypes.SUCCESS
        })
      );
      setShowSuccessNotifier(false);
    }
  }, [stopPolling, stopWalletsPolling, showSuccessNotifier]);

  useEffect(() => {
    if (addTransactionResponse.isSuccess) {
      handleTransactionFormPopupClose();
    }
  }, [addTransactionResponse]);

  const handleSyncTransactionClick = async () => {
    gAEventTracker(eventKeyMapper.syncInDashboard);
    dispatch(
      showNotifier({
        message: translate('notifierMessage.syncInProgress'),
        type: NotifierTypes.LOADING
      })
    );
    const exchangeIds = linkedExchanges?.map(
      linkedExchange => linkedExchange.exchange_id
    );
    const payload = { exchange_ids: exchangeIds };
    const walletPayload = { sync_request: [], sync_all: true };

    if (isEmpty(linkedExchanges) && !isEmpty(walletsDetails?.result))
      await syncWalletTransactions({ walletPayload, clientId });
    if (!isEmpty(linkedExchanges) && isEmpty(walletsDetails?.result))
      await syncTransactions({ payload, clientId });
    if (!isEmpty(linkedExchanges) && !isEmpty(walletsDetails?.result))
      await Promise.all([
        syncTransactions({ payload, clientId }),
        syncWalletTransactions({ walletPayload, clientId })
      ]);
    setStopPolling(false);
    setStopWalletsPolling(false);
  };

  const handleTabSelect = (tabId: TAB_BAR_CONSTANTS) => {
    setSelectedTab(tabId);
  };

  const handleClose = () => {
    setShowMissingCostBasisWarning(false);
  };

  const handleShowAddTransactionForm = () => {
    setIsTransactionFormPopupOpen(true);
  };

  const setShowAddTransactionForm = () => {
    navigate(RoutesPath.SOURCE);
  };

  const handleTransactionFormPopupClose = () => {
    setIsTransactionFormPopupOpen(false);
  };

  const infoIconContent = (
    <div className="py-3 px-2 w-[250px] text-xs text-davyGrey sm:w-[350px] sm:text-sm">
      {translate('dashboard.lastSyncDataInfoMessage1')}
      <span
        className="text-primaryColor underline cursor-pointer"
        onClick={setShowAddTransactionForm}>
        {translate('dashboard.mySources')}
      </span>
      {translate('dashboard.lastSyncDataInfoMessage2')}
    </div>
  );

  const primaryButtonConfig = {
    buttonLabel: translate('dashboard.syncNow'),
    buttonHandler: () => handleSyncTransactionClick(),
    icon: RefreshIcon,
    customClass: 'text-white bg-primaryColor',
    iconClass: `${
      (!stopPolling && allExchangeIds) || (!stopWalletsPolling && allWallets)
        ? 'animate-rotate'
        : ''
    }`,
    showMainViewButton: true,
    showButton: !!allExchangeIds || !!allWallets,
    isDisabled:
      syncTransactionResponse.isLoading ||
      syncWalletTransactionResponse.isLoading ||
      (isEmpty(linkedExchanges) && isEmpty(walletsDetails)) ||
      (!stopPolling && !!allExchangeIds) ||
      (!stopWalletsPolling && !!allWallets) ||
      (userDetails.userType === USER_ROLE.PROFESSIONAL &&
        clientProfile.type !== ACCOUNT_TYPE.MANAGED)
  };

  const topBarMiddleRightChild = (
    <div className="flex items-center">
      {(!!allExchangeIds || !!allWallets) && match && (
        <>
          <Tooltip body={infoIconContent} contentNoWrap={false}>
            <InfoIconGrey width="28" height="28" className="mr-4" />
          </Tooltip>
        </>
      )}
    </div>
  );

  return (
    <div className="w-full">
      <TopBar
        title={TopBarConstants.DASHBOARD}
        primaryButtonConfig={primaryButtonConfig}
        middleChild={{
          right: topBarMiddleRightChild
        }}
      />
      <If condition={isLoadingPortfolioAsset || isLoadingPortfolioNft}>
        <DashboardSkeleton />
      </If>
      <If condition={!(isLoadingPortfolioAsset || isLoadingPortfolioNft)}>
        {!isEmpty(portfolioAsset?.wallet_balance_details) ||
        !isEmpty(portfolioAsset?.crypto_balance_details) ? (
          <>
            {showMissingCostBasisWarning && (
              <Banner
                content={translate('dashboard.missingCostBasisWarningInfo')}
                contentStyle="font-medium text-davyGrey bg-lightOrange"
                handleClose={handleClose}
              />
            )}
            {investmentSummary && (
              <InvestmentSummary summary={investmentSummary} />
            )}
            <div className="mx-4 bg-white rounded-lg md:mx-6">
              <PortfolioSummary
                topContentHeight={topContentHeight + investmentSummaryHeight}
                selectedTab={selectedTab}
                handleTabSelect={handleTabSelect}
                cryptoData={portfolioAsset?.crypto_balance_details}
                nftData={portfolioNft}
              />
            </div>
          </>
        ) : (
          <div className="flex w-full">
            <EmptyData
              styleConfig={{
                height: isMobileView
                  ? `calc(100vh - ${topContentHeight}px - 32px)`
                  : `calc(100vh - ${topContentHeight}px - 64px)`,
                wrapperStyle: 'm-4 sm:m-8',
                titleStyle:
                  'text-sm font-semibold mt-2 sm:text-xl lg:text-2xl mt-[30px]',
                imageStyle: 'w-[150px] sm:w-[200px] md:w-[300px] lg:w-[450px]',
                primaryDescriptionStyle:
                  'text-[10px] text-primaryColor sm:text-base',
                secondaryDescriptionStyle:
                  'text-[10px] sm:text-sm w-[80%] leading-normal font-normal'
              }}
              contentConfig={{
                highlightedImage: EmptyPortfolioImage,
                title: translate('dashboard.userWelcome', {
                  userName: userDetails.firstName
                }),
                showPrimaryDescription: true,
                showSecondaryDescription: true,
                showPrimaryButton: true,
                showSecondaryButton: true,
                primaryDescription: translate(
                  'dashboard.emptyTransactionMessage1'
                ),
                secondaryDescription: translate(
                  'dashboard.emptyTransactionMessage2'
                ),
                primaryButtonConfig: {
                  icon: AttachmentPinIcon,
                  buttonHandler: () =>
                    navigateToPage(TAB_BAR_CONSTANTS.CONNECT_EXCHANGES),
                  showLabelInSmallScreen: true,
                  buttonLabel: match
                    ? translate('transactionPage.connectExchange')
                    : translate('transactionPage.mobileView.connectExchange')
                },
                secondaryButtonConfig: {
                  buttonHandler: () => handleShowAddTransactionForm(),
                  showLabelInSmallScreen: true,
                  buttonLabel: match
                    ? translate('dashboard.addTransaction')
                    : translate('dashboard.mobileView.addTransaction'),
                  isDisabled:
                    userDetails.userType === USER_ROLE.PROFESSIONAL &&
                    clientProfile.type !== ACCOUNT_TYPE.MANAGED,
                  customClass: `${
                    userDetails.userType === USER_ROLE.PROFESSIONAL &&
                    clientProfile.type !== ACCOUNT_TYPE.MANAGED
                      ? 'cursor-not-allowed'
                      : ''
                  }`
                }
              }}
            />
          </div>
        )}
      </If>
      <AddTransaction
        exchangesList={exchangesList}
        addTransaction={addTransaction}
        clientId={clientId}
        isUpdatingTransaction={addTransactionResponse.isLoading}
        handleFormPopUpClose={handleTransactionFormPopupClose}
        isTransactionFormPopupOpen={isTransactionFormPopupOpen}
      />
    </div>
  );
};

export default Dashboard;
