import React, { FC, useEffect, useState } from 'react';

import { Button, SplitOTPInput, StringToTSX } from '@components';
import { EditIconFilled, EnterOtp } from '@assets/icons';
import { KEYBOARD_KEYS, REGEX } from '@constants/common';
import { useTimeout } from '@hooks';
import { getTimeStringFromSeconds } from '@utils/generalUtils';
import { translate } from '@utils/locale';
import { OTPScreenProps } from './types';

const emptyOTPArray = new Array(6).fill('');

const OTPScreen: FC<OTPScreenProps> = props => {
  const {
    navigateToPhoneScreen,
    mobileNumber,
    isLoadingValidateOtp,
    handleValidateOtp,
    handleSendOtp
  } = props;

  const [otpValues, setOtpValues] = useState<string[]>(emptyOTPArray);
  const [isInvalidOtp, setIsInvalidOtp] = useState(false);

  const { count, resetTimer } = useTimeout(
    Number(process.env.REACT_APP_RESEND_OTP_TIMER_DURATION)
  );

  const handleEnterKeyDown = (event: KeyboardEvent) => {
    if (
      event.key === KEYBOARD_KEYS.enter &&
      checkIsValidOTP() &&
      !isLoadingValidateOtp
    ) {
      handleProceedClick();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEnterKeyDown);
    return () => {
      document.removeEventListener('keydown', handleEnterKeyDown);
    };
  }, [handleEnterKeyDown]);

  const onResendClick = () => {
    isInvalidOtp && setIsInvalidOtp(false);
    setOtpValues(emptyOTPArray);
    handleSendOtp(resetTimer);
  };

  const handleOTPChange = (updatedValues: string[]) => {
    setOtpValues([...updatedValues]);
    isInvalidOtp && setIsInvalidOtp(false);
  };

  const checkIsValidOTP = () => {
    return otpValues.reduce(
      (isOTPValid, otp) => isOTPValid && REGEX.digits.test(otp),
      true
    );
  };

  const handleNavigateBack = () => {
    isInvalidOtp && setIsInvalidOtp(false);
    setOtpValues(emptyOTPArray);
    navigateToPhoneScreen();
  };

  const invalidateOtp = () => {
    setIsInvalidOtp(true);
  };

  const handleProceedClick = () => {
    if (!checkIsValidOTP()) {
      return;
    }
    handleValidateOtp(otpValues, invalidateOtp);
  };

  return (
    <>
      <div className="pt-14 pb-4 text-[22px] font-medium leading-8 text-center text-black sm:pt-0 sm:text-left">
        {translate('welcomePopup.otpVerification')}
      </div>
      <div className="flex flex-col items-center sm:flex-row">
        <EnterOtp />
        <div className="flex flex-col pt-[17px] pl-0 sm:pt-0 sm:pl-[28px]">
          <div
            className="flex flex-row justify-center items-baseline pb-[13px] text-[15px] font-normal
                leading-8 text-davyGrey sm:justify-start sm:pb-4">
            <StringToTSX
              domString={translate('welcomePopup.enterOtp', {
                mobileNumber
              })}
            />
            <EditIconFilled
              className="ml-[3px] cursor-pointer"
              width={16}
              height={16}
              onClick={handleNavigateBack}
            />
          </div>
          <SplitOTPInput
            fieldValues={otpValues}
            handleValueChange={handleOTPChange}
            hasError={isInvalidOtp}
          />
          <div
            className="pt-1 pb-[7px] text-[15px] font-normal leading-8 text-center
                 text-davyGrey sm:pb-7 sm:text-left">
            {isInvalidOtp ? (
              <span className="text-coralRed">
                {translate('welcomePopup.invalidOtpEntered')}
              </span>
            ) : (
              translate('welcomePopup.otpNotReceived')
            )}
            {count > 0 ? (
              <span className="flex-row text-primaryColor cursor-default">
                {getTimeStringFromSeconds(count, false)}
              </span>
            ) : (
              <span
                className="flex-row text-primaryColor cursor-pointer"
                onClick={onResendClick}
                data-testid="resend-otp">
                {translate('welcomePopup.resendOtp')}
              </span>
            )}
          </div>
          <div className="flex flex-row justify-center sm:justify-start">
            <Button
              label={translate('back')}
              className="py-[10px] px-5 mr-4 w-[130px] h-10 bg-white border border-primaryColor hover:opacity-100"
              labelClass="text-primaryColor font-medium text-sm"
              onClick={handleNavigateBack}
            />
            <Button
              label={translate('proceed')}
              className="py-[10px] px-5 w-[130px] h-10 bg-primaryColor 
                    disabled:bg-primaryColor hover:opacity-100 disabled:opacity-50"
              labelClass="text-white font-medium text-sm"
              disabled={!checkIsValidOTP() || isLoadingValidateOtp}
              onClick={handleProceedClick}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OTPScreen;
