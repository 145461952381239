import {
  BuyIcon,
  SellIcon,
  TradeIcon,
  DepositIcon,
  WithdrawalIcon,
  TransferIcon,
  DeadCostIcon,
  RealizedProfit,
  RealizedLoss,
  MarginFee
} from '@assets/icons';
import COLORS from '@constants/colors';

import { TRANSACTION_TYPES } from '@constants/common';

export const TRANSACTION_ICON_LIST = {
  [TRANSACTION_TYPES.buy]: BuyIcon,
  [TRANSACTION_TYPES.sell]: SellIcon,
  [TRANSACTION_TYPES.trade]: TradeIcon,
  [TRANSACTION_TYPES.transferIn]: TransferIcon,
  [TRANSACTION_TYPES.transferOut]: TransferIcon,
  [TRANSACTION_TYPES.deposit]: DepositIcon,
  [TRANSACTION_TYPES.withdrawal]: WithdrawalIcon,
  [TRANSACTION_TYPES.cost]: DeadCostIcon,
  [TRANSACTION_TYPES.realizedProfit]: RealizedProfit,
  [TRANSACTION_TYPES.realizedLoss]: RealizedLoss,
  [TRANSACTION_TYPES.marginFee]: MarginFee
};

export const TRANSACTION_ICON_LIST_STYLE = {
  [TRANSACTION_TYPES.buy]: {
    primary: { fill: COLORS.PRIMARY_COLOR },
    secondary: { fill: COLORS.DAVY_GREY }
  },
  [TRANSACTION_TYPES.sell]: {
    primary: { fill: COLORS.PRIMARY_COLOR },
    secondary: { fill: COLORS.DAVY_GREY }
  },
  [TRANSACTION_TYPES.trade]: {
    primary: { fill: COLORS.PRIMARY_COLOR },
    secondary: { fill: COLORS.DAVY_GREY }
  },
  [TRANSACTION_TYPES.transferIn]: {
    primary: { fill: COLORS.PRIMARY_COLOR },
    secondary: { fill: COLORS.DAVY_GREY }
  },
  [TRANSACTION_TYPES.transferOut]: {
    primary: { fill: COLORS.PRIMARY_COLOR },
    secondary: { fill: COLORS.DAVY_GREY }
  },
  [TRANSACTION_TYPES.deposit]: {
    primary: { fill: COLORS.PRIMARY_COLOR },
    secondary: { fill: COLORS.DAVY_GREY }
  },
  [TRANSACTION_TYPES.withdrawal]: {
    primary: { stroke: COLORS.PRIMARY_COLOR },
    secondary: { stroke: COLORS.DAVY_GREY }
  },
  [TRANSACTION_TYPES.cost]: {
    primary: { fill: COLORS.PRIMARY_COLOR },
    secondary: { fill: COLORS.DAVY_GREY }
  },
  [TRANSACTION_TYPES.realizedProfit]: {
    primary: { fill: COLORS.PRIMARY_COLOR },
    secondary: { fill: COLORS.DAVY_GREY }
  },
  [TRANSACTION_TYPES.realizedLoss]: {
    primary: { fill: COLORS.PRIMARY_COLOR },
    secondary: { fill: COLORS.DAVY_GREY }
  },
  [TRANSACTION_TYPES.marginFee]: {
    primary: { fill: 'none', stroke: COLORS.PRIMARY_COLOR },
    secondary: { fill: 'none', stroke: COLORS.DAVY_GREY }
  }
};

export const TRANSACTION_VALUE_CATEGORY = {
  sent: 'sent',
  received: 'received'
};

export const TIMEMAP_TYPE = {
  START: 'start',
  END: 'end'
};

export const COST_BASIS_CATEGORY = {
  COST_BASIS: 'costBasisData',
  FEE_COST_BASIS: 'feeCostBasisData'
};

export const TRANSACTIONS_GENERIC_TAG_LIST = {
  'gift-from-relative': 'Gift From Relative',
  'gift-from-others': 'Gift From Others',
  'internal-transfer': 'Internal Transfer',
  'staking-reward': 'Staking Reward',
  'referral-bonus': 'Referral Bonus',
  otc: 'OTC',
  airdrop: 'Airdrop',
  fork: 'Fork',
  interest: 'Interest',
  income: 'Income',
  'gift-sent': 'Gift',
  external: 'External Transfer',
  lost: 'Lost',
  p2p: 'P2P',
  mining: 'Mining',
  rewards: 'Rewards'
};

export const TRANSACTION_FILTER_PROPERTY_LIST = {
  TYPE: 'transactionType',
  TAG: 'transactionTag',
  SOURCE: 'source',
  CURRENCY: 'currency',
  STARTDATE: 'startDate',
  ENDDATE: 'endDate'
};

export const NUMERICAL_SIGNS = {
  POSITIVE: '+',
  NEGATIVE: '-'
};
