import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IconButton } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

import { EyeCrossedIcon, EyeIcon } from '@assets/icons';
import { Button, TextField } from '@components';
import { resetPasswordFormSchema } from '../validate';
import { translate } from '@utils/locale';
import { PasswordProps, ResetPassWordFieldValues } from '../types';

const ResetPassword: FC<PasswordProps> = props => {
  const { handleValues, disabled, handleBackClick } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<ResetPassWordFieldValues>({
    defaultValues: {
      password: '',
      confirmPassword: ''
    },
    resolver: yupResolver(resetPasswordFormSchema)
  });

  useEffect(() => {
    // reset form on unmount
    return () => {
      reset();
    };
  }, []);

  const changeShowPasswordState = () => {
    setShowPassword(currState => !currState);
  };

  const changeShowConfirmPasswordState = () => {
    setShowConfirmPassword(currState => !currState);
  };

  const handleChange = e => {
    e.preventDefault();
    return false;
  };

  return (
    <>
      <div className="flex overflow-hidden flex-col justify-center items-center my-7 w-full">
        <div className="text-2xl font-semibold text-center text-blackGreen">
          {translate('resetPasswordPage.createNewPassword')}
        </div>
        <div className="mt-[13px] text-sm text-center text-davyGrey">
          {translate('resetPasswordPage.newPasswordDescription')}
        </div>
      </div>
      <form className="w-full" onSubmit={handleSubmit(handleValues)}>
        <div className="my-6">
          <TextField
            placeholder={translate('password')}
            autoComplete="current-password"
            name="password"
            errors={errors}
            type={showPassword ? 'text' : 'password'}
            control={control}
            onCut={handleChange}
            onCopy={handleChange}
            onPaste={handleChange}
            isControlledField={true}
            InputLabelProps={{ style: { fontSize: 14 } }}
            showInfoIcon={true} //for showing eye icon
            endAdornment={
              <IconButton onClick={changeShowPasswordState}>
                {showPassword ? <EyeCrossedIcon /> : <EyeIcon />}
              </IconButton>
            }
            InputProps={{ style: { height: 54 } }}
            testid="new-password"
          />
        </div>
        <div className="my-6">
          <TextField
            placeholder={translate('resetPasswordPage.confirmPassword')}
            name="confirmPassword"
            autoComplete="current-password"
            errors={errors}
            type={showConfirmPassword ? 'text' : 'password'}
            control={control}
            onCut={handleChange}
            onCopy={handleChange}
            onPaste={handleChange}
            isControlledField={true}
            InputLabelProps={{ style: { fontSize: 14 } }}
            showInfoIcon={true} //for showing eye icon
            endAdornment={
              <IconButton onClick={changeShowConfirmPasswordState}>
                {showConfirmPassword ? <EyeCrossedIcon /> : <EyeIcon />}
              </IconButton>
            }
            InputProps={{ style: { height: 54 } }}
          />
        </div>
        <Button
          label={translate('resetPasswordPage.updatePassword')}
          className="flex justify-center items-center w-full h-[54px] bg-primaryColor"
          labelClass="text-white"
          type="submit"
          disabled={disabled}
        />
      </form>
      <div className="flex justify-center mt-3 w-full text-davyGrey">
        <span
          className="flex flex-row items-center text-sm text-davyGrey underline cursor-pointer"
          onClick={handleBackClick}>
          {translate('resetPasswordPage.backToLogin')}
        </span>
      </div>
    </>
  );
};

export default ResetPassword;
