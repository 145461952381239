import { KeyboardEvent, lazy } from 'react';
import { range } from 'lodash';
import { format } from 'date-fns';
import Decimal from 'decimal.js-light';

import {
  PAGE_HAS_BEEN_FORCE_REFRESHED,
  Currency,
  MOBLIE_SCREEN_CARD_ALIGNMENT_BREAKPOINT,
  DATE_FORMAT,
  FILE,
  REGEX,
  SORTING_ORDER
} from '@constants/common';
import { TIMEMAP_TYPE } from '@containers/transactions/constants';
import { NumberFormatParamsType } from 'types/generalTypes';

/*  This function will return the  list[x][returnValueKey]:
     id: this value is used for the comparison
     list: an array of objects from which the find operation is performing
     keyForComparison: the value of this object key will be compared with the id
     returnValueKey: the value of this object key will be returned as result of this function
*/
export const getValueFromArray = (
  id: string,
  list: Array<object>,
  keyForComparison: string,
  returnValueKey: string
): string =>
  list?.find(item => item[keyForComparison] === id)?.[returnValueKey];

export const formatDate = (date: string) => {
  const dateFormat = new Date(date);
  return format(dateFormat, DATE_FORMAT);
};

/*  For creating time stamp by appending the given date and time
    params :
     date: in Date format
     time: in Date format*/
export const createTimeStamp = (date: Date, time: Date) => {
  const hours = time.getHours();
  const minutes = time.getMinutes();
  const seconds = time.getSeconds();
  const upadtedTimeStamp = new Date(date);
  upadtedTimeStamp.setHours(hours, minutes, seconds, 0);
  return upadtedTimeStamp.toISOString();
};

export const limitDigits = (value: string, len: number = 8) =>
  value?.length > len ? `${value?.substring(0, len)}...` : value;

/*  For getting start date and end date from financial year
    params :
     financialYear: in string
    returns an object containing start date and end date */
export const convertFinancialYearToUTC = (financialYear: string) => {
  const fromDate = new Date(`20${financialYear.slice(0, 2)}/04/01 00:00:00`);
  const toDate = new Date(`20${financialYear.slice(3, 5)}/03/31 23:59:59`);
  return {
    startDate: fromDate.toISOString(),
    endDate: toDate.toISOString()
  };
};

/* To find out to which financial year a given date belongs to
  params: timestamp: dateString
  returns financialYear "22_23"
*/
export const getFinancialYearFromUTC = (timestamp: string) => {
  let financialYear = '';
  const dateString = new Date(timestamp);
  if (dateString.getMonth() + 1 <= 3) {
    financialYear =
      (dateString.getFullYear() - 1).toString().slice(-2) +
      '_' +
      dateString.getFullYear().toString().slice(-2);
  } else {
    financialYear =
      dateString.getFullYear().toString().slice(-2) +
      '_' +
      (dateString.getFullYear() + 1).toString().slice(-2);
  }
  return financialYear;
};

/*  For getting n number of financial years return 
    an array containing financial years looped from 
    current year at top and rest in decreasing order*/
export const generateFinancialYears = (years: number) => {
  const financialYears: { name: string; label: string }[] = [];
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth() + 1;
  if (currentMonth <= 3) {
    // case when date falls between January and March
    for (let i = 1; i <= years; i++) {
      financialYears.push({
        name: `${formatValue(currentYear - i)}_${formatValue(
          currentYear - (i - 1)
        )}`,
        label: `FY ${currentYear - i}-${formatValue(currentYear - (i - 1))}`
      });
    }
  } else {
    // case when date falls between April and December
    for (let i = 0; i < years; i++) {
      financialYears.push({
        name: `${formatValue(currentYear - i)}_${formatValue(
          currentYear - (i - 1)
        )}`,
        label: `FY ${currentYear - i}-${formatValue(currentYear - (i - 1))}`
      });
    }
  }
  return financialYears;
};

/*  For getting number of financial years by passing the initial year 2020
 (denoting FY 20-21)*/
export const getNumberOfFinancialYear = (initialFyYear = 2020) => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth() + 1;
  if (currentMonth <= 3)
    // case when date falls between January and March
    return currentYear - initialFyYear;
  return currentYear - initialFyYear + 1;
};

/*  For getting number of financial years by passing the initial year as parameter*/
export const generateFY = (initialFyYear = 2020) => {
  const numberOfFY = getNumberOfFinancialYear(initialFyYear);
  return generateFinancialYears(numberOfFY);
};

export const formatDateTime = (date, timemapType) => {
  if (timemapType === TIMEMAP_TYPE.START)
    return new Date(date.setHours(0, 0, 0, 0));
  if (timemapType === TIMEMAP_TYPE.END)
    return new Date(date.setHours(23, 59, 59, 999));
};

export const getMobileScreenCardAlignment = (width: number) => {
  return width <= MOBLIE_SCREEN_CARD_ALIGNMENT_BREAKPOINT
    ? 'justify-center'
    : 'justify-start';
};

// Convert to string and then slice 2 characters from 2nd index
const formatValue = (value: number) => {
  return value.toString().slice(2, 4);
};

export const lazyWithRetry = (componentImport: Function) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem(PAGE_HAS_BEEN_FORCE_REFRESHED) || 'false'
    );
    try {
      const component = await componentImport();
      window.localStorage.setItem(PAGE_HAS_BEEN_FORCE_REFRESHED, 'false');
      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem(PAGE_HAS_BEEN_FORCE_REFRESHED, 'true');
        return window.location.reload();
      }
      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Therefore the error boundary will handle that error
      throw error;
    }
  });

export const getDecimalValue = (number: string, noOfDecimals: number) => {
  if (Number(number) === 0) return 0;
  const formattedNumber = new Decimal(number);
  return formattedNumber.toFixed(noOfDecimals, Decimal.ROUND_UP);
};

export const formatPriceWithCurrency = (number: string, currency: Currency) => {
  const value = Number(number);
  if (value === 0) return 0;
  if (value < 0) {
    return `- ${currency}${Number(number) * -1}`;
  }
  return `${currency} ${Number(number)}`;
};

export const handleNegative = (
  number: string,
  hideCurrencySymbol: boolean = false,
  limit: number
) => {
  if (Number(number) < 0)
    return `- ${
      !hideCurrencySymbol ? Currency.RUPEE : ''
    } ${getNumberInIndianFormat(
      Number(parseFloat(number.substring(1)).toFixed(2)),
      '',
      false,
      limit
    )}`;
  return `${
    !hideCurrencySymbol ? Currency.RUPEE : ''
  } ${getNumberInIndianFormat(
    Number(parseFloat(number).toFixed(2)),
    '',
    false,
    limit
  )}`;
};

export const getUploadedFileName = (filename: string = 'File Name') => {
  return filename.slice(filename.indexOf('_') + 1);
};

export const removeInvalidNumericCharactersOnInput = (
  e: KeyboardEvent<HTMLDivElement>
) => {
  if (INVALID_NUMERIC_CHARACTERS.includes(e.key)) {
    if (e?.preventDefault) e.preventDefault();
    return false;
  }
  return true;
};

export const INVALID_NUMERIC_CHARACTERS = ['e', 'E', '-', '+'];

export const maskCharacters = (string, maskingCharacter, notToMask = 4) => {
  return (
    ('' + string).slice(0, -notToMask).replace(/./g, maskingCharacter) +
    ('' + string).slice(-notToMask)
  );
};

export const getTimeStringFromSeconds = (
  seconds: number,
  showHour: boolean = false
) => {
  const hour = Math.floor(seconds / 3600);
  const minute = Math.floor((seconds - hour * 3600) / 60);
  const sec = seconds - hour * 3600 - minute * 60;

  if (showHour)
    return (
      (hour < 10 ? '0' + hour : hour) +
      ':' +
      (minute < 10 ? '0' + minute : minute) +
      ':' +
      (sec < 10 ? '0' + sec : sec)
    );
  return (
    (minute < 10 ? '0' + minute : minute) + ':' + (sec < 10 ? '0' + sec : sec)
  );
};

export const getFormattedNumbering = (
  numberFormatParams: NumberFormatParamsType
) => {
  const {
    value,
    hideRupeeSymbol = false,
    showZeroValue = true,
    showNegativeSign = true
  } = numberFormatParams;
  const numberValue = Number(value);
  const isNegative = numberValue < 0;
  const absValue = Math.abs(numberValue);
  const decimalNumVal = new Decimal(absValue);
  const prefixContent =
    (isNegative && showNegativeSign ? '- ' : '') +
    (hideRupeeSymbol ? '' : Currency.RUPEE);
  let formattedNumber = absValue.toString();
  if (absValue >= 10000000) {
    formattedNumber =
      prefixContent +
      Number((absValue / 10000000).toFixed(2)).toLocaleString('en-IN') +
      'Cr';
  } else if (absValue >= 100000) {
    formattedNumber =
      prefixContent +
      Number((absValue / 100000).toFixed(2)).toLocaleString('en-IN') +
      'L';
  } else if (absValue >= 100) {
    formattedNumber =
      prefixContent + Math.ceil(absValue).toLocaleString('en-IN');
  } else if (absValue >= 1) {
    formattedNumber =
      prefixContent +
      Number(decimalNumVal.toFixed(2, Decimal.ROUND_UP)).toLocaleString(
        'en-IN'
      );
  } else if (absValue > 0) {
    formattedNumber =
      prefixContent +
      Number(decimalNumVal.toFixed(5, Decimal.ROUND_UP)).toLocaleString(
        'en-IN'
      );
  } else if (absValue === 0) {
    formattedNumber = showZeroValue ? prefixContent + '0' : '--';
  } else {
    formattedNumber =
      prefixContent +
      Number(getNumberInIndianFormat(absValue)).toLocaleString('en-IN');
  }
  return formattedNumber;
};

export const formatDecimalPecentage = (value: string) => {
  let formattedNumber = '';
  const numberValue = Number(value);
  const absValue = Math.abs(numberValue);
  const isNegative = numberValue < 0;
  const decimalNumVal = new Decimal(absValue);
  if (absValue >= 100) {
    formattedNumber =
      (isNegative ? '-' : '') +
      Number(decimalNumVal.toFixed(0, Decimal.ROUND_UP)).toLocaleString(
        'en-IN'
      ) +
      '%';
  } else if (absValue > 0) {
    formattedNumber =
      (isNegative ? '-' : '') +
      decimalNumVal.toFixed(2, Decimal.ROUND_UP) +
      '%';
  } else {
    formattedNumber = (isNegative ? '-' : '') + absValue + '%';
  }
  return formattedNumber;
};

export const nonEmptyArrayLength = (dataArray: any[]) => {
  if (dataArray) {
    let count = 0;
    dataArray.forEach(item => {
      if (item) {
        count++;
      }
    });
    return count;
  }
  return -1;
};

export const getNumberInIndianFormat = (
  number: Number,
  currency?: string,
  disableElipsis: boolean = false,
  limit: number = 11
) => {
  let formattedOutput: string;
  if (currency) {
    formattedOutput = number.toLocaleString('en-IN', {
      maximumFractionDigits: number === 0 ? 0 : 2,
      minimumFractionDigits: 0,
      style: 'currency',
      currency: currency
    });
  } else {
    formattedOutput = number.toLocaleString('en-IN', {
      maximumFractionDigits: number === 0 ? 0 : 16,
      minimumFractionDigits: 0
    });
  }
  return disableElipsis ? formattedOutput : limitDigits(formattedOutput, limit);
};

export const getFloatingPointSum = (number1: number, number2: number) => {
  return new Decimal(number1).add(number2).toString();
};

export const findMissingNumberInSequence = num => {
  const max = Math.max(...num); // Will find highest number
  const min = Math.min(...num); // Will find lowest number
  const missingNumbers = [];

  for (let i = min; i <= max; i++) {
    if (!num.includes(i)) {
      // Checking whether i(current value) present in num(argument)
      missingNumbers.push(i); // Adding numbers which are not in num(argument) array
    }
  }
  return missingNumbers;
};

export const checkIsSequentialNumbers = data => {
  return data?.every(
    (num, i) => i === data.length - 1 || Number(num) + 1 === Number(data[i + 1])
  );
};

export const generateArray = (start, end, step) =>
  Array.from(range(start, end, step));

export const shortenString = (
  name: string,
  shortenLength: number, // the length of the name after which it should be trimmed.
  numberOfCharacters: number, //  In case of file type-the number of characters in the name to be displayed once
  //the trim happens, in all other cases it should be the number of characters to be displayed after the ellipsis.
  type?: string
) => {
  if (type === FILE) {
    const fileNameArray = name.split('.');
    return name.length < shortenLength
      ? name
      : fileNameArray[0].slice(0, numberOfCharacters) +
          '...' +
          fileNameArray[1];
  }
  return (
    name.substring(0, shortenLength + 1) +
    '...' +
    name.substring(name.length - numberOfCharacters)
  );
};

export const checkIsEqualArrays = (arrayA, arrayB) => {
  return JSON.stringify(arrayA) === JSON.stringify(arrayB);
};

export const getPastDate = (noOfDays: number) => {
  const currentDate = new Date();
  const daysToSubtract = currentDate.getDate() - noOfDays;
  currentDate.setDate(daysToSubtract);
  return format(currentDate, DATE_FORMAT);
};

export const getPastDateByReducingMonth = (noOfMonths: number) => {
  const currentDate = new Date();
  const monthsToSubtract = currentDate.getMonth() - noOfMonths;
  currentDate.setMonth(monthsToSubtract);
  return format(currentDate, DATE_FORMAT);
};

export const appendClientIdInEndpoints = (
  endpointUrl: string,
  clientId: string | void
) => {
  if (clientId) {
    if (endpointUrl.includes('?')) {
      return `${endpointUrl}&client_id=${clientId}`;
    }
    return `${endpointUrl}?client_id=${clientId}`;
  }
  return endpointUrl;
};

export const compareArrays = (a: string[], b: string[]) =>
  a.length === b.length && a.every((element, index) => element === b[index]);

export const hasCommonElement = (a: string[], b: string[]) => {
  return a.some(element => b.includes(element));
};

export const sortArrayOfObjects = (
  arrayOfObjects,
  key: string,
  order: string
) => {
  if (order === SORTING_ORDER.DEFAULT) return arrayOfObjects;
  const sortedArray =
    arrayOfObjects &&
    [...arrayOfObjects]?.sort((a: string[], b: string[]) => {
      if (REGEX.decimal.test(a[key]) && REGEX.decimal.test(b[key]))
        return order === SORTING_ORDER.ASC ? a[key] - b[key] : b[key] - a[key];
      if (order === SORTING_ORDER.ASC) {
        if (a[key] < b[key]) return -1;
        return a[key] > b[key] ? 1 : 0;
      }
      if (b[key] < a[key]) return -1;
      return b[key] > a[key] ? 1 : 0;
    });
  return sortedArray;
};

export const convertFYToStandardFormat = (financialYear: string) => {
  const yearArray = financialYear.split('-');
  return yearArray.map(year => '20' + year).join('-');
};
