import React, { FC, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Checkbox, Tooltip } from '@mui/material';

import {
  Button,
  FileDropZone,
  RejectedFileSection,
  NotesSection,
  InstructionSection
} from '@components';
import { EXCHANGE_INFO } from '@containers/source/constants';
import { CloseIcon, FailedIcon, RefreshIcon, ImportIcon } from '@assets/icons';
import { translate } from '@utils/locale';
import {
  useGeneratePresignedURLMutation,
  useUploadFileToS3Mutation,
  useCancelFileUploadMutation,
  useSubmitFilesMutation
} from '@containers/source/api';
import {
  API_RESPONSE_STATUS,
  FILE,
  FILE_UPLOAD_STATUS
} from '@constants/common';
import { RootState } from '@store/reducers';
import { shortenString } from '@utils/generalUtils';
import { gAEventTracker } from '@utils/gaUtils';
import { eventKeyMapper } from '@constants/gaEvents';
import { GENERIC_FILE } from '@constants/connectExchangeConstants';
import { TAB_BAR_CONSTANTS } from '@constants/tabBar';
import {
  FileUploadRequests,
  ImportFilePopupProps,
  MultipleFileUpload,
  UploadedFileData
} from '../types';
import COLORS from '@constants/colors';
import routesPath from '@routes/RoutesPath';

const MAX_ALLOWED_FILE_COUNT = 5;

const getFileButtonStyles = status => {
  switch (status) {
    case FILE_UPLOAD_STATUS.uploading:
      return 'bg-floralWhite border border-pastelOrange text-blackGreen';
    case FILE_UPLOAD_STATUS.failed:
      return 'bg-lightPink border border-coralRed text-blackGreen';
    default:
      return 'bg-harp text-slateGrey';
  }
};

const checkBoxCustomStyle = {
  width: '16px',
  height: '16px',
  color: COLORS.PRIMARY_COLOR,
  backgroundColor: COLORS.WHITE,
  '&:hover': {
    backgroundColor: COLORS.WHITE
  },
  '& .MuiSvgIcon-root': {
    color: COLORS.PRIMARY_COLOR
  }
};

const ImportFilePopup: FC<ImportFilePopupProps> = props => {
  const { handleClose, exchangeData } = props;

  const {
    code: exchangeCode,
    name: exchangeName,
    file_meta_data: fileMetaData
  } = exchangeData;

  const { import: fileUploadConfig = [] } = fileMetaData;

  const [uploadedFiles, setUploadedFiles] = useState<MultipleFileUpload[]>([
    []
  ]);
  const [rejectedFiles, setRejectedFiles] = useState<File[]>([]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const fileUploadRequests = useRef<FileUploadRequests[]>([]);
  const preSignedURLDetails = useRef({});

  const { clientId } = useSelector((state: RootState) => state.rootReducer.app);

  const [generatePresignedURL] = useGeneratePresignedURLMutation();
  const [uploadFileToS3] = useUploadFileToS3Mutation();
  const [cancelFileUpload] = useCancelFileUploadMutation();
  const [submitFiles] = useSubmitFilesMutation();

  const navigate = useNavigate();

  const uploadFile = async (fileObj: UploadedFileData) => {
    const { id, file } = fileObj;
    const payload = {
      template: exchangeName.toLowerCase(),
      exchange_code: exchangeCode !== GENERIC_FILE.code ? exchangeCode : '',
      filename: file.name
    };
    try {
      const response = await generatePresignedURL({
        payload,
        clientId
      }).unwrap();
      if (response?.status === API_RESPONSE_STATUS.OK) {
        /*
          storing generatePresignedURL() API response for accessing the
          upload_request_id inorder to cancel the file upload
        */
        let currentPreSignedURLDetails = preSignedURLDetails.current;
        currentPreSignedURLDetails = {
          ...currentPreSignedURLDetails,
          [id]: response.result.upload_request_id
        };
        preSignedURLDetails.current = { ...currentPreSignedURLDetails };

        /*
          Uploading files to S3 using the upload_url received from the response of
          generatePresignedURL() API response
        */
        const uploadFileToS3Request = uploadFileToS3({
          url: response.result.upload_url,
          payload: file,
          contentType: file.type
        });
        /*
          Storing the fileUpload request for aborting the file upload , if user wants
        */
        const currentRequests = fileUploadRequests.current;
        currentRequests.push({ id: id, request: uploadFileToS3Request });
        fileUploadRequests.current = currentRequests;
        await uploadFileToS3Request.unwrap();
        /*
          Changing the status of the current file upload to 'success'
        */
        setUploadedFiles(currentState => {
          const formattedData = [...currentState];
          for (const section of formattedData) {
            if (section) {
              for (const obj of section) {
                if (obj?.id === id) {
                  obj.status = FILE_UPLOAD_STATUS.success;
                  break;
                }
              }
            }
          }
          return [...formattedData];
        });
      }
    } catch (error) {
      /*
        Changing the status of the current file upload to 'failed'
      */
      setUploadedFiles(currentState => {
        const formattedData = [...currentState];
        for (const section of formattedData) {
          for (const obj of section) {
            if (obj?.id === id) {
              obj.status = FILE_UPLOAD_STATUS.failed;
              break;
            }
          }
        }
        return [...formattedData];
      });
      throw error;
    }
  };

  const handleFileSelect = (
    selectedFiles: File[],
    rejectedFilesDetails: File[],
    fileIndex: number
  ) => {
    /*
      Adding a random id to the file data and assign status as 'uploading'
    */
    const fileData = selectedFiles.map(file => ({
      id: Math.random().toString(),
      file: file,
      status: FILE_UPLOAD_STATUS.uploading
    }));
    setRejectedFiles([...rejectedFilesDetails]);

    setUploadedFiles(currentState => {
      let tempState = [...currentState];
      let value = tempState[fileIndex] ? [...tempState[fileIndex]] : [];
      value = [...value, ...fileData];
      tempState[fileIndex] = [...value];
      return tempState;
    });

    // calling uploadFile()  to upload each accepted files to S3
    fileData.forEach(fileObj => uploadFile(fileObj));
  };

  const isButtonDisabled = () => {
    const validLength = [...uploadedFiles].reduce((length, section) => {
      return section ? length + section.length : length;
    }, 0);

    if (validLength < 1) {
      return true;
    }
    /*
      returns false if all the fileUpload statuses are 'success' else returns true
    */
    return ![...uploadedFiles].reduce((isEnabled, section) => {
      return section
        ? [...section].reduce((enabled, fileData) => {
            return fileData
              ? fileData?.status === FILE_UPLOAD_STATUS.success && enabled
              : enabled;
          }, true) && isEnabled
        : isEnabled;
    }, true);
  };

  const handleUploadAbort = (id: string) => {
    const currentRequestData = fileUploadRequests.current.find(
      requestData => requestData.id === id
    );
    /*
      calls cancelFileUpload() API for non-failed presignedAPI requests inorder to delete file from S3
    */
    if (preSignedURLDetails.current[id]) {
      cancelFileUpload({
        payload: { upload_request_id: preSignedURLDetails.current[id] },
        clientId
      });
    }
    currentRequestData?.request.abort();
    /*
      deleting the file from the uploadedFiles array
    */
    const updatedUploadedFiles = [...uploadedFiles];

    updatedUploadedFiles.map((section, sectionIndex) => {
      section?.map((file, fileIndex) => {
        if (file?.id === id) {
          updatedUploadedFiles[sectionIndex].splice(fileIndex, 1);
        }
      });
    });
    setUploadedFiles(updatedUploadedFiles);
  };

  const handleFailedInfoClose = () => {
    setRejectedFiles([]);
  };

  const handleImportClick = async () => {
    const successfullyUploadedFilesIds = [];
    /*
      we need to send the upload_request_ids of successful file uploads only
    */
    uploadedFiles?.forEach(fileSection => {
      fileSection?.forEach(fileData => {
        if (fileData?.status === FILE_UPLOAD_STATUS.success) {
          successfullyUploadedFilesIds?.push(
            preSignedURLDetails.current[fileData.id]
          );
        }
      });
    });
    const response = await submitFiles({
      payload: {
        upload_request_ids: successfullyUploadedFilesIds
      },
      clientId
    }).unwrap();
    if (response.status === API_RESPONSE_STATUS.OK) {
      gAEventTracker(
        eventKeyMapper.fileUploadCount,
        `${exchangeData.name}: ${successfullyUploadedFilesIds.length}`
      );
      handleClose();
      setTimeout(() => {
        navigate(
          `${routesPath.SOURCE}?tab=${TAB_BAR_CONSTANTS.UPLOADED_FILES}`
        );
      }, 700);
    }
  };

  const getFileChipData = index => {
    return uploadedFiles[index]?.map(file => (
      <Tooltip
        title={file?.file?.name}
        arrow={true}
        enterTouchDelay={0}
        key={file?.id}>
        <div
          key={file?.id}
          className={`flex flex-row items-center max-w-[200px] overflow-hidden rounded-[16px]
         text-sm px-2 py-[3px]
         ${getFileButtonStyles(file?.status)}`}>
          {file?.status === FILE_UPLOAD_STATUS.uploading && (
            <div
              className="flex shrink-0 justify-center items-center 
          mr-1 w-4 h-4 bg-pastelOrange rounded-full">
              <RefreshIcon className="w-2 h-2 animate-rotate" />
            </div>
          )}
          {file?.status === FILE_UPLOAD_STATUS.failed && (
            <div
              className="flex shrink-0 justify-center items-center 
          mr-1 w-4 h-4 bg-coralRed rounded-full">
              <FailedIcon className="w-[10px] h-[10px]" />
            </div>
          )}
          <span className="flex overflow-hidden flex-row">
            {shortenString(file?.file?.name, 15, 8, FILE)}
          </span>
          <CloseIcon
            className="ml-2 w-[10px] h-[9px] cursor-pointer"
            fill={COLORS.DAVY_GREY}
            stroke={COLORS.DAVY_GREY}
            onClick={() => handleUploadAbort(file?.id)}
          />
        </div>
      </Tooltip>
    ));
  };

  return (
    <div
      className={`flex ${
        exchangeCode === GENERIC_FILE.code
          ? 'sm:max-h-[600px] sm:relative'
          : 'sm:max-h-[650px]'
      }
       h-full flex-col sm:flex-row sm:overflow-auto customNormalScroll max-h-[100%]
        w-full items-start pl-[25px] pr-[25px] sm:pr-0`}
      onClick={e => e.stopPropagation()}>
      <div className="pr-[10px] pb-[30px] w-full sm:mr-7 sm:w-[40%]">
        <div className="mt-[18px] text-2xl font-semibold">
          {exchangeCode === GENERIC_FILE.code
            ? translate('sourcePage.uploadGenericCsv')
            : translate('sourcePage.fileImportTitle')}
        </div>
        <div className="w-full">
          <div className="mt-[7px]  text-sm font-normal text-davyGrey">
            {translate('sourcePage.fileImportSubTitile')}
          </div>

          <div className="mt-[15px]">
            {fileUploadConfig?.map((config, index) => (
              <div key={index} className="mb-[24px]">
                {/* fileUploadConfig.length > 1 means we need to show multiple file uploaders */}

                <div className="flex justify-between mb-[7px] text-sm font-medium text-davyGrey">
                  {fileUploadConfig.length > 1 && (
                    <span>{config.file_name}</span>
                  )}
                  {uploadedFiles[index]?.length > 0 && (
                    <span>
                      {uploadedFiles[index]?.length}&nbsp;
                      {translate('sourcePage.filesUploaded')}
                    </span>
                  )}
                </div>

                {fileUploadConfig.length >= 1 && (
                  <FileDropZone
                    maxFileCount={MAX_ALLOWED_FILE_COUNT}
                    handleFileSelect={(acceptedFiles, rejectedFilesData) =>
                      handleFileSelect(acceptedFiles, rejectedFilesData, index)
                    }
                    currentUploadedFileCount={
                      uploadedFiles[index] ? uploadedFiles[index].length : 0
                    }
                    fileFormats={config.extension}
                    uploaderSize={
                      fileUploadConfig.length > 1 ? 'small' : 'large'
                    }
                    isDisabled={
                      uploadedFiles[index]?.length >= MAX_ALLOWED_FILE_COUNT
                    }
                  />
                )}
                {uploadedFiles && (
                  <div className="flex flex-wrap gap-2 my-2">
                    {getFileChipData(index)}
                  </div>
                )}
              </div>
            ))}
          </div>

          {rejectedFiles.length > 0 && (
            <div className="mt-[7px]">
              <RejectedFileSection
                failedFileDetails={rejectedFiles}
                handleClose={handleFailedInfoClose}
              />
            </div>
          )}
          <div className="flex items-center py-1 px-2 mt-2 bg-pastelOrange/20 rounded-md">
            <Checkbox
              sx={checkBoxCustomStyle}
              onChange={e => setIsCheckboxChecked(e.target.checked)}
            />
            <span className="ml-[6px] text-xs leading-5">
              {translate('sourcePage.allTransactionsAdded')}
            </span>
          </div>
          <Button
            label={translate('sourcePage.import')}
            className="py-[9px] px-[17px] mt-2 w-full font-bold text-white
               bg-primaryColor rounded-md disabled:opacity-50"
            Icon={ImportIcon}
            showMainViewButton={true}
            disabled={!isCheckboxChecked || isButtonDisabled()}
            onClick={handleImportClick}
          />
        </div>
      </div>
      <div
        className="pt-[25px] w-full h-full border-b-[1px] border-greyCloud border-dashed sm:absolute
         sm:left-[43%] sm:pt-0 sm:w-[1px] sm:border-l-[1px]"
      />
      <div className="pr-[10px] pb-[30px] mt-2 w-full sm:ml-7 sm:w-[50%]">
        <InstructionSection
          exchangeCode={exchangeCode}
          type="importFile"
          heading={
            exchangeCode === GENERIC_FILE.code
              ? translate('sourcePage.importFromGenericFile')
              : translate('sourcePage.importFromExchangeFile', {
                  exchange: exchangeName
                })
          }
        />
        {!isEmpty(EXCHANGE_INFO[exchangeCode].fileNotes) && (
          <NotesSection
            exchangeCode={exchangeCode}
            showBorder={false}
            noteType="fileNotes"
          />
        )}
      </div>
    </div>
  );
};

export default ImportFilePopup;
