import React, { FC } from 'react';
import { Skeleton } from '@mui/material';

import { GenericSkeletonProps } from './types';
import COLORS from '@constants/colors';

const RectangularSkeleton: FC<GenericSkeletonProps> = props => {
  const { width, height, color = COLORS.HARP, customStyles } = props;

  return (
    <Skeleton
      variant="rectangular"
      width={width}
      height={height}
      sx={{
        bgcolor: color,
        ...customStyles
      }}
    />
  );
};

export default RectangularSkeleton;
