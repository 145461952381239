import React, { FC } from 'react';

import { GreenTickCircle } from '@assets/icons';
import { Button } from '@components';
import { translate } from '@utils/locale';
import { ResetSuccessfullProps } from '../types';

const PasswordResetSuccessful: FC<ResetSuccessfullProps> = props => {
  const { handleContinueClick } = props;

  return (
    <>
      <GreenTickCircle />
      <div className="flex overflow-hidden flex-col justify-center items-center my-7 w-full">
        <div className="text-2xl font-semibold text-center text-blackGreen">
          {translate('resetSuccessfulPage.resetSuccessful')}
        </div>
        <div className="mt-[13px] text-sm text-center text-davyGrey">
          {translate('resetSuccessfulPage.resetDescription')}
        </div>
      </div>
      <Button
        label={translate('resetSuccessfulPage.continue')}
        className="flex justify-center w-full h-10 bg-primaryColor"
        labelClass="text-white"
        onClick={handleContinueClick}
        type="submit"
      />
    </>
  );
};

export default PasswordResetSuccessful;
