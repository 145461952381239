import React, { FC } from 'react';

import { CircularSkeleton, TextSkeleton } from '@components';
import { ConnectExchangeSkeletonProps } from './types';

const ConnectExchangeSkeleton: FC<ConnectExchangeSkeletonProps> = props => {
  const { isWallet = true, cardCount = 6 } = props;

  return (
    <>
      <div
        className="flex p-2 m-[14px] w-[90%] bg-white
             rounded-md sm:m-6 md:w-[60%] lg:w-[40%] xl:w-[35%]">
        <CircularSkeleton width={20} height={20} />
        <div className="ml-3 w-full">
          <TextSkeleton fontSize="14px" customStyles={{ width: '50%' }} />
        </div>
      </div>
      <div
        className={`flex justify-center pb-8 border-b border-greyCloud/50
             ${isWallet ? 'block' : 'hidden'} sm:justify-start `}>
        <div
          className="flex items-center p-4 m-[14px] mt-10 mr-4  w-[200px] h-[60px]
      bg-white rounded-md sm:mx-6 sm:mb-4">
          <CircularSkeleton width={36} height={30} />
          <div className="ml-3 w-full">
            <TextSkeleton fontSize="16px" customStyles={{ width: '80%' }} />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap justify-center m-[14px] sm:justify-start sm:m-6 lg:flex-row">
        {[...Array(cardCount)].map((item, index) => (
          <div
            key={index}
            className="flex items-center p-4 my-2 w-[200px] 
                  h-[60px] bg-white rounded-md sm:mr-4">
            <CircularSkeleton width={36} height={30} />
            <div className="ml-3 w-full">
              <TextSkeleton fontSize="16px" customStyles={{ width: '80%' }} />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ConnectExchangeSkeleton;
