import { object, string } from 'yup';

import { translate } from '@utils/locale';

export const connectWalletSchema = object({
  blockchain: string()
    .required(translate('connectExchangePage.connectWallet.requiredField'))
    .trim(),
  walletAddress: string()
    .required(translate('connectExchangePage.connectWallet.requiredField'))
    .trim(),
  walletName: string()
    .max(25, translate('connectExchangePage.connectWallet.characterLimit'))
    .required(translate('connectExchangePage.connectWallet.requiredField'))
});
