import { authBaseApi } from '@services/api';
import { translate } from '@utils/locale';
import { GenericApiResponse } from 'types/generalTypes';
import {
  GetUserDetailsResponseType,
  SignUpObjectType,
  UserDetails,
  GenericResponseType,
  GenerateOTPPayload,
  ValidateOTPPayload
} from './types';

const AuthBaseAPI = authBaseApi.injectEndpoints({
  endpoints: builder => ({
    getUserData: builder.query<UserDetails, void>({
      query: () => '/profile',
      transformResponse: (response: GetUserDetailsResponseType) =>
        response.result,
      providesTags: ['GetUserDetails']
    }),
    userSignIn: builder.mutation<GenericResponseType, SignUpObjectType>({
      query: ({ email, password, user_type, source, user_token }) => ({
        url: '/login/classic',
        method: 'POST',
        body: {
          email,
          password,
          user_type,
          ...(source && { source }),
          ...(user_token && { user_token })
        }
      }),
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.signInAPIError')
      }
    }),
    logoutUser: builder.mutation<GenericResponseType, void>({
      query: () => ({
        url: '/logout',
        method: 'POST'
      })
    }),
    acceptTermsAndConditions: builder.mutation<GenericResponseType, void>({
      query: () => ({
        url: '/accept-terms',
        method: 'POST'
      }),
      extraOptions: {
        showNotifier: true,
        showCustomMessage: false,
        failure: translate('notifierMessage.termsAndConditionsError')
      },
      invalidatesTags: ['GetUserDetails']
    }),
    generateOTP: builder.mutation<
      GenericApiResponse<string>,
      GenerateOTPPayload
    >({
      query: ({ payload }) => ({
        url: '/users/phone',
        method: 'POST',
        body: payload
      }),
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.generateOtpError')
      }
    }),
    validateOTP: builder.mutation<
      GenericApiResponse<string>,
      ValidateOTPPayload
    >({
      query: ({ payload }) => ({
        url: '/users/phone/verify',
        method: 'POST',
        body: payload
      })
    })
  })
});

export const {
  useLazyGetUserDataQuery,
  useUserSignInMutation,
  useLogoutUserMutation,
  useAcceptTermsAndConditionsMutation,
  useGenerateOTPMutation,
  useValidateOTPMutation
} = AuthBaseAPI;
