import { appendClientIdInEndpoints, translate } from '@utils';
import { GenericApiResponse } from 'types/generalTypes';
import {
  TaxReportPayloadType,
  ReportSummaryType,
  DownloadReportPayloadType,
  SubscriptionType,
  SubscriptionResponseType,
  TransactionCountPayloadType,
  DownloadReportResponseType,
  TransactionCount,
  TransactionCountResponseType,
  SubscriptionPayloadType,
  UserResourceType,
  UserResourcePayloadType,
  UserResourceResponseType,
  ReportRangeType
} from './types';
import baseApi from '@services/api';

const TaxReportBaseApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getTaxReport: builder.query<ReportSummaryType, TaxReportPayloadType>({
      query: ({ fromTimestamp, toTimestamp, clientId }) =>
        appendClientIdInEndpoints(
          `user/transaction/summary?from_timestamp=${fromTimestamp}&to_timestamp=${toTimestamp}`,
          clientId
        ),
      transformResponse: (response: GenericApiResponse<ReportSummaryType>) =>
        response.result
    }),
    getTaxReportRange: builder.query<ReportRangeType, TaxReportPayloadType>({
      query: ({ fromTimestamp, toTimestamp, clientId }) =>
        appendClientIdInEndpoints(
          `user/transaction/summary/range?from_timestamp=${fromTimestamp}&to_timestamp=${toTimestamp}`,
          clientId
        ),
      transformResponse: (response: GenericApiResponse<ReportRangeType>) =>
        response.result
    }),
    getUserResourceData: builder.query<
      UserResourceType,
      UserResourcePayloadType
    >({
      query: ({ fromTimestamp, toTimestamp, clientId }) =>
        appendClientIdInEndpoints(
          `user/transaction/user-resource?from_timestamp=${fromTimestamp}&to_timestamp=${toTimestamp}`,
          clientId
        ),
      transformResponse: (response: UserResourceResponseType) => response.result
    }),
    downloadTaxReport: builder.query<
      DownloadReportResponseType,
      DownloadReportPayloadType
    >({
      query: ({ payload, clientId }) => ({
        url: appendClientIdInEndpoints('user/taxation/report', clientId),
        method: 'POST',
        body: payload
      }),
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.downloadReportError')
      }
    }),
    getCurrentFYSubscription: builder.query<
      SubscriptionType[],
      SubscriptionPayloadType
    >({
      query: ({ from_timestamp, to_timestamp, clientId }) =>
        appendClientIdInEndpoints(
          `user/subscription?from_timestamp=${from_timestamp}&to_timestamp=${to_timestamp}`,
          clientId
        ),
      transformResponse: (response: SubscriptionResponseType) =>
        response.result,
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.errorFetchingSubscriptionDetails')
      }
    }),
    getUserSubscription: builder.query<
      SubscriptionType[],
      SubscriptionPayloadType
    >({
      query: ({ from_timestamp, to_timestamp, clientId }) =>
        appendClientIdInEndpoints(
          `user/subscription?from_timestamp=${from_timestamp}&to_timestamp=${to_timestamp}`,
          clientId
        ),
      transformResponse: (response: SubscriptionResponseType) =>
        response.result,
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.errorFetchingSubscriptionDetails')
      }
    }),
    getTransactionCount: builder.query<
      TransactionCount,
      TransactionCountPayloadType
    >({
      query: ({ from_timestamp, to_timestamp, clientId }) =>
        appendClientIdInEndpoints(
          `user/transaction/count?from_timestamp=${from_timestamp}&to_timestamp=${to_timestamp}`,
          clientId
        ),
      transformResponse: (response: TransactionCountResponseType) =>
        response.result,
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.errorFetchingTransactionCount')
      },
      providesTags: ['GetTransactionCount']
    })
  })
});

export const {
  useLazyGetTaxReportQuery,
  useLazyGetTaxReportRangeQuery,
  useLazyGetUserResourceDataQuery,
  useLazyDownloadTaxReportQuery,
  useGetCurrentFYSubscriptionQuery,
  useGetUserSubscriptionQuery,
  useGetTransactionCountQuery
} = TaxReportBaseApi;
