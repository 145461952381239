import React, { FC, useEffect, useState, useRef } from 'react';
import { Checkbox, IconButton } from '@mui/material';
import { isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ReCAPTCHA from 'react-google-recaptcha';

import { setUserLogout } from '@utils';
import { Button, TextField, SideModal as Modal, OTPScreen } from '@components';
import { showNotifier } from '@reducers/appReducer';
import { useAppDispatch } from '@store/store';
import { translate } from '@utils/locale';
import {
  CloseIcon,
  EyeCrossedIcon,
  EyeIcon,
  RevenuePlanner,
  VerificationSuccess
} from '@assets/icons';
import {
  API_RESPONSE_STATUS,
  INDIAN_TELEPHONE_CODE,
  KEYBOARD_KEYS,
  NotifierTypes,
  REGEX,
  USER_ROLE,
  WELCOME_FLOW
} from '@constants/common';
import {
  useGenerateOTPMutation,
  useLogoutUserMutation,
  useValidateOTPMutation
} from '@containers/login-flow/sign-in/api';
import { useSetupPasswordMutation } from '@containers/settings/api';
import {
  AUTH_SVC_ERROR_CODE_MAPPER,
  INVALID_OTP_CODE,
  OTP_EXPIRED
} from '@constants/errorCodeMapper';
import { handleNotifier } from '@services/api';
import { ErrorObject } from 'types/generalTypes';
import { resetPasswordFormSchema } from './validate';
import {
  WelcomePopUpProps,
  InputFieldErrors,
  ChangePasswordFieldValues
} from './types';
import COLORS from '@constants/colors';

const checkBoxCustomStyle = {
  marginRight: '9px',
  width: '18px',
  height: '18px',
  color: COLORS.CLOUD,
  backgroundColor: COLORS.WHITE,
  '&:hover': {
    backgroundColor: COLORS.WHITE
  },
  '&.Mui-checked': {
    color: COLORS.PRIMARY_COLOR,
    backgroundColor: COLORS.WHITE
  }
};

const startAdornment = (
  <span className="text-sm font-medium leading-5 text-slateGrey">
    {translate('welcomePopup.91')}
  </span>
);

const extraOptions = {
  showNotifier: true,
  failure: translate('notifierMessage.validateOtpError')
};

const WelcomePopUp: FC<WelcomePopUpProps> = props => {
  const {
    disabled = false,
    onContinuePress = () => {},
    openPrivacyPolicy = () => {},
    openTandC = () => {},
    showModal,
    firstName,
    lastName,
    isNewUser,
    isPhoneVerified,
    handleClose,
    isEdit = false,
    currentMobileNumber = '',
    showCloseButton = false,
    userType,
    hasPassword = true
  } = props;

  const reCaptchaRef = useRef(null);
  const dispatch = useAppDispatch();

  const [isTnCCheckboxChecked, setIsTnCCheckboxChecked] = useState(false);
  const [isWhatsAppOTPChecked, setIsWhatsAppOTPChecked] = useState(false);
  const [currentScreen, setCurrentScreen] = useState(
    isPhoneVerified && !isEdit
      ? WELCOME_FLOW.successScreen
      : WELCOME_FLOW.phoneScreen
  );
  const [mobileNumber, setMobileNumber] = useState(currentMobileNumber);
  const [isInputFieldError, setIsInputFieldError] = useState<InputFieldErrors>(
    {}
  );
  const [isTouchedMobileInput, setIsTouchedMobileInput] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<ChangePasswordFieldValues>({
    defaultValues: {
      password: '',
      confirmPassword: ''
    },
    resolver: yupResolver(resetPasswordFormSchema)
  });

  const [generateOtp, { isLoading: isLoadingGenerateOtp }] =
    useGenerateOTPMutation();
  const [validateOtp, { isLoading: isLoadingValidateOtp }] =
    useValidateOTPMutation();
  const [userLogout, logoutResponse] = useLogoutUserMutation();
  const [setupPassword] = useSetupPasswordMutation();

  useEffect(() => {
    let screen = WELCOME_FLOW.successScreen;
    if (!isPhoneVerified || isEdit) {
      screen = WELCOME_FLOW.phoneScreen;
    } else if (!hasPassword && !isNewUser) {
      screen = WELCOME_FLOW.resetPasswordScreen;
    }
    setCurrentScreen(screen);
  }, [isPhoneVerified, hasPassword, isEdit, isNewUser]);

  useEffect(() => {
    logoutResponse.isSuccess && setUserLogout();
    if (logoutResponse.isError) {
      dispatch(
        showNotifier({
          message: translate('notifierMessage.logoutError'),
          type: NotifierTypes.ERROR
        })
      );
    }
  }, [logoutResponse]);

  const changeShowPasswordState = () => {
    setShowPassword(currState => !currState);
  };

  const changeShowConfirmPasswordState = () => {
    setShowConfirmPassword(currState => !currState);
  };

  const handleSendOtp = async (resetTimer?: () => void) => {
    try {
      let captchaToken = await reCaptchaRef.current.executeAsync();
      if (!isTouchedMobileInput) {
        setIsTouchedMobileInput(true);
      }
      if (!isEmpty(isInputFieldError) && isInputFieldError.mobileNumber) {
        // To handle case when mobile number input validation failed
        reCaptchaRef.current?.reset();
        return;
      }
      const payload = {
        phone_country_code: INDIAN_TELEPHONE_CODE,
        phone: mobileNumber,
        send_in_whatsapp: isWhatsAppOTPChecked,
        recaptcha: captchaToken
      };

      const response = await generateOtp({ payload }).unwrap();
      if (response && response.status === API_RESPONSE_STATUS.OK) {
        if (resetTimer) {
          resetTimer();
          return;
        }
        setCurrentScreen(WELCOME_FLOW.otpScreen);
      }
      reCaptchaRef.current?.reset();
    } catch (err) {
      // To handle case when some error occurs in generateOtp api call
      reCaptchaRef.current?.reset();
    }
  };

  const handleValidateOtp = async (
    otpValues: string[],
    invalidateOtp: () => void
  ) => {
    try {
      const payload = {
        otp: otpValues.join(''),
        phone_country_code: INDIAN_TELEPHONE_CODE,
        phone: mobileNumber
      };
      const response = await validateOtp({ payload }).unwrap();
      if (response && response.status === API_RESPONSE_STATUS.OK) {
        if (!(hasPassword || isNewUser)) {
          setCurrentScreen(WELCOME_FLOW.resetPasswordScreen);
        } else {
          setCurrentScreen(WELCOME_FLOW.successScreen);
        }
      }
    } catch (error) {
      const errorData: ErrorObject = error?.data?.error;
      if ([INVALID_OTP_CODE, OTP_EXPIRED].includes(errorData?.message)) {
        invalidateOtp();
      } else {
        handleNotifier({ error }, extraOptions, AUTH_SVC_ERROR_CODE_MAPPER);
      }
    }
  };

  const navigateToPhoneScreen = () => {
    isTouchedMobileInput && setIsTouchedMobileInput(false);
    setCurrentScreen(WELCOME_FLOW.phoneScreen);
  };

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (REGEX.digits.test(value) || value === '') {
      setMobileNumber(value);
      if (REGEX.mobile.test(value)) {
        setIsInputFieldError({});
      } else {
        setIsInputFieldError({
          mobileNumber: {
            message: translate('welcomePopup.invalidPhoneNumber')
          }
        });
      }
    }
  };

  const handleLogout = () => {
    userLogout();
  };

  const handleEnterKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === KEYBOARD_KEYS.enter) {
      handleSendOtp();
      e.preventDefault();
    }
  };

  const handleResetPassword = async (values: ChangePasswordFieldValues) => {
    const payload = {
      password: values.password
    };
    const response = await setupPassword(payload).unwrap();
    if (response && response.status === API_RESPONSE_STATUS.OK) {
      setCurrentScreen(WELCOME_FLOW.successScreen);
    }
  };

  const handleContinueClick = () => {
    const checkForResetPasswordEligibility = () => {
      if (!hasPassword) {
        setCurrentScreen(WELCOME_FLOW.resetPasswordScreen);
      }
    };
    onContinuePress(checkForResetPasswordEligibility);
  };

  const renderContent = () => {
    switch (currentScreen) {
      case WELCOME_FLOW.phoneScreen:
        return (
          <>
            <div
              className="pt-14 pb-6 text-[22px] font-medium leading-8 text-center 
            text-black sm:pt-0 sm:pb-4 sm:text-left">
              {translate(
                isNewUser
                  ? 'welcomePopup.almostThere'
                  : 'welcomePopup.pleaseProvidePhoneNumber'
              )}
            </div>
            <div className="flex flex-col items-center sm:flex-row">
              <RevenuePlanner />
              <div className="flex flex-col items-center pt-[21px] pl-0 sm:items-start sm:pt-0 sm:pl-[47px]">
                <div
                  className="pb-[18px] text-sm font-normal leading-4 text-center text-davyGrey sm:pb-4 sm:text-[15px] 
                 sm:leading-6 sm:text-left sm:text-black lg:leading-8">
                  {translate(
                    isNewUser
                      ? 'welcomePopup.phoneSubText'
                      : 'welcomePopup.oldUserPhoneSubText'
                  )}
                </div>
                <TextField
                  name="mobileNumber"
                  value={mobileNumber}
                  type="text"
                  onChange={handleValueChange}
                  label={translate('welcomePopup.enterMobile')}
                  showStartAdornment={true}
                  startAdornment={startAdornment}
                  errors={isInputFieldError}
                  showErrorMessage={isTouchedMobileInput}
                  autoComplete="off"
                  onKeyDown={handleEnterKeyDown}
                />
                <div
                  className="py-2 text-sm font-normal leading-[18px] text-center text-slateGrey 
                  sm:pb-2 sm:text-[15px] sm:text-left lg:leading-8">
                  {translate('welcomePopup.otpText')}
                </div>
                <div
                  className="flex flex-row items-center  pb-3 text-sm font-normal leading-[18px] text-center 
                  text-black sm:pb-2 sm:text-[15px] sm:text-left lg:leading-8">
                  <Checkbox
                    sx={{ ...checkBoxCustomStyle, color: COLORS.LIGHT_BLACK }}
                    checked={isWhatsAppOTPChecked}
                    onChange={e => setIsWhatsAppOTPChecked(e.target.checked)}
                  />
                  <span>{translate('welcomePopup.whatsAppAgreeText')}</span>
                </div>
                <div className="flex flex-row">
                  {!isEdit && (
                    <Button
                      label={translate('logout')}
                      className="py-[10px] px-5 mr-4 w-[130px] h-10 border border-primaryColor"
                      labelClass="text-blackGreen font-medium text-sm"
                      onClick={handleLogout}
                    />
                  )}
                  <Button
                    label={translate('welcomePopup.getOtp')}
                    className="py-[10px] px-5 w-[130px] h-10 bg-primaryColor
                disabled:bg-primaryColor hover:opacity-100 disabled:opacity-50"
                    labelClass="text-white font-medium text-sm"
                    disabled={
                      !mobileNumber ||
                      isLoadingGenerateOtp ||
                      mobileNumber === currentMobileNumber
                    }
                    onClick={() => handleSendOtp()}
                  />
                </div>
              </div>
            </div>
          </>
        );
      case WELCOME_FLOW.otpScreen:
        return (
          <OTPScreen
            handleValidateOtp={handleValidateOtp}
            isLoadingValidateOtp={isLoadingValidateOtp}
            mobileNumber={mobileNumber}
            navigateToPhoneScreen={navigateToPhoneScreen}
            handleSendOtp={handleSendOtp}
          />
        );
      case WELCOME_FLOW.resetPasswordScreen:
        return (
          <>
            <div
              className="pt-14 pb-6 text-[22px] font-medium leading-8 text-center 
            text-black sm:pt-0 sm:pb-4 sm:text-left">
              {translate('welcomePopup.setupYourPassword')}
            </div>
            <div className="flex flex-col items-center sm:flex-row">
              <RevenuePlanner />
              <div className="flex flex-col items-center pt-[21px] pl-0 sm:items-start sm:pt-0 sm:pl-[47px]">
                <div
                  className="pb-[18px] text-sm font-normal leading-4 text-center text-davyGrey sm:pb-4 sm:text-[15px] 
                 sm:leading-6 sm:text-left sm:text-black lg:leading-8">
                  {translate('welcomePopup.changePasswordSubText')}
                </div>
                <form
                  className="w-full max-w-[360px]"
                  onSubmit={handleSubmit(handleResetPassword)}>
                  <div className="mb-4 w-full">
                    <TextField
                      placeholder={translate('password')}
                      name="password"
                      autoComplete="new-password"
                      isControlledField={true}
                      control={control}
                      errors={errors}
                      type={showPassword ? 'text' : 'password'}
                      InputLabelProps={{ style: { fontSize: 14 } }}
                      InputProps={{
                        style: { height: 54 }
                      }}
                      showInfoIcon={true} //for showing eye icon
                      endAdornment={
                        <IconButton onClick={changeShowPasswordState}>
                          {showPassword ? <EyeCrossedIcon /> : <EyeIcon />}
                        </IconButton>
                      }
                      testid="newpassword"
                    />
                  </div>
                  <div className="mb-4 w-full">
                    <TextField
                      placeholder={translate(
                        'resetPasswordPage.confirmPassword'
                      )}
                      name="confirmPassword"
                      autoComplete="new-password"
                      isControlledField={true}
                      control={control}
                      errors={errors}
                      type={showConfirmPassword ? 'text' : 'password'}
                      InputLabelProps={{ style: { fontSize: 14 } }}
                      InputProps={{
                        style: { height: 54 }
                      }}
                      showInfoIcon={true} //for showing eye icon
                      endAdornment={
                        <IconButton onClick={changeShowConfirmPasswordState}>
                          {showConfirmPassword ? (
                            <EyeCrossedIcon />
                          ) : (
                            <EyeIcon />
                          )}
                        </IconButton>
                      }
                      testid="confirmpassword"
                    />
                  </div>
                  <div className="flex flex-row">
                    {!isEdit && (
                      <Button
                        label={translate('logout')}
                        className="py-[10px] px-5 mr-4 w-[130px] h-10 border border-primaryColor"
                        labelClass="text-blackGreen font-medium text-sm"
                        onClick={handleLogout}
                      />
                    )}
                    <Button
                      label={translate('proceed')}
                      className="py-[10px] px-5 w-[130px] h-10 bg-primaryColor
                      disabled:bg-primaryColor hover:opacity-100 disabled:opacity-50"
                      labelClass="text-white font-medium text-sm"
                      type="submit"
                    />
                  </div>
                </form>
              </div>
            </div>
          </>
        );
      case WELCOME_FLOW.successScreen:
        return (
          <div
            className={`flex flex-col w-full 
           ${
             !isNewUser && currentScreen === WELCOME_FLOW.successScreen
               ? 'h-auto'
               : 'h-full'
           }`}>
            <div
              className={`pt-14 pb-[50px] text-[22px] font-medium leading-8 text-center text-black
              sm:pt-0 sm:pb-4  ${
                isNewUser
                  ? 'sm:text-left sm:pb-4'
                  : 'sm:text-center sm:pb-[23px]'
              }`}>
              {translate(
                isNewUser || isEdit
                  ? 'welcomePopup.verificationSuccess'
                  : 'welcomePopup.accountSetupCompleted'
              )}
            </div>
            <div
              className={`flex flex-col items-center ${
                isNewUser ? 'sm:flex-row h-full' : ''
              }`}>
              <VerificationSuccess className="min-w-[218px] min-h-[182px] lg:min-w-[235px] lg:min-h-[218px]" />
              {isNewUser ? (
                <div
                  className="flex flex-col justify-between items-center pt-[26px] pl-0 h-full text-center 
               sm:items-start sm:pt-[30px] sm:pl-[30px] sm:text-left">
                  <div>
                    <div className="pb-3 text-sm font-medium leading-8 text-davyGrey sm:text-[15px]">
                      {translate('welcomePopup.welcomeUser', {
                        userName: `${firstName} ${lastName}`
                      })}
                    </div>
                    <div className="text-sm font-normal leading-5 text-davyGrey sm:text-[15px] xl:whitespace-pre">
                      {userType === USER_ROLE.PROFESSIONAL
                        ? translate(
                            'welcomePopup.otpVerificationSuccessSubTextForProfessionals'
                          )
                        : translate(
                            'welcomePopup.otpVerificationSuccessSubText'
                          )}
                    </div>
                  </div>
                  <div className="flex flex-col items-center pb-8 w-full sm:items-start sm:pb-0">
                    <div
                      className="flex flex-row items-center mt-7 mb-[9px] text-xs font-medium leading-[15px]
                 text-grey sm:mt-11 sm:mb-3">
                      <Checkbox
                        sx={checkBoxCustomStyle}
                        onChange={e =>
                          setIsTnCCheckboxChecked(e.target.checked)
                        }
                      />
                      <span className="inline-block text-left">
                        {translate('welcomePopup.tc')}
                        <span
                          className="inline px-1 font-semibold text-blackCow cursor-pointer"
                          onClick={openTandC}>
                          {translate('welcomePopup.tandc')}
                        </span>

                        {translate('welcomePopup.and')}
                        <span
                          className="inline pl-1 font-semibold text-blackCow cursor-pointer"
                          onClick={openPrivacyPolicy}>
                          {translate('welcomePopup.pp')}
                        </span>
                      </span>
                    </div>
                    <Button
                      label={translate('resetSuccessfulPage.continue')}
                      className="py-[10px] px-5 w-full h-10 bg-primaryColor disabled:bg-primaryColor 
                  hover:opacity-100 disabled:opacity-50 sm:w-[130px]"
                      labelClass="text-white font-medium text-sm"
                      disabled={!isTnCCheckboxChecked || disabled}
                      onClick={handleContinueClick}
                    />
                  </div>
                </div>
              ) : (
                <Button
                  label={translate('close')}
                  className="py-[10px] px-5 mt-9 w-full h-10 bg-white border border-primaryColor
                   hover:opacity-100 cursor-pointer sm:w-[130px]"
                  labelClass="text-primaryColor font-medium text-sm"
                  onClick={() => handleClose(true)}
                />
              )}
            </div>
          </div>
        );
    }
  };

  return (
    <div>
      <Modal isOpen={showModal} clickOutSideClose={false}>
        <div
          className={`flex overflow-y-auto absolute inset-0 p-[14px] m-auto
            w-[100%] h-[100%] bg-white outline-none sm:p-6 sm:w-[90%]
            sm:h-[360px] sm:rounded-xl
             ${
               !isNewUser && currentScreen === WELCOME_FLOW.successScreen
                 ? 'sm:w-[498px] sm:h-[398px] md:w-[498px] md:h-[398px] lg:w-[498px] lg:h-[398px]'
                 : 'sm:w-[90%] sm:h-[360px]md:w-[85%] md:min-h-[324px] lg:w-[830px] lg:h-[334px]'
             }`}>
          <div
            className={`flex flex-col w-full h-full 
          ${
            !isNewUser && currentScreen === WELCOME_FLOW.successScreen
              ? 'justify-around'
              : ''
          }`}>
            {showCloseButton && currentScreen !== WELCOME_FLOW.successScreen && (
              <div
                className="flex absolute top-[14px] right-[14px] justify-center items-center w-[30px] h-[30px]
             bg-whiteSmoke rounded-full cursor-pointer sm:top-6 sm:right-6"
                onClick={() => handleClose(false)}>
                <CloseIcon stroke={COLORS.DAVY_GREY} />
              </div>
            )}
            {renderContent()}
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY_INVISIBLE}
              size="invisible"
              ref={reCaptchaRef}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default WelcomePopUp;
