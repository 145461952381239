import React, { FC, useEffect, useState } from 'react';
import { Tooltip, useMediaQuery } from '@mui/material';

import {
  INDIAN_RUPEE,
  SORTING_ORDER,
  SORTING_ORDER_DECIMAL,
  SORTING_ORDER_STRING
} from '@constants/common';
import {
  getFormattedNumbering,
  getNumberInIndianFormat,
  sortArrayOfObjects
} from '@utils/generalUtils';
import { translate } from '@utils/locale';
import {
  AscendingIcon,
  AscendingTransparentIcon,
  DescendingIcon,
  DescendingTransparentIcon,
  ExternalLinkIcon
} from '@assets/icons';
import { NftSectionProps, PortfolioNftData } from '../types';
import NftSummaryMobile from './NftSummaryMobile';

const NftSummary: FC<NftSectionProps> = ({
  nftData,
  customStyle,
  accordianTableHeadingStyle,
  headingStyle,
  sortData
}) => {
  const [sortingKey, setSortingKey] = useState('');
  const [sortingOrder, setSortingOrder] = useState('');
  const [sortedNftData, setSortedNftData] = useState(nftData);

  const match = useMediaQuery('(min-width: 1192px)');

  useEffect(() => {
    const sortedNft = sortArrayOfObjects(nftData, sortingKey, sortingOrder);
    setSortedNftData(sortedNft);
  }, [sortingKey, sortingOrder]);

  const applySorting = (key: string, order) => {
    if (sortData) {
      if (key === sortingKey)
        setSortingOrder(currentState => order[currentState]);
      else {
        setSortingOrder(order[SORTING_ORDER.DEFAULT]);
        setSortingKey(key);
      }
    }
  };

  const getOrderIcon = (key: string) => {
    const DescIcon =
      key === sortingKey && sortingOrder === SORTING_ORDER.DESC
        ? DescendingIcon
        : DescendingTransparentIcon;
    const AscIcon =
      key === sortingKey && sortingOrder === SORTING_ORDER.ASC
        ? AscendingIcon
        : AscendingTransparentIcon;
    return (
      <span className="flex flex-col">
        <DescIcon className="mb-[2px]" />
        <AscIcon />
      </span>
    );
  };

  const nftTableHeading = [
    {
      title: 'name',
      style: 'w-3/5',
      data: 'name',
      order: SORTING_ORDER_STRING
    },
    {
      title: 'purchaseValue',
      style: 'w-1/5',
      data: 'purchase_value',
      order: SORTING_ORDER_DECIMAL
    },
    { title: 'link', style: 'w-1/5' }
  ];

  const nftRow = (nft: PortfolioNftData) => {
    const { name, purchase_value, url } = nft;
    return (
      <div className="flex justify-between items-start py-[14px] w-full text-base">
        <div className="w-3/5 font-semibold text-davyGrey truncate">
          <Tooltip title={name} arrow={true} enterTouchDelay={0}>
            <span>{name}</span>
          </Tooltip>
        </div>
        <div className="flex items-center w-1/5 font-semibold text-right text-davyGrey">
          <Tooltip
            title={getNumberInIndianFormat(
              Number(purchase_value),
              INDIAN_RUPEE.code,
              true
            )}
            arrow={true}
            enterTouchDelay={0}
            placement="bottom-start">
            <div className="w-fit">
              {getFormattedNumbering({
                value: purchase_value,
                showZeroValue: true
              })}
            </div>
          </Tooltip>
        </div>
        <div className="flex flex-wrap items-center w-1/5 font-semibold text-right ">
          <ExternalLinkIcon
            className="cursor-pointer"
            onClick={() => window.open(url, '_blank')}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="mt-6 mb-3 text-base font-medium text-slateGrey">
      <div className={`pt-6 pb-1 w-full bg-white ${match ? headingStyle : ''}`}>
        {translate('dashboard.nft')}
      </div>
      {match ? (
        <div
          className={`px-4 pt-3 pb-2 mt-3 rounded-lg border border-harp sm:px-6 ${customStyle}`}>
          <div
            className={`flex justify-between pb-3 mb-4 text-sm font-normal text-slateGrey border-b
           lg:sticky lg:z-10 ${accordianTableHeadingStyle}`}>
            {nftTableHeading.map((heading, key) => (
              <div key={key} className={`flex items-center ${heading.style}`}>
                <span
                  className={`${
                    sortData && heading.data
                      ? 'inline-block cursor-pointer'
                      : ''
                  }`}
                  onClick={() => applySorting(heading.data, heading.order)}>
                  <span className="flex flex-row items-center">
                    {translate(`dashboard.${heading.title}`)}
                    {heading.data && sortData && (
                      <span className="flex justify-center items-center pl-1">
                        {getOrderIcon(heading.data)}
                      </span>
                    )}
                  </span>
                </span>
              </div>
            ))}
          </div>
          {sortedNftData?.map((nft, index) => (
            <div key={index}>{nftRow(nft)}</div>
          ))}
        </div>
      ) : (
        <NftSummaryMobile nftData={nftData} />
      )}
    </div>
  );
};

export default NftSummary;
