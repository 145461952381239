import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { updateIsUserAuthenticated } from '@reducers/userAuth';
import { IS_AUTHENTICATED } from '@constants/common';
import { useAppDispatch } from '@store/store';
import routesPath from '@routes/RoutesPath';

const OneClickLandingScreen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem(IS_AUTHENTICATED, 'true');
    dispatch(updateIsUserAuthenticated('true'));
    navigate(routesPath.CONNECT_EXCHANGE);
  }, []);
  return <></>;
};

export default OneClickLandingScreen;
