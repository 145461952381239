import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button, TextField } from '@components';
import { forgotPasswordSchema } from '../validate';
import { translate } from '@utils/locale';
import { ForgotPasswordFieldValues, PasswordProps } from '../types';

const ForgotPassword: FC<PasswordProps> = props => {
  const { disabled, handleValues, handleBackClick } = props;

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<ForgotPasswordFieldValues>({
    defaultValues: {
      email: ''
    },
    resolver: yupResolver(forgotPasswordSchema)
  });

  useEffect(() => {
    // reset form on unmount
    return () => {
      reset();
    };
  }, []);

  return (
    <>
      <div className="flex overflow-hidden flex-col justify-center items-center my-7 w-full">
        <div className="text-2xl font-semibold text-center text-blackGreen">
          {translate('forgotPasswordPage.forgotPassword')}
        </div>
        <div className="mt-[13px] text-sm text-center text-davyGrey">
          {translate('forgotPasswordPage.forgotDescription')}
        </div>
      </div>
      <form className="w-full" onSubmit={handleSubmit(handleValues)}>
        <div className="my-6">
          <TextField
            placeholder={translate('email')}
            name="email"
            control={control}
            errors={errors}
            type={'email'}
            testid="forgotPasswordEmail"
            isControlledField={true}
            InputLabelProps={{ style: { fontSize: 14 } }}
            data-testid="email"
            InputProps={{ style: { height: 54 } }}
          />
        </div>
        <Button
          label={translate('forgotPasswordPage.resetPassword')}
          className="flex justify-center items-center w-full h-[54px] bg-primaryColor"
          labelClass="text-white text-center"
          type="submit"
          dataTestId="forgotPasswordSubmitButton"
          disabled={disabled}
        />
      </form>
      <div className="flex justify-center mt-3 w-full text-davyGrey">
        <span
          data-testid="forgotPasswordNavigateToLoginButton"
          className="flex flex-row items-center text-sm
          text-davyGrey underline cursor-pointer"
          onClick={handleBackClick}>
          {translate('resetPasswordPage.backToLogin')}
        </span>
      </div>
    </>
  );
};

export default ForgotPassword;
