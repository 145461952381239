import React, { FC } from 'react';

import { USER_ROLE } from '@constants/common';
import { SignUpProps } from './types';
import SignUp from '@containers/login-flow/sign-up/SignUp';

const SignUpPage: FC<SignUpProps> = ({ userRole = USER_ROLE.PERSONAL }) => (
  <SignUp userRole={userRole} />
);

export default SignUpPage;
