export const eventKeyMapper = {
  addTransactionsInTransactions: 'ADD_TRANSACTION_IN_TRANSACTIONS',
  syncInDashboard: 'SYNC_IN_DASHBOARD',
  profileEdit: 'PROFILE_EDIT',
  taxRuleEdit: 'TAXATION_RULE_EDIT',
  subscriptionSettings: 'SUBSCRIPTION_SETTINGS',
  planSelect: 'PLAN_SELECT',
  syncAllExchangeInSources: 'SYNC_ALL_EXCHANGE_IN_SOURCES',
  connectExchangesBtnInSources: 'CONNECT_EXCHANGE_IN_SOURCES',
  transactionForm: 'TRANSACTION_FORM',
  genericFileUpload: 'GENERIC_FILE_UPLOAD',
  taxReportDownload: 'TAX_REPORT_DOWNLOAD',
  transactionExport: 'TRANSACTION_EXPORT',
  exchangeCardClick: 'EXCHANGE_CARD_CLICK',
  supportClick: 'SUPPORT_BTN_CLICK',
  upgradePlanFromNav: 'UPGRADE_PLAN_FROM_NAV',
  addTdsExchange: 'ADD_TDS_EXCHANGE',
  editTdsExchange: 'EDIT_TDS_EXCHANGE',
  confirmTdsExchange: 'CONFIRM_TDS_EXCHANGE',
  addTdsManually: 'ADD_TDS_MANUALLY',
  leftMainMenu: 'LEFT_MAIN_MENU',
  transactionDuplicateValueConfirm: 'TRANSACTION_DUPLICATE_VALUE_CONFIRM',
  fileUploadCount: 'FILE_UPLOAD_COUNT',
  connectExchangeViaAPIKey: 'CONNECT_EXCHANGE_VIA_API_KEY',
  connectExchangeViaOauth: 'CONNECT_EXCHANGE_VIA_OAUTH',
  individualExchangeSync: 'INDIVIDUAL_EXCHANGE_SYNC',
  editExchangeButton: 'EDIT_EXCHANGE_BUTTON',
  deleteExchangeButton: 'DELETE_EXCHANGE_BUTTON',
  oauthReconnectInExchangeListing: 'OAUTH_RECONNECT_EXCHANGE_LISTING',
  deleteExchangeFileButton: 'DELETE_EXCHANGE_FILE_BUTTON',
  editTransactionClicked: 'EDIT_TRANSACTION_CLICKED',
  addTransactionClicked: 'ADD_TRANSACTION_CLICKED',
  deleteTransactionInTransactionListing:
    'DELETE_TRANSACTION_IN_TRANSACTION_LISTING',
  couponRedeemBtnClicked: 'COUPON_REDEEM_BTN_CLICKED',
  couponCodeCopied: 'COUPON_CODE_COPIED',
  walletCardClicked: 'WALLET_CARD_CLICKED',
  blockchainCardClicked: 'BLOCKCHAIN_CARD_CLICKED',
  walletConnectBtnClicked: 'WALLET_CONNECT_BTN_CLICKED',
  walletSyncBtnClicked: 'WALLET_SYNC_BTN_CLICKED',
  walletDeleteBtnClicked: 'WALLET_DELETE_BTN_CLICKED',
  continueBtnClicked: 'PARTNER_LANDING_PAGE_CONTINUE_BTN_CLICKED',
  userOnboardingSuccess: 'USER_ONBOARDING_SUCCESS_VIA_PARTNER_ONBOARDING_PAGE'
};

export const eventObjMapper = {
  [eventKeyMapper.syncInDashboard]: {
    category: 'Sync Transactions Btn',
    action: 'Sync Transactions Btn clicked in dashboard'
  },
  [eventKeyMapper.profileEdit]: {
    category: 'Profile Edit Btn',
    action: 'Profile Edit Btn clicked'
  },
  [eventKeyMapper.taxRuleEdit]: {
    category: 'Taxtion Rule Edit Btn',
    action: 'Taxation Rule Edit Btn clicked'
  },
  [eventKeyMapper.subscriptionSettings]: {
    category: 'Subscription Settings Btn',
    action: 'Subscription Settings Btn clicked'
  },
  [eventKeyMapper.planSelect]: {
    category: 'Select Plan Btn',
    action: 'Select Plan Btn clicked'
  },
  [eventKeyMapper.syncAllExchangeInSources]: {
    category: 'Sync All Transactions',
    action: 'Sync All Transactions Btn clicked in Sources page'
  },
  [eventKeyMapper.connectExchangesBtnInSources]: {
    category: 'Connect exchanges btn in source page',
    action: 'Connect Exchanges btn clicked in source page'
  },
  [eventKeyMapper.transactionForm]: {
    category: 'Add transaction form accessed',
    action: 'Add Transaction Form Accessed'
  },
  [eventKeyMapper.genericFileUpload]: {
    category: 'Upload Generic File Click',
    action: 'Upload Generic File clicked'
  },
  [eventKeyMapper.taxReportDownload]: {
    category: 'Download Tax report Btn',
    action: 'Download Tax report btn clicked'
  },
  [eventKeyMapper.transactionExport]: {
    category: 'Export Transaction Btn',
    action: 'Export Transactions Btn clicked'
  },
  [eventKeyMapper.addTransactionsInTransactions]: {
    category: 'Add Transcation',
    action: 'Add Transactions Btn clicked in Transactions page'
  },
  [eventKeyMapper.exchangeCardClick]: {
    category: 'Exchange Card Click',
    action: 'Exchange Card clicked'
  },
  [eventKeyMapper.supportClick]: {
    category: 'Support Btn Click',
    action: 'Support Btn clicked'
  },
  [eventKeyMapper.upgradePlanFromNav]: {
    category: 'Upgrade Plan from Nav Menu click',
    action: 'Upgrade Plan from Nav Menu clicked'
  },
  [eventKeyMapper.addTdsExchange]: {
    category: 'Add TDS for an exchange button click',
    action: 'Add TDS for an exchange button clicked'
  },
  [eventKeyMapper.editTdsExchange]: {
    category: 'Edit TDS for an exchange button click',
    action: 'Edit TDS for an exchange button clicked'
  },
  [eventKeyMapper.confirmTdsExchange]: {
    category: 'Confirm TDS for an exchange button click',
    action: 'Confirm TDS for an exchange button clicked'
  },
  [eventKeyMapper.addTdsManually]: {
    category: 'Manually add TDS for an exchange button click',
    action: 'Manually add TDS for an exchange button clicked'
  },
  [eventKeyMapper.leftMainMenu]: {
    category: 'Left main menu item click',
    action: 'Left main menu item clicked'
  },
  [eventKeyMapper.transactionDuplicateValueConfirm]: {
    category: 'Connect Exchange warning popup confirm click',
    action: 'DuplicateTransactionConfirmClicked'
  },
  [eventKeyMapper.fileUploadCount]: {
    category: 'Total files uploaded for exchange',
    action: 'Files uploaded for exchange'
  },
  [eventKeyMapper.connectExchangeViaAPIKey]: {
    category: 'Connect exchange via API key',
    action: 'Connect exchange via API clicked'
  },
  [eventKeyMapper.connectExchangeViaOauth]: {
    category: 'Connect exchange via Oauth',
    action: 'Connect exchange via Oauth clicked'
  },
  [eventKeyMapper.individualExchangeSync]: {
    category: 'Individual exchange sync button click',
    action: 'Exchange sync button clicked'
  },
  [eventKeyMapper.editExchangeButton]: {
    category: 'Edit button of exchange click',
    action: 'Exchange edit btn clicked'
  },
  [eventKeyMapper.deleteExchangeButton]: {
    category: 'Delete button of exchange click',
    action: 'Exchange delete btn clicked'
  },
  [eventKeyMapper.oauthReconnectInExchangeListing]: {
    category: 'Oauth reconnect exchange click',
    action: 'Oauth reconnect exchange clicked'
  },
  [eventKeyMapper.deleteExchangeFileButton]: {
    category: 'Delete file button of exchange click',
    action: 'Exchange file delete btn clicked'
  },
  [eventKeyMapper.editTransactionClicked]: {
    category: 'Edit transaction click',
    action: 'Edit transaction btn clicked'
  },
  [eventKeyMapper.addTransactionClicked]: {
    category: 'Edit transaction click',
    action: 'Add transaction btn clicked'
  },
  [eventKeyMapper.deleteTransactionInTransactionListing]: {
    category: 'Delete transaction in transaction listing',
    action: 'Delete in transaction listing clicked'
  },
  [eventKeyMapper.couponRedeemBtnClicked]: {
    category: 'Partner discount banner redeem btn clicked',
    action: 'Coupon redeem btn clicked'
  },
  [eventKeyMapper.couponCodeCopied]: {
    category: 'Partner discount banner coupon copy btn clicked',
    action: 'Partner discount coupon copied'
  },
  [eventKeyMapper.walletCardClicked]: {
    category: 'Wallet card click',
    action: 'Wallet card clicked'
  },
  [eventKeyMapper.blockchainCardClicked]: {
    category: 'Blockchain card click',
    action: 'Blockchain card clicked'
  },
  [eventKeyMapper.walletConnectBtnClicked]: {
    category: 'Wallet connect btn clicked',
    action: 'Wallet connect btn clicked'
  },
  [eventKeyMapper.walletSyncBtnClicked]: {
    category: 'Wallet sync btn clicked',
    action: 'Wallet sync btn clicked'
  },
  [eventKeyMapper.walletDeleteBtnClicked]: {
    category: 'Wallet delete btn clicked',
    action: 'Wallet delete btn clicked'
  },
  [eventKeyMapper.continueBtnClicked]: {
    category: 'Partner landing page continue btn clicked',
    action: 'Partner landing page continue btn clicked'
  },
  [eventKeyMapper.userOnboardingSuccess]: {
    category: 'User onboarding success via partner landing page',
    action: 'User onboarding success via partner landing page'
  }
};
