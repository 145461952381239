import React, { FC } from 'react';
import { Skeleton } from '@mui/material';

import { TextSkeletonProps } from './types';
import COLORS from '@constants/colors';

const TextSkeleton: FC<TextSkeletonProps> = props => {
  const { width, fontSize, color = COLORS.HARP, customStyles } = props;

  return (
    <Skeleton
      variant="text"
      width={width}
      sx={{
        fontSize: { fontSize },
        bgcolor: color,
        ...customStyles
      }}
    />
  );
};

export default TextSkeleton;
