import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button, SelectDropdown, StringToTSX, TextField } from '@components';
import { handleNotifier } from '@services/api';
import {
  API_RESPONSE_STATUS,
  AVAILABLE_SUBSCRIPTION_FY,
  capitalizeFirstLetter
} from '@constants/common';
import {
  DEFI_CONNECTION_TYPE,
  LINK_WALLET_STEPS
} from '@containers/connect-exchange/constants';
import { connectWalletSchema } from '@containers/connect-exchange/validate';
import { useGetBlockchainsQuery } from '@containers/connect-exchange/api';
import { DangerIcon, WarningIcon } from '@assets/icons';
import { eventKeyMapper } from '@constants/gaEvents';
import { gAEventTracker, translate } from '@utils';
import {
  TRANSACTION_SVC_ERROR_CODE_MAPPER,
  WALLET_TXN_LIMIT_EXCEEDED
} from '@constants/errorCodeMapper';
import { WalletSyncFormFields } from '@containers/connect-exchange/types';
import { AutoSyncWalletProps } from '../types';

const AutoSyncWallet: FC<AutoSyncWalletProps> = props => {
  const {
    walletId,
    walletType,
    walletName,
    blockchainId,
    connectWallet,
    isConnectWalletInprogress,
    isPlanUpgradeRequired,
    clientId
  } = props;

  const [walletTxnLimitExceeded, setWalletTxnLimitExceeded] = useState(false);

  const {
    control,
    formState: { errors },
    watch,
    handleSubmit
  } = useForm<WalletSyncFormFields>({
    defaultValues: {
      blockchain: blockchainId,
      walletAddress: '',
      walletName: ''
    },
    resolver: yupResolver(connectWalletSchema)
  });

  const { data: blockchainListDropDown } = useGetBlockchainsQuery({
    searchTerm: ''
  });

  const blockchainWatchValue = watch('blockchain');
  const walletAddressWatchValue = watch('walletAddress');

  useEffect(() => {
    setWalletTxnLimitExceeded(false);
  }, [walletAddressWatchValue]);

  const extraOptions = {
    showNotifier: true,
    failure: translate('notifierMessage.connectWalletError'),
    success: translate('notifierMessage.connectWalletSuccess')
  };

  const handleEditWalletConnection = async (values: WalletSyncFormFields) => {
    try {
      gAEventTracker(eventKeyMapper.walletConnectBtnClicked);
      const response = await connectWallet({
        payload: {
          wallet_address: values?.walletAddress,
          nick_name: capitalizeFirstLetter(values.walletName),
          ...(walletType === DEFI_CONNECTION_TYPE.wallet && {
            wallet_id: walletId
          }),
          exchange_id: values?.blockchain
        },
        clientId: clientId
      }).unwrap();
      if (response && response.status === API_RESPONSE_STATUS.OK) {
        handleNotifier(
          response,
          extraOptions,
          TRANSACTION_SVC_ERROR_CODE_MAPPER
        );
      }
    } catch (error) {
      const errorData = error?.data?.error;
      if (errorData?.message === WALLET_TXN_LIMIT_EXCEEDED)
        setWalletTxnLimitExceeded(true);
      else {
        handleNotifier(
          { error },
          extraOptions,
          TRANSACTION_SVC_ERROR_CODE_MAPPER
        );
      }
    }
  };

  const getBlockchainDropdownValues = () => {
    const dropdown = [];
    blockchainListDropDown?.map(blockchain => {
      dropdown.push({
        id: blockchain.id,
        name: blockchain.name
      });
    });
    return dropdown;
  };

  return (
    <div
      className="flex overflow-auto flex-col pl-4 ml-0 w-full h-[calc(100vh-115px)] sm:mr-6 sm:h-full
    sm:max-h-[550px] md:flex-row md:pl-6 md:h-auto customNormalScroll">
      <div className="w-full">
        <form onSubmit={handleSubmit(handleEditWalletConnection)}>
          <div className="flex relative flex-row flex-wrap mb-6">
            <div
              className="flex after:absolute order-1 pr-4 mb-5 w-full after:h-full sm:after:bottom-[5%] 
              sm:after:left-[55%] sm:w-[55%] sm:after:border-l-[1px] sm:after:border-greyCloud sm:after:border-dashed">
              <div className="flex flex-col w-full">
                <p className="mt-4 mb-6 text-2xl font-semibold">
                  {translate(
                    'connectExchangePage.connectWallet.autoSyncWallet'
                  )}
                </p>
                <div className="mb-4">
                  <SelectDropdown
                    name="blockchain"
                    placeholder={translate(
                      'connectExchangePage.connectWallet.blockchain'
                    )}
                    control={control}
                    errors={errors}
                    dropdownLOV={getBlockchainDropdownValues()}
                    isDisabled={walletType === DEFI_CONNECTION_TYPE.blockchain}
                    selectedItem={blockchainWatchValue}
                  />
                </div>
                <div className="mb-4">
                  <TextField
                    name="walletAddress"
                    className="mt-6"
                    placeholder={translate(
                      'connectExchangePage.connectWallet.publicAddress'
                    )}
                    control={control}
                    errors={errors}
                    isControlledField={true}
                  />
                </div>
                <div className="mb-4">
                  <TextField
                    name="walletName"
                    className="mt-6"
                    placeholder={translate(
                      'connectExchangePage.connectWallet.walletNickname'
                    )}
                    control={control}
                    errors={errors}
                    isControlledField={true}
                  />
                </div>
                {!walletTxnLimitExceeded && (
                  <Button
                    label={translate(
                      'connectExchangePage.connectWallet.connectWallet'
                    )}
                    className="py-[9px] px-[17px] w-full text-white whitespace-nowrap
                     bg-primaryColor sm:mr-4 sm:w-[160px]"
                    type="submit"
                    disabled={
                      isConnectWalletInprogress || walletTxnLimitExceeded
                    }
                  />
                )}
              </div>
            </div>
            <div className="order-3 pb-8 pl-1 sm:order-2 sm:pb-3 sm:pl-4 sm:w-[45%] md:pr-6">
              <div className="mt-4 mb-6 text-base font-semibold sm:pr-5">
                {translate(
                  'connectExchangePage.connectWallet.howToLinkWalletTitle',
                  {
                    walletName: walletName.toLowerCase().includes('wallet')
                      ? walletName
                      : walletName + ' wallet'
                  }
                )}
              </div>
              <div className="pl-5">
                <ol className="text-base font-normal list-decimal text-comet">
                  <li className="mt-4 sm:pr-5 md:ml-4">
                    {translate(
                      'connectExchangePage.connectWallet.openYourWallet',
                      {
                        walletName: walletName.toLowerCase().includes('wallet')
                          ? walletName
                          : walletName + ' wallet'
                      }
                    )}
                  </li>
                  {LINK_WALLET_STEPS.map(step => (
                    <li
                      className="mt-4 whitespace-pre-line sm:pr-5 md:ml-4"
                      key={step}>
                      {step}
                    </li>
                  ))}
                </ol>
                <p
                  className="inline-flex relative -left-6 items-start pt-3 
                text-base font-normal text-comet sm:-left-3">
                  {isPlanUpgradeRequired && (
                    <WarningIcon
                      className="inline shrink-0 mr-2"
                      width="20px"
                    />
                  )}
                  <span>
                    <StringToTSX
                      domString={translate(
                        isPlanUpgradeRequired
                          ? 'connectExchangePage.connectWallet.walletNoteSection'
                          : 'connectExchangePage.connectWallet.assistanceEmail',
                        {
                          supportEmail: process.env.REACT_APP_SUPPORT_EMAIL,
                          redirectionUrl: `#/settings/plans?financialYear=${AVAILABLE_SUBSCRIPTION_FY[0].name}`
                        }
                      )}
                    />
                  </span>
                </p>
              </div>
            </div>
            {walletTxnLimitExceeded && (
              <div
                className="flex flex-col-reverse order-2 justify-center items-start pb-4 mr-4 mb-4 w-full
              h-auto bg-white border-b-[1px] border-greyCloud border-dashed sm:flex-row sm:order-3
              sm:justify-start sm:items-center sm:pb-0 sm:border-0 md:pr-4 md:mr-0">
                <Button
                  label={translate(
                    'connectExchangePage.connectWallet.connectWallet'
                  )}
                  className="py-[9px] px-[17px] w-full min-w-[120px] text-white whitespace-nowrap bg-primaryColor
                    sm:mr-4 sm:w-auto"
                  type="submit"
                  disabled={isConnectWalletInprogress || walletTxnLimitExceeded}
                />

                <div className="relative z-50 py-3 bg-white">
                  <div className="flex flex-row items-start p-4 bg-amour rounded-md sm:items-center">
                    <DangerIcon width={18} height={18} />
                    <div className="ml-4 w-[80%] text-sm">
                      <StringToTSX
                        domString={translate(
                          'sourcePage.walletTxnLimitExceedMsg',
                          {
                            supportEmail: process.env.REACT_APP_SUPPORT_EMAIL
                          }
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AutoSyncWallet;
