import React, { useContext, useState } from 'react';
import { ListChildComponentProps } from 'react-window';
import { Box, Tooltip } from '@mui/material';

import { InnerElementContext } from './OuterElementType';
import { LISTBOX_PADDING } from '@constants/common';
import { CheckIcon } from '@assets/icons';
import ImageFetcher from '@components/image-fetcher/ImageFetcher';
import DefaultCoinIcon from '@assets/icons/defaultCoinIcon.svg';

const ListItemRow = (prop: ListChildComponentProps) => {
  const { data, index, style } = prop;

  const {
    tooltipVisibility,
    showCheckBox = false,
    selectedValues,
    handleCheckBoxClick,
    handleOptionClick,
    showId,
    name
  } = useContext(InnerElementContext);

  const [isOpen, setIsOpen] = useState(false);

  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING
  };

  const updateItemList = (isAddToList: boolean, ItemId: string) => {
    let updatedList = [...selectedValues];
    if (isAddToList) updatedList = [...selectedValues, ItemId];
    else updatedList.splice(selectedValues.indexOf(ItemId), 1);
    handleCheckBoxClick(updatedList.join(','));
  };

  const isChecked = selectedValues.includes(dataSet?.id);

  return (
    <Box component="li" style={inlineStyle} id={dataSet?.id}>
      <Tooltip
        data-testid={dataSet?.id}
        title={dataSet?.name}
        arrow={true}
        placement={`${showCheckBox ? 'bottom' : 'left-start'}`}
        enterTouchDelay={0}
        open={isOpen && !tooltipVisibility}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}>
        <label
          id={dataSet?.id}
          key={`${dataSet?.id}-${name}`}
          htmlFor={`${dataSet?.id}-${name}`}
          data-testid={`${dataSet?.id}Coin-${name}`}
          className="flex items-center px-4 h-[38px] text-sm text-davyGrey hover:bg-hawksBlue/20 cursor-pointer">
          <div
            className="flex justify-center items-center mr-2 w-[22px] min-w-[22px]
                h-[22px] rounded-[50%] border-[1px]">
            <ImageFetcher
              imageUrl={dataSet?.icon}
              defaultImage={DefaultCoinIcon}
              imageStyle="w-auto max-w-[76%] h-auto max-h-[76%]"
            />
          </div>
          <span
            className={`flex flex-row items-baseline pr-2 mr-2 truncate text-davyGrey 
            ${isChecked ? 'font-semibold' : 'font-medium'}`}>
            <p className="truncate">
              {`${showId ? dataSet?.id + ' -' : ''} ${dataSet?.name}`}
            </p>
          </span>
          <input
            type="checkbox"
            id={`${dataSet?.id}-${name}`}
            name={dataSet?.name}
            value={dataSet?.id}
            className={`absolute shrink-0 opacity-0 cursor-pointer customInput
              ${isChecked ? 'font-semibold' : 'font-medium'}`}
            onChange={event => {
              showCheckBox
                ? updateItemList(event.target.checked, event.target.value)
                : handleOptionClick(event);
            }}
            checked={isChecked}
          />
          <CheckIcon className="hidden absolute right-2 shrink-0 justify-center checkedIcon" />
        </label>
      </Tooltip>
    </Box>
  );
};

export default ListItemRow;
