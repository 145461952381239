import { object, string, ref } from 'yup';

import { translate } from '@utils/locale';
import { REGEX } from '@constants/common';

export const resetPasswordFormSchema = object().shape({
  password: string()
    .required(translate('resetPasswordPage.requiredField'))
    .matches(REGEX.password, translate('resetPasswordPage.passwordWarning')),
  confirmPassword: string()
    .required(translate('resetPasswordPage.requiredField'))
    .oneOf(
      [ref('password')],
      translate('resetPasswordPage.passwordsDoNotMatch')
    )
});
