import React from 'react';

import {
  CircularSkeleton,
  RectangularSkeleton,
  TextSkeleton
} from '@components';

const ItrFillingPageSkeleton = () => {
  return (
    <div
      className="p-8 m-[14px] min-h-[calc(100vh-200px)] bg-white rounded-[10px] sm:my-8 
        sm:mx-[27px] lg:px-28 xl:px-56">
      <div className="flex flex-col items-center mb-6 w-full ">
        <TextSkeleton customStyles={{ width: '60%' }} fontSize="24px" />
        <TextSkeleton
          customStyles={{ width: '35%', marginTop: '16px' }}
          fontSize="16px"
        />
      </div>
      {[...Array(6)].map((item, index) => (
        <div
          key={index}
          className="flex flex-col justify-center mb-10 w-full md:flex-row ">
          <TextSkeleton customStyles={{ width: '80%' }} fontSize="20px" />
          <div className="flex mt-2 md:mt-0">
            <CircularSkeleton
              customStyles={{ marginRight: '30px', marginLeft: '60px' }}
              width={25}
              height={25}
            />
            <CircularSkeleton
              width={25}
              height={25}
              customStyles={{ marginLeft: '30px' }}
            />
          </div>
        </div>
      ))}
      <div className="2xl:pl-8">
        <RectangularSkeleton
          height={24}
          width={150}
          customStyles={{ marginRight: 'auto', borderRadius: '5px' }}
        />
      </div>
    </div>
  );
};

export default ItrFillingPageSkeleton;
