import { YES_OR_NO_RADIO_VALUES } from '@constants/common';

export const ITR_FORM = [
  {
    questionId: 'hasIncomeFromSalaries',
    question: 'Do you have income from Salaries?',
    radioValues: YES_OR_NO_RADIO_VALUES
  },
  {
    questionId: 'hasIncomeFromHouseProperty',
    question: 'Do you have income from House property?',
    radioValues: YES_OR_NO_RADIO_VALUES
  },
  {
    questionId: 'hasIncomeFromSale',
    question: 'Do you have income from Sale of any asset / shares?',
    radioValues: YES_OR_NO_RADIO_VALUES
  },
  {
    questionId: 'hasIncomeFromBusiness',
    question: 'Do you have income from Business / Profession?',
    radioValues: YES_OR_NO_RADIO_VALUES
  },
  {
    questionId: 'hasIncomeFromOtherSources',
    question: 'Do you have income from Other sources?',
    radioValues: YES_OR_NO_RADIO_VALUES
  },
  {
    questionId: 'hasAgriculturalIncome',
    question: 'Do you have Agricultural income?',
    radioValues: YES_OR_NO_RADIO_VALUES
  }
];
