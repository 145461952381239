import React, { Suspense, useEffect, useState } from 'react';
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty, startCase, toLower } from 'lodash';
import { Tooltip } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';

import { CircularLoader, LeftNav, WelcomePopUp } from '@components';
import { RootState } from '@store/reducers';
import { useAppDispatch } from '@store/store';
import { updateTransactionClearPage } from '@containers/transactions/slice';
import {
  convertFinancialYearToUTC,
  gAPageView,
  lazyWithRetry,
  translate,
  getFinancialYearFromUTC
} from '@utils';
import {
  ACCOUNT_TYPE,
  CLIENT_ID,
  JOB_NOTIFIER,
  NotifierTypes,
  USER_ORIGIN,
  USER_ROLE
} from '@constants/common';
import baseApi, { authBaseApi } from '@services/api';
import { DownArrow, GoBack } from '@assets/icons';
import { useAcceptTermsAndConditionsMutation } from '@containers/login-flow/sign-in/api';
import { useClientBannerHeight, useScreenDimensions } from '@hooks';
import { useGetJobStatusesQuery } from '@containers/transactions/api';
import { useLazyGetUserDiscountDetailsQuery } from '@containers/settings/api';
import { useLazyGetPromotionDetailsQuery } from '@containers/give-away/api';
import { updateGiveAwayStatus } from '@reducers/userDetails';
import {
  clearClientProfile,
  hideNotifier,
  showNotifier,
  updateClientId,
  updateJobStatusPolling,
  updateNotifier
} from '@reducers/appReducer';
import { updateSubscriptionDetails } from '@reducers/subscription';
import { useGetCurrentFYSubscriptionQuery } from '@containers/tax-report/api';
import { useLazyGetClientProfileQuery } from '@containers/tax-professional-dashboard/client-listing/api';
import { VoidFn } from 'types/generalTypes';
import { ClearPageReducerFunctionType } from './types';
import SourcePage from '@pages/SourcePage';
import DashboardPage from '@pages/DashboardPage';
import COLORS from '@constants/colors';
import ConnectExchangePage from '@pages/ConnectExchangePage';
import ResolveErrorsPage from '@pages/ResolveErrorPage';
import AccountRecoveryPage from '@pages/AccountRecoveryPage';
import ItrFilingPage from '@pages/ItrFilingPage';
import RoutesPath from './RoutesPath';
import TaxProfessionalLayout from './TaxProfessionalLayout';
import RouteRedirector from './RouteRedirector';

const TransactionPage = lazyWithRetry(() => import('@pages/TransactionPage'));
const TransactionDetails = lazyWithRetry(
  () => import('@containers/transactions/transaction-detail/TransactionDetails')
);
const TaxReportPage = lazyWithRetry(() => import('@pages/TaxReportPage'));
const TdsTrackerPage = lazyWithRetry(() => import('@pages/TdsTrackerPage'));
const SettingsPage = lazyWithRetry(() => import('@pages/SettingsPage'));
const SubscriptionPlanPage = lazyWithRetry(
  () => import('@pages/SubscriptionPlanPage')
);
const ProfileSettingsPage = lazyWithRetry(
  () => import('@pages/ProfileSettingsPage')
);
const TaxationRuleSettingsPage = lazyWithRetry(
  () => import('@pages/TaxationRuleSettingsPage')
);
const ConnectExchangeByAuthPage = lazyWithRetry(
  () => import('@pages/ConnectExchangeByAuthPage')
);
const TaxProfessionalListingPage = lazyWithRetry(
  () => import('@pages/TaxProfessionalListingPage')
);
const GiveAwayPage = lazyWithRetry(() => import('@pages/GiveAwayPage'));

/*
  add the clearPage function configs here
  paths: Array of url path that need to be whitelisted
  reducerFunction: setClear page function of listing page
 */
const clearPageReducerFunctions: ClearPageReducerFunctionType[] = [
  {
    paths: ['transaction/detail', 'transaction/edit'],
    reducerFunction: updateTransactionClearPage
  }
];

const PrivateLayout = () => {
  const [pageDimension, setPageDimension] = useState('');

  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [redirectionPathName] = useState(
    ((location.state as any) || { from: null })?.from
  );

  useEffect(() => {
    if (redirectionPathName) {
      navigate(redirectionPathName);
    }
  }, [redirectionPathName]);

  const { isMobileView } = useScreenDimensions();

  const {
    isNavOpen,
    stopJobStatusPolling,
    clientId,
    disableGiveAway,
    userDetails,
    clientProfile,
    navigatedFrom
  } = useSelector((state: RootState) => ({
    ...state.rootReducer.leftNavigation,
    ...state.rootReducer.app,
    ...state.rootReducer.professional,
    ...state.rootReducer.userDetails
  }));

  const currentFY = getFinancialYearFromUTC(new Date().toISOString());
  const dateRange = convertFinancialYearToUTC(currentFY);

  const [clientViewBannerHeight] = useClientBannerHeight(
    !!clientId && userDetails.email !== ''
  );
  const [isBannerOpen, setIsBannerOpen] = useState(false);

  const { data: userSubscription, refetch: refetchSubscriptionData } =
    useGetCurrentFYSubscriptionQuery(
      (userDetails.isActive &&
        !userDetails.isDeleted &&
        userDetails.userType === USER_ROLE.PERSONAL) ||
        (userDetails.userType === USER_ROLE.PROFESSIONAL &&
          !!clientId &&
          clientProfile.type &&
          clientProfile.type !== ACCOUNT_TYPE.MANAGED)
        ? {
            from_timestamp: dateRange.startDate,
            to_timestamp: dateRange.endDate,
            clientId
          }
        : skipToken,
      { refetchOnFocus: true }
    );

  const [activateUser, activateUserResponse] =
    useAcceptTermsAndConditionsMutation();
  const [getUserDiscountDetails] = useLazyGetUserDiscountDetailsQuery();

  const [getPromotionDetails, { data: promotionDetails }] =
    useLazyGetPromotionDetailsQuery();

  const { data: jobStatusesResponse, isFetching: isFetchingJobStatuses } =
    useGetJobStatusesQuery(null, {
      pollingInterval: 5000,
      skip:
        !userDetails.isActive ||
        userDetails.isDeleted ||
        stopJobStatusPolling ||
        userDetails.userType !== USER_ROLE.PERSONAL
    });
  const [getClientProfile] = useLazyGetClientProfileQuery();

  useEffect(() => {
    let storedClientId = window.localStorage.getItem(CLIENT_ID);
    if (storedClientId === null) {
      window.localStorage.setItem(CLIENT_ID, '');
      storedClientId = '';
    }
    if (storedClientId) {
      getClientProfile(storedClientId);
    }
    dispatch(updateClientId(storedClientId));
  }, []);

  useEffect(() => {
    if (
      userDetails.isActive &&
      !userDetails.isDeleted &&
      userDetails.userType === USER_ROLE.PERSONAL
    ) {
      getUserDiscountDetails();
      getPromotionDetails(process.env.REACT_APP_PROMOTION_ID);
    }
  }, [userDetails]);

  useEffect(() => {
    if (promotionDetails?.expires_at) {
      let today = new Date();
      let expiryDate = new Date(promotionDetails.expires_at);
      if (expiryDate && expiryDate < today) {
        dispatch(updateGiveAwayStatus(true));
      } else {
        dispatch(updateGiveAwayStatus(false));
      }
    }
  }, [promotionDetails]);

  useEffect(() => {
    if (userSubscription)
      dispatch(updateSubscriptionDetails(userSubscription[0]));
  }, [userSubscription]);

  useEffect(() => {
    return () => {
      dispatch(hideNotifier(JOB_NOTIFIER));
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(jobStatusesResponse) && !isFetchingJobStatuses) {
      if (jobStatusesResponse.gain_or_loss_pending_count === 0) {
        dispatch(updateJobStatusPolling(true));
        dispatch(
          updateNotifier({
            message: translate('notifierMessage.gainOrLossComputationSuccess'),
            id: JOB_NOTIFIER,
            type: NotifierTypes.SUCCESS
          })
        );
      } else {
        dispatch(
          showNotifier({
            message: translate(
              'notifierMessage.gainOrLossComputationInProgress'
            ),
            autoHideDisabled: true,
            type: NotifierTypes.JOB_QUEUE_LOADING,
            id: JOB_NOTIFIER
          })
        );
      }
    }
  }, [jobStatusesResponse, isFetchingJobStatuses]);

  useEffect(() => {
    gAPageView(location.pathname);
    clearPageReducerFunctions.forEach(item => {
      // checks whether the current url is whitelisted or not
      const pathChecks = item.paths.some(path => {
        return location.pathname.includes(path);
      });
      // should not clear pageNo. incase of edit-page, details-page
      if (!pathChecks) dispatch(item.reducerFunction(true));
    });
  }, [location.pathname]);

  useEffect(() => {
    if (isNavOpen) {
      setPageDimension(
        'left-0  w-100 z-70 bg-gray/[06] sm:left-80  sm:w-[calc(100%-20rem)]'
      );
    } else {
      setPageDimension('left-0  w-full sm:left-[5rem] sm:w-[calc(100%-5rem)]');
    }
  }, [isNavOpen]);

  useEffect(() => {
    const mainElement = document.getElementById('main-section');
    if (mainElement) {
      if (isNavOpen && isMobileView) {
        mainElement.style.overflow = 'hidden';
      } else {
        mainElement.style.overflow = 'auto';
      }
    }
    setIsBannerOpen(false);
  }, [isMobileView, isNavOpen]);

  const handleActivateUserClick = async (activateSuccessCallback: VoidFn) => {
    await activateUser();
    activateSuccessCallback();
    if (userDetails.userType === USER_ROLE.PERSONAL) {
      const userDiscountDetails = await getUserDiscountDetails().unwrap();
      if (!isEmpty(userDiscountDetails)) {
        navigate(`${RoutesPath.SETTINGS}/plans`);
      }
    }
  };

  const invalidateUserDetails = () => {
    dispatch(authBaseApi.util.invalidateTags(['GetUserDetails']));
    dispatch(baseApi.util.invalidateTags(['GetSettingsDetails']));
    dispatch(authBaseApi.util.invalidateTags(['GetProfessionalSettingsData']));
  };

  const getMainSectionHeight = () => {
    if (clientViewBannerHeight)
      return isMobileView
        ? `calc(100vh - ${clientViewBannerHeight}px - 75px)`
        : `calc(100vh - ${clientViewBannerHeight}px`;
    return isMobileView ? 'calc(100vh - 75px)' : '100vh';
  };

  const handleExit = () => {
    window.localStorage.setItem(CLIENT_ID, '');
    navigate(`${RoutesPath.TAX_PROFESSIONALS}/clients?tab=${navigatedFrom}`);
    dispatch(updateClientId(''));
    dispatch(clearClientProfile());
    setIsBannerOpen(false);
  };

  const handleBannerClick = () => {
    setIsBannerOpen(currentState => !currentState);
  };

  return (
    <>
      {userDetails.email !== '' && userDetails.isDeleted ? (
        <div className="w-full h-full">
          <Routes>
            <Route
              path={RoutesPath.ALL}
              element={<Navigate replace={true} to={RoutesPath.RECOVER} />}
            />
            <Route
              path={RoutesPath.RECOVER}
              element={<AccountRecoveryPage />}
            />
          </Routes>
        </div>
      ) : (
        userDetails.email !== '' && (
          <>
            {clientId && (
              <div
                id="clientViewBanner"
                className={`flex top-0 flex-row items-center w-full absolute z-[120]  
                ${isBannerOpen ? 'h-[100px]' : 'h-12'} 
              text-sm font-semibold text-primaryColor bg-titanWhite selection:bg-primaryColor/20 lg:text-base 
              transition-all duration-300 border-b border-mercury shadow-shadowBottomTwlightLite
              `}>
                <div className="flex px-2 w-[25%] h-full text-xs sm:w-[20%] lg:w-[10%] lg:text-sm">
                  <div
                    className="flex flex-row items-center mr-2 whitespace-nowrap cursor-pointer"
                    onClick={handleExit}>
                    <GoBack
                      className="shrink-0 mr-2"
                      fill={COLORS.PRIMARY_COLOR}
                    />
                    {translate('goBack')}
                  </div>
                </div>
                <div
                  className="flex justify-center items-center my-4 w-[70%] text-center
              sm:w-[80%] lg:w-[90%]">
                  <Tooltip
                    title={
                      <span>
                        <span className="mr-[3px]">Client Name:</span>
                        <span className="mr-[3px]">
                          {startCase(toLower(clientProfile.firstName))}
                        </span>
                        {startCase(toLower(clientProfile.lastName))},<br />
                        <span className="mr-[3px]">Client Email:</span>
                        {clientProfile.email}
                      </span>
                    }
                    enterTouchDelay={0}
                    arrow={true}
                    className="w-full">
                    <div
                      className={`w-[95%] ${
                        !isBannerOpen && 'truncate'
                      } break-words`}>
                      {translate('ca.clientViewBannerText', {
                        clientName: `${startCase(
                          toLower(clientProfile.firstName)
                        )} ${startCase(toLower(clientProfile.lastName))}`,
                        clientEmail: clientProfile.email
                      })}
                    </div>
                  </Tooltip>
                  {isMobileView && (
                    <span className="w-[20%]">
                      <DownArrow
                        className={`${
                          isBannerOpen ? 'rotate-180' : ''
                        } ml-2 cursor-pointer duration-500`}
                        onClick={handleBannerClick}
                        fill={COLORS.PRIMARY_COLOR}
                      />
                    </span>
                  )}
                </div>
              </div>
            )}
            <div
              className={`flex ${clientId ? 'absolute top-12' : ''} z-[100] 
            flex-col w-full selection:bg-primaryColor/20 sm:flex-row`}>
              <LeftNav isClientViewBannerActive={!!clientId} />
              <div
                id="main-section"
                className={`relative ${pageDimension} transition-all duration-[725ms] ease-out overflow-auto 
                customNormalScroll
                `}
                style={{
                  height: getMainSectionHeight()
                }}>
                {/*Waiting before mounting pages for user details to be retrived. */}
                <Suspense fallback={<CircularLoader isFullScreen={true} />}>
                  <Routes>
                    {userDetails.userType === USER_ROLE.PROFESSIONAL &&
                      !clientId && (
                        <>
                          <Route
                            path={`${RoutesPath.TAX_PROFESSIONALS}/*`}
                            element={<TaxProfessionalLayout />}
                          />
                          <Route
                            path={`${RoutesPath.TAX_PROFESSIONALS}/settings`}
                            element={<SettingsPage />}
                          />
                          <Route
                            path={`${RoutesPath.TAX_PROFESSIONALS}${RoutesPath.SETTINGS}/profile`}
                            element={<ProfileSettingsPage />}
                          />
                          <Route
                            path={RoutesPath.ALL}
                            element={
                              <Navigate
                                replace={true}
                                to={RoutesPath.TAX_PROFESSIONALS}
                              />
                            }
                          />
                        </>
                      )}
                    {(userDetails.userType === USER_ROLE.PERSONAL ||
                      (userDetails.userType === USER_ROLE.PROFESSIONAL &&
                        !!clientId)) && (
                      <>
                        {userDetails.isActive !== true &&
                        userDetails.userType !== USER_ROLE.PROFESSIONAL ? (
                          <>
                            <Route
                              path={RoutesPath.ALL}
                              element={
                                <Navigate
                                  replace={true}
                                  to={RoutesPath.CONNECT_EXCHANGE}
                                />
                              }
                            />
                            <Route
                              path={RoutesPath.CONNECT_EXCHANGE}
                              element={<ConnectExchangePage />}
                            />
                          </>
                        ) : (
                          // First time logged in user will be seeing welcome screen only(Inside source page).
                          // Restricting access to other pages.
                          <>
                            <Route
                              path={RoutesPath.DASHBOARD}
                              element={
                                <RouteRedirector>
                                  <DashboardPage />
                                </RouteRedirector>
                              }
                            />
                            <Route
                              path={RoutesPath.TRANSACTION}
                              element={
                                <RouteRedirector>
                                  <TransactionPage />
                                </RouteRedirector>
                              }
                            />
                            <Route
                              path={`${RoutesPath.TRANSACTION}/detail/:transactionId`}
                              element={
                                <RouteRedirector>
                                  <TransactionDetails />
                                </RouteRedirector>
                              }
                            />
                            <Route
                              path={RoutesPath.SOURCE}
                              element={
                                <RouteRedirector>
                                  <SourcePage />
                                </RouteRedirector>
                              }
                            />
                            <Route
                              path={RoutesPath.CONNECT_EXCHANGE}
                              element={
                                <RouteRedirector>
                                  <ConnectExchangePage />
                                </RouteRedirector>
                              }
                            />
                            <Route
                              path={`${RoutesPath.SOURCE}/auth/:exchangeCode`}
                              element={
                                <RouteRedirector>
                                  <ConnectExchangeByAuthPage />
                                </RouteRedirector>
                              }
                            />
                            <Route
                              path={RoutesPath.TAX_REPORT}
                              element={
                                <RouteRedirector>
                                  <TaxReportPage />
                                </RouteRedirector>
                              }
                            />

                            <Route
                              path={RoutesPath.ITR_FILING}
                              element={
                                <RouteRedirector>
                                  <ItrFilingPage />
                                </RouteRedirector>
                              }
                            />
                            <Route
                              path={RoutesPath.TDS_TRACKER}
                              element={
                                <RouteRedirector>
                                  <TdsTrackerPage />
                                </RouteRedirector>
                              }
                            />
                            <Route
                              path={RoutesPath.SETTINGS}
                              element={
                                <RouteRedirector>
                                  <SettingsPage />
                                </RouteRedirector>
                              }
                            />
                            <Route
                              path={`${RoutesPath.SETTINGS}/plans`}
                              element={
                                <RouteRedirector>
                                  <SubscriptionPlanPage
                                    refetchSubscriptionData={
                                      refetchSubscriptionData
                                    }
                                  />
                                </RouteRedirector>
                              }
                            />
                            <Route
                              path={`${RoutesPath.SETTINGS}/profile`}
                              element={
                                <RouteRedirector>
                                  <ProfileSettingsPage />
                                </RouteRedirector>
                              }
                            />
                            <Route
                              path={`${RoutesPath.SETTINGS}/taxation`}
                              element={
                                <RouteRedirector>
                                  <TaxationRuleSettingsPage />
                                </RouteRedirector>
                              }
                            />
                            <Route
                              path={`${RoutesPath.TAX_PROFESSIONAL_INVITES}`}
                              element={
                                <RouteRedirector>
                                  <TaxProfessionalListingPage />
                                </RouteRedirector>
                              }
                            />
                            {!disableGiveAway && (
                              <Route
                                path={`${RoutesPath.GIVE_AWAY}`}
                                element={
                                  <RouteRedirector>
                                    <GiveAwayPage />
                                  </RouteRedirector>
                                }
                              />
                            )}
                            <Route
                              path={`${RoutesPath.TRANSACTION}${RoutesPath.RESOLVE_ERRORS}`}
                              element={
                                <RouteRedirector>
                                  <ResolveErrorsPage />
                                </RouteRedirector>
                              }
                            />
                            <Route
                              path={RoutesPath.ALL}
                              element={
                                <Navigate
                                  replace={true}
                                  to={RoutesPath.DASHBOARD}
                                />
                              }
                            />
                            <Route
                              path="/tax-report"
                              element={
                                <Navigate
                                  replace={true}
                                  to={RoutesPath.TAX_REPORT}
                                />
                              }
                            />
                            <Route
                              path="/tax-professional-invites"
                              element={
                                <Navigate
                                  replace={true}
                                  to={RoutesPath.TAX_PROFESSIONAL_INVITES}
                                />
                              }
                            />
                            <Route
                              path="/tds-tracker"
                              element={
                                <Navigate
                                  replace={true}
                                  to={RoutesPath.TDS_TRACKER}
                                />
                              }
                            />
                            <Route
                              path="/itr-filing"
                              element={
                                <Navigate
                                  replace={true}
                                  to={RoutesPath.ITR_FILING}
                                />
                              }
                            />
                          </>
                        )}
                      </>
                    )}
                  </Routes>
                </Suspense>
                {(!userDetails.isActive ||
                  !userDetails.phone.number ||
                  (!userDetails.hasPassword &&
                    userDetails.origin !== USER_ORIGIN.GOOGLE)) && (
                  <WelcomePopUp
                    onContinuePress={handleActivateUserClick}
                    showModal={
                      !userDetails.isActive ||
                      !userDetails.phone.number ||
                      (!userDetails.hasPassword &&
                        userDetails.origin !== USER_ORIGIN.GOOGLE)
                    }
                    isNewUser={!userDetails.isActive}
                    isPhoneVerified={!!userDetails.phone.number}
                    firstName={userDetails.firstName}
                    lastName={userDetails.lastName}
                    hasPassword={
                      userDetails.hasPassword ||
                      userDetails.origin === USER_ORIGIN.GOOGLE
                    }
                    openTandC={() =>
                      window.open(process.env.REACT_APP_TERMS_AND_CONDITIONS)
                    }
                    openPrivacyPolicy={() =>
                      window.open(process.env.REACT_APP_PRIVACY_POLICY)
                    }
                    userType={userDetails.userType}
                    disabled={activateUserResponse.isLoading}
                    handleClose={invalidateUserDetails}
                  />
                )}
              </div>
            </div>
          </>
        )
      )}
    </>
  );
};

export default PrivateLayout;
