import { authBaseApi } from '@services/api';
import { translate } from '@utils/locale';
import { USER_ROLE } from '@constants/common';
import { GenericResponseType, SignUpObjectType } from './types';

const AuthBaseAPI = authBaseApi.injectEndpoints({
  endpoints: builder => ({
    userSignUp: builder.mutation<GenericResponseType, SignUpObjectType>({
      query: ({
        email,
        firstName,
        lastName,
        password,
        source,
        user_type,
        companyName,
        user_token,
        referral_code
      }) => ({
        url: '/signup/classic',
        method: 'POST',
        body: {
          first_name: firstName,
          last_name: lastName,
          email,
          password,
          ...(source && { source }),
          user_type,
          ...(user_type === USER_ROLE.PROFESSIONAL && {
            company_name: companyName
          }),
          ...(user_token && { user_token }),
          ...(referral_code && { referral_code })
        }
      }),
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.signupError')
      }
    })
  })
});

export const { useUserSignUpMutation } = AuthBaseAPI;
