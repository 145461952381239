import React, { FC, useEffect, useRef, useState } from 'react';
import { Tooltip } from '@mui/material';

import { Button } from '@components';
import { getFormattedNumbering, translate } from '@utils';
import { PLAN_TYPES } from '@constants/subscriptionConstants';
import {
  CrossFilled,
  DownArrow,
  InfoIconDynamic,
  TickFilled
} from '@assets/icons';
import { PlanCardProps } from './types';
import COLORS from '@constants/colors';

const ACCORDION_BODY_OFFSET_HEIGHT = 30;

const PlanCard: FC<PlanCardProps> = props => {
  const {
    planDetails,
    isSubscribed = false,
    handleSubscribe,
    handleCardClick,
    selectedPlanId,
    isDisabled = false,
    isMobileView,
    isRecommended,
    isMostPopular
  } = props;

  const [bodyHeight, setBodyHeight] = useState(ACCORDION_BODY_OFFSET_HEIGHT);

  const bodyRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const finalHeight = bodyRef.current?.clientHeight
      ? bodyRef.current.clientHeight + ACCORDION_BODY_OFFSET_HEIGHT
      : ACCORDION_BODY_OFFSET_HEIGHT;
    setBodyHeight(finalHeight);
  }, [selectedPlanId, isMobileView]);

  const handleSubscribeClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    if (planDetails?.plan === PLAN_TYPES.CUSTOM) {
      window.location.assign(
        'mailto:support@taxcryp.com?subject=Interested in TaxCryp Custom plan'
      );
      return;
    }
    handleSubscribe(planDetails?.plan);
  };

  const handlePlanCardClick = () => {
    if (selectedPlanId === planDetails?.plan && isMobileView) {
      handleCardClick('');
      return;
    }
    handleCardClick(planDetails?.plan);
  };

  const getButtonColor = () => {
    if (isDisabled && selectedPlanId === planDetails.plan) {
      return 'text-primaryColor bg-white border border-primaryColor sw-1250:border-0';
    } else if (selectedPlanId === planDetails.plan) {
      return 'text-primaryColor bg-white border border-primaryColor sw-1250:border-0';
    }
    return `text-primaryColor sw-1250:text-white bg-white sw-1250:bg-primaryColor
     border border-primaryColor sw-1250:border-0`;
  };

  const getBorderColor = () => {
    if (isMostPopular) {
      return 'border border-primaryColor';
    }
    return '';
  };

  return (
    <div
      className={`flex flex-col relative w-full sw-1250:items-center p-4 sw-1250:p-2 mb-5 sw-1250:mb-5
      last:mb-0 sw-1250:min-w-[550px] xl:min-w-[600px] min-h-[95px] shadow-shadowBottomBlack
       cursor-pointer rounded-lg sw-1250:shadow-shadowBottomLightBlack ${getBorderColor()}
     ${
       selectedPlanId === planDetails.plan
         ? 'bg-white sw-1250:bg-primaryColor'
         : 'bg-white sw-1250:bg-whiteLilac'
     }
      sw-1250:flex-row`}
      onClick={handlePlanCardClick}>
      {isMostPopular && (
        <div
          className="absolute inset-x-0 -top-2 py-[1px] px-2 mx-auto w-max text-[10px] font-semibold text-white 
        bg-primaryColor rounded-md">
          {translate('subscription.mostPopular')}
        </div>
      )}
      <div
        className={`flex flex-col px-2 pb-3 sw-1250:pb-0 sw-1250:w-[50%]
       ${
         planDetails.plan !== PLAN_TYPES.CUSTOM
           ? 'sw-1250:border-r-[1px] sw-1250:border-greyGhoose'
           : ''
       }`}>
        <div
          className={`flex flex-wrap flex-row items-center text-xl font-medium pb-3
         ${
           selectedPlanId === planDetails.plan
             ? 'text-blackGreen sw-1250:text-white'
             : 'text-blackGreen'
         }
          sw-1250:pb-0`}>
          <span className="pr-1 align-middle">{planDetails.plan}</span>
          {isSubscribed && (
            <span
              className={`text-sm align-middle
             ${
               selectedPlanId === planDetails.plan
                 ? 'text-primaryColor sw-1250:text-white'
                 : 'text-primaryColor'
             }`}>
              {`(${translate('subscription.currentPlan')})`}
            </span>
          )}
          {isRecommended && (
            <span
              className={`text-sm align-middle
             ${
               selectedPlanId === planDetails.plan
                 ? 'text-primaryColor sw-1250:text-white'
                 : 'text-primaryColor'
             }`}>
              {`(${translate('subscription.recommendedPlan')})`}
            </span>
          )}
          {planDetails.info?.description?.length > 0 && (
            <span className="pl-1">
              <Tooltip
                title={
                  <div style={{ whiteSpace: 'pre-line' }}>
                    {planDetails.info.description.join(',\n')}
                  </div>
                }
                arrow={true}
                enterTouchDelay={0}>
                <InfoIconDynamic
                  height={18}
                  width={18}
                  stroke={
                    selectedPlanId === planDetails.plan && !isMobileView
                      ? COLORS.WHITE
                      : COLORS.SLATE_GREY
                  }
                />
              </Tooltip>
            </span>
          )}
        </div>
        <div
          className={`text-xs font-normal ${
            selectedPlanId === planDetails.plan
              ? 'text-mobster sw-1250:text-white'
              : 'text-mobster'
          }`}>
          {planDetails.summary}
          {planDetails.plan === PLAN_TYPES.CUSTOM && (
            <p className="visible my-2 sw-1250:hidden">
              {translate('subscription.contactSupportForCustomPlan', {
                count: getFormattedNumbering({
                  value: '10000',
                  hideRupeeSymbol: true
                })
              })}
            </p>
          )}
        </div>
      </div>
      <div
        className="flex flex-col items-start px-2 sw-1250:flex-row sw-1250:justify-between
       sw-1250:items-center sw-1250:pl-4 sw-1250:w-[50%]">
        <div
          className={`text-lg font-bold  pb-3
        ${
          selectedPlanId === planDetails.plan
            ? 'text-primaryColor sw-1250:text-white'
            : 'text-primaryColor sw-1250:text-blackGreen '
        } 
        sw-1250:pb-0 ${
          planDetails.plan === PLAN_TYPES.CUSTOM
            ? 'hidden sw-1250:block sw-1250:invisible'
            : 'visible'
        }`}>
          {getFormattedNumbering({ value: String(planDetails.amount) })}
          {planDetails.amount > 0 && (
            <span className="pl-1 text-xs font-medium align-baseline">
              {translate('subscription.plusGST')}
            </span>
          )}
        </div>
        <Button
          label={translate(
            planDetails.plan === PLAN_TYPES.CUSTOM
              ? 'contactUs'
              : 'subscription.selectPlan'
          )}
          labelClass="text-sm font-medium"
          className={`py-[9px] px-4 ${getButtonColor()}`}
          onClick={handleSubscribeClick}
          disabled={isDisabled}
        />
      </div>
      <div
        className={`flex overflow-hidden flex-col justify-center items-center pt-4 mx-3
       mt-4 border-t border-lightGrey cursor-pointer 
       ${
         planDetails.plan === PLAN_TYPES.CUSTOM ? 'hidden' : 'sw-1250:hidden'
       } `}>
        <div
          className={`flex flex-col mb-3 w-full ${
            selectedPlanId === planDetails.plan ? 'visible' : 'invisible'
          } duration-1000 transition-height ease opacity-100`}
          style={{
            maxHeight:
              selectedPlanId === planDetails.plan ? `${bodyHeight}px` : '0px'
          }}>
          <div ref={bodyRef}>
            {planDetails.feature_details.features.map(details => (
              <div
                key={details.feature}
                className={`flex flex-row justify-between p-2 text-sm 
                        ${
                          details.is_available
                            ? 'text-blackGreen'
                            : 'text-greyCloud'
                        }
                       ${
                         details.is_highlighted && details.is_available
                           ? 'font-semibold'
                           : ''
                       }`}>
                <span className="mr-4">{details.feature}</span>
                {details.is_available ? (
                  <TickFilled className="shrink-0" height={20} width={20} />
                ) : (
                  <CrossFilled className="shrink-0" height={20} width={20} />
                )}
              </div>
            ))}
          </div>
        </div>
        <DownArrow
          className={`${
            selectedPlanId === planDetails.plan ? 'transform  rotate-180 ' : ''
          }
           opacity-100 duration-1000 `}
          fill={COLORS.PRIMARY_COLOR}
        />
      </div>
    </div>
  );
};

export default PlanCard;
