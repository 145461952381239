import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { IS_AUTHENTICATED, USER_ROLE } from '@constants/common';
import { useAppDispatch } from '@store/store';
import { updateUserDetails } from '@reducers/userDetails';
import { RootState } from '@store/reducers';
import { clearClientDetails } from '@utils';
import { useLazyGetUserDataQuery } from '@containers/login-flow/sign-in/api';
import { updateIsUserAuthenticated } from '@reducers/userAuth';
import NotifierStack from '@containers/notifier-stack/NotifierStack';
import VerifyPage from '@pages/VerifyPage';
import ResetPasswordPage from '@pages/ResetPasswordPage';
import OneClickLandingPage from '@pages/OneClickLandingPage';
import PublicLayout from './PublicLayout';
import PrivateLayout from './PrivateLayout';
import routesPath from './RoutesPath';

const RouteLayout = () => {
  const [isAuth, setIsAuth] = useState(localStorage.getItem(IS_AUTHENTICATED));

  const dispatch = useAppDispatch();
  const { isUserAuthenticated } = useSelector(
    (state: RootState) => state.rootReducer.userAuth
  );

  // This api will automatically triggers on browser TAB change
  const [fetchUserDetails, { data: userDetails, isError }] =
    useLazyGetUserDataQuery({ refetchOnFocus: true });

  useEffect(() => {
    const defaultVal = localStorage.getItem(IS_AUTHENTICATED);
    //deafultVal will be undefined for new user and false for logged out user.
    if (!defaultVal) {
      localStorage.setItem(IS_AUTHENTICATED, 'false');
    }
    dispatch(updateIsUserAuthenticated(defaultVal ? defaultVal : 'false'));
  }, []);

  useEffect(() => {
    if (isUserAuthenticated === 'true') fetchUserDetails(); //fetching details of logged in user
  }, [isUserAuthenticated]);

  useEffect(() => {
    if (isError || isUserAuthenticated === 'false') {
      setIsAuth('false');
      localStorage.setItem(IS_AUTHENTICATED, 'false');
      clearClientDetails();
    }
  }, [isError, isUserAuthenticated]);

  useEffect(() => {
    if (userDetails) dispatch(updateUserDetails(userDetails));
    if (isUserAuthenticated === 'true') {
      setIsAuth('true');
      localStorage.setItem(IS_AUTHENTICATED, 'true');
    }
  }, [isUserAuthenticated, userDetails]);

  return (
    <>
      {isAuth && (
        <Routes>
          <Route
            path={`${routesPath.TAX_PROFESSIONALS}/verify`}
            element={<VerifyPage userRole={USER_ROLE.PROFESSIONAL} />}
          />
          <Route
            path={`${routesPath.TAX_PROFESSIONALS}/reset`}
            element={<ResetPasswordPage userRole={USER_ROLE.PROFESSIONAL} />}
          />
          <Route path={routesPath.VERIFY} element={<VerifyPage />} />
          <Route path={routesPath.RESET} element={<ResetPasswordPage />} />
          <Route
            path={routesPath.ONE_CLICK}
            element={<OneClickLandingPage />}
          />
          <Route
            path="*"
            element={isAuth === 'false' ? <PublicLayout /> : <PrivateLayout />}
          />
          {/* User who are not logged in will be taken to public layout(Pages that doesnt need to validate user),
          Logged in users will be taken to private layout.  */}
        </Routes>
      )}
      {/* verify and reset pages are given access irrespective of any checks */}

      <NotifierStack />
    </>
  );
};

export default RouteLayout;
