import React, { FC } from 'react';
import { Tooltip, useMediaQuery, useTheme } from '@mui/material';

import { InfoIconGrey } from '@assets/icons';
import { Currency, INDIAN_RUPEE } from '@constants/common';
import {
  formatDecimalPecentage,
  getFormattedNumbering,
  getNumberInIndianFormat
} from '@utils/generalUtils';
import { KpiCardProps } from './types';

const KpiCard: FC<KpiCardProps> = props => {
  const {
    title,
    subTitle,
    description,
    infoText,
    cardStyle,
    titleStyle,
    subTitleStyle,
    descriptionStyle,
    enableTitleBlur = false
  } = props;

  const gainOrLoss = subTitle && parseFloat(subTitle);

  const theme = useTheme();
  const matchXl = useMediaQuery(theme.breakpoints.down('xl'));
  return (
    <div
      className={`
        flex sm:flex-col grow mt-4 w-full bg-white rounded-[10px] lg:mr-6 lg:mt-0 lg:w-2/12 sm:text-base text-sm
        flex-col-reverse justify-center relative
        ${cardStyle ? cardStyle : 'pt-4 px-4'} shadow-shadowBottomTwlightLite`}>
      <div className="hidden absolute top-[5px] right-[7px] sm:flex sm:justify-end">
        <Tooltip title={infoText} arrow={true} enterTouchDelay={0}>
          <InfoIconGrey className="inline ml-1" width="20" height="20" />
        </Tooltip>
      </div>
      <div className={`flex ${matchXl ? 'flex-col' : 'flex-row'}`}>
        <span
          className={`break-all ${
            titleStyle ? titleStyle : 'text-2xl font-semibold'
          } ${!enableTitleBlur ? 'blur-none' : 'blur-[11px]'}`}>
          {Number(title) === 0 ? (
            <div>
              {Currency.RUPEE}
              &nbsp;
              {enableTitleBlur ? title : Number(title)}
            </div>
          ) : (
            <Tooltip
              title={getNumberInIndianFormat(
                Number(title),
                INDIAN_RUPEE.code,
                true
              )}
              arrow={true}
              enterTouchDelay={0}>
              <span>{getFormattedNumbering({ value: title })}</span>
            </Tooltip>
          )}
        </span>
        {gainOrLoss ? (
          <span
            className={`flex items-center ml-2 font-semibold ${subTitleStyle}`}>
            ({Number(gainOrLoss) > 0 ? '+' : ''}
            {formatDecimalPecentage(String(gainOrLoss))})
          </span>
        ) : (
          ''
        )}
      </div>
      <div
        className={`font-medium leading-6 sm:leading-10 sm:block flex 
        ${descriptionStyle ? descriptionStyle : 'text-davyGrey pt-1'}`}>
        <span>{description}</span>
        <div className="sm:hidden">
          <Tooltip title={infoText} arrow={true} enterTouchDelay={0}>
            <InfoIconGrey className="inline ml-1" width="20" height="20" />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default KpiCard;
