import { useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';

import { useClientBannerHeight } from '@hooks';

export default function useTopContentHeight(isClientViewBannerActive: boolean) {
  const [topBarHeight, setTopBarHeight] = useState(0);
  const [tabBarHeight, setTabBarHeight] = useState(0);
  const [mobileHeaderHeight, setMobileHeaderHeight] = useState(0);

  const [clientViewBannerHeight] = useClientBannerHeight(
    isClientViewBannerActive
  );

  const isMobileView = useMediaQuery('(max-width:640px)');

  useEffect(() => {
    function handleResize() {
      const tabBarElement = document.getElementById('tabBar');
      const topBarElement = document.getElementById('topBar');
      const mobileHeaderElement = document.getElementById('mobile-header');
      setTabBarHeight(tabBarElement?.offsetHeight || 0);
      setTopBarHeight(topBarElement?.offsetHeight || 0);
      setMobileHeaderHeight(mobileHeaderElement?.offsetHeight || 0);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobileView]);

  return (
    topBarHeight +
    tabBarHeight +
    Number(clientViewBannerHeight) +
    mobileHeaderHeight
  );
}
