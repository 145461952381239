import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import {
  CircularSkeleton,
  RectangularSkeleton,
  TextSkeleton
} from '@components';

const SubscriptionPlanSkeleton = () => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const arrayLength = isMobileView ? 3 : 5;
  const contentArrayLenght = 8;

  return (
    <div
      className="flex flex-col p-[20px] m-4 min-h-[calc(100vh-200px)]
         bg-white rounded-[10px] sm:my-8 sm:mx-[27px] sm:min-h-[calc(100vh-150px)]">
      <div className="flex flex-col items-center mb-4 w-full ">
        <TextSkeleton customStyles={{ width: '60%' }} fontSize="24px" />
        <TextSkeleton
          customStyles={{ width: '35%', marginTop: '16px' }}
          fontSize="16px"
        />
      </div>
      <div className="flex flex-row justify-center mt-[38px] w-full h-full">
        <div className="hidden w-full max-w-[320px] sw-1250:block">
          <div className="py-2 px-4 mb-3 rounded-lg shadow-shadowBottomLightBlack">
            <TextSkeleton
              customStyles={{ width: '85%', marginTop: '8px' }}
              fontSize="14px"
            />
            <TextSkeleton
              customStyles={{ width: '65%', marginTop: '8px' }}
              fontSize="12px"
            />
            <TextSkeleton
              customStyles={{ width: '65%', marginTop: '8px' }}
              fontSize="12px"
            />
          </div>
          <div className="p-2 pb-5 rounded-lg shadow-shadowBottomLightBlack">
            {[...Array(contentArrayLenght)].map((item, index) => (
              <div
                key={index}
                className="flex flex-row justify-between items-end p-2">
                <TextSkeleton
                  customStyles={{ width: '85%', marginTop: '8px' }}
                  fontSize="14px"
                />
                <CircularSkeleton height={20} width={20} />
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col w-full sw-1250:ml-7 sw-1250:w-auto">
          {[...Array(arrayLength)].map((item, index) => (
            <div
              key={index}
              className="flex flex-col p-4 mb-5 last:mb-0 w-full min-h-[90px] rounded-lg 
            shadow-shadowBottomBlack sw-1250:flex-row sw-1250:items-center sw-1250:p-4 sw-1250:mb-3
             sw-1250:min-w-[550px] sw-1250:shadow-shadowBottomLightBlack">
              <div className="flex flex-col w-full sw-1250:w-[50%]">
                <TextSkeleton customStyles={{ width: '15%' }} fontSize="16px" />
                <TextSkeleton
                  customStyles={{ width: '45%', marginTop: '16px' }}
                  fontSize="14px"
                />
              </div>
              <div className="flex flex-col justify-between mt-4 w-full sw-1250:flex-row sw-1250:mt-0 sw-1250:w-[50%]">
                <TextSkeleton customStyles={{ width: '25%' }} fontSize="16px" />
                <div className="flex mt-4 w-full sw-1250:justify-end sw-1250:mt-0">
                  <RectangularSkeleton
                    customStyles={{
                      width: '30%',
                      height: '40px',
                      borderRadius: '6px'
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlanSkeleton;
