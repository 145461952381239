import React, {
  DetailedHTMLProps,
  FC,
  useCallback,
  HTMLAttributes,
  useEffect,
  useState
} from 'react';
import { useNavigate } from 'react-router-dom';

import { useQuery } from '@hooks';
import { CircularLoader } from '@components';
import { USER_ROLE } from '@constants/common';
import { useUserSignUpMutation } from './api';
import { SignUpFieldValues, SignUpValues, SignUpProps } from './types';
import CheckEmail from '@containers/login-flow/sign-in/components/CheckEmail';
import routesPath from '@routes/RoutesPath';
import EmailPasswordSignUp from './components/EmailPasswordSignUp';

const SignUp: FC<SignUpProps> = props => {
  const { userRole } = props;

  const [signUpUser, signUpResponse] = useUserSignUpMutation();
  const navigate = useNavigate();
  const query = useQuery();
  const partnerCode = query.get('partner_code');
  const source = query.get('source');
  const userToken = query.get('user_token');
  const referralCode = query.get('referral_code');

  const [signUpValues, setSignUpValues] = useState<SignUpValues | null>(null);
  const [signUpSuccess, setSignUpSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (signUpResponse.isSuccess) {
      setSignUpSuccess(true);
    }
  }, [signUpResponse]);

  const handleUserSignUp = (values: SignUpFieldValues) => {
    setSignUpValues({
      ...values,
      user_type: userRole,
      user_token: userToken,
      referral_code: referralCode || partnerCode
    });
    if (values !== null)
      signUpUser({
        ...values,
        source,
        user_type: userRole,
        user_token: userToken,
        referral_code: referralCode || partnerCode
      });
  };

  const navigateToSignIn = () => {
    let additionalParams = '';
    if (userToken) additionalParams = `user_token=${userToken}`;
    if (source) additionalParams += `&source=${source}`;
    if (referralCode) additionalParams += `&referral_code=${referralCode}`;
    const route =
      userRole === USER_ROLE.PROFESSIONAL
        ? `${routesPath.TAX_PROFESSIONALS}/signin`
        : `${routesPath.SIGNIN}?${additionalParams}`;
    navigate(route);
  };

  const renderChildComponent = useCallback((): DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > => {
    if (signUpSuccess) {
      return (
        <CheckEmail
          email={signUpValues.email}
          handleResendClick={() => signUpUser(signUpValues)}
          handleBackClick={() => setSignUpSuccess(false)}
          isConfirmEmail={true}
        />
      );
    }
    return (
      <EmailPasswordSignUp
        handleValues={handleUserSignUp}
        isLoading={signUpResponse.isLoading}
        navigateToSignIn={navigateToSignIn}
        extraParams={{
          source,
          userToken,
          referralCode: referralCode || partnerCode
        }}
        role={userRole}
      />
    );
  }, [signUpSuccess, location.pathname, userRole]);

  return (
    <div className="flex w-full h-[-webkit-fill-available] min-h-full bg-white">
      {signUpResponse.isLoading && <CircularLoader isFullScreen={true} />}
      <div className="hidden relative justify-start items-end w-3/5 bg-alabaster rounded-l-lg sm:flex">
        <img
          src="/images/curvedBg.png"
          className="absolute w-full h-full"
          alt=""
        />
        <img
          src="images/SignIn.png"
          className="absolute inset-0 m-auto w-[90%]"
          alt="Sign In"
        />
      </div>
      <div className="flex overflow-y-auto justify-center w-full bg-white rounded-r-lg sm:w-2/5 customNormalScroll">
        <div className="flex flex-col items-center my-auto w-[72%] h-full sm:w-[60%]">
          {renderChildComponent()}
        </div>
      </div>
    </div>
  );
};

export default SignUp;
