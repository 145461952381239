import { useEffect } from 'react';

export default function useUpdateAppHeight() {
  useEffect(() => {
    window.addEventListener('resize', appHeight);
  }, []);

  const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  };
  appHeight();
}
