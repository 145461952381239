import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ReducerStateType } from './types';

const initialState = {
  transactionType: [],
  transactionTag: [],
  errorTag: [],
  source: [],
  currency: [],
  startDate: null,
  endDate: null,
  currentPage: 1,
  clearPage: true
} as ReducerStateType;

const filterDefaultValues: ReducerStateType = {
  transactionType: [],
  transactionTag: [],
  errorTag: [],
  source: [],
  currency: [],
  startDate: null,
  endDate: null
} as ReducerStateType;

export const transactionSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    updateFilterValue: (state, { payload }) => ({
      ...state,
      ...payload
    }),
    updatePage: (state, { payload }) => ({
      ...state,
      currentPage: payload.page
    }),
    resetPage: state => ({
      ...state,
      currentPage: 1
    }),
    updateTransactionClearPage: (
      state,
      { payload }: PayloadAction<boolean>
    ) => ({
      ...state,
      clearPage: payload
    }),
    clearFilter: state => ({
      ...state,
      transactionType: [],
      transactionTag: [],
      errorTag: [],
      source: [],
      currency: [],
      startDate: null,
      endDate: null
    }),
    resetAndUpdateFilter: (state, { payload }) => ({
      ...state,
      ...filterDefaultValues,
      ...payload
    })
  }
});

export const {
  updateFilterValue,
  updatePage,
  resetPage,
  updateTransactionClearPage,
  clearFilter,
  resetAndUpdateFilter
} = transactionSlice.actions;

export default transactionSlice.reducer;
