import { object, string } from 'yup';

import { translate } from '@utils/locale';

export const itrFormSchema = object({
  hasIncomeFromSalaries: string().required(translate('itr.requiredField')),
  hasIncomeFromHouseProperty: string().required(translate('itr.requiredField')),
  hasIncomeFromSale: string().required(translate('itr.requiredField')),
  hasIncomeFromBusiness: string().required(translate('itr.requiredField')),
  hasIncomeFromOtherSources: string().required(translate('itr.requiredField')),
  hasAgriculturalIncome: string().required(translate('itr.requiredField'))
});
