import { translate } from '@utils/locale';
import {
  appendClientIdInEndpoints,
  restartJobStatusPolling,
  getNotifierMessage
} from '@utils';
import { NotifierTypes, NOTIFIER_MESSAGE_TYPES } from '@constants/common';
import { GenericApiResponse } from 'types/generalTypes';
import {
  TransactionDetail,
  TrasactionDetailPayload
} from './transaction-detail/types';
import {
  TransactionsListQueryParams,
  DeletleTransactionParams,
  DeleteTransactionResponse,
  TransactionReport,
  TransactionReportPayload,
  EditTransactionResponse,
  EditTransactionPayload,
  TransactionErrorCountResponse,
  TransactionListResponse,
  JobStatusesData,
  FilterCount,
  UserSpecificExchangeList
} from './list-transactions/types';
import baseApi from '@services/api';

const TransactionBaseApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getUserTransaction: builder.query<
      TransactionListResponse,
      TransactionsListQueryParams
    >({
      query: ({ queryParams, page = 0, limit = 10, clientId }) => {
        const allArgs = { ...queryParams };
        Object.keys(allArgs).forEach(item => {
          if (!allArgs[item]) {
            delete allArgs[item];
          }
        });
        let url = `user/transaction?page=${page}&limit=${limit}`;
        Object.keys(queryParams).forEach(item => {
          if (queryParams[item]) {
            url = `${url}&${item}=${encodeURIComponent(queryParams[item])}`;
          }
        });
        return appendClientIdInEndpoints(url, clientId);
      },
      transformResponse: (
        response: GenericApiResponse<TransactionListResponse>
      ) => response.result,
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.getTransactionsError')
      },
      providesTags: ['GetTransactionList']
    }),

    getTransactionDetail: builder.query<
      TransactionDetail,
      TrasactionDetailPayload
    >({
      query: ({ transactionId, clientId }) =>
        appendClientIdInEndpoints(
          `/user/transaction/${transactionId}`,
          clientId
        ),
      transformResponse: (response: GenericApiResponse<TransactionDetail>) =>
        response?.result,
      providesTags: ['GetTransactionDetails']
    }),

    deleteTransaction: builder.mutation<
      DeleteTransactionResponse,
      DeletleTransactionParams
    >({
      query: ({ transactionId, clientId }) => ({
        url: appendClientIdInEndpoints(
          `/user/transaction/${transactionId}`,
          clientId
        ),
        method: 'DELETE'
      }),
      extraOptions: {
        showNotifier: true,
        failure: getNotifierMessage(NOTIFIER_MESSAGE_TYPES.deleteFailed),
        showCustomMessage: true,
        success: getNotifierMessage(
          NOTIFIER_MESSAGE_TYPES.deleteSuccess,
          'Transaction'
        ),
        successNotifierType: NotifierTypes.DELETE
      },
      onQueryStarted: (_, lifeCycleAPI) =>
        restartJobStatusPolling(lifeCycleAPI, [
          'GetTransactionErrorCount',
          'GetUserSpecificCoins',
          'GetUserSpecificExchanges',
          'GetTransactionList',
          'GetTransactionTypeFilterCount',
          'GetTransactionCount',
          'GetTransactionLimit'
        ])
    }),

    getTransactionReport: builder.query<
      GenericApiResponse<TransactionReport>,
      TransactionReportPayload
    >({
      query: ({ queryParams, clientId }) => {
        let url = 'user/transaction/report?';
        Object.keys(queryParams).forEach(item => {
          if (queryParams[item]) {
            url = `${url}&${item}=${encodeURIComponent(queryParams[item])}`;
          }
        });
        return appendClientIdInEndpoints(url, clientId);
      },
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.exportTransactionError')
      }
    }),

    editTransaction: builder.mutation<
      EditTransactionResponse,
      EditTransactionPayload
    >({
      query: ({ payload, transactionId, clientId }) => ({
        url: appendClientIdInEndpoints(
          `/user/transaction/${transactionId}`,
          clientId
        ),
        method: 'PUT',
        body: payload
      }),
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.editTransactionError'),
        success: translate('notifierMessage.editTransactionSuccess')
      },
      onQueryStarted: (_, lifeCycleAPI) =>
        restartJobStatusPolling(lifeCycleAPI, [
          'GetTransactionErrorCount',
          'GetUserSpecificCoins',
          'GetUserSpecificExchanges',
          'GetTransactionList',
          'GetTransactionTypeFilterCount'
        ])
    }),

    getTransactionErrorCount: builder.query<
      TransactionErrorCountResponse,
      string | void
    >({
      query: clientId =>
        appendClientIdInEndpoints('/user/transaction/missing/count', clientId),
      transformResponse: (
        response: GenericApiResponse<TransactionErrorCountResponse>
      ) => response?.result,
      providesTags: ['GetTransactionErrorCount']
    }),

    getJobStatuses: builder.query<JobStatusesData, string | void>({
      query: clientId =>
        appendClientIdInEndpoints(
          '/user/transaction/gain-or-loss/status',
          clientId
        ),
      transformResponse: (response: GenericApiResponse<JobStatusesData>) =>
        response?.result,
      providesTags: ['GetJobStatuses']
    }),

    getTransactionFilterCount: builder.query<
      FilterCount,
      TransactionsListQueryParams
    >({
      query: ({ queryParams, clientId }) => {
        const allArgs = { ...queryParams };
        Object.keys(allArgs).forEach(item => {
          if (!allArgs[item]) {
            delete allArgs[item];
          }
        });
        let url = 'user/transaction/type/count?';
        Object.keys(queryParams).forEach(item => {
          if (queryParams[item]) {
            url = `${url}&${item}=${encodeURIComponent(queryParams[item])}`;
          }
        });
        return appendClientIdInEndpoints(url, clientId);
      },
      transformResponse: (response: GenericApiResponse<FilterCount>) =>
        response.result,
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.getTransactionsError')
      },
      providesTags: ['GetTransactionTypeFilterCount']
    }),

    getUserSpecificExchanges: builder.query<
      UserSpecificExchangeList[],
      string | void
    >({
      query: clientId =>
        appendClientIdInEndpoints('/user/sources-linked', clientId),
      transformResponse: (
        response: GenericApiResponse<UserSpecificExchangeList[]>
      ) => response?.result,
      providesTags: ['GetUserSpecificExchanges']
    })
  })
});

export const {
  useGetTransactionDetailQuery,
  useLazyGetTransactionDetailQuery,
  useGetUserTransactionQuery,
  useDeleteTransactionMutation,
  useLazyGetTransactionReportQuery,
  useEditTransactionMutation,
  useGetTransactionErrorCountQuery,
  useGetJobStatusesQuery,
  useGetTransactionFilterCountQuery,
  useGetUserSpecificExchangesQuery
} = TransactionBaseApi;
