import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';
import { isNull } from 'lodash';

import { Accordian } from '@components';
import { INDIAN_RUPEE } from '@constants/common';
import { RootState } from '@store/reducers';
import { translate } from '@utils/locale';
import { getGainLossStyle } from '@utils/transactionUtils';
import { useLazyGetPortfolioAssetDetailQuery } from '@containers/dashboard/api';
import { CryptoPortfolioSkeleton } from '@containers/skeleton-loader';
import {
  formatDecimalPecentage,
  getFormattedNumbering,
  getNumberInIndianFormat
} from '@utils/generalUtils';
import {
  CryptoPortfolioMobileViewProps,
  PortfolioAssetData,
  PortfolioCryptoData
} from '../types';
import ImageFetcher from '@components/image-fetcher/ImageFetcher';
import DefaultCoinIcon from '@assets/icons/defaultCoinIcon.svg';
import CryptoExchangeDetailsCard from './crypto-exchange-details-card/CryptoExchangeDetailsCard';

const accordianStyleConfig = {
  accordianStyle: 'bg-white px-0 rounded-[10px] border',
  accordianHeadingWrapperStyle: 'flex-col',
  accordianIconStyle: 'sm:ml-6',
  accordianBodyHeightOffset: 50
};

const CryptoPortfolioMobileView: FC<CryptoPortfolioMobileViewProps> = props => {
  const [openCryptoAssetKey, setOpenCryptoAssetKey] = useState();
  const [cryptoAssetData, setCurrencyAssetData] =
    useState<PortfolioAssetData>();

  const { clientId } = useSelector((state: RootState) => state.rootReducer.app);

  const [getPortfolioAssetDetail, portfolioAssetDetailResponse] =
    useLazyGetPortfolioAssetDetailQuery();

  const handleCryptoAssetToggle = async (key, currencyCode) => {
    setOpenCryptoAssetKey(openCryptoAssetKey !== key ? key : null);

    if (!isNull(key) && openCryptoAssetKey !== key) {
      const assetDetail = await getPortfolioAssetDetail({
        assetCode: currencyCode,
        clientId
      }).unwrap();
      setCurrencyAssetData({ currency_code: currencyCode, assetDetail });
    }
  };
  const { cryptoData } = props;

  const cryptoAssetHeading = (cryptoAsset: PortfolioCryptoData) => {
    const {
      currency_code,
      currency_icon,
      quantity,
      market_value,
      gain_or_loss,
      gain_or_loss_percent,
      cost_basis
    } = cryptoAsset;

    return (
      <div className="px-5 pt-3.5 my-2 w-full text-xs font-normal text-slateGrey bg-white sm:px-6 sm:text-base">
        <div className="flex justify-between items-center mb-1">
          <div>{translate('dashboard.asset')}</div>
          <div className="font-semibold text-davyGrey">
            <div className="flex flex-row items-center w-1/5 font-semibold text-davyGrey">
              <ImageFetcher
                imageUrl={currency_icon}
                defaultImage={DefaultCoinIcon}
                imageStyle="w-auto max-w-[20px] h-auto max-h-[20px]"
              />
              <span className="ml-2">{currency_code}</span>
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-1">
          <div>{translate('dashboard.totalAssets')}</div>
          <div className="font-semibold text-davyGrey">
            <Tooltip
              title={`${getNumberInIndianFormat(
                Number(quantity),
                '',
                true
              )} ${currency_code}`}
              arrow={true}
              enterTouchDelay={0}
              placement="bottom-start">
              <div className="w-fit">
                {`${getNumberInIndianFormat(Number(quantity), '')}`}
              </div>
            </Tooltip>
          </div>
        </div>
        <div className="flex justify-between mb-1">
          <div>{translate('dashboard.mobileView.investedAmount')}</div>
          <div className="font-semibold text-davyGrey">
            <Tooltip
              title={getNumberInIndianFormat(
                Number(cost_basis),
                INDIAN_RUPEE.code,
                true
              )}
              arrow={true}
              enterTouchDelay={0}
              placement="bottom-start">
              <div className="w-fit">
                {getFormattedNumbering({
                  value: cost_basis,
                  showZeroValue: true
                })}
              </div>
            </Tooltip>
          </div>
        </div>
        <div className="flex justify-between mb-1">
          <div>{translate('dashboard.mobileView.marketValue')}</div>
          <div className="font-semibold text-davyGrey">
            <Tooltip
              title={getNumberInIndianFormat(
                Number(market_value),
                INDIAN_RUPEE.code,
                true
              )}
              arrow={true}
              enterTouchDelay={0}
              placement="bottom-start">
              <div className="w-fit">
                {getFormattedNumbering({ value: market_value })}
              </div>
            </Tooltip>
          </div>
        </div>
        <div className="flex justify-between mb-1">
          <div>{translate('dashboard.mobileView.unrealisedGainOrLoss')}</div>
          <div
            className={`text-davyGrey font-semibold ${getGainLossStyle(
              Number(gain_or_loss)
            )}`}>
            <Tooltip
              title={getNumberInIndianFormat(
                Number(gain_or_loss),
                INDIAN_RUPEE.code,
                true
              )}
              arrow={true}
              enterTouchDelay={0}
              placement="bottom-start">
              <div className="flex justify-end">
                {getFormattedNumbering({ value: gain_or_loss })}
              </div>
            </Tooltip>
            <span>{`(${formatDecimalPecentage(gain_or_loss_percent)})`}</span>
          </div>
        </div>
        <div className="mt-2.5 mb-3.5 border-b" />
      </div>
    );
  };

  const cryptoAssetBody = (cryptoAsset: PortfolioAssetData) => {
    const { currency_code, assetDetail } = cryptoAsset || {};
    const hasOnlyOneData = assetDetail?.length === 1;
    return (
      <div
        className={`flex overflow-x-auto mt-8
        ${
          (portfolioAssetDetailResponse?.isFetching && 'm-auto') ||
          (hasOnlyOneData ? 'ml-3' : 'ml-5')
        }`}>
        {portfolioAssetDetailResponse?.isFetching ? (
          <div className="w-full">
            <CryptoPortfolioSkeleton />
          </div>
        ) : (
          assetDetail?.map((exchange, index) => (
            <CryptoExchangeDetailsCard
              key={index}
              data={exchange}
              currencyCode={currency_code}
              hasOnlyOneData={hasOnlyOneData}
            />
          ))
        )}
      </div>
    );
  };

  return (
    <div className="mt-2">
      {cryptoData?.map((cryptoAsset, index) => (
        <div key={index} className="mb-2">
          <Accordian
            dataTestId="accordianHeading"
            styleConfig={accordianStyleConfig}
            contentConfig={{
              accordianHeading: cryptoAssetHeading(cryptoAsset),
              accordianBody: cryptoAssetBody(cryptoAssetData),
              handleOnClickHeading: () =>
                handleCryptoAssetToggle(index, cryptoAsset?.currency_code)
            }}
            conditionalFlag={{
              showDetails: openCryptoAssetKey === index
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default CryptoPortfolioMobileView;
