import React, { FC, useCallback, useState } from 'react';
import { debounce } from 'lodash';
import { Tooltip, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';

import { SearchBar, EmptyData, WalletCard, Paginator } from '@components';
import { RootState } from '@store/reducers';
import { NoSearchResult } from '@assets/images';
import { ConnectExchangeSkeleton } from '@containers/skeleton-loader';
import { ConnectWalletPopup } from '..';
import {
  useGetBlockchainsQuery,
  useGetBlockchainWalletsQuery
} from '@containers/connect-exchange/api';
import { eventKeyMapper } from '@constants/gaEvents';
import { ACCOUNT_TYPE, USER_ROLE } from '@constants/common';
import { gAEventTracker, translate } from '@utils';
import { useTopContentHeight } from '@hooks';
import {
  DEFI_CONNECTION_TYPE,
  WALLETS_PER_PAGE
} from '@containers/connect-exchange/constants';
import {
  BlockchainListType,
  WalletListType
} from '@containers/connect-exchange/types';
import { ConnectWalletPopupData } from '../types';

const WalletCardListing: FC = () => {
  const [searchText, setSearchText] = useState('');
  const [debounceSearchTerm, setDebounceSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [showConnectWalletPopup, setShowConnectWalletPopup] = useState(false);
  const [currentWalletData, setCurrentWalletData] =
    useState<ConnectWalletPopupData>(null);

  const { subscriptionDetails, clientId, userDetails, clientProfile } =
    useSelector((state: RootState) => ({
      ...state.rootReducer.subscriptionDetails,
      ...state.rootReducer.app,
      ...state.rootReducer.userDetails
    }));

  const topContentHeight = useTopContentHeight(!!clientId);
  const isMobileView = useMediaQuery('(max-width:640px)');

  const { data: walletList, isLoading: walletListLoading } =
    useGetBlockchainWalletsQuery({
      searchTerm: debounceSearchTerm,
      page: currentPage - 1,
      limit: WALLETS_PER_PAGE,
      clientId
    });
  const { data: blockchainList } = useGetBlockchainsQuery({
    searchTerm: debounceSearchTerm
  });

  const { result: walletItems, pagination } = walletList || {};

  const debounceSearch = useCallback(
    debounce(value => {
      setDebounceSearchTerm(value);
      handlePageChangeEvent(1);
    }, 300),
    [debounce]
  );

  const handlePageChangeEvent = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const getNoOfRecordsDisplayed = () => {
    return walletItems?.length || 0;
  };

  const getTotalCount = () => {
    return pagination?.total_rows;
  };

  const handleShowConnectWallet = (wallet: WalletListType) => {
    gAEventTracker(eventKeyMapper.walletCardClicked, `${wallet.name}:`);
    setShowConnectWalletPopup(true);
    setCurrentWalletData({
      imageUrl: wallet?.image_url?.lg,
      name: wallet?.name,
      walletId: wallet?.id,
      blockchainId: wallet?.exchange_id,
      type: DEFI_CONNECTION_TYPE.wallet
    });
  };

  const handleShowConnectBlockchain = (blockchain: BlockchainListType) => {
    gAEventTracker(eventKeyMapper.blockchainCardClicked, `${blockchain.name}:`);
    setShowConnectWalletPopup(true);
    setCurrentWalletData({
      imageUrl: blockchain?.icons.sm,
      name: blockchain?.name,
      blockchainId: blockchain?.id,
      type: DEFI_CONNECTION_TYPE.blockchain
    });
  };

  const handleSearchTextChange = useCallback(
    value => {
      debounceSearch(value);
      setSearchText(value);
    },
    [debounceSearch]
  );

  return walletListLoading ? (
    <ConnectExchangeSkeleton cardCount={3} />
  ) : (
    <>
      <div className="px-[14px] pt-6 w-full sm:px-6 md:w-[60%] lg:w-[40%] xl:w-[35%]">
        <SearchBar
          wrapperStyle="bg-white border-white"
          searchInputStyle="bg-white py-2"
          searchValue={searchText}
          handleChange={handleSearchTextChange}
        />
      </div>
      {walletItems?.length > 0 || blockchainList?.length > 0 ? (
        <>
          {blockchainList?.length > 0 && (
            <div className="px-[14px] pt-4 pb-2 w-full text-base font-semibold border-b border-greyCloud/50 sm:px-6">
              {translate('connectExchangePage.blockchain')}
              <div className="flex flex-wrap justify-center items-center pt-6 md:justify-start">
                {blockchainList?.map(blockchain => (
                  <div key={blockchain.id}>
                    <WalletCard
                      id={blockchain.id}
                      name={blockchain.name}
                      imageUrl={blockchain.icons.lg}
                      iconStyle="w-full"
                      isWallet={false}
                      handleCardClick={() =>
                        handleShowConnectBlockchain(blockchain)
                      }
                      isDisabled={
                        true ||
                        (userDetails.userType === USER_ROLE.PROFESSIONAL &&
                          clientProfile.type !== ACCOUNT_TYPE.MANAGED)
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          {walletItems?.length > 0 && (
            <div className="p-[14px] w-full text-base font-semibold sm:p-6">
              <div>{translate('connectExchangePage.wallets')}</div>
              <div
                className="flex overflow-y-auto flex-wrap justify-center
                items-center pt-6 pb-4 mb-4 md:justify-start md:max-h-[calc(100vh-525px)] customNormalScroll">
                {walletItems?.map(wallet => (
                  <div key={wallet.id}>
                    <Tooltip
                      title={wallet.name}
                      arrow={true}
                      enterTouchDelay={0}
                      placement="bottom">
                      <div className="w-fit">
                        <WalletCard
                          id={wallet.id}
                          name={wallet.name}
                          handleCardClick={() =>
                            handleShowConnectWallet(wallet)
                          }
                          imageUrl={wallet?.image_url?.lg}
                          isDisabled={
                            true ||
                            (userDetails.userType === USER_ROLE.PROFESSIONAL &&
                              clientProfile.type !== ACCOUNT_TYPE.MANAGED)
                          }
                        />
                      </div>
                    </Tooltip>
                  </div>
                ))}
              </div>
              {getTotalCount() > 0 && (
                <div className="text-sm font-medium text-slateGrey">
                  <Paginator
                    page={currentPage}
                    limit={WALLETS_PER_PAGE}
                    noOfRecordsDisplayed={getNoOfRecordsDisplayed()}
                    totalCount={getTotalCount()}
                    className={'justify-center sm:justify-end sm:mr-8 sm:ml-6'}
                    handlePageChangeEvent={handlePageChangeEvent}
                  />
                </div>
              )}
            </div>
          )}
          <ConnectWalletPopup
            showModal={showConnectWalletPopup}
            blockchainList={blockchainList}
            data={currentWalletData}
            handleClose={() => setShowConnectWalletPopup(false)}
            subscriptionType={subscriptionDetails?.subscriptionType}
          />
        </>
      ) : (
        <div className="flex px-[14px] pt-4 w-full sm:px-6">
          <EmptyData
            styleConfig={{
              height: isMobileView
                ? `calc(100vh - ${topContentHeight}px - 86px)`
                : `calc(100vh - ${topContentHeight}px - 94px)`,
              wrapperStyle: 'mb-2 sm:mb-4 min-h-[340px] tall:min-h-[400px]',
              imageStyle: 'w-[50%] sm:w-[250px] md:w-[300px] lg:w-[350px]',
              titleStyle:
                'text-primaryColor text-sm mt-1 sm:text-base font-semibold'
            }}
            contentConfig={{
              title: translate('sourcePage.noSearchResult'),
              highlightedImage: NoSearchResult,
              showPrimaryDescription: false,
              showSecondaryDescription: false
            }}
          />
        </div>
      )}
    </>
  );
};

export default WalletCardListing;
