import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Tooltip } from '@mui/material';

import { translate } from '@utils/locale';
import { TabBarProps } from './type';

const TabBar: FC<TabBarProps> = props => {
  const {
    selectedTab,
    handleTabSelect,
    tabBarItems,
    tabStyle,
    customHeight = 'h-[70px]',
    wrapperStyle,
    tooltipText = ''
  } = props;

  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  const tabsRef = useRef([]);

  useEffect(() => {
    const handleTabPositionChange = () => {
      const currentTab = tabsRef.current[selectedTab];
      setTabUnderlineLeft(currentTab?.offsetLeft);
      setTabUnderlineWidth(currentTab?.clientWidth);
    };
    handleTabPositionChange();
    window.addEventListener('resize', handleTabPositionChange);
    return () => window.removeEventListener('resize', handleTabPositionChange);
  }, [selectedTab]);

  const handleClick = (tabId: string, isActive: boolean) => {
    if (!isActive) {
      return;
    }
    document.getElementById(tabId).scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });
    handleTabSelect(tabId);
  };

  const getTextColor = useCallback(
    (tabId: string, isActive: boolean) => {
      if (selectedTab === tabId) {
        return 'text-primaryColor';
      } else if (!isActive) {
        return 'text-greyChateau cursor-not-allowed';
      }
      return 'text-slateGrey';
    },
    [selectedTab]
  );

  const getTabbarIcon = useCallback(
    tabItem => {
      if (tabItem?.isDisabled) return tabItem.disabledIcon;
      return selectedTab === tabItem.id
        ? tabItem.activeIcon
        : tabItem.inactiveIcon;
    },
    [selectedTab]
  );

  return (
    <div
      id="tabBar"
      className={`relative flex overflow-x-auto items-end ${customHeight}
    bg-white customTransparentScroll ${wrapperStyle ? wrapperStyle : ''}`}>
      {tabBarItems.map(tabItem => (
        <div
          id={tabItem.id}
          key={tabItem.id}
          ref={el => (tabsRef.current[tabItem.id] = el)}
          className={`flex  items-center ${tabItem.minWidth} ml-8 pb-[7px]
          ${
            tabItem.isActive && !tabItem.isDisabled
              ? 'cursor-pointer'
              : 'cursor-default'
          }
           ${selectedTab !== tabItem.id && 'border-white'} ${tabStyle}`}
          onClick={() =>
            handleClick(tabItem.id, tabItem.isActive && !tabItem.isDisabled)
          }>
          {getTabbarIcon(tabItem)}
          <Tooltip
            title={tooltipText}
            arrow={true}
            enterTouchDelay={0}
            disableHoverListener={!tabItem.isDisabled}
            disableTouchListener={!tabItem.isDisabled}>
            <div
              className={`ml-2 sm:text-base text-sm font-semibold ${getTextColor(
                tabItem.id,
                tabItem.isActive && !tabItem.isDisabled
              )}`}>
              {tabItem.label}
            </div>
          </Tooltip>
          {!tabItem.isActive && (
            <div className="px-1 ml-2 text-[10px] font-normal text-center text-primaryColor bg-harp rounded-md">
              {translate('comingSoon')}
            </div>
          )}
        </div>
      ))}
      <span
        className={
          'absolute h-[2px] bg-primaryColor transition-all duration-300'
        }
        style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
      />
    </div>
  );
};

export default TabBar;
