import React, { FC, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import { AttachmentIcon, CloseIcon } from '@assets/icons';
import { TabBar, SideModal } from '@components';
import { TAB_BAR_CONSTANTS, TAB_BAR_ITEMS } from '@constants/tabBar';
import { translate } from '@utils/locale';
import { computeLinkedViaLabel, getLinkedViaDetails } from '@utils/sourceUtils';
import { EXCHANGE_INFO } from '@containers/source/constants';
import { GENERIC_FILE } from '@constants/connectExchangeConstants';
import { ConnectExchangePopUpProps } from '../types';
import COLORS from '@constants/colors';
import ApiLinkingSection from './ApiLinkingSection';
import ImportFilePopup from './ImportFileSection';

const ConnectExchangePopup: FC<ConnectExchangePopUpProps> = props => {
  const {
    showModal,
    exchangeData,
    handleAddExchange,
    exchangeLinkingDetails,
    handleClose,
    isSubmittingAddExchange,
    handleNewConnection,
    isEdit,
    clientId
  } = props;

  const [selectedTab, setSelectedTab] = useState<string>();
  const [tabBarItems, setTabBarItems] = useState(
    TAB_BAR_ITEMS.connectExchangePopup
  );

  useEffect(() => {
    if (exchangeData) {
      const availableTabBarItems = [];
      if (exchangeData.is_active_by_import) {
        availableTabBarItems.push({
          ...TAB_BAR_ITEMS.connectExchangePopup.find(
            data => data.id === TAB_BAR_CONSTANTS.IMPORT_FILE
          ),
          isDisabled:
            isEdit &&
            exchangeData.is_active_by_api &&
            exchangeData.is_active_by_import &&
            linkedViaAPI
        });
      }
      if (exchangeData.is_active_by_api || exchangeData.is_active_by_oauth) {
        const isExchangeNotLinkedViaAPI = isEmpty(
          exchangeLinkingDetails?.find(details => {
            return details.exchange_id === exchangeData.id && details.via_api;
          })
        );
        availableTabBarItems.push({
          ...TAB_BAR_ITEMS.connectExchangePopup.find(
            data => data.id === TAB_BAR_CONSTANTS.ADD_EXCHANGE
          ),
          isDisabled:
            !(
              isEdit ||
              isExchangeNotLinkedViaAPI ||
              exchangeData.is_active_by_oauth
            ) ||
            (linkedViaFile &&
              exchangeData?.is_active_by_api &&
              exchangeData?.is_active_by_import)
        });
      }
      const selectedTabId = availableTabBarItems.filter(
        items => !items.isDisabled
      )[0]?.id;
      setSelectedTab(selectedTabId);
      setTabBarItems([...availableTabBarItems]);
    }
  }, [exchangeData]);

  const handleTabSelect = (tabId: string) => {
    setSelectedTab(tabId);
  };

  const linkedVia = computeLinkedViaLabel(
    exchangeData?.id,
    exchangeLinkingDetails
  );

  const { linkedViaFile, linkedViaAPI } = getLinkedViaDetails(
    exchangeData?.id,
    exchangeLinkingDetails
  );

  const renderContent = () => {
    switch (selectedTab) {
      case TAB_BAR_CONSTANTS.ADD_EXCHANGE:
        return (
          <ApiLinkingSection
            handleClose={handleClose}
            exchangeData={exchangeData}
            submitHandler={handleAddExchange}
            isLoading={isSubmittingAddExchange}
            isConnectingViaAuth={exchangeData?.is_active_by_oauth}
            setAddedExchangeData={handleNewConnection}
            isEdit={isEdit}
            clientId={clientId}
          />
        );
      case TAB_BAR_CONSTANTS.IMPORT_FILE:
        return (
          <ImportFilePopup
            handleClose={handleClose}
            exchangeData={exchangeData}
          />
        );
      default:
        return (
          <div className="flex justify-center items-center">
            {translate('comingSoon')}
          </div>
        );
    }
  };

  const getTooltipText = () => {
    if (exchangeData?.is_active_by_import && exchangeData?.is_active_by_api)
      return translate(
        linkedViaFile
          ? 'connectExchangePage.fileUploadWarning'
          : 'connectExchangePage.apiWarning',
        {
          exchangeName: exchangeData?.name
        }
      );
    return '';
  };

  const ExchangeIcon = EXCHANGE_INFO[exchangeData?.code]?.icon;

  const closeIcon = (
    <span
      onClick={handleClose}
      className={`flex absolute ${
        exchangeData?.code !== GENERIC_FILE.code ? 'top-0' : 'top-3'
      } 
      right-6 z-10 justify-center items-center w-7
       h-7 bg-whiteSmoke rounded-full cursor-pointer`}>
      <CloseIcon fill={COLORS.SLATE_GREY} stroke={COLORS.SLATE_GREY} />
    </span>
  );

  return (
    <div>
      <SideModal isOpen={showModal} handleClose={handleClose}>
        <div
          className={`flex justify-center items-center self-center
                    w-full h-full bg-transparent
                     ${
                       showModal
                         ? 'animate-zoom-to-front'
                         : 'animate-zoom-to-back'
                     }`}
          onClick={e => e.stopPropagation()}>
          <div
            className="flex overflow-auto relative flex-col  items-center w-[100%]
         max-h-full bg-white outline-none sm:overflow-hidden sm:w-[80%] sm:h-auto sm:max-h-[800px]
          sm:rounded-xl md:w-[65%] md:max-w-[1000px]">
            <div
              className={`flex relative flex-row justify-between 
            ${
              exchangeData?.code !== GENERIC_FILE.code ? 'px-6 mt-6' : ''
            } w-full`}>
              {exchangeData?.code !== GENERIC_FILE.code && (
                <span className="flex items-center">
                  {ExchangeIcon && (
                    <span className="flex w-[140px] h-[27px]">
                      <ExchangeIcon className="w-auto h-auto" />
                    </span>
                  )}
                  {linkedVia !== '' && (
                    <span className="flex flex-row items-center w-full">
                      <AttachmentIcon className="mr-1 ml-[11px] w-4 h-4" />
                      <span className="flex w-full text-xs font-normal text-greenHaze">
                        {linkedVia}
                      </span>
                    </span>
                  )}
                </span>
              )}
              {closeIcon}
            </div>
            <div
              className={`flex flex-col mt-[14px] 
            ${exchangeData?.code === GENERIC_FILE.code ? 'sm:mb-[7px]' : ''}
            w-full h-full`}>
              {exchangeData?.code !== GENERIC_FILE.code && (
                <TabBar
                  selectedTab={selectedTab}
                  handleTabSelect={handleTabSelect}
                  tabBarItems={tabBarItems}
                  tabStyle="flex justify-start ml-2 "
                  customHeight="h-[36px]"
                  wrapperStyle="pr-4"
                  tooltipText={getTooltipText()}
                />
              )}
              <div
                className={`
              ${
                exchangeData?.code !== GENERIC_FILE.code
                  ? 'border-t border-mercury'
                  : ''
              }`}>
                {renderContent()}
              </div>
            </div>
          </div>
        </div>
      </SideModal>
    </div>
  );
};

export default ConnectExchangePopup;
