import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';

import {
  Button,
  Input,
  InstructionSection,
  NotesSection,
  StringToTSX
} from '@components';
import { EXCHANGE_INFO } from '@containers/source/constants';
import { useLazyGetAuthRedirectionURLQuery } from '@containers/source/api';
import { translate } from '@utils/locale';
import { gAEventTracker } from '@utils/gaUtils';
import { eventKeyMapper } from '@constants/gaEvents';
import { CSRF_TOKEN_FOR_EXCHANGE_AUTH } from '@constants/common';
import { DangerIcon, RefreshIcon } from '@assets/icons';
import { AddExchangePayload } from '@containers/connect-exchange/types';
import { ApiLinkingPopupProps } from '../types';

const ApiLinkingSection: FC<ApiLinkingPopupProps> = props => {
  const {
    handleClose,
    exchangeData,
    isEdit = false,
    setAddedExchangeData = () => null,
    submitHandler,
    isLoading,
    isConnectingViaAuth = false,
    clientId
  } = props;

  const { id, code, conf, name } = exchangeData;

  const [getAuthRedirectionURL] = useLazyGetAuthRedirectionURLQuery();

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
    setValue
  } = useForm();

  useEffect(() => {
    // unmount clean-up
    return () => {
      reset();
    };
  }, []);

  useEffect(() => {
    if (isEdit) {
      Object.keys(conf).map(key => {
        setValue(key, exchangeData[key]);
      });
    }
  }, [isEdit, exchangeData]);

  const formSubmitHandler = values => {
    let payload = {
      exchange_id: id
    } as unknown as AddExchangePayload;
    Object.keys(values).forEach(key => {
      payload = {
        ...payload,
        ...{ [key]: values[key]?.trim() }
      };
    });
    submitHandler({
      payload,
      ...(isEdit && { exchangeId: id, clientId })
    });
    gAEventTracker(
      eventKeyMapper.connectExchangeViaAPIKey,
      `${exchangeData.name}: `
    );
    setAddedExchangeData({ ...exchangeData, ...getValues() });
  };

  const handleAuthConnect = async () => {
    try {
      const response = await getAuthRedirectionURL({
        exchangeId: id,
        clientId
      }).unwrap();
      gAEventTracker(
        eventKeyMapper.connectExchangeViaOauth,
        `${exchangeData.name}:`
      );
      // storing CSRF token for comparison
      window.localStorage.setItem(CSRF_TOKEN_FOR_EXCHANGE_AUTH, response.state);
      // Redirects to the exchanges auth screen
      window.open(response.oauth_url, '_self');
    } catch (error) {
      handleClose();
      window.localStorage.removeItem(CSRF_TOKEN_FOR_EXCHANGE_AUTH);
      throw error;
    }
  };

  return (
    <div
      className="flex overflow-auto flex-col py-4 px-6 mb-2 ml-0 w-full h-full sm:mr-6 sm:h-full sm:max-h-[650px] 
      md:flex-row md:px-6 md:h-auto customNormalScroll">
      <div className="md:pr-4">
        <form onSubmit={handleSubmit(formSubmitHandler)}>
          <div className="flex relative flex-row flex-wrap">
            <div
              className="flex after:absolute order-1 w-full after:h-full sm:after:bottom-[10%] sm:after:left-[45%]
              sm:pr-4 sm:w-[45%] sm:after:border-l-[1px] sm:after:border-greyCloud sm:after:border-dashed">
              <div className="flex flex-col w-full">
                <p className="mb-4 text-2xl font-semibold">
                  {translate(
                    isEdit && !isConnectingViaAuth
                      ? 'sourcePage.editSource'
                      : 'sourcePage.importTransactions'
                  )}
                </p>
                {isConnectingViaAuth ? (
                  <div className="flex flex-col">
                    <div className="mt-2 text-sm text-comet whitespace-pre-line">
                      {translate('sourcePage.authConnectSubText')}
                    </div>
                    <Button
                      className="py-2 px-4 mt-8 max-w-[160px] text-sm text-white bg-primaryColor"
                      label={translate('sourcePage.connectExchange')}
                      onClick={handleAuthConnect}
                    />
                  </div>
                ) : (
                  <>
                    {Object.keys(conf).map(key => (
                      <div key={key} className="mb-4">
                        <Input
                          name={key}
                          placeholder={conf[key]}
                          register={register}
                          errors={errors}
                          validation={{
                            required: translate('required'),
                            validate: (value: string) =>
                              !value.trim() ? translate('required') : true
                          }}
                        />
                        <div className="mt-2 text-sm text-comet">
                          {EXCHANGE_INFO[code]?.label[key]}
                        </div>
                      </div>
                    ))}
                    {!EXCHANGE_INFO[code].isOutOfOrder && (
                      <button
                        type="submit"
                        className="flex justify-center py-2 px-4 mt-6 w-[160px] text-sm text-white 
                            whitespace-nowrap bg-primaryColor rounded-md disabled:opacity-50 sm:mr-4"
                        disabled={isLoading}>
                        {isLoading ? (
                          <RefreshIcon className="animate-spin" />
                        ) : (
                          translate(
                            isEdit
                              ? 'sourcePage.updateSource'
                              : 'sourcePage.importTransaction'
                          )
                        )}
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="order-3 pl-1 sm:order-2 sm:pl-4 sm:w-[55%]">
              <InstructionSection
                exchangeCode={code}
                type="exchangeLink"
                heading={translate('sourcePage.howToLinkAccount', {
                  exchangeName: name
                })}
              />
              {!isEmpty(EXCHANGE_INFO[code].notes) && (
                <NotesSection
                  exchangeCode={code}
                  showBorder={false}
                  noteType="notes"
                />
              )}
            </div>
            <div
              className="flex flex-col-reverse order-2 justify-center items-start pb-4 mr-4
              w-full h-auto bg-white border-b-[1px] border-greyCloud border-dashed sm:flex-row
              sm:order-3 sm:justify-start sm:items-center sm:pb-0 sm:border-0 md:mr-0">
              {EXCHANGE_INFO[code].isOutOfOrder && (
                <>
                  <button
                    className="flex justify-center py-2 px-4 mt-6 w-[160px] text-sm text-white 
                            whitespace-nowrap bg-primaryColor rounded-md disabled:opacity-50 sm:mr-4"
                    disabled={true}>
                    {translate('sourcePage.importTransaction')}
                  </button>
                  <div className="relative z-50 py-3 bg-white">
                    <div className="flex flex-row items-start p-4 bg-amour rounded-md sm:items-center">
                      <DangerIcon width={18} height={18} />
                      <div className="ml-4 w-[80%] text-sm">
                        <StringToTSX
                          domString={translate(
                            'sourcePage.exchangeOutOfOrderText',
                            {
                              exchangeName: name
                            }
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ApiLinkingSection;
