import React, { FC, useEffect, useState } from 'react';

import { RefreshBlue } from '@assets/icons';
import { getTableDisplayData } from './utils';
import { SyncRequestStatus } from '@constants/mySources';
import { ActionButtons } from '@components';
import { ExchangeListViewRowProps } from './types';

const ListViewMobileRow: FC<ExchangeListViewRowProps> = props => {
  const {
    ExchangeIcon,
    exchangeId,
    exchangeSyncStatus,
    lastSync,
    handleSyncClick,
    isSyncDisabled,
    actionItems,
    handleTryAgain = () => null,
    isActionsDisabled = false
  } = props;

  const [syncButtonEnabled, setSyncButtonEnabled] = useState<boolean>(true);

  useEffect(() => {
    if (exchangeSyncStatus) {
      setSyncButtonEnabled(
        [SyncRequestStatus.COMPLETED, SyncRequestStatus.DEAD].includes(
          exchangeSyncStatus
        )
      );
    }
  }, [exchangeSyncStatus]);

  return (
    <div className="flex justify-between py-4 px-6 mb-2 w-full bg-white rounded-md">
      <div className="flex flex-col justify-between w-full">
        <div className="flex justify-between items-center">
          {ExchangeIcon && (
            <div className="max-w-[94px] h-[20px]">
              <ExchangeIcon className="w-full h-full" />
            </div>
          )}
          <div className="flex">
            <div
              className={`flex ${
                exchangeSyncStatus !== SyncRequestStatus.AUTH_EXPIRED &&
                (!syncButtonEnabled || isSyncDisabled)
                  ? 'cursor-default opacity-30 pointer-events-none'
                  : 'cursor-pointer'
              }`}>
              <ActionButtons
                actionItems={actionItems}
                iconClass="w-[17px] h-[20px] pb-[5px]"
                customStyle={`${
                  isActionsDisabled ? 'cursor-not-allowed opacity-50' : ''
                }`}
                isDisabled={isActionsDisabled}
              />
            </div>
            <button
              className="ml-2 disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={handleSyncClick}
              disabled={
                !syncButtonEnabled || isSyncDisabled || isActionsDisabled
              }>
              <RefreshBlue className="pb-[5px] w-[17px] h-[20px]" />
            </button>
          </div>
        </div>
        <div className="flex flex-col justify-center items-start mt-3 text-sm text-slateGrey">
          {getTableDisplayData(
            exchangeId,
            exchangeSyncStatus,
            lastSync,
            handleTryAgain,
            isActionsDisabled
          )}
        </div>
      </div>
    </div>
  );
};

export default ListViewMobileRow;
