import { appendClientIdInEndpoints, translate } from '@utils';
import {
  PortfolioAsset,
  PortfolioAssetDetail,
  PortfolioAssetDetailPayload,
  PortfolioAssetDetailResponse,
  PortfolioAssetResponse,
  PortfolioNft,
  PortfolioNftResponse,
  PortfolioSource,
  PortfolioSourceResponse
} from './components/types';
import baseApi from '@services/api';

const DashboardBaseApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getPortfolioAsset: builder.query<PortfolioAsset, string | void>({
      query: clientId => ({
        url: appendClientIdInEndpoints(
          'user/portfolio/holdings/asset',
          clientId
        ),
        method: 'GET'
      }),
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.assetsFailure')
      },
      transformResponse: (response: PortfolioAssetResponse) => response.result,
      providesTags: ['GetPortfolioAsset']
    }),
    getPortfolioNft: builder.query<PortfolioNft[], string | void>({
      query: clientId => ({
        url: appendClientIdInEndpoints('user/portfolio/holdings/nft', clientId),
        method: 'GET'
      }),
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.assetsFailure')
      },
      transformResponse: (response: PortfolioNftResponse) => response.result
    }),
    getPortfolioAssetDetail: builder.query<
      PortfolioAssetDetail[],
      PortfolioAssetDetailPayload
    >({
      query: ({ assetCode, clientId }) => ({
        url: appendClientIdInEndpoints(
          `user/portfolio/holdings/asset/${assetCode}`,
          clientId
        ),
        method: 'GET'
      }),
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.assetsFailure')
      },
      transformResponse: (response: PortfolioAssetDetailResponse) =>
        response.result
    }),
    getPortfolioSource: builder.query<PortfolioSource, string | void>({
      query: clientId => ({
        url: appendClientIdInEndpoints(
          'user/portfolio/holdings/source',
          clientId
        ),
        method: 'GET'
      }),
      transformResponse: (response: PortfolioSourceResponse) => response.result
    })
  })
});

export const {
  useGetPortfolioAssetQuery,
  useGetPortfolioNftQuery,
  useLazyGetPortfolioAssetDetailQuery,
  useGetPortfolioSourceQuery
} = DashboardBaseApi;
