import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import { CircularSkeleton, TextSkeleton } from '@components';

const ProfessionalListingSkeleton = () => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  const arrayLength = isMobileView ? 3 : 5;

  return (
    <div className="overflow-hidden p-6 w-full mui-md:pt-[64px]">
      <div className="flex overflow-hidden flex-col w-full">
        {[...Array(arrayLength)].map((card, index) => (
          <div key={index}>
            <div
              className="hidden items-center p-2 mb-[12px] 
                h-[62px] bg-white rounded-md mui-md:flex">
              <div className="flex flex-row items-center mr-2 w-[230px] lg:w-[25%]">
                <CircularSkeleton height={30} width={30} />
                <TextSkeleton
                  width={150}
                  customStyles={{ marginLeft: '8px' }}
                />
              </div>
              <div className="flex flex-col w-[450px] lg:w-[25%]">
                <TextSkeleton fontSize="14px" width={125} />
              </div>
              <div className="flex flex-col w-[450px] lg:w-[25%]">
                <TextSkeleton fontSize="14px" width={125} />
              </div>
              <div className="flex flex-col mr-2 w-[280px] lg:w-[15%]">
                <TextSkeleton fontSize="14px" width={50} />
              </div>
              <div className="flex flex-row items-center w-[280px] lg:w-[10%]">
                <CircularSkeleton height={24} width={24} />
              </div>
            </div>

            <div className="flex flex-col p-[22px] mb-[10px] bg-white  rounded-md mui-md:hidden">
              <div className="flex flex-row justify-between mb-4">
                <TextSkeleton fontSize="12px" width={80} />
                <TextSkeleton fontSize="12px" width={110} />
              </div>
              <div className="flex flex-row justify-between mb-4">
                <TextSkeleton
                  fontSize="12px"
                  customStyles={{ height: '18px' }}
                  width={80}
                />
                <TextSkeleton fontSize="12px" width={110} />
              </div>
              <div className="flex flex-row justify-between mb-4">
                <TextSkeleton
                  fontSize="12px"
                  customStyles={{ height: '18px' }}
                  width={80}
                />
                <TextSkeleton fontSize="12px" width={110} />
              </div>
              <div className="flex flex-row justify-between mb-4">
                <TextSkeleton fontSize="12px" width={80} />
                <TextSkeleton fontSize="12px" width={50} />
              </div>
              <div className="flex flex-row justify-between">
                <TextSkeleton fontSize="12px" width={80} />
                <CircularSkeleton height={24} width={24} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProfessionalListingSkeleton;
