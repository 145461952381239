export const LINK_WALLET_STEPS = [
  'Copy the public wallet address of the required blockchain.',
  'Paste the public wallet address here.',
  'Enter a nickname for the wallet. \n\n You are ready to go!'
];

export const WALLETS_PER_PAGE = 36;

export const DEFI_CONNECTION_TYPE = {
  wallet: 'wallet',
  blockchain: 'blockchain'
};
