import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IconButton } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  EyeCrossedIcon,
  EyeIcon,
  GoogleIcon,
  BetaLogoVertical
} from '@assets/icons';
import { Button, TextField } from '@components';
import {
  GOOGLE_AUTH_REDIRECTION_URL,
  IS_AUTHENTICATED,
  USER_ROLE
} from '@constants/common';
import { clearClientDetails, translate } from '@utils';
import { signUpFormSchema, signUpFormSchemaForCA } from '../validate';
import { EmailPasswordProps, SignUpFieldValues } from '../types';

const EmailPasswordSignUp: FC<EmailPasswordProps> = props => {
  const { handleValues, isLoading, navigateToSignIn, role, extraParams } =
    props;

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<SignUpFieldValues>({
    defaultValues: {
      companyName: '',
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: ''
    },
    resolver: yupResolver(
      role === USER_ROLE.PROFESSIONAL ? signUpFormSchemaForCA : signUpFormSchema
    )
  });

  useEffect(() => {
    // Reset form on unmount
    return () => {
      reset();
    };
  }, []);

  const googleSignInButtonCLick = () => {
    localStorage.setItem(IS_AUTHENTICATED, 'true'); //Assuming that user will login and become valid.
    clearClientDetails();
    let additionalParams = '';
    if (extraParams?.userToken)
      additionalParams = `&user_token=${extraParams.userToken}`;
    if (extraParams?.source)
      additionalParams += `&source=${extraParams.source}`;
    if (extraParams?.referralCode)
      additionalParams += `&referral_code=${extraParams.referralCode}`;
    location.replace(
      `${GOOGLE_AUTH_REDIRECTION_URL}&user_type=${role}${additionalParams}`
    );
  };

  const changeShowPasswordState = () => {
    setShowPassword(currState => !currState);
  };

  const changeShowConfirmPasswordState = () => {
    setShowConfirmPassword(currState => !currState);
  };

  const restrictCopyPaste = e => {
    e.preventDefault();
    return false;
  };

  return (
    <div className="flex flex-col items-center my-auto w-full">
      <BetaLogoVertical className="shrink-0 mx-auto mt-4 w-[60%] sm:w-[100%]" />
      {role === USER_ROLE.PROFESSIONAL && (
        <div className="mb-11 text-[18px] font-semibold text-center text-lemonGrass">
          {translate('ca.forTaxProfessionals')}
        </div>
      )}
      {role === USER_ROLE.PERSONAL && (
        <>
          <button
            onClick={googleSignInButtonCLick}
            disabled={false}
            className={
              'flex flex-row items-center mt-11 w-full rounded-md border shadow-shadowBottom hover:opacity-90'
            }
            data-testid="googleSignInButton">
            <div className="flex items-center p-4 rounded-r border-r">
              <GoogleIcon />
            </div>
            <div className="flex justify-center items-center px-6 w-full text-base">
              {translate('signInWithGoogle')}
            </div>
          </button>
          <div className="flex overflow-hidden flex-row justify-center items-center my-7 w-full">
            <span className="flex basis-[100%] grow-0 shrink-0 mx-2 ml-8 w-full h-[1px] bg-slateGrey" />
            <span className="flex text-slateGrey">or</span>
            <span className="flex basis-[100%] grow-0 shrink-0 mx-2 mr-8 w-full h-[1px] bg-slateGrey" />
          </div>
        </>
      )}
      <form className="w-full" onSubmit={handleSubmit(handleValues)}>
        {role === USER_ROLE.PROFESSIONAL && (
          <div className="my-6">
            <TextField
              placeholder={translate('ca.companyName')}
              name="companyName"
              autoComplete="off"
              control={control}
              errors={errors}
              isControlledField={true}
              InputLabelProps={{ style: { fontSize: 14 } }}
              InputProps={{ style: { height: 54 } }}
              testid="signUpCompanyName"
            />
          </div>
        )}
        <div className="my-6">
          <TextField
            placeholder={translate('signUpPage.firstName')}
            name="firstName"
            autoComplete="off"
            control={control}
            errors={errors}
            isControlledField={true}
            InputLabelProps={{ style: { fontSize: 14 } }}
            InputProps={{ style: { height: 54 } }}
            testid="signUpFirstName"
          />
        </div>
        <div className="my-6">
          <TextField
            placeholder={translate('signUpPage.lastName')}
            name="lastName"
            autoComplete="off"
            control={control}
            errors={errors}
            isControlledField={true}
            InputLabelProps={{ style: { fontSize: 14 } }}
            InputProps={{ style: { height: 54 } }}
            testid="signUpLastName"
          />
        </div>
        <div className="my-6">
          <TextField
            placeholder={translate('email')}
            autoComplete="off"
            name="email"
            control={control}
            errors={errors}
            type={'email'}
            isControlledField={true}
            InputLabelProps={{ style: { fontSize: 14 } }}
            InputProps={{ style: { height: 54 } }}
            testid="signUpEmail"
          />
        </div>
        <div className="my-6">
          <TextField
            placeholder={translate('password')}
            name="password"
            autoComplete="new-password"
            errors={errors}
            type={showPassword ? 'text' : 'password'}
            control={control}
            isControlledField={true}
            InputLabelProps={{ style: { fontSize: 14 } }}
            showInfoIcon={true} //for showing eye icon
            endAdornment={
              <IconButton onClick={changeShowPasswordState}>
                {showPassword ? <EyeCrossedIcon /> : <EyeIcon />}
              </IconButton>
            }
            InputProps={{ style: { height: 54 } }}
            testid="signUppassword"
          />
        </div>
        <div className="my-6">
          <TextField
            placeholder={translate('resetPasswordPage.confirmPassword')}
            name="confirmPassword"
            autoComplete="new-password"
            errors={errors}
            type={showConfirmPassword ? 'text' : 'password'}
            control={control}
            onCut={restrictCopyPaste}
            onCopy={restrictCopyPaste}
            onPaste={restrictCopyPaste}
            isControlledField={true}
            InputLabelProps={{ style: { fontSize: 14 } }}
            showInfoIcon={true} //for showing eye icon
            endAdornment={
              <IconButton onClick={changeShowConfirmPasswordState}>
                {showConfirmPassword ? <EyeCrossedIcon /> : <EyeIcon />}
              </IconButton>
            }
            InputProps={{ style: { height: 54 } }}
            testid="signUpConfirmPassword"
          />
        </div>
        <Button
          label={translate('signUpPage.signUp')}
          className="flex justify-center items-center w-full h-[54px] bg-primaryColor"
          labelClass="text-white"
          disabled={isLoading}
          type="submit"
          dataTestId="signUpButton"
        />
      </form>
      <div className="pb-[60px] my-3 w-full text-davyGrey sm:pb-0">
        {translate('signUpPage.alreadyHaveAccount')}
        <span
          className="ml-1 text-primaryColor cursor-pointer"
          onClick={navigateToSignIn}
          data-testid="navigate-to-sign-in">
          {translate('signInPage.signIn')}
        </span>
      </div>
    </div>
  );
};

export default EmailPasswordSignUp;
