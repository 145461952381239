import React, { FC } from 'react';
import { Tooltip } from '@mui/material';

import { EXCHANGE_INFO } from '@containers/source/constants';
import { LinkIcon } from '@assets/icons';
import { translate } from '@utils/locale';
import { eventKeyMapper } from '@constants/gaEvents';
import { gAEventTracker } from '@utils/gaUtils';
import { ExchangeCardProps } from '@containers/source/types';

const ExchangeCard: FC<ExchangeCardProps> = props => {
  const {
    data,
    handleCardClick,
    isLinkedViaApi,
    isAlreadyLinked = false,
    linkedVia = '',
    isComingSoon,
    isDisabled = false
  } = props;

  const ExchangeIcon = EXCHANGE_INFO[data?.code]?.icon;

  const handleClick = () => {
    if (isComingSoon || isAlreadyLinked || isDisabled) {
      return;
    }
    gAEventTracker(eventKeyMapper.exchangeCardClick, data.name);
    handleCardClick();
  };

  return (
    <Tooltip
      title={
        isLinkedViaApi && data.is_active_by_api && data.is_active_by_import
          ? translate('connectExchangePage.apiWarning', {
              exchangeName: data.name
            })
          : ''
      }
      arrow={true}
      enterTouchDelay={0}>
      <div
        key={data.id}
        className={`flex relative flex-row justify-center items-center px-12 mr-4 mb-[14px] sm:mb-4 w-[200px] h-[60px]
       bg-white rounded-md ${
         !isComingSoon && !isAlreadyLinked && !isLinkedViaApi && !isDisabled
           ? 'cursor-pointer sm:hover:shadow-md transition sm:duration-300 sm:hover:-translate-y-1'
           : 'cursor-not-allowed opacity-80'
       } shadow-shadowBottomTwlightLite`}
        onClick={handleClick}
        data-testid="exchangeCard">
        {ExchangeIcon && <ExchangeIcon width="100%" height="100%" />}
        {linkedVia && (
          <div
            className="flex absolute bottom-[2px] justify-center items-center w-[98%] h-[14px] text-xs text-greenHaze
           bg-harp rounded-b-md">
            <LinkIcon className="mr-1" />
            {linkedVia}
          </div>
        )}
        {isComingSoon && (
          <div
            className="flex absolute bottom-[2px] justify-center items-center w-[98%] h-[14px] text-xs
          text-primaryColor bg-harp rounded-b-md">
            {translate('comingSoon')}
          </div>
        )}
      </div>
    </Tooltip>
  );
};

export default ExchangeCard;
