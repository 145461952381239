import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

import { translate, setUserLogout } from '@utils';
import { Button, ConfirmationPopup } from '@components';
import { RootState } from '@store/reducers';
import { BetaLogo } from '@assets/icons';
import { WorkInProgress } from '@assets/images';
import { API_RESPONSE_STATUS, DATE_FORMAT } from '@constants/common';
import { useLogoutUserMutation } from '@containers/login-flow/sign-in/api';
import { useRecoverAccountMutation } from './api';

const AccountRecovery = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [userLogout] = useLogoutUserMutation();
  const [recoverAccount, { isLoading: isRecoveringAccount }] =
    useRecoverAccountMutation();

  const { userDetails } = useSelector(
    (state: RootState) => state.rootReducer.userDetails
  );

  const getFormattedDate = () => {
    if (userDetails.hardDeletedAt) {
      const formattedDate = new Date(userDetails.hardDeletedAt);
      return format(formattedDate, DATE_FORMAT);
    }
    return '';
  };

  const handleLogout = async () => {
    const response = await userLogout().unwrap();
    if (response.status === API_RESPONSE_STATUS.OK) setUserLogout();
  };

  const handleRecoverAccountClick = () => {
    recoverAccount();
    setIsPopupOpen(false);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
      <div
        className="flex items-center px-[14px] w-full min-h-[100px] bg-white  border-b-[1px] 
                border-mercury sm:px-6">
        <div className="flex items-center">
          <BetaLogo />
        </div>
      </div>
      <div
        className="flex flex-col justify-center items-center m-[14px] h-[calc(100vh-150px)] min-h-[400px]
          text-sm bg-white rounded-md sm:min-h-[600px] md:m-6">
        <div className="flex justify-center mb-[20px] w-[50%] sm:mb-0 sm:w-[300px] md:w-[350px] lg:w-[400px] ">
          <img src={WorkInProgress} alt="Deletion in process" />
        </div>
        <div
          className="mt-3 w-[90%] text-sm text-center text-davyGrey sm:mx-auto 
        sm:text-lg md:w-[80%] md:text-lg lg:w-[60%] ">
          {translate('recoveryDescription', { date: getFormattedDate() })}
          <div className="flex justify-center mt-4 sm:mt-8">
            <Button
              className={'py-2 px-3 bg-primaryColor sm:py-2'}
              labelClass="text-white text-xs sm:text-sm"
              label={translate('recoverAccount')}
              onClick={() => setIsPopupOpen(true)}
              showMainViewButton={true}
            />
            <Button
              className={`border border-primaryColor
               py-2 px-8 sm:py-2 ml-4 `}
              labelClass="text-blackGreen text-xs sm:text-sm"
              label={translate('logout')}
              onClick={handleLogout}
              showMainViewButton={true}
            />
          </div>
        </div>
      </div>
      <ConfirmationPopup
        buttonOneText={translate('cancel')}
        buttonTwoText={translate('recoverAccount')}
        message={translate('recoverAccountNote')}
        title={translate('recoverAccount')}
        messageStyle="whitespace-pre-line"
        onButtonOneClick={handleClosePopup}
        onButtonTwoClick={handleRecoverAccountClick}
        onCloseButtonClick={handleClosePopup}
        visibility={isPopupOpen}
        disabled={isRecoveringAccount}
      />
    </>
  );
};

export default AccountRecovery;
