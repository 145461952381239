/* eslint-disable no-unused-vars */
import React from 'react';

import {
  CloudUploadIcon,
  ConnectExchangeIcon,
  WalletIconInactive,
  WalletIconActive
} from '@assets/icons';
import COLORS from './colors';

export enum TAB_BAR_CONSTANTS {
  ASSET = 'asset',
  SOURCE = 'sources',
  ADD_EXCHANGE = 'add_exchange',
  ADD_TRANSACTION = 'add_transaction',
  IMPORT_FILE = 'import_file',
  WALLET = 'wallet',
  CONNECTIONS = 'connections',
  UPLOADED_FILES = 'uploaded_files',
  ADD_WALLET = 'add_wallet',
  CONNECT_EXCHANGES = 'connect_exchanges',
  CONNECT_WALLET_AUTO_SYNC = 'connect_wallet_auto_sync',
  CONNECT_WALLET_FILE_UPLOAD = 'connect_wallet_file_upload',
  SHARED = 'shared',
  MANAGED = 'managed',
  CONNECT_SHARED_ACCOUNT = 'connect_shared_account',
  CREATE_MANAGED_ACCOUNT = 'create_managed_account'
}

export const TAB_BAR_ITEMS = {
  dashboardPage: [
    {
      id: TAB_BAR_CONSTANTS.ASSET,
      label: 'Asset',
      isActive: true,
      minWidth: 'sm:min-w-[150px] min-w-[70px]'
    },
    {
      id: TAB_BAR_CONSTANTS.SOURCE,
      label: 'Sources',
      isActive: true,
      minWidth: 'sm:min-w-[150px] min-w-[70px]'
    }
  ],
  sourcePage: [
    {
      id: TAB_BAR_CONSTANTS.CONNECTIONS,
      label: 'Connections',
      activeIcon: (
        <ConnectExchangeIcon fill="none" stroke={COLORS.PRIMARY_COLOR} />
      ),
      inactiveIcon: (
        <ConnectExchangeIcon fill="none" stroke={COLORS.SLATE_GREY} />
      ),
      isActive: true,
      minWidth: 'sm:min-w-[124px] min-w-[114px]'
    },
    {
      id: TAB_BAR_CONSTANTS.UPLOADED_FILES,
      label: 'Uploaded Files',
      activeIcon: <CloudUploadIcon fill="none" stroke={COLORS.PRIMARY_COLOR} />,
      inactiveIcon: <CloudUploadIcon fill="none" stroke={COLORS.SLATE_GREY} />,
      isActive: true,
      minWidth: 'sm:min-w-[149px] min-w-[175px]'
    },
    {
      id: TAB_BAR_CONSTANTS.WALLET,
      label: 'Wallet',
      activeIcon: <WalletIconActive width={20} />,
      inactiveIcon: <WalletIconInactive width={20} />,
      isActive: true,
      minWidth: 'min-w-[78px]'
    }
  ],
  connectExchange: [
    {
      id: TAB_BAR_CONSTANTS.CONNECT_EXCHANGES,
      label: 'Exchanges',
      activeIcon: (
        <ConnectExchangeIcon fill="none" stroke={COLORS.PRIMARY_COLOR} />
      ),
      inactiveIcon: (
        <ConnectExchangeIcon fill="none" stroke={COLORS.SLATE_GREY} />
      ),
      isActive: true,
      minWidth: 'min-w-[114px]'
    },
    {
      id: TAB_BAR_CONSTANTS.ADD_WALLET,
      label: 'Wallets',
      activeIcon: <WalletIconActive width={20} />,
      inactiveIcon: <WalletIconInactive width={20} />,
      isActive: true,
      minWidth: 'min-w-[90px]'
    }
  ],
  connectExchangePopup: [
    {
      id: TAB_BAR_CONSTANTS.ADD_EXCHANGE,
      label: 'Connect Exchange',
      activeIcon: (
        <ConnectExchangeIcon fill="none" stroke={COLORS.PRIMARY_COLOR} />
      ),
      inactiveIcon: (
        <ConnectExchangeIcon fill="none" stroke={COLORS.SLATE_GREY} />
      ),
      disabledIcon: (
        <ConnectExchangeIcon fill="none" stroke={COLORS.GREY_CHATEAU} />
      ),
      isActive: true,
      minWidth: 'min-w-[174px]'
    },
    {
      id: TAB_BAR_CONSTANTS.IMPORT_FILE,
      label: 'File Upload',
      activeIcon: <CloudUploadIcon fill="none" stroke={COLORS.PRIMARY_COLOR} />,
      inactiveIcon: <CloudUploadIcon fill="none" stroke={COLORS.SLATE_GREY} />,
      disabledIcon: (
        <CloudUploadIcon fill="none" stroke={COLORS.GREY_CHATEAU} />
      ),
      isActive: true,
      minWidth: 'min-w-[119px]'
    }
  ],
  connectWalletPopup: [
    {
      id: TAB_BAR_CONSTANTS.CONNECT_WALLET_AUTO_SYNC,
      label: 'Auto Sync',
      activeIcon: (
        <ConnectExchangeIcon fill="none" stroke={COLORS.PRIMARY_COLOR} />
      ),
      inactiveIcon: (
        <ConnectExchangeIcon fill="none" stroke={COLORS.SLATE_GREY} />
      ),
      isActive: true,
      minWidth: 'min-w-[115px]'
    },
    {
      id: TAB_BAR_CONSTANTS.CONNECT_WALLET_FILE_UPLOAD,
      label: 'File Upload',
      activeIcon: <CloudUploadIcon fill="none" stroke={COLORS.PRIMARY_COLOR} />,
      inactiveIcon: <CloudUploadIcon fill="none" stroke={COLORS.SLATE_GREY} />,
      isActive: false,
      minWidth: 'sm:min-w-[190px] min-w-[225px]'
    }
  ],
  professionalDashboard: [
    {
      id: TAB_BAR_CONSTANTS.MANAGED,
      label: 'Managed',
      isActive: true,
      minWidth: 'min-w-[70px]'
    },
    {
      id: TAB_BAR_CONSTANTS.SHARED,
      label: 'Shared',
      isActive: true,
      minWidth: 'min-w-[54px]'
    }
  ],
  inviteClientPopup: [
    {
      id: TAB_BAR_CONSTANTS.CREATE_MANAGED_ACCOUNT,
      label: 'Managed Account',
      isActive: true,
      minWidth: 'min-w-[144px]'
    },
    {
      id: TAB_BAR_CONSTANTS.CONNECT_SHARED_ACCOUNT,
      label: 'Shared Account',
      isActive: true,
      minWidth: 'min-w-[124px]'
    }
  ]
};
