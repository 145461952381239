/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import { showLanguageSwitchButton } from '@config/index';
import {
  BuyIcon,
  DeadCostIcon,
  DepositIcon,
  IndianFlag,
  MarginFee,
  RealizedLoss,
  RealizedProfit,
  SellIcon,
  TradeIcon,
  TransferIcon,
  WithdrawalIcon
} from '@assets/icons';
import { LocalStorage } from '@services/storage';
import { GENERIC } from './connectExchangeConstants';

export enum TopBarConstants {
  MY_SOURCES = 'My Sources',
  SYNC_TRANSACTIONS = 'Sync Transactions',
  IMPORT_TRANSACTIONS = 'Import Transactions',
  DASHBOARD = 'Dashboard',
  TAX_REPORT = 'Tax Report',
  TDS_TRACKER = 'TDS Tracker',
  ITR_FILING = 'ITR Filing',
  SETTINGS = 'Settings',
  SUBSCRIPTION_PLANS = 'Subscription Plans',
  DOWNLOAD_REPORT = 'Download Report',
  LINK_TRANSACTIONS = 'Link Transactions',
  MY_TRANSACTIONS = 'My Transactions',
  EDIT_TRANSACTION = 'Edit Transaction',
  EDIT = 'Edit',
  DELETE = 'Delete',
  PROFILE_SETTINGS = 'Profile Settings',
  CONNECT_EXCHANGE = 'Connect Exchange',
  TAXATION_RULE_SETTINGS = 'Taxation Rule Settings',
  GIVE_AWAY = 'Giveaway'
}

export enum NotifierTypes {
  LOADING = 'loadingState',
  LINK_ERROR = 'linkErrorState',
  DELETE = 'deleteState',
  SYNC_ERROR = 'syncErrorState',
  SUCCESS = 'successState',
  ERROR = 'errorState',
  JOB_QUEUE_LOADING = 'jobQueueLoadingState'
}

export enum Currency {
  RUPEE = '₹',
  DOLLAR = '$'
}

export const LANGUAGE_LOCAL_STATE = 'language';

export enum APP_LANGUAGES {
  EN = 'en'
}

const currentLanguage =
  LocalStorage.get<string>(LANGUAGE_LOCAL_STATE) ?? APP_LANGUAGES.EN;

// showLanguageSwitchButton is currently set only for dev environment.Change it for future use
export const CURRENT_APP_LANGUAGE = showLanguageSwitchButton
  ? currentLanguage
  : APP_LANGUAGES.EN;

export const DATE_FORMAT = 'dd MMM yyyy';

export const DATE_TIME_FORMAT = 'dd MMM yyyy, hh:mm:ss a';

export const DEFAULT_DATE_FORMAT = 'dd/MM/yyyy';

export const DEFAULT_TIME_FORMAT = 'hh:mm:ss a';

export const DATE_TIME_NO_SEC_FORMAT = 'dd MMM yyyy  hh:mm aaaa';

export const DATE_TIME_NUMBER_DOT_FORMAT = 'dd.MM.yyyy, hh.mm aaa';

export const ROWS_PER_PAGE = 10;

export const ROWS_PER_PAGE_PROFESSIONAL = 25;

export const NOTIFIER_MESSAGE_TYPES = {
  linkingAccount: 'linkingAccount',
  linkingFailed: 'linkingFailed',
  linkingSuccess: 'linkingSuccess',
  deleteSuccess: 'deleteSuccess',
  deleteInProgress: 'deleteInProgress',
  syncingTransactions: 'syncingTransactions',
  syncingTransactionsSuccess: 'syncingTransactionsSuccess',
  syncingTransactionsFailed: 'syncingTransactionsFailed',
  syncingSingleTransaction: 'syncingSingleTransaction',
  syncingSingleTransactionSuccess: 'syncingSingleTransactionSuccess',
  deleteFailed: 'deleteFailed'
};

export const TRANSACTION_TYPES = {
  buy: 'buy',
  sell: 'sell',
  trade: 'trade',
  transferIn: 'transfer-in',
  transferOut: 'transfer-out',
  deposit: 'deposit',
  withdrawal: 'withdrawal',
  cost: 'cost',
  realizedProfit: 'r-pnl-profit',
  realizedLoss: 'r-pnl-loss',
  marginFee: 'margin-fee'
};

export const TRANSACTION_TRANSFER_IN_TAGS = {
  giftFromRelative: 'gift-from-relative',
  gitFromOthers: 'gift-from-others',
  internalTransfer: 'internal-transfer',
  stakingReward: 'staking-reward',
  airdrop: 'airdrop',
  fork: 'fork',
  interest: 'interest',
  income: 'income',
  mining: 'mining',
  referralBonus: 'referral-bonus',
  rewards: 'rewards'
};

export const TRANSACTION_TRANSFER_OUT_TAGS = {
  giftSent: 'gift-sent',
  internalTransfer: 'internal-transfer',
  external: 'external',
  manual: 'is_manual',
  lost: 'lost'
};

export const TRANSACTION_TRADE_TAGS = {
  otc: 'otc'
};

export const TRANSACTION_BUY_SELL_TAGS = {
  p2p: 'p2p'
};

export const TRANSACTION_TAGS = {
  ...TRANSACTION_TRANSFER_IN_TAGS,
  ...TRANSACTION_TRANSFER_OUT_TAGS,
  ...TRANSACTION_BUY_SELL_TAGS,
  ...TRANSACTION_TRADE_TAGS
};

export const TRANSACTION_TYPE_DROPDOWN_VALUES = [
  { id: TRANSACTION_TYPES.buy, name: 'Buy', icon: BuyIcon },
  { id: TRANSACTION_TYPES.sell, name: 'Sell', icon: SellIcon },
  { id: TRANSACTION_TYPES.trade, name: 'Trade', icon: TradeIcon },
  { id: TRANSACTION_TYPES.deposit, name: 'Deposit', icon: DepositIcon },
  {
    id: TRANSACTION_TYPES.withdrawal,
    name: 'Withdrawal',
    icon: WithdrawalIcon
  },
  { id: TRANSACTION_TYPES.transferIn, name: 'Transfer In', icon: TransferIcon },
  {
    id: TRANSACTION_TYPES.transferOut,
    name: 'Transfer Out',
    icon: TransferIcon
  },
  { id: TRANSACTION_TYPES.cost, name: 'Cost', icon: DeadCostIcon },
  {
    id: TRANSACTION_TYPES.realizedProfit,
    name: 'Realized P&L (Profit)',
    icon: RealizedProfit
  },
  {
    id: TRANSACTION_TYPES.realizedLoss,
    name: 'Realized P&L (Loss)',
    icon: RealizedLoss
  },
  { id: TRANSACTION_TYPES.marginFee, name: 'Margin Fee', icon: MarginFee }
];

export const TRANSACTION_TAGS_FILTER_VALUES = [
  {
    id: TRANSACTION_TRANSFER_IN_TAGS.internalTransfer,
    name: 'Internal Transfer'
  },
  { id: TRANSACTION_TRANSFER_OUT_TAGS.external, name: 'External Transfer' },
  { id: TRANSACTION_TRANSFER_IN_TAGS.income, name: 'Income' },
  {
    id: TRANSACTION_TRANSFER_IN_TAGS.giftFromRelative,
    name: 'Gift from Relative'
  },
  { id: TRANSACTION_TRANSFER_IN_TAGS.gitFromOthers, name: 'Gift from Others' },
  { id: TRANSACTION_TRANSFER_IN_TAGS.stakingReward, name: 'Staking Reward' },
  { id: TRANSACTION_TRANSFER_IN_TAGS.airdrop, name: 'Airdrop' },
  { id: TRANSACTION_TRANSFER_IN_TAGS.fork, name: 'Fork' },
  { id: TRANSACTION_TRANSFER_IN_TAGS.interest, name: 'Interest' },
  { id: TRANSACTION_TRANSFER_IN_TAGS.mining, name: 'Mining' },
  { id: TRANSACTION_TRANSFER_IN_TAGS.rewards, name: 'Rewards' },
  { id: TRANSACTION_TRANSFER_IN_TAGS.referralBonus, name: 'Referral Bonus' },
  { id: TRANSACTION_TRANSFER_OUT_TAGS.giftSent, name: 'Gift Sent' },
  { id: TRANSACTION_TRANSFER_OUT_TAGS.lost, name: 'Lost' },
  { id: TRANSACTION_TRADE_TAGS.otc, name: 'OTC' },
  { id: TRANSACTION_BUY_SELL_TAGS.p2p, name: 'P2P' },
  { id: TRANSACTION_TRANSFER_OUT_TAGS.manual, name: 'Manual' }
];

export const TRANSACTION_TRANSFER_IN_TAGS_DROPDOWN_VALUES = [
  { id: TRANSACTION_TRANSFER_IN_TAGS.income, name: 'Income' },
  {
    id: TRANSACTION_TRANSFER_IN_TAGS.giftFromRelative,
    name: 'Gift from Relative'
  },
  { id: TRANSACTION_TRANSFER_IN_TAGS.gitFromOthers, name: 'Gift from Others' },
  {
    id: TRANSACTION_TRANSFER_IN_TAGS.internalTransfer,
    name: 'Internal Transfer'
  },
  { id: TRANSACTION_TRANSFER_IN_TAGS.stakingReward, name: 'Staking Reward' },
  { id: TRANSACTION_TRANSFER_IN_TAGS.airdrop, name: 'Airdrop' },
  { id: TRANSACTION_TRANSFER_IN_TAGS.fork, name: 'Fork' },
  { id: TRANSACTION_TRANSFER_IN_TAGS.interest, name: 'Interest' },
  { id: TRANSACTION_TRANSFER_IN_TAGS.mining, name: 'Mining' },
  { id: TRANSACTION_TRANSFER_IN_TAGS.rewards, name: 'Rewards' },
  { id: TRANSACTION_TRANSFER_IN_TAGS.referralBonus, name: 'Referral Bonus' }
];

export const TRANSACTION_TRANSFER_OUT_TAGS_DROPDOWN_VALUES = [
  { id: TRANSACTION_TRANSFER_OUT_TAGS.external, name: 'External Transfer' },
  { id: TRANSACTION_TRANSFER_OUT_TAGS.giftSent, name: 'Gift Sent' },
  {
    id: TRANSACTION_TRANSFER_OUT_TAGS.internalTransfer,
    name: 'Internal Transfer'
  },
  { id: TRANSACTION_TRANSFER_OUT_TAGS.lost, name: 'Lost' }
];

const TRANSACTION_BUY_SELL_TAGS_VALUES = [
  { id: TRANSACTION_BUY_SELL_TAGS.p2p, name: 'P2P' }
];

const TRANSACTION_TRADE_TAGS_VALUES = [
  { id: TRANSACTION_TRADE_TAGS.otc, name: 'OTC' }
];

export const REGULAR = 'regular';

export const TRANSACTION_BUY_SELL_TAGS_DROPDOWN_VALUES = [
  { id: REGULAR, name: 'Regular' },
  ...TRANSACTION_BUY_SELL_TAGS_VALUES
];

export const REGULAR_TRADE = 'regular_trade';

export const TRANSACTION_TRADE_TAGS_DROPDOWN_VALUES = [
  { id: REGULAR_TRADE, name: 'Regular' },
  ...TRANSACTION_TRADE_TAGS_VALUES
];

export const TRANSACTION_TAGS_OBJECT_LIST = [...TRANSACTION_TAGS_FILTER_VALUES];

export const MARKED_AS_TAGS = [TRANSACTION_TRANSFER_OUT_TAGS.manual];

export const INDIAN_RUPEE = {
  code: 'INR',
  name: 'Indian Rupee'
};

export const IS_AUTHENTICATED = 'isAuthenticated';
export const PAGE_HAS_BEEN_FORCE_REFRESHED = 'pageHasBeenForceRefreshed';
export const CSRF_TOKEN_FOR_EXCHANGE_AUTH = 'csrfTokenForExchangeAuth';
export const CLIENT_ID = 'clientID';

export const TRANSACTION_MODES = {
  manual: 'manual',
  scheduler: 'scheduler',
  import: 'import'
};

export const SUMMARY_DISPLAY_TEXT = {
  [TRANSACTION_TYPES.buy]: 'Total Cost',
  [TRANSACTION_TYPES.sell]: 'Gross Sale Value',
  [TRANSACTION_TYPES.trade]: 'Asset Sold',
  [TRANSACTION_TYPES.deposit]: 'Total Amount',
  [TRANSACTION_TYPES.withdrawal]: 'Total Amount',
  [TRANSACTION_TYPES.transferIn]: 'Market Value',
  [TRANSACTION_TYPES.transferOut]: 'Market Value',
  [TRANSACTION_TYPES.cost]: 'Market Value',
  [TRANSACTION_TYPES.realizedProfit]: 'Market Value',
  [TRANSACTION_TYPES.realizedLoss]: 'Market Value',
  [TRANSACTION_TYPES.marginFee]: 'Market Value'
};

export const SUMMARY_NET_DISPLAY_TEXT = {
  [TRANSACTION_TYPES.sell]: 'Net Sale Value',
  [TRANSACTION_TYPES.transferOut]: 'Net Value',
  [TRANSACTION_TYPES.cost]: 'Net Value',
  [TRANSACTION_TYPES.realizedLoss]: 'Net Value',
  [TRANSACTION_TYPES.marginFee]: 'Net Value'
};

export const TRANSACTION_TYPE_NET_VALUES = [
  TRANSACTION_TYPES.sell,
  TRANSACTION_TYPES.transferOut,
  TRANSACTION_TYPES.cost,
  TRANSACTION_TYPES.realizedLoss,
  TRANSACTION_TYPES.marginFee
];

export const SUMMARY_RECIEVED_TEXT = {
  [TRANSACTION_TYPES.trade]: 'Asset Received'
};

export const DATE_FILTER_TYPE = {
  from: 'From',
  to: 'To'
};

export const COINDCX_CODE = 'DCX';

export const LISTBOX_PADDING = 8; // px

export const API_RESPONSE_STATUS = {
  OK: 'ok',
  NOT_OK: 'nok'
};

export const FILE_UPLOAD_STATUS = {
  uploading: 'uploading',
  failed: 'failed',
  success: 'success'
};

export const SUPPORTED_FILE_FORMATS = {
  '.csv': {
    'text/csv': ['.csv']
  },
  '.xlsx': {
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
      '.xlsx'
    ]
  },
  '.xls': {
    'application/vnd.ms-excel': ['.xls']
  },
  DEFAULT: {
    'text/csv': ['.csv'],
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
      '.xlsx'
    ]
  }
};

export const SUPPORTED_FILE_EXTENSIONS = {
  [GENERIC]: '.csv',
  WRX: '.xlsx',
  CSK: '.xlsx',
  DCX: '.csv',
  ZEB: '.csv',
  DEFAULT: '.csv, .xls or .xlsx'
};

export const REGEX = {
  email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  name: /^[a-zA-Z][a-zA-Z .]*$/,
  noWhiteSpace: /^(\S+$)/g,
  digits: /^\d+$/,
  mobile: /^\d{10}$/,
  decimal: /^-?\d*\.?\d*$/,
  // eslint-disable-next-line no-control-regex
  textWithoutEmogis: /^[\x00-\x7F]*$/,
  numberWith2Decimals: /^(?:\d*\.\d{1,2}|\d+|\.\d{1,2})$/,
  mobileOptional: /^(\d{10})?$/,
  numberWith25Decimals: /^(?:\d{0,25}(?:\.\d{1,25})?|\.\d{1,25})$/
};

export const FIAT_CURRENCIES = [
  {
    id: INDIAN_RUPEE.code,
    name: INDIAN_RUPEE.name,
    icon: process.env.REACT_APP_INDIAN_RUPEE_ICON
  }
];

export const AVAILABLE_SUBSCRIPTION_FY = [
  { label: 'FY 2023-24', name: '23-24' },
  { label: 'FY 2022-23', name: '22-23' }
  // { label: 'FY 2021-22', name: '21-22' },
  // { label: 'FY 2020-21', name: '20-21' }
];

export const AVAILABLE_FY = [
  { label: 'FY 2023-24', name: '23-24' },
  { label: 'FY 2022-23', name: '22-23' },
  { label: 'FY 2021-22', name: '21-22' },
  { label: 'FY 2020-21', name: '20-21' }
];

export const FY_BUNDLE_MAPPER = {
  '21-22': '22-23',
  '20-21': '22-23'
};

export const BUNDLE_PARENT_MAPPER = {
  '2021-2022': '2022-2023',
  '2020-2021': '2022-2023'
};

export const NON_TDS_SUBSCRIPTION_FY_THRESHOLD = 21;

export const CURRENCY_CODE_TO_SYMBOL_MAPPER = {
  INR: Currency.RUPEE,
  USD: Currency.DOLLAR
};

export const COUNTRYFLAG_MAPPER = {
  India: IndianFlag
};

export const LOGIN_FLOW_TIMER_DURATION_IN_SEC = 30;

export const AUTH_CALLBACK_MESSAGES = {
  success: 'Success',
  userRejectedPermission: 'User rejected permission'
};

export const DISCOUNT_TYPE = {
  percentage: 'percentage',
  fixedAmount: 'fixed_amount'
};

export const OFFER_TYPE = {
  cashback: 'cashback',
  discount: 'discount'
};

export const DISCOUNT_TYPE_MAPPER = {
  [DISCOUNT_TYPE.percentage]: '%',
  [DISCOUNT_TYPE.fixedAmount]: 'Rs'
};

export const TDS_LEVEL = {
  EXCHANGE: 'exchange_level',
  TRANSACTION: 'transaction_level',
  NOT_APPLICABLE: 'na'
};

export const TDS_TRANSACTION_TYPE = [
  TRANSACTION_TYPES.sell,
  TRANSACTION_TYPES.trade,
  TRANSACTION_TYPES.transferOut,
  TRANSACTION_TYPES.realizedLoss,
  TRANSACTION_TYPES.marginFee
];

// eslint-disable-next-line max-len
export const GOOGLE_AUTH_REDIRECTION_URL = `${process.env.REACT_APP_AUTH_SVC_API_ENDPOINT}/${process.env.REACT_APP_AUTH_SVC_API_VERSION}/oauth/google?redirect_url=${process.env.REACT_APP_GOOGLE_AUTH_REDIRECT_URL}`;

export const generateGoogleAuthRedirectionUrl = (redirectionUrl: string) => {
  // eslint-disable-next-line max-len
  return `${process.env.REACT_APP_AUTH_SVC_API_ENDPOINT}/${process.env.REACT_APP_AUTH_SVC_API_VERSION}/oauth/google?redirect_url=${redirectionUrl}`;
};

export const MISSING_TAG_IDS = {
  missingCostBasis: 'missing-cost-basis',
  missingAsset: 'missing-asset'
};

export const MISSING_TAGS = [
  {
    id: MISSING_TAG_IDS.missingCostBasis,
    name: 'Missing Market Value'
  },
  {
    id: MISSING_TAG_IDS.missingAsset,
    name: 'Missing Asset'
  }
];

export const ERROR_TAG_MAPPER = {
  missing_asset_count: 'Missing Asset',
  missing_cost_basis_count: 'Missing Market Value'
};

export const MOBLIE_SCREEN_CARD_ALIGNMENT_BREAKPOINT = 479;

export const PAGINATOR_ALIGNMENT_BREAKPOINT = 390;

export const KEYBOARD_KEYS = {
  arrowUp: 'ArrowUp',
  arrowDown: 'ArrowDown',
  arrowRight: 'ArrowRight',
  arrrowLeft: 'ArrowLeft',
  backspace: 'Backspace',
  enter: 'Enter',
  minus: 'Minus'
};

export const WELCOME_FLOW = {
  phoneScreen: 'phoneScreen',
  otpScreen: 'otpScreen',
  successScreen: 'successScreen',
  resetPasswordScreen: 'resetPasswordScreen'
};

export const INDIAN_TELEPHONE_CODE = '+91';

export const JOB_NOTIFIER = 'JOB_NOTIFIER';

export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const PROMOTION_TYPE = {
  influencer: 'influencer',
  exchangePartner: 'exchange_partner',
  internal: 'internal'
};

export const FILE = 'file';

export enum USER_ROLE {
  PERSONAL = 'personal',
  PROFESSIONAL = 'professional'
}

export enum USER_ORIGIN {
  SIMPLE = 'simple',
  GOOGLE = 'google',
  PARTNER = 'partner'
}

export const SORTING_ORDER = {
  ASC: 'ascending',
  DESC: 'descending',
  DEFAULT: 'default'
};

export const SORTING_ORDER_STRING = {
  [SORTING_ORDER.DEFAULT]: SORTING_ORDER.ASC,
  [SORTING_ORDER.ASC]: SORTING_ORDER.DESC,
  [SORTING_ORDER.DESC]: SORTING_ORDER.DEFAULT
};

export const SORTING_ORDER_DECIMAL = {
  [SORTING_ORDER.DEFAULT]: SORTING_ORDER.DESC,
  [SORTING_ORDER.DESC]: SORTING_ORDER.ASC,
  [SORTING_ORDER.ASC]: SORTING_ORDER.DEFAULT
};

export const NOT_AVAILABLE = 'N/A';

export const TAX_TOGGLE_VALUES = {
  capitalGain: 'capital_gain',
  otherIncome: 'other_income'
};

export const DEFAULT_DROPDOWN_OPTION = 'Choose an option';

export const YES_OR_NO_RADIO_VALUES = [
  {
    id: 'yes',
    label: 'Yes'
  },
  {
    id: 'no',
    label: 'No'
  }
];

export const ACCOUNT_TYPE = {
  MANAGED: 'managed',
  SHARED: 'individual'
};

export const PROMOTION_STATUS = {
  REGISTERED: 'registered',
  NOT_REGISTERED: 'not_registered'
};
