import React, { FC, useEffect, useRef, useState } from 'react';

import { SentEmailIcon } from '@assets/icons';
import { translate } from '@utils/locale';
import { LOGIN_FLOW_TIMER_DURATION_IN_SEC } from '@constants/common';
import { getTimeStringFromSeconds } from '@utils/generalUtils';
import { EmailProps } from '../types';

const CheckEmail: FC<EmailProps> = props => {
  const { email, handleResendClick, handleBackClick, isConfirmEmail } = props;

  const [count, setCount] = useState(LOGIN_FLOW_TIMER_DURATION_IN_SEC);
  const [retryCount, setRetryCount] = useState(0);

  const interval = useRef(null);

  useEffect(() => {
    interval.current = setInterval(() => {
      setCount(pcount => pcount - 1);
    }, 1000);
    return () => clearInterval(interval.current);
  }, [retryCount]);

  useEffect(() => {
    if (count <= 0) clearInterval(interval.current);
  }, [count]);

  const onResendClick = () => {
    handleResendClick();
    setRetryCount(rCount => rCount + 1);
    setCount(LOGIN_FLOW_TIMER_DURATION_IN_SEC);
  };

  return (
    <div className="flex flex-col items-center my-auto w-full">
      <SentEmailIcon />
      <div className="flex overflow-hidden flex-col justify-center items-center my-7 w-full">
        <div className="text-2xl font-semibold text-center text-blackGreen">
          {isConfirmEmail
            ? translate('confirmEmailPage.confirmEmail')
            : translate('checkEmailPage.checkEmail')}
        </div>
        <div className="mt-[13px] text-sm text-center text-davyGrey">
          {isConfirmEmail
            ? translate('confirmEmailPage.confirmDescription', {
                emailAddress: email
              })
            : translate('checkEmailPage.checkDescription', {
                emailAddress: email
              })}
        </div>
      </div>
      <div className="mt-7 text-sm text-center text-davyGrey">
        {translate('confirmEmailPage.mailNotRecieved')}
        {count > 0 ? (
          <span className="flex-row text-santaGrey cursor-not-allowed">
            {translate('checkEmailPage.resendTimer', {
              timer: getTimeStringFromSeconds(count, false)
            })}
          </span>
        ) : (
          <span
            className="flex-row text-primaryColor cursor-pointer"
            onClick={onResendClick}
            data-testid="resend-link">
            {translate('checkEmailPage.resend')}
          </span>
        )}
      </div>
      <div
        className="mt-[27px] text-sm text-davyGrey underline cursor-pointer"
        onClick={handleBackClick}
        data-testid="back-to-sign-in">
        {isConfirmEmail
          ? translate('confirmEmailPage.backToSignUp')
          : translate('checkEmailPage.backToSignIn')}
      </div>
    </div>
  );
};

export default CheckEmail;
