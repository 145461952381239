import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { TopBar, StringToTSX } from '@components';
import { TopBarConstants } from '@constants/common';
import { translate } from '@utils/locale';
import routesPath from '@routes/RoutesPath';

const ResolveErrors: FC = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(routesPath.TRANSACTION);
  };

  return (
    <>
      <TopBar
        showNavigateBack={true}
        handleBackClick={handleBackClick}
        title={TopBarConstants.MY_TRANSACTIONS}
      />
      <div className="p-5 w-full">
        <div className="p-8 mb-10 text-xs bg-white rounded-md sm:text-base">
          <div className="text-2xl font-bold text-blackGreen">
            {translate('transactionPage.howToResolveMissingAssetError')}
          </div>
          <br />
          <div className="font-sans text-xl font-bold text-blackGreen">
            <StringToTSX
              domString={translate('transactionPage.missingAssetTitle1')}
            />
          </div>
          <br />
          <div className="flex flex-wrap">
            <StringToTSX
              domString={translate('transactionPage.missingAssetContent1')}
            />
          </div>
          <br />
          <br />
          <div className="font-sans text-xl font-bold text-blackGreen">
            <StringToTSX
              domString={translate('transactionPage.missingAssetTitle2')}
            />
          </div>
          <br />
          <div className="flex flex-wrap">
            <StringToTSX
              domString={translate('transactionPage.missingAssetContent2P1')}
            />
          </div>
          <br />
          <div className="flex flex-wrap">
            <StringToTSX
              domString={translate('transactionPage.missingAssetContent2P2')}
            />
          </div>
          <br />
          <div className="flex flex-wrap">
            <StringToTSX
              domString={translate('transactionPage.missingAssetContent2P3')}
            />
          </div>
          <br />
          <br />
          <div className="font-sans text-xl font-bold text-blackGreen">
            <StringToTSX
              domString={translate('transactionPage.missingAssetTitle3')}
            />
          </div>
          <br />
          <div className="flex flex-wrap">
            <StringToTSX
              domString={translate('transactionPage.missingAssetContent3')}
            />
          </div>
          <br />
          <br />

          <div className="text-2xl font-bold text-blackGreen">
            {translate('transactionPage.howToResolveMissingMarketError')}
          </div>
          <br />
          <div className="font-sans text-xl font-bold text-blackGreen">
            <StringToTSX
              domString={translate('transactionPage.missingMarketTitle1')}
            />
          </div>
          <br />
          <div className="flex flex-wrap">
            <StringToTSX
              domString={translate('transactionPage.missingMarketContent1')}
            />
          </div>
          <br />
          <br />
          <div className="font-sans text-xl font-bold text-blackGreen">
            <StringToTSX
              domString={translate('transactionPage.missingMarketTitle2')}
            />
          </div>
          <br />
          <div className="flex flex-wrap">
            <StringToTSX
              domString={translate('transactionPage.missingMarketContent2P1')}
            />
          </div>
          <br />
          <div className="font-sans text-base font-bold text-blackGreen">
            <StringToTSX
              domString={translate('transactionPage.missingMarketContent2P2')}
            />
          </div>
          <div className="flex flex-wrap">
            <StringToTSX
              domString={translate('transactionPage.missingMarketContent2P3')}
            />
          </div>
          <br />
        </div>
      </div>
    </>
  );
};

export default ResolveErrors;
