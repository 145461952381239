import React, { FC } from 'react';

import { USER_ROLE } from '@constants/common';
import { SignInProps } from './types';
import SignIn from '@containers/login-flow/sign-in/SignIn';

const SignInPage: FC<SignInProps> = ({ userRole = USER_ROLE.PERSONAL }) => (
  <SignIn userRole={userRole} />
);

export default SignInPage;
