import { authBaseApi } from '@services/api';
import { translate } from '@utils/locale';
import { GeneralResponse } from '@containers/source/types';

const AuthBaseAPI = authBaseApi.injectEndpoints({
  endpoints: builder => ({
    recoverAccount: builder.mutation<GeneralResponse, void>({
      query: () => ({
        url: '/users/recover',
        method: 'PATCH'
      }),
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.accountRecoverError'),
        success: translate('notifierMessage.accountRecoverSuccess')
      },
      invalidatesTags: ['GetUserDetails']
    })
  })
});

export const { useRecoverAccountMutation } = AuthBaseAPI;
