import { object, string, date } from 'yup';

import {
  TDS_TRANSACTION_TYPE,
  TRANSACTION_TAGS,
  TRANSACTION_TYPES
} from '@constants/common';
import { createTimeStamp } from '@utils/generalUtils';
import {
  checkTDSIsTransactionLevel,
  checkTDSStartDate
} from '@utils/transactionUtils';
import { REGEX } from '@constants/common';
import { SourceListType } from '@containers/source/types';

export const transactionFormSchema = object({
  transactionType: string().required('Required field'),
  transactionSource: string().required('Required field'),
  transactionTag: string().test(
    'tagValidation',
    'Required field',
    (value, { parent }) => {
      if (
        [TRANSACTION_TYPES.transferIn, TRANSACTION_TYPES.transferOut].includes(
          parent.transactionType
        ) &&
        !value
      ) {
        return false;
      }
      return true;
    }
  ),
  date: date()
    .required('Choose date')
    .nullable()
    .typeError('Invalid Date')
    .test('dateValidation', 'Future dates are not allowed', value => {
      return value <= new Date();
    }),
  time: date()
    .required('Choose time')
    .nullable()
    .typeError('Invalid Time')
    .test('parentDateValidation', 'Invalid Time', (value, { parent }) => {
      if (parent.date && isNaN(parent.date.getTime())) {
        return false;
      }
      return true;
    })
    .test('dateValidation', 'Future time not allowed', (value, { parent }) => {
      if (parent.date && value) {
        if (!isNaN(parent.date.getTime())) {
          return new Date(createTimeStamp(parent.date, value)) <= new Date();
        }
        return false;
      }
      return true;
    }),
  noOfCoins: string()
    .test('coinValidity', 'Required field', (value, { parent }) => {
      if (
        [
          TRANSACTION_TYPES.sell,
          TRANSACTION_TYPES.buy,
          TRANSACTION_TYPES.trade,
          TRANSACTION_TYPES.transferOut,
          TRANSACTION_TYPES.realizedLoss,
          TRANSACTION_TYPES.marginFee
        ].includes(parent.transactionType) &&
        !value
      ) {
        return false;
      }
      return true;
    })
    .test(
      'coinNegativeValidity',
      'Must be greater than 0',
      (value, { parent }) => {
        if (
          [
            TRANSACTION_TYPES.sell,
            TRANSACTION_TYPES.buy,
            TRANSACTION_TYPES.trade,
            TRANSACTION_TYPES.transferOut,
            TRANSACTION_TYPES.realizedLoss,
            TRANSACTION_TYPES.marginFee
          ].includes(parent.transactionType) &&
          value &&
          Number(value) <= 0
        ) {
          return false;
        }
        return true;
      }
    )
    .test(
      'decimalError',
      'Maximum 25 digits before or after decimal',
      (value, { parent }) => {
        if (
          [
            TRANSACTION_TYPES.sell,
            TRANSACTION_TYPES.buy,
            TRANSACTION_TYPES.trade,
            TRANSACTION_TYPES.transferOut,
            TRANSACTION_TYPES.realizedLoss,
            TRANSACTION_TYPES.marginFee
          ].includes(parent.transactionType) &&
          value
        ) {
          return REGEX.numberWith25Decimals.test(value);
        }
        return true;
      }
    ),
  coinName: string().test('coinName', 'Required field', (value, { parent }) => {
    if (
      [
        TRANSACTION_TYPES.sell,
        TRANSACTION_TYPES.buy,
        TRANSACTION_TYPES.trade,
        TRANSACTION_TYPES.transferOut,
        TRANSACTION_TYPES.realizedLoss,
        TRANSACTION_TYPES.marginFee
      ].includes(parent.transactionType) &&
      !value
    ) {
      return false;
    }
    return true;
  }),
  noOfCoinsReceived: string()
    .test('coinReceivedValidity', 'Required field', (value, { parent }) => {
      if (
        [
          TRANSACTION_TYPES.trade,
          TRANSACTION_TYPES.transferIn,
          TRANSACTION_TYPES.realizedProfit
        ].includes(parent.transactionType) &&
        !value
      ) {
        return false;
      }
      return true;
    })
    .test(
      'coinReceivedNegativeValidity',
      'Must be greater than 0',
      (value, { parent }) => {
        if (
          [
            TRANSACTION_TYPES.trade,
            TRANSACTION_TYPES.transferIn,
            TRANSACTION_TYPES.realizedProfit
          ].includes(parent.transactionType) &&
          value &&
          Number(value) <= 0
        ) {
          return false;
        }
        return true;
      }
    )
    .test(
      'decimalError',
      'Maximum 25 digits before or after decimal',
      (value, { parent }) => {
        if (
          [
            TRANSACTION_TYPES.trade,
            TRANSACTION_TYPES.transferIn,
            TRANSACTION_TYPES.realizedProfit
          ].includes(parent.transactionType) &&
          value
        ) {
          return REGEX.numberWith25Decimals.test(value);
        }
        return true;
      }
    ),
  coinNameReceived: string()
    .test('coinNameReceivedValidity', 'Required field', (value, { parent }) => {
      if (
        [
          TRANSACTION_TYPES.trade,
          TRANSACTION_TYPES.transferIn,
          TRANSACTION_TYPES.realizedProfit
        ].includes(parent.transactionType) &&
        !value
      ) {
        return false;
      }
      return true;
    })
    .test(
      'sameCoinSentAndRecievedInTrade',
      'Coins sent and recieved should not be same',
      (value, { parent }) => {
        if (
          parent.transactionType === TRANSACTION_TYPES.trade &&
          value === parent.coinName
        ) {
          return false;
        }
        return true;
      }
    ),
  receivedAmount: string()
    .test('receivedAmountValidity', 'Required field', (value, { parent }) => {
      if ([TRANSACTION_TYPES.sell].includes(parent.transactionType) && !value) {
        return false;
      }
      return true;
    })
    .test(
      'receivedAmountNegativeValidity',
      'Must be greater than 0',
      (value, { parent }) => {
        if (
          [TRANSACTION_TYPES.sell].includes(parent.transactionType) &&
          value &&
          Number(value) <= 0
        ) {
          return false;
        }
        return true;
      }
    )
    .test(
      'decimalError',
      'Maximum 2 decimal places allowed',
      (value, { parent }) => {
        if (
          [TRANSACTION_TYPES.sell].includes(parent.transactionType) &&
          value
        ) {
          return REGEX.numberWith2Decimals.test(value);
        }
        return true;
      }
    ),
  paidAmount: string()
    .test('paidAmountValidity', 'Required field', (value, { parent }) => {
      if ([TRANSACTION_TYPES.buy].includes(parent.transactionType) && !value) {
        return false;
      }
      return true;
    })
    .test(
      'paidAmountNegativeValidity',
      'Must be greater than 0',
      (value, { parent }) => {
        if (
          [TRANSACTION_TYPES.buy].includes(parent.transactionType) &&
          value &&
          Number(value) <= 0
        ) {
          return false;
        }
        return true;
      }
    )
    .test(
      'decimalError',
      'Maximum 2 decimal places allowed',
      (value, { parent }) => {
        if ([TRANSACTION_TYPES.buy].includes(parent.transactionType) && value) {
          return REGEX.numberWith2Decimals.test(value);
        }
        return true;
      }
    ),
  costBasis: string()
    .test('costBasisValidity', 'Required field', (value, { parent }) => {
      if (
        parent.transactionType === TRANSACTION_TYPES.transferIn &&
        [
          TRANSACTION_TAGS.giftFromRelative,
          TRANSACTION_TAGS.internalTransfer
        ].includes(parent.transactionTag) &&
        !value
      ) {
        return false;
      }
      return true;
    })
    .test(
      'costBasisNegativeValidity',
      'Must be greater than 0',
      (value, { parent }) => {
        if (
          parent.transactionType === TRANSACTION_TYPES.transferIn &&
          [
            TRANSACTION_TAGS.giftFromRelative,
            TRANSACTION_TAGS.internalTransfer
          ].includes(parent.transactionTag) &&
          value &&
          Number(value) <= 0
        ) {
          return false;
        }
        return true;
      }
    )
    .test(
      'decimalError',
      'Maximum 2 decimal places allowed',
      (value, { parent }) => {
        if (
          parent.transactionType === TRANSACTION_TYPES.transferIn &&
          [
            TRANSACTION_TAGS.giftFromRelative,
            TRANSACTION_TAGS.internalTransfer
          ].includes(parent.transactionTag) &&
          value
        ) {
          return REGEX.numberWith2Decimals.test(value);
        }
        return true;
      }
    ),
  marketValueOfCoin: string()
    .test(
      'marketValueOfCoinValidity',
      'Required field',
      (value, { parent }) => {
        if (
          ([
            TRANSACTION_TYPES.trade,
            TRANSACTION_TYPES.transferOut,
            TRANSACTION_TYPES.realizedLoss,
            TRANSACTION_TYPES.marginFee,
            TRANSACTION_TYPES.realizedProfit
          ].includes(parent.transactionType) ||
            (parent.transactionType === TRANSACTION_TYPES.transferIn &&
              [
                TRANSACTION_TAGS.stakingReward,
                TRANSACTION_TAGS.gitFromOthers,
                TRANSACTION_TAGS.income,
                TRANSACTION_TAGS.airdrop,
                TRANSACTION_TAGS.fork,
                TRANSACTION_TAGS.interest,
                TRANSACTION_TAGS.mining,
                TRANSACTION_TAGS.rewards
              ].includes(parent.transactionTag))) &&
          !value
        ) {
          return false;
        }
        return true;
      }
    )
    .test(
      'marketValueOfCoinNegativeValidity',
      'Must be greater than 0',
      (value, { parent }) => {
        if (
          ([
            TRANSACTION_TYPES.trade,
            TRANSACTION_TYPES.transferOut,
            TRANSACTION_TYPES.realizedLoss,
            TRANSACTION_TYPES.marginFee,
            TRANSACTION_TYPES.realizedProfit
          ].includes(parent.transactionType) ||
            (parent.transactionType === TRANSACTION_TYPES.transferIn &&
              [
                TRANSACTION_TAGS.stakingReward,
                TRANSACTION_TAGS.gitFromOthers,
                TRANSACTION_TAGS.income,
                TRANSACTION_TAGS.airdrop,
                TRANSACTION_TAGS.fork,
                TRANSACTION_TAGS.interest,
                TRANSACTION_TAGS.mining,
                TRANSACTION_TAGS.rewards
              ].includes(parent.transactionTag))) &&
          value &&
          Number(value) <= 0
        ) {
          return false;
        }
        return true;
      }
    )
    .test(
      'decimalError',
      'Maximum 2 decimal places allowed',
      (value, { parent }) => {
        if (
          ([
            TRANSACTION_TYPES.trade,
            TRANSACTION_TYPES.transferOut,
            TRANSACTION_TYPES.realizedLoss,
            TRANSACTION_TYPES.marginFee,
            TRANSACTION_TYPES.realizedProfit
          ].includes(parent.transactionType) ||
            (parent.transactionType === TRANSACTION_TYPES.transferIn &&
              [
                TRANSACTION_TAGS.stakingReward,
                TRANSACTION_TAGS.gitFromOthers,
                TRANSACTION_TAGS.income,
                TRANSACTION_TAGS.airdrop,
                TRANSACTION_TAGS.fork,
                TRANSACTION_TAGS.interest,
                TRANSACTION_TAGS.mining,
                TRANSACTION_TAGS.rewards
              ].includes(parent.transactionTag))) &&
          value
        ) {
          return REGEX.numberWith2Decimals.test(value);
        }
        return true;
      }
    ),
  depositAmount: string()
    .test('depositAmountValidity', 'Required field', (value, { parent }) => {
      if (
        [TRANSACTION_TYPES.deposit].includes(parent.transactionType) &&
        !value
      ) {
        return false;
      }
      return true;
    })
    .test(
      'depositAmountNegativeValidity',
      'Must be greater than 0',
      (value, { parent }) => {
        if (
          [TRANSACTION_TYPES.deposit].includes(parent.transactionType) &&
          value &&
          Number(value) <= 0
        ) {
          return false;
        }
        return true;
      }
    )
    .test(
      'decimalError',
      'Maximum 2 decimal places allowed',
      (value, { parent }) => {
        if (
          [TRANSACTION_TYPES.deposit].includes(parent.transactionType) &&
          value
        ) {
          return REGEX.numberWith2Decimals.test(value);
        }
        return true;
      }
    ),
  withdrawalAmount: string()
    .test('withdrawalAmountValidity', 'Required field', (value, { parent }) => {
      if (
        [TRANSACTION_TYPES.withdrawal].includes(parent.transactionType) &&
        !value
      ) {
        return false;
      }
      return true;
    })
    .test(
      'withdrawalAmountNegativeValidity',
      'Must be greater than 0',
      (value, { parent }) => {
        if (
          [TRANSACTION_TYPES.withdrawal].includes(parent.transactionType) &&
          value &&
          Number(value) <= 0
        ) {
          return false;
        }
        return true;
      }
    )
    .test(
      'decimalError',
      'Maximum 2 decimal places allowed',
      (value, { parent }) => {
        if (
          [TRANSACTION_TYPES.withdrawal].includes(parent.transactionType) &&
          value
        ) {
          return REGEX.numberWith2Decimals.test(value);
        }
        return true;
      }
    ),
  fee: string()
    .test('feeValidity', 'Required field', (value, { parent }) => {
      if (
        [TRANSACTION_TYPES.transferOut, TRANSACTION_TYPES.trade].includes(
          parent.transactionType
        ) &&
        parent.feeCoinName &&
        !value
      ) {
        return false;
      }
      return true;
    })
    .test(
      'feeNegativeValidity',
      'Must be greater than 0',
      (value, { parent }) => {
        if (
          [
            TRANSACTION_TYPES.sell,
            TRANSACTION_TYPES.buy,
            TRANSACTION_TYPES.deposit,
            TRANSACTION_TYPES.withdrawal,
            TRANSACTION_TYPES.transferOut,
            TRANSACTION_TYPES.trade
          ].includes(parent.transactionType) &&
          value &&
          Number(value) <= 0
        ) {
          return false;
        }
        return true;
      }
    )
    .test(
      'decimalError',
      'Maximum 2 decimal places allowed',
      (value, { parent }) => {
        if (
          [
            TRANSACTION_TYPES.sell,
            TRANSACTION_TYPES.buy,
            TRANSACTION_TYPES.deposit,
            TRANSACTION_TYPES.withdrawal
          ].includes(parent.transactionType) &&
          value
        ) {
          return REGEX.numberWith2Decimals.test(value);
        }
        return true;
      }
    )
    .test(
      'decimalError',
      'Maximum 25 digits before or after decimal',
      (value, { parent }) => {
        if (
          [TRANSACTION_TYPES.transferOut, TRANSACTION_TYPES.trade].includes(
            parent.transactionType
          ) &&
          value
        ) {
          return REGEX.numberWith25Decimals.test(value);
        }
        return true;
      }
    ),
  feeCoinName: string().test(
    'feeCoinNameValidity',
    'Required field',
    (value, { parent }) => {
      if (
        [TRANSACTION_TYPES.trade, TRANSACTION_TYPES.transferOut].includes(
          parent.transactionType
        ) &&
        parent.fee &&
        !value
      ) {
        return false;
      }
      return true;
    }
  ),
  tdsCharged: string()
    .test('tdsValidity', 'Required field', (value, { parent, options }) => {
      if (
        [TRANSACTION_TYPES.trade, TRANSACTION_TYPES.transferOut].includes(
          parent.transactionType
        ) &&
        parent.date &&
        checkTDSStartDate(parent.date) &&
        parent.tdsCoinName &&
        checkTDSIsTransactionLevel(
          options.context as SourceListType[],
          parent.transactionSource
        ) &&
        !value
      ) {
        return false;
      }
      return true;
    })
    .test(
      'tdsChargedNegativeValidity',
      'Must be greater than 0',
      (value, { parent, options }) => {
        if (
          TDS_TRANSACTION_TYPE.includes(parent.transactionType) &&
          parent.date &&
          checkTDSStartDate(parent.date) &&
          checkTDSIsTransactionLevel(
            options.context as SourceListType[],
            parent.transactionSource
          ) &&
          value &&
          Number(value) <= 0
        ) {
          return false;
        }
        return true;
      }
    )
    .test(
      'decimalError',
      'Maximum 2 decimal places allowed',
      (value, { parent, options }) => {
        if (
          [
            TRANSACTION_TYPES.sell,
            TRANSACTION_TYPES.realizedLoss,
            TRANSACTION_TYPES.marginFee
          ].includes(parent.transactionType) &&
          parent.date &&
          checkTDSStartDate(parent.date) &&
          checkTDSIsTransactionLevel(
            options.context as SourceListType[],
            parent.transactionSource
          ) &&
          value
        ) {
          return REGEX.numberWith2Decimals.test(value);
        }
        return true;
      }
    )
    .test(
      'decimalError',
      'Maximum 25 digits before or after decimal',
      (value, { parent, options }) => {
        if (
          [TRANSACTION_TYPES.trade, TRANSACTION_TYPES.transferOut].includes(
            parent.transactionType
          ) &&
          parent.date &&
          checkTDSStartDate(parent.date) &&
          checkTDSIsTransactionLevel(
            options.context as SourceListType[],
            parent.transactionSource
          ) &&
          value
        ) {
          return REGEX.numberWith25Decimals.test(value);
        }
        return true;
      }
    ),
  tdsCoinName: string().test(
    'tdsCoinNameValidity',
    'Required field',
    (value, { parent, options }) => {
      if (
        [TRANSACTION_TYPES.trade, TRANSACTION_TYPES.transferOut].includes(
          parent.transactionType
        ) &&
        parent.tdsCharged &&
        parent.date &&
        checkTDSStartDate(parent.date) &&
        checkTDSIsTransactionLevel(
          options.context as SourceListType[],
          parent.transactionSource
        ) &&
        !value
      ) {
        return false;
      }
      return true;
    }
  ),
  notes: string().trim().max(200, 'Maximum 200 characters allowed')
});
