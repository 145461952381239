import React, { FC } from 'react';
import { Tooltip } from '@mui/material';

import { getUploadedFileName } from '@utils/generalUtils';
import { FileProcessStatus } from '@constants/mySources';
import { ActionButtons } from '@components';
import { getFileTableDisplayData, hasfileProcessing } from './utils';
import { FileListViewRowProps } from './types';

const ListViewRow: FC<FileListViewRowProps> = props => {
  const {
    ExchangeIcon,
    isGenericFile = false,
    fileUploadStatus,
    lastSync,
    isDeleteDisabled,
    actionItems,
    fileName
  } = props;

  return (
    <tr className="hover:shadow-md transition duration-300 hover:-translate-y-1">
      <td className="pt-2 ">
        <div className="flex items-center py-2 pl-6 min-w-[100px] h-14 bg-white rounded-l-md">
          {ExchangeIcon && (
            <div
              className={`${
                isGenericFile ? 'w-[110px] h-[40px]' : 'max-w-[94px] h-[20px]'
              }`}>
              <ExchangeIcon className="shrink-0 pr-4 w-full h-full " />
            </div>
          )}
        </div>
      </td>
      <td className="pt-2">
        <div className="flex items-center py-2 h-14 text-sm text-slateGrey bg-white">
          {getFileTableDisplayData(fileUploadStatus, lastSync)}
          {!hasfileProcessing(fileUploadStatus) && (
            <>
              <span className="mx-4">|</span>
              <Tooltip
                title={getUploadedFileName(fileName)}
                arrow={true}
                enterTouchDelay={0}>
                <div className="w-[15%] xl:w-[40%] 2xl:w-[45%]">
                  <div className="truncate">
                    {getUploadedFileName(fileName)}
                  </div>
                </div>
              </Tooltip>
            </>
          )}
        </div>
      </td>
      <td className="pt-2">
        <div className="flex justify-center items-center py-2 pr-6 h-14 bg-white"></div>
      </td>
      <td className="pt-2">
        <div className="flex justify-end items-center py-2 pr-6 h-14 bg-white rounded-r-md">
          <div
            className={`${
              isDeleteDisabled ||
              ![
                FileProcessStatus.FILE_PROCESSING_FAILED,
                FileProcessStatus.UPLOAD_COMPLETED
              ].includes(fileUploadStatus)
                ? 'opacity-30'
                : 'cursor-pointer'
            }`}>
            <ActionButtons
              isDisabled={
                isDeleteDisabled ||
                ![
                  FileProcessStatus.FILE_PROCESSING_FAILED,
                  FileProcessStatus.UPLOAD_COMPLETED
                ].includes(fileUploadStatus)
              }
              actionItems={actionItems}
              customStyle={
                isDeleteDisabled ||
                ![
                  FileProcessStatus.FILE_PROCESSING_FAILED,
                  FileProcessStatus.UPLOAD_COMPLETED
                ].includes(fileUploadStatus)
                  ? 'cursor-not-allowed'
                  : ''
              }
            />
          </div>
        </div>
      </td>
    </tr>
  );
};

export default ListViewRow;
