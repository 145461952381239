/* eslint-disable max-len */
import { GenericApiResponse } from 'types/generalTypes';
import {
  ItrPayloadType,
  ItrRequestCountDetails,
  QueryParamType
} from './types';
import baseApi from '@services/api';

const ItrFormBaseApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    submitItr: builder.mutation<GenericApiResponse<string>, ItrPayloadType>({
      query: ({ payload, queryParam }) => ({
        url: `/user/itr-filing?from_timestamp=${queryParam.fromTimestamp}&to_timestamp=${queryParam.toTimestamp}`,
        method: 'POST',
        body: payload
      })
    }),
    getItrRequestCount: builder.query<ItrRequestCountDetails, QueryParamType>({
      query: queryParam =>
        `/user/itr-filing/count?from_timestamp=${queryParam.fromTimestamp}&to_timestamp=${queryParam.toTimestamp}`,
      transformResponse: (
        response: GenericApiResponse<ItrRequestCountDetails>
      ) => response.result
    })
  })
});

export const { useSubmitItrMutation, useGetItrRequestCountQuery } =
  ItrFormBaseApi;
