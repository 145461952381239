import React, { FC } from 'react';

import { EXCHANGE_INFO } from '@containers/source/constants';
import { InstructionSectionProps } from './types';

const InstructionSection: FC<InstructionSectionProps> = props => {
  const { exchangeCode, type, heading } = props;

  const instruction =
    type === 'importFile' ? 'fileInstructions' : 'instructions';

  return (
    <div>
      <div className="mt-4 text-base font-semibold">{heading}</div>
      <div className="py-5 pl-5">
        <ol className="text-base font-normal list-decimal text-comet">
          {EXCHANGE_INFO[exchangeCode]?.[instruction]?.map(mainSteps => (
            <li key={mainSteps.step} className="mb-2">
              <p dangerouslySetInnerHTML={{ __html: mainSteps.step }} />
              {mainSteps?.subStep?.length > 0 && (
                <ul className="pl-5 list-disc">
                  {mainSteps.subStep.map(subStep => (
                    <li key={subStep}>
                      <p dangerouslySetInnerHTML={{ __html: subStep }} />
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default InstructionSection;
