import RoutesPath from './RoutesPath';

export const TAX_PROFESSIONAL_SHARED_CLIENT_ALLOWED_PATHS = [
  RoutesPath.DASHBOARD,
  RoutesPath.TRANSACTION,
  `${RoutesPath.TRANSACTION}/detail/:transactionId`,
  RoutesPath.SOURCE,
  RoutesPath.CONNECT_EXCHANGE,
  RoutesPath.TAX_REPORT,
  RoutesPath.TDS_TRACKER,
  `${RoutesPath.SETTINGS}/taxation`,
  `${RoutesPath.TRANSACTION}${RoutesPath.RESOLVE_ERRORS}`
];

export const TAX_PROFESSIONAL_MANAGED_CLIENT_ALLOWED_PATHS = [
  RoutesPath.DASHBOARD,
  RoutesPath.TRANSACTION,
  `${RoutesPath.TRANSACTION}/detail/:transactionId`,
  RoutesPath.SOURCE,
  RoutesPath.CONNECT_EXCHANGE,
  RoutesPath.TAX_REPORT,
  RoutesPath.TDS_TRACKER,
  `${RoutesPath.SETTINGS}/taxation`,
  `${RoutesPath.TRANSACTION}${RoutesPath.RESOLVE_ERRORS}`,
  RoutesPath.SETTINGS,
  `${RoutesPath.SETTINGS}/plans`,
  `${RoutesPath.SOURCE}/auth/:exchangeCode`
];
