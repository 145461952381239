import React from 'react';
import { useMediaQuery } from '@mui/material';

import { CircularSkeleton, TextSkeleton } from '@components';

const CryptoPortfolioSkeleton = () => {
  const match = useMediaQuery('(min-width: 1192px)');

  return (
    <>
      {match ? (
        <div className="flex flex-row justify-between w-[90%]">
          {[...Array(5)].map((item, index) => (
            <div key={index} className="mr-10 w-[7%]">
              <TextSkeleton customStyles={{ width: '50%' }} fontSize="14px" />
            </div>
          ))}
        </div>
      ) : (
        <div className=" py-2.5 px-3 m-2 bg-whiteSmoke rounded-[10px] border">
          <div className="flex flex-row">
            <CircularSkeleton height={24} width={24} />
            <div className="ml-2 w-[40%]">
              <TextSkeleton fontSize="16px" />
            </div>
          </div>
          <div className="flex flex-col w-full">
            {[...Array(4)].map((item, index) => (
              <div key={index} className="flex flex-row justify-between mt-2">
                <TextSkeleton fontSize="16px" width={100} />
                <TextSkeleton fontSize="16px" width={60} />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default CryptoPortfolioSkeleton;
