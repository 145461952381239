import { authBaseApi } from '@services/api';
import { translate } from '@utils';
import { GenericApiResponse } from 'types/generalTypes';
import {
  InviteActionPayload,
  PendingInvitesCount,
  ProfessionalListingDetails
} from './types';

const ProfessionalListingBaseApi = authBaseApi.injectEndpoints({
  endpoints: builder => ({
    getProfessionalDetails: builder.query<ProfessionalListingDetails[], void>({
      query: () => '/users/connection',
      transformResponse: (
        response: GenericApiResponse<ProfessionalListingDetails[]>
      ) => response.result,
      providesTags: ['GetProfessionalDetails']
    }),
    getPendingInviteCount: builder.query<PendingInvitesCount, void>({
      query: () => '/users/connection/count',
      transformResponse: (response: GenericApiResponse<PendingInvitesCount>) =>
        response.result,
      providesTags: ['GetPendingInviteCount']
    }),
    acceptInvite: builder.mutation<
      GenericApiResponse<string>,
      InviteActionPayload
    >({
      query: ({ payload }) => ({
        url: '/users/connection/accept',
        method: 'PATCH',
        body: payload
      }),
      extraOptions: {
        showNotifier: true,
        success: translate('notifierMessage.inviteAcceptSuccess'),
        failure: translate('notifierMessage.inviteAcceptFailed')
      },
      invalidatesTags: ['GetProfessionalDetails', 'GetPendingInviteCount']
    }),
    rejectInvite: builder.mutation<
      GenericApiResponse<string>,
      InviteActionPayload
    >({
      query: ({ payload }) => ({
        url: '/users/connection/reject',
        method: 'PATCH',
        body: payload
      }),
      extraOptions: {
        showNotifier: true,
        success: translate('notifierMessage.inviteRejectSuccess'),
        failure: translate('notifierMessage.inviteRejectFailed')
      },
      invalidatesTags: ['GetProfessionalDetails', 'GetPendingInviteCount']
    }),
    deleteConnection: builder.mutation<GenericApiResponse<string>, string>({
      query: id => ({
        url: `/users/connection/${id}`,
        method: 'DELETE'
      }),
      extraOptions: {
        showNotifier: true,
        success: translate('notifierMessage.deleteSuccess'),
        failure: translate('notifierMessage.deleteInviteFailed')
      },
      invalidatesTags: ['GetProfessionalDetails', 'GetPendingInviteCount']
    })
  })
});

export const {
  useGetProfessionalDetailsQuery,
  useGetPendingInviteCountQuery,
  useAcceptInviteMutation,
  useRejectInviteMutation,
  useDeleteConnectionMutation
} = ProfessionalListingBaseApi;
