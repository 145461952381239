import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IconButton } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  EyeCrossedIcon,
  EyeIcon,
  GoogleIcon,
  BetaLogoVertical
} from '@assets/icons';
import { Button, TextField } from '@components';
import { clearClientDetails, translate } from '@utils';
import {
  generateGoogleAuthRedirectionUrl,
  IS_AUTHENTICATED,
  USER_ROLE
} from '@constants/common';
import { signInFormSchema } from '../validate';
import { EmailPasswordSignInProps, SignInFieldValues } from '../types';

const EmailPasswordSignIn: FC<EmailPasswordSignInProps> = props => {
  const {
    handleValues,
    disabled,
    backToSignUpClick,
    forgotPasswordClick,
    role,
    redirectionPathName = null,
    extraParams
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<SignInFieldValues>({
    defaultValues: {
      email: '',
      password: ''
    },
    resolver: yupResolver(signInFormSchema)
  });

  useEffect(() => {
    // Reset form on unmount
    return () => {
      reset();
    };
  }, []);

  const googleSignInButtonCLick = () => {
    localStorage.setItem(IS_AUTHENTICATED, 'true'); //Assuming that user will login and become valid.
    clearClientDetails();
    let redirectionUrl = process.env.REACT_APP_GOOGLE_AUTH_REDIRECT_URL;
    if (redirectionPathName) {
      redirectionUrl = encodeURIComponent(
        redirectionUrl + '/#' + redirectionPathName
      );
    }
    let additionalParams = '';
    if (extraParams?.userToken)
      additionalParams = `&user_token=${extraParams.userToken}`;
    if (extraParams?.sourceCode)
      additionalParams += `&source=${extraParams.sourceCode}`;
    if (extraParams?.referralCode)
      additionalParams += `&referral_code=${extraParams.referralCode}`;
    location.assign(
      `${generateGoogleAuthRedirectionUrl(
        redirectionUrl
      )}&user_type=${role}${additionalParams}`
    );
  };

  const changeShowPasswordState = () => {
    setShowPassword(currState => !currState);
  };

  return (
    <div className="w-full h-full align-middle sm:flex sm:flex-col sm:justify-center ">
      <BetaLogoVertical className="shrink-0 mx-auto mt-4 w-[60%] sm:w-[100%]" />
      {role === USER_ROLE.PROFESSIONAL && (
        <div className="mb-11 text-[18px] font-semibold text-center text-lemonGrass">
          {translate('ca.forTaxProfessionals')}
        </div>
      )}
      {role === USER_ROLE.PERSONAL && (
        <>
          <button
            onClick={googleSignInButtonCLick}
            disabled={false}
            className={
              'flex flex-row items-center m-1 mt-11 w-full rounded-md border shadow-shadowBottom hover:opacity-90'
            }
            data-testid="googleSignInButton">
            <div className="flex items-center p-4 rounded-r border-r">
              <GoogleIcon />
            </div>
            <div className="flex justify-center items-center px-6 w-full text-base">
              {translate('signInWithGoogle')}
            </div>
          </button>
          <div className="flex overflow-hidden flex-row justify-center items-center my-7 w-full">
            <span className="flex basis-[100%] grow-0 shrink-0 mx-2 ml-8 w-full h-[1px] bg-harp" />
            <span className="flex font-medium text-slateGrey">or</span>
            <span className="flex basis-[100%] grow-0 shrink-0 mx-2 mr-8 w-full h-[1px] bg-harp" />
          </div>
        </>
      )}
      <form className="w-full" onSubmit={handleSubmit(handleValues)}>
        <div className="my-6">
          <TextField
            placeholder={translate('email')}
            name="email"
            control={control}
            errors={errors}
            type={'email'}
            isControlledField={true}
            InputLabelProps={{ style: { fontSize: 14 } }}
            InputProps={{ style: { height: 54 } }}
            testid="sign-in-email"
          />
        </div>
        <div className="mt-6 mb-[19px] sm:my-6">
          <TextField
            placeholder={translate('password')}
            autoComplete="current-password"
            name="password"
            errors={errors}
            type={showPassword ? 'text' : 'password'}
            control={control}
            isControlledField={true}
            InputLabelProps={{ style: { fontSize: 14 } }}
            showInfoIcon={true} //for showing eye icon
            endAdornment={
              <IconButton
                data-testid="showPasswordButton"
                onClick={changeShowPasswordState}>
                {showPassword ? <EyeCrossedIcon /> : <EyeIcon />}
              </IconButton>
            }
            InputProps={{ style: { height: 54 } }}
            testid="sign-in-password"
          />
          <div
            className="block mt-[5px] ml-1 text-sm text-right text-primaryColor cursor-pointer sm:hidden"
            onClick={forgotPasswordClick}
            data-testid="navigate-to-forgot-mobile">
            {translate('signInPage.forgotPassword')}
          </div>
        </div>
        <Button
          label={translate('signInPage.signIn')}
          className="flex justify-center items-center w-full h-[54px] bg-primaryColor"
          labelClass="text-[15px] font-semibold text-white text-center"
          type="submit"
          disabled={disabled}
          dataTestId="signInButton"
        />
      </form>
      <div className="flex justify-between mt-3 mb-2 w-full text-davyGrey sm:mb-0">
        <span className="ml-1 text-sm text-davyGrey ">
          {translate('signInPage.noAccount')}
          <span
            className="ml-1 text-primaryColor cursor-pointer"
            onClick={backToSignUpClick}
            data-testid="navigate-to-sign-up">
            {translate('signUpPage.signUp')}
          </span>
        </span>
        <span
          className="hidden ml-1 text-sm text-primaryColor cursor-pointer sm:block"
          onClick={forgotPasswordClick}
          data-testid="navigate-to-forgot">
          {translate('signInPage.forgotPassword')}
        </span>
      </div>
    </div>
  );
};

export default EmailPasswordSignIn;
