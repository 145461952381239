import React, { FC } from 'react';
import { Grid } from '@mui/material';

import { TextField, CoinDropdown } from '@components';
import { CoinSelectorProps } from './types';

const textFieldSX = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': { borderRadius: '5px 0px 0px 5px' },
    '&.Mui-focused fieldset': { borderRadius: '5px 0px 0px 5px' }
  }
};

const CoinSelector: FC<CoinSelectorProps> = props => {
  const {
    control,
    errors,
    isDisabled = false,
    selectedItem,
    dropDownValues,
    inputField,
    selectField,
    showSelector = true,
    componentName
  } = props;

  return (
    <Grid
      container={true}
      item={true}
      md={6}
      sm={12}
      xs={12}
      direction="row"
      alignItems="flex-start">
      <Grid
        item={true}
        xs={showSelector ? 7 : 12}
        md={showSelector ? 8 : 12}
        pr="1px">
        <TextField
          name={inputField.name}
          placeholder={inputField.placeholder}
          control={control}
          errors={errors}
          isControlledField={true}
          testid={inputField.name}
          type="number"
          isDisabled={isDisabled}
          customClass={showSelector ? textFieldSX : {}}
        />
      </Grid>
      {showSelector && (
        <Grid item={true} xs={5} md={4}>
          <CoinDropdown
            name={selectField.name}
            placeholder={selectField.placeholder}
            control={control}
            selectedItem={selectedItem}
            options={dropDownValues || []}
            errors={errors}
            isDisabled={isDisabled}
            componentName={componentName}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default CoinSelector;
