import { createSlice } from '@reduxjs/toolkit';

import { SubscriptionDetails } from '@containers/settings/subscription-plan-listing/types';

const initialState = {
  subscriptionDetails: {
    activatedAt: '',
    createdAt: '',
    currencyCode: '',
    id: '',
    planAmount: 0,
    subscriptionType: '',
    transactionUpperlimit: ''
  }
};

export const subscriptionDetailsSlice = createSlice({
  name: 'homePage',
  initialState,
  reducers: {
    updateSubscriptionDetails: (
      state,
      { payload }: { payload: SubscriptionDetails }
    ) => {
      state.subscriptionDetails = {
        activatedAt: payload.activated_at,
        createdAt: payload.created_at,
        currencyCode: payload.currency_code,
        id: payload.id,
        planAmount: payload.plan_amount,
        subscriptionType: payload.subscription_type,
        transactionUpperlimit: payload.transaction_upperlimit
      };
    }
  }
});

export const { updateSubscriptionDetails } = subscriptionDetailsSlice.actions;

export default subscriptionDetailsSlice.reducer;
