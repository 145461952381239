import { object, string } from 'yup';

import {
  REGEX,
  TDS_TRANSACTION_TYPE,
  TRANSACTION_TAGS,
  TRANSACTION_TYPES
} from '@constants/common';
import {
  checkTDSIsTransactionLevel,
  checkTDSStartDate
} from '@utils/transactionUtils';
import { SourceListType } from '@containers/source/types';

export const schedulerTransactionFormSchema = object({
  transactionTag: string().test(
    'tagValidation',
    'Required field',
    (value, { parent }) => {
      if (
        [TRANSACTION_TYPES.transferIn, TRANSACTION_TYPES.transferOut].includes(
          parent.transactionType
        ) &&
        !value
      ) {
        return false;
      }
      return true;
    }
  ),
  costBasis: string()
    .test('costBasisValidity', 'Required field', (value, { parent }) => {
      if (
        parent.transactionType === TRANSACTION_TYPES.transferIn &&
        [
          TRANSACTION_TAGS.giftFromRelative,
          TRANSACTION_TAGS.internalTransfer
        ].includes(parent.transactionTag) &&
        !value
      ) {
        return false;
      }
      return true;
    })
    .test(
      'costBasisNegativeValidity',
      'Must be greater than 0',
      (value, { parent }) => {
        if (
          parent.transactionType === TRANSACTION_TYPES.transferIn &&
          [
            TRANSACTION_TAGS.giftFromRelative,
            TRANSACTION_TAGS.internalTransfer
          ].includes(parent.transactionTag) &&
          value &&
          Number(value) <= 0
        ) {
          return false;
        }
        return true;
      }
    )
    .test(
      'decimalError',
      'Maximum 2 decimal places allowed',
      (value, { parent }) => {
        if (
          parent.transactionType === TRANSACTION_TYPES.transferIn &&
          [
            TRANSACTION_TAGS.giftFromRelative,
            TRANSACTION_TAGS.internalTransfer
          ].includes(parent.transactionTag) &&
          value
        ) {
          return REGEX.numberWith2Decimals.test(value);
        }
        return true;
      }
    ),
  marketValueOfCoin: string()
    .test(
      'marketValueOfCoinValidity',
      'Required field',
      (value, { parent }) => {
        if (
          ([
            TRANSACTION_TYPES.trade,
            TRANSACTION_TYPES.transferOut,
            TRANSACTION_TYPES.realizedLoss,
            TRANSACTION_TYPES.marginFee,
            TRANSACTION_TYPES.cost,
            TRANSACTION_TYPES.realizedProfit
          ].includes(parent.transactionType) ||
            (parent.transactionType === TRANSACTION_TYPES.transferIn &&
              [
                TRANSACTION_TAGS.stakingReward,
                TRANSACTION_TAGS.gitFromOthers,
                TRANSACTION_TAGS.income,
                TRANSACTION_TAGS.airdrop,
                TRANSACTION_TAGS.fork,
                TRANSACTION_TAGS.interest,
                TRANSACTION_TAGS.mining,
                TRANSACTION_TAGS.rewards,
                TRANSACTION_TAGS.referralBonus
              ].includes(parent.transactionTag))) &&
          !value
        ) {
          return false;
        }
        return true;
      }
    )
    .test(
      'marketValueOfCoinNegativeValidity',
      'Must be greater than 0',
      (value, { parent }) => {
        if (
          ([
            TRANSACTION_TYPES.trade,
            TRANSACTION_TYPES.transferOut,
            TRANSACTION_TYPES.realizedLoss,
            TRANSACTION_TYPES.marginFee,
            TRANSACTION_TYPES.cost,
            TRANSACTION_TYPES.realizedProfit
          ].includes(parent.transactionType) ||
            (parent.transactionType === TRANSACTION_TYPES.transferIn &&
              [
                TRANSACTION_TAGS.stakingReward,
                TRANSACTION_TAGS.gitFromOthers,
                TRANSACTION_TAGS.income,
                TRANSACTION_TAGS.airdrop,
                TRANSACTION_TAGS.fork,
                TRANSACTION_TAGS.interest,
                TRANSACTION_TAGS.mining,
                TRANSACTION_TAGS.rewards,
                TRANSACTION_TAGS.referralBonus
              ].includes(parent.transactionTag))) &&
          value &&
          Number(value) <= 0
        ) {
          return false;
        }
        return true;
      }
    )
    .test(
      'decimalError',
      'Maximum 2 decimal places allowed',
      (value, { parent }) => {
        if (
          ([
            TRANSACTION_TYPES.trade,
            TRANSACTION_TYPES.transferOut,
            TRANSACTION_TYPES.realizedLoss,
            TRANSACTION_TYPES.marginFee
          ].includes(parent.transactionType) ||
            (parent.transactionType === TRANSACTION_TYPES.transferIn &&
              [
                TRANSACTION_TAGS.stakingReward,
                TRANSACTION_TAGS.gitFromOthers,
                TRANSACTION_TAGS.income,
                TRANSACTION_TAGS.airdrop,
                TRANSACTION_TAGS.fork,
                TRANSACTION_TAGS.interest,
                TRANSACTION_TAGS.mining,
                TRANSACTION_TAGS.rewards
              ].includes(parent.transactionTag))) &&
          value
        ) {
          return REGEX.numberWith2Decimals.test(value);
        }
        return true;
      }
    ),
  tdsCharged: string()
    .test('tdsValidity', 'Required field', (value, { parent, options }) => {
      if (
        [TRANSACTION_TYPES.trade, TRANSACTION_TYPES.transferOut].includes(
          parent.transactionType
        ) &&
        parent.date &&
        checkTDSStartDate(parent.date) &&
        parent.tdsCoinName &&
        checkTDSIsTransactionLevel(
          options.context as SourceListType[],
          parent.transactionSource
        ) &&
        !value
      ) {
        return false;
      }
      return true;
    })
    .test(
      'tdsChargedNegativeValidity',
      'Must be greater than 0',
      (value, { parent, options }) => {
        if (
          TDS_TRANSACTION_TYPE.includes(parent.transactionType) &&
          parent.date &&
          checkTDSStartDate(parent.date) &&
          checkTDSIsTransactionLevel(
            options.context as SourceListType[],
            parent.transactionSource
          ) &&
          value &&
          Number(value) <= 0
        ) {
          return false;
        }
        return true;
      }
    )
    .test(
      'decimalError',
      'Maximum 2 decimal places allowed',
      (value, { parent, options }) => {
        if (
          [
            TRANSACTION_TYPES.sell,
            TRANSACTION_TYPES.realizedLoss,
            TRANSACTION_TYPES.marginFee
          ].includes(parent.transactionType) &&
          parent.date &&
          checkTDSStartDate(parent.date) &&
          checkTDSIsTransactionLevel(
            options.context as SourceListType[],
            parent.transactionSource
          ) &&
          value
        ) {
          return REGEX.numberWith2Decimals.test(value);
        }
        return true;
      }
    )
    .test(
      'decimalError',
      'Maximum 25 digits before or after decimal',
      (value, { parent, options }) => {
        if (
          [TRANSACTION_TYPES.trade, TRANSACTION_TYPES.transferOut].includes(
            parent.transactionType
          ) &&
          parent.date &&
          checkTDSStartDate(parent.date) &&
          checkTDSIsTransactionLevel(
            options.context as SourceListType[],
            parent.transactionSource
          ) &&
          value
        ) {
          return REGEX.numberWith25Decimals.test(value);
        }
        return true;
      }
    ),
  tdsCoinName: string().test(
    'tdsCoinNameValidity',
    'Required field',
    (value, { parent, options }) => {
      if (
        [TRANSACTION_TYPES.trade, TRANSACTION_TYPES.transferOut].includes(
          parent.transactionType
        ) &&
        parent.tdsCharged &&
        parent.date &&
        checkTDSStartDate(parent.date) &&
        checkTDSIsTransactionLevel(
          options.context as SourceListType[],
          parent.transactionSource
        ) &&
        !value
      ) {
        return false;
      }
      return true;
    }
  ),
  notes: string().trim().max(200, 'Maximum 200 characters allowed')
});
