import { useEffect, useState } from 'react';

export default function useClientBannerHeight(
  isClientViewBannerActive: boolean
) {
  const [clientViewBannerHeight, setClientViewBannerHeight] = useState(0);

  useEffect(() => {
    const bannerElement = document.getElementById('clientViewBanner');
    setClientViewBannerHeight(bannerElement?.offsetHeight || 0);
  }, [isClientViewBannerActive]);

  return [clientViewBannerHeight, setClientViewBannerHeight];
}
