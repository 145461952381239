import {
  addDays,
  addMonths,
  endOfWeek,
  startOfMonth,
  startOfWeek
} from 'date-fns';

import { translate } from '@utils';

export const staticDateSelectors = [
  {
    id: 'thisWeek',
    label: translate('transactionPage.thisWeek'),
    range: {
      startDate: startOfWeek(new Date()),
      endDate: new Date()
    }
  },
  {
    id: 'lastWeek',
    label: translate('transactionPage.lastWeek'),
    range: {
      startDate: startOfWeek(addDays(new Date(), -7)),
      endDate: endOfWeek(addDays(new Date(), -7))
    }
  },
  {
    id: 'thisMonth',
    label: translate('transactionPage.thisMonth'),
    range: {
      startDate: startOfMonth(new Date()),
      endDate: new Date()
    }
  },
  {
    id: 'last3Months',
    label: translate('transactionPage.last3Months'),
    range: {
      startDate: startOfMonth(addMonths(new Date(), -2)),
      endDate: new Date()
    }
  },
  {
    id: 'last6Months',
    label: translate('transactionPage.last6Months'),
    range: {
      startDate: startOfMonth(addMonths(new Date(), -5)),
      endDate: new Date()
    }
  }
];

export const PICKER_ID = {
  monthPicker1: 'monthPicker1',
  monthPicker2: 'monthPicker2',
  yearPicker1: 'yearPicker1',
  yearPicker2: 'yearPicker2'
};
