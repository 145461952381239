import { isEmpty } from 'lodash';

import {
  INDIAN_RUPEE,
  REGULAR,
  REGULAR_TRADE,
  TDS_LEVEL,
  TRANSACTION_TAGS,
  TRANSACTION_TYPES
} from '@constants/common';
import { EXCHANGE_INFO } from '@containers/source/constants';
import { TDS_VALID_AFTER_DATE } from '@constants/transaction';
import { createTimeStamp, getValueFromArray } from './generalUtils';
import {
  CoinListType,
  SourceListType,
  SourceOptionListType
} from '@containers/source/types';
import {
  createPayloadObjectType,
  TransactionArgs
} from '@components/add-transaction/types';
import { DefaultValue } from '@components/edit-transaction/types';
import { TransactionDetail } from '@containers/transactions/transaction-detail/types';
import { DefaultExchangeIcon } from '@assets/icons';

export const createPayload = (
  createPayloadObject: createPayloadObjectType
): TransactionArgs => {
  const {
    values,
    exchangesList,
    coinsList,
    exchangeCode,
    isWallet,
    isManual = true
  } = createPayloadObject;
  let payload: TransactionArgs = {
    exchange_id: values.transactionSource,
    exchange_code: !isWallet
      ? getValueFromArray(values.transactionSource, exchangesList, 'id', 'code')
      : exchangeCode,
    transaction_timestamp: createTimeStamp(values.date, values.time),
    type: values.transactionType,
    ...(values.notes && { notes: values.notes.trim() })
  };

  switch (values.transactionType) {
    case TRANSACTION_TYPES.buy:
      return {
        ...(isManual && {
          received_coin_qty: values.noOfCoins,
          received_coin_name: getValueFromArray(
            values.coinName,
            coinsList,
            'id',
            'name'
          ),
          received_coin_code: values.coinName,
          sent_coin_qty: values.paidAmount,
          ...(values.fee && {
            fee_amount: values.fee,
            fee_coin_code: INDIAN_RUPEE.code,
            fee_coin_name: INDIAN_RUPEE.name
          })
        }),
        ...payload,
        ...(values.transactionTag !== REGULAR && {
          transaction_tag: values.transactionTag,
          sent_coin_qty: values.paidAmount
        })
      };
    case TRANSACTION_TYPES.sell:
      return {
        ...(isManual && {
          sent_coin_qty: values.noOfCoins,
          sent_coin_name: getValueFromArray(
            values.coinName,
            coinsList,
            'id',
            'name'
          ),
          sent_coin_code: values.coinName,
          received_coin_qty: values.receivedAmount,
          ...(values.fee && {
            fee_amount: values.fee,
            fee_coin_code: INDIAN_RUPEE.code,
            fee_coin_name: INDIAN_RUPEE.name
          })
        }),
        ...(checkTDSIsTransactionLevel(
          exchangesList,
          values.transactionSource
        ) &&
          values.tdsCharged && {
            tds_amount: values.tdsCharged,
            tds_coin_code: INDIAN_RUPEE.code,
            tds_coin_name: INDIAN_RUPEE.name
          }),
        ...payload,
        ...(values.transactionTag !== REGULAR && {
          transaction_tag: values.transactionTag,
          received_coin_qty: values.receivedAmount
        })
      };
    case TRANSACTION_TYPES.trade:
      return {
        ...(isManual && {
          sent_coin_qty: values.noOfCoins,
          sent_coin_name: getValueFromArray(
            values.coinName,
            coinsList,
            'id',
            'name'
          ),
          received_coin_qty: values.noOfCoinsReceived,
          received_coin_name: getValueFromArray(
            values.coinNameReceived,
            coinsList,
            'id',
            'name'
          ),
          ...(values.fee && {
            fee_amount: values.fee,
            fee_coin_code: values.feeCoinName,
            fee_coin_name: getValueFromArray(
              values.feeCoinName,
              coinsList,
              'id',
              'name'
            )
          })
        }),
        ...(checkTDSIsTransactionLevel(
          exchangesList,
          values.transactionSource
        ) &&
          values.tdsCharged &&
          values.tdsCoinName &&
          checkTDSStartDate(values.date) && {
            tds_amount: values.tdsCharged,
            tds_coin_code: values.tdsCoinName,
            tds_coin_name: getValueFromArray(
              values.tdsCoinName,
              coinsList,
              'id',
              'name'
            )
          }),
        ...payload,
        sent_coin_code: values.coinName,
        received_coin_code: values.coinNameReceived,
        cost_basis: values.marketValueOfCoin,
        ...(values.transactionTag !== REGULAR_TRADE && {
          transaction_tag: values.transactionTag
        })
      };
    case TRANSACTION_TYPES.deposit:
      return {
        ...(isManual && {
          deposit_amount: values.depositAmount,
          ...(values.fee && {
            fee_amount: values.fee,
            fee_coin_code: INDIAN_RUPEE.code,
            fee_coin_name: INDIAN_RUPEE.name
          })
        }),
        ...payload
      };
    case TRANSACTION_TYPES.withdrawal:
      return {
        ...(isManual && {
          withdrawn_amount: values.withdrawalAmount,
          ...(values.fee && {
            fee_amount: values.fee,
            fee_coin_code: INDIAN_RUPEE.code,
            fee_coin_name: INDIAN_RUPEE.name
          })
        }),
        ...payload
      };
    case TRANSACTION_TYPES.transferIn:
      payload = {
        ...(isManual && {
          received_coin_qty: values.noOfCoinsReceived,
          received_coin_name: getValueFromArray(
            values.coinNameReceived,
            coinsList,
            'id',
            'name'
          ),
          received_coin_code: values.coinNameReceived
        }),
        ...payload,
        transaction_tag: values.transactionTag
      };
      if (
        [
          TRANSACTION_TAGS.giftFromRelative,
          TRANSACTION_TAGS.internalTransfer
        ].includes(values.transactionTag)
      ) {
        return {
          ...payload,
          cost_basis: values.costBasis
        };
      }
      if (
        [
          TRANSACTION_TAGS.gitFromOthers,
          TRANSACTION_TAGS.income,
          TRANSACTION_TAGS.stakingReward,
          TRANSACTION_TAGS.airdrop,
          TRANSACTION_TAGS.fork,
          TRANSACTION_TAGS.interest,
          TRANSACTION_TAGS.mining,
          TRANSACTION_TAGS.rewards,
          TRANSACTION_TAGS.referralBonus
        ].includes(values.transactionTag)
      ) {
        return {
          ...payload,
          cost_basis: values.marketValueOfCoin
        };
      }
      break;
    case TRANSACTION_TYPES.realizedProfit:
      return {
        ...(isManual && {
          received_coin_qty: values.noOfCoinsReceived,
          received_coin_name: getValueFromArray(
            values.coinNameReceived,
            coinsList,
            'id',
            'name'
          ),
          received_coin_code: values.coinNameReceived
        }),
        ...payload,
        cost_basis: values.marketValueOfCoin
      };
    case TRANSACTION_TYPES.transferOut:
      return {
        ...(isManual && {
          sent_coin_qty: values.noOfCoins,
          sent_coin_name: getValueFromArray(
            values.coinName,
            coinsList,
            'id',
            'name'
          ),
          sent_coin_code: values.coinName,
          ...(values.fee && {
            fee_amount: values.fee,
            fee_coin_code: values.feeCoinName,
            fee_coin_name: getValueFromArray(
              values.feeCoinName,
              coinsList,
              'id',
              'name'
            )
          })
        }),
        ...(checkTDSIsTransactionLevel(
          exchangesList,
          values.transactionSource
        ) &&
          values.tdsCharged &&
          values.tdsCoinName &&
          checkTDSStartDate(values.date) && {
            tds_amount: values.tdsCharged,
            tds_coin_code: values.tdsCoinName,
            tds_coin_name: getValueFromArray(
              values.tdsCoinName,
              coinsList,
              'id',
              'name'
            )
          }),
        ...payload,
        transaction_tag: values.transactionTag,
        cost_basis: values.marketValueOfCoin
      };
    case TRANSACTION_TYPES.realizedLoss:
    case TRANSACTION_TYPES.marginFee:
      return {
        ...(isManual && {
          sent_coin_qty: values.noOfCoins,
          sent_coin_name: getValueFromArray(
            values.coinName,
            coinsList,
            'id',
            'name'
          ),
          sent_coin_code: values.coinName
        }),
        ...payload,
        cost_basis: values.marketValueOfCoin
      };
    case TRANSACTION_TYPES.cost:
      return {
        ...(isManual && {
          sent_coin_qty: values.noOfCoins,
          sent_coin_name: getValueFromArray(
            values.coinName,
            coinsList,
            'id',
            'name'
          ),
          sent_coin_code: values.coinName
        }),
        ...payload,
        transaction_tag: values.transactionTag,
        cost_basis: values.marketValueOfCoin
      };
  }
};

export const checkTDSIsTransactionLevel = (
  exchangeList: SourceListType[] | SourceOptionListType[],
  source: string
) => {
  return exchangeList.find(
    item => item.id === source && item.tds_level === TDS_LEVEL.TRANSACTION
  );
};

export const checkTDSStartDate = (transactionDate: Date) => {
  const tdsStartDate = new Date(
    new Date(TDS_VALID_AFTER_DATE).setHours(0, 0, 0, 0)
  ).toISOString();

  if (dateIsValid(transactionDate)) {
    const selectedDate = new Date(
      new Date(transactionDate).setHours(0, 0, 0, 0)
    ).toISOString();
    return selectedDate >= tdsStartDate;
  }
  return false;
};

export const getFormattedExchangeList = (exchangesList: SourceListType[]) => {
  return exchangesList
    ?.filter(exchange => exchange.is_transactable)
    .map(exchange => ({
      id: exchange.id,
      name: exchange.name,
      tds_level: exchange.tds_level
    }));
};

export const generateDefaultValues = (
  transactionDetails: TransactionDetail
) => {
  let defaultValues: DefaultValue[] = [];
  let costBasisObject: DefaultValue;
  switch (transactionDetails.type) {
    case TRANSACTION_TYPES.buy:
      defaultValues = [
        { name: 'transactionType', value: transactionDetails.type },
        {
          name: 'transactionSource',
          value: transactionDetails.exchange_id
        },
        {
          name: 'transactionTag',
          value: !isEmpty(transactionDetails.tags)
            ? transactionDetails.tags[0]
            : REGULAR
        },
        {
          name: 'date',
          value: new Date(transactionDetails.transaction_timestamp)
        },
        {
          name: 'time',
          value: new Date(transactionDetails.transaction_timestamp)
        },
        { name: 'noOfCoins', value: transactionDetails.recieved_coin_qty },
        { name: 'coinName', value: transactionDetails.received_coin_code },
        { name: 'paidAmount', value: transactionDetails.sent_coin_qty },
        {
          name: 'fee',
          value:
            Number(transactionDetails.summary?.fee_amount) > 0
              ? transactionDetails.summary?.fee_amount
              : ''
        },
        { name: 'notes', value: transactionDetails.notes }
      ];
      break;
    case TRANSACTION_TYPES.sell:
      defaultValues = [
        { name: 'transactionType', value: transactionDetails.type },
        {
          name: 'transactionSource',
          value: transactionDetails.exchange_id
        },
        {
          name: 'transactionTag',
          value: !isEmpty(transactionDetails.tags)
            ? transactionDetails.tags[0]
            : REGULAR
        },
        {
          name: 'date',
          value: new Date(transactionDetails.transaction_timestamp)
        },
        {
          name: 'time',
          value: new Date(transactionDetails.transaction_timestamp)
        },
        { name: 'noOfCoins', value: transactionDetails.sent_coin_qty },
        { name: 'coinName', value: transactionDetails.sent_coin_code },
        {
          name: 'receivedAmount',
          value: transactionDetails.recieved_coin_qty
        },
        {
          name: 'fee',
          value:
            Number(transactionDetails.summary?.fee_amount) > 0
              ? transactionDetails.summary?.fee_amount
              : ''
        },
        { name: 'notes', value: transactionDetails.notes },
        {
          name: 'tdsCharged',
          value:
            Number(transactionDetails.summary?.tds_amount) > 0
              ? transactionDetails.summary?.tds_amount
              : ''
        }
      ];
      break;
    case TRANSACTION_TYPES.trade:
      defaultValues = [
        { name: 'transactionType', value: transactionDetails.type },
        {
          name: 'transactionSource',
          value: transactionDetails.exchange_id
        },
        {
          name: 'transactionTag',
          value: !isEmpty(transactionDetails.tags)
            ? transactionDetails.tags[0]
            : REGULAR_TRADE
        },
        {
          name: 'date',
          value: new Date(transactionDetails.transaction_timestamp)
        },
        {
          name: 'time',
          value: new Date(transactionDetails.transaction_timestamp)
        },
        { name: 'noOfCoins', value: transactionDetails.sent_coin_qty },
        { name: 'coinName', value: transactionDetails.sent_coin_code },
        {
          name: 'noOfCoinsReceived',
          value: transactionDetails.recieved_coin_qty
        },
        {
          name: 'coinNameReceived',
          value: transactionDetails.received_coin_code
        },
        {
          name: 'marketValueOfCoin',
          value: transactionDetails.received_coin_fiat_value
        },
        {
          name: 'fee',
          value:
            Number(transactionDetails.summary?.fee_amount) > 0
              ? transactionDetails.summary?.fee_amount
              : ''
        },
        {
          name: 'feeCoinName',
          value: transactionDetails.summary?.fee_currency_code
        },
        { name: 'notes', value: transactionDetails.notes },
        {
          name: 'tdsCharged',
          value:
            Number(transactionDetails.summary?.tds_amount) > 0
              ? transactionDetails.summary?.tds_amount
              : ''
        },
        {
          name: 'tdsCoinName',
          value: transactionDetails.summary?.tds_currency_code
        }
      ];
      break;
    case TRANSACTION_TYPES.deposit:
      defaultValues = [
        { name: 'transactionType', value: transactionDetails.type },
        {
          name: 'transactionSource',
          value: transactionDetails.exchange_id
        },
        {
          name: 'date',
          value: new Date(transactionDetails.transaction_timestamp)
        },
        {
          name: 'time',
          value: new Date(transactionDetails.transaction_timestamp)
        },
        {
          name: 'depositAmount',
          value: transactionDetails.received_coin_fiat_value
        },
        {
          name: 'fee',
          value:
            Number(transactionDetails.summary?.fee_amount) > 0
              ? transactionDetails.summary?.fee_amount
              : ''
        },
        { name: 'notes', value: transactionDetails.notes }
      ];
      break;
    case TRANSACTION_TYPES.withdrawal:
      defaultValues = [
        { name: 'transactionType', value: transactionDetails.type },
        {
          name: 'transactionSource',
          value: transactionDetails.exchange_id
        },
        {
          name: 'date',
          value: new Date(transactionDetails.transaction_timestamp)
        },
        {
          name: 'time',
          value: new Date(transactionDetails.transaction_timestamp)
        },
        {
          name: 'withdrawalAmount',
          value: transactionDetails.sent_coin_qty
        },
        {
          name: 'fee',
          value:
            Number(transactionDetails.summary?.fee_amount) > 0
              ? transactionDetails.summary?.fee_amount
              : ''
        },
        { name: 'notes', value: transactionDetails.notes }
      ];
      break;
    case TRANSACTION_TYPES.transferIn:
      if (
        !isEmpty(transactionDetails.tags) &&
        [
          TRANSACTION_TAGS.giftFromRelative,
          TRANSACTION_TAGS.internalTransfer
        ].includes(transactionDetails.tags[0])
      ) {
        costBasisObject = {
          name: 'costBasis',
          value: transactionDetails.received_coin_fiat_value
        };
      }
      if (
        !isEmpty(transactionDetails.tags) &&
        [
          TRANSACTION_TAGS.gitFromOthers,
          TRANSACTION_TAGS.income,
          TRANSACTION_TAGS.stakingReward,
          TRANSACTION_TAGS.airdrop,
          TRANSACTION_TAGS.fork,
          TRANSACTION_TAGS.interest,
          TRANSACTION_TAGS.mining,
          TRANSACTION_TAGS.rewards,
          TRANSACTION_TAGS.referralBonus
        ].includes(transactionDetails.tags[0])
      ) {
        costBasisObject = {
          name: 'marketValueOfCoin',
          value: transactionDetails.received_coin_fiat_value
        };
      }

      defaultValues = [
        { name: 'transactionType', value: transactionDetails.type },
        {
          name: 'transactionSource',
          value: transactionDetails.exchange_id
        },
        {
          name: 'date',
          value: new Date(transactionDetails.transaction_timestamp)
        },
        {
          name: 'time',
          value: new Date(transactionDetails.transaction_timestamp)
        },
        {
          name: 'transactionTag',
          value: !isEmpty(transactionDetails.tags)
            ? transactionDetails.tags[0]
            : ''
        },
        {
          name: 'noOfCoinsReceived',
          value: transactionDetails.recieved_coin_qty
        },
        {
          name: 'coinNameReceived',
          value: transactionDetails.received_coin_code
        },
        {
          name: 'fee',
          value:
            Number(transactionDetails.summary?.fee_amount) > 0
              ? transactionDetails.summary?.fee_amount
              : ''
        },
        {
          name: 'feeCoinName',
          value: transactionDetails.summary?.fee_currency_code
        },
        { name: 'notes', value: transactionDetails.notes },
        ...(costBasisObject ? [{ ...costBasisObject }] : [])
      ];
      break;
    case TRANSACTION_TYPES.realizedProfit:
      defaultValues = [
        { name: 'transactionType', value: transactionDetails.type },
        {
          name: 'transactionSource',
          value: transactionDetails.exchange_id
        },
        {
          name: 'date',
          value: new Date(transactionDetails.transaction_timestamp)
        },
        {
          name: 'time',
          value: new Date(transactionDetails.transaction_timestamp)
        },
        {
          name: 'noOfCoinsReceived',
          value: transactionDetails.recieved_coin_qty
        },
        {
          name: 'coinNameReceived',
          value: transactionDetails.received_coin_code
        },
        { name: 'notes', value: transactionDetails.notes },
        {
          name: 'marketValueOfCoin',
          value: transactionDetails.received_coin_fiat_value
        }
      ];
      break;

    case TRANSACTION_TYPES.transferOut:
      defaultValues = [
        { name: 'transactionType', value: transactionDetails.type },
        {
          name: 'transactionSource',
          value: transactionDetails.exchange_id
        },
        {
          name: 'date',
          value: new Date(transactionDetails.transaction_timestamp)
        },
        {
          name: 'time',
          value: new Date(transactionDetails.transaction_timestamp)
        },
        {
          name: 'transactionTag',
          value: !isEmpty(transactionDetails.tags)
            ? transactionDetails.tags[0]
            : ''
        },
        { name: 'noOfCoins', value: transactionDetails.sent_coin_qty },
        { name: 'coinName', value: transactionDetails.sent_coin_code },
        {
          name: 'marketValueOfCoin',
          value: transactionDetails.sent_coin_fiat_value
        },
        {
          name: 'fee',
          value:
            Number(transactionDetails.summary?.fee_amount) > 0
              ? transactionDetails.summary?.fee_amount
              : ''
        },
        {
          name: 'feeCoinName',
          value: transactionDetails.summary?.fee_currency_code
        },
        { name: 'notes', value: transactionDetails.notes },
        {
          name: 'tdsCharged',
          value:
            Number(transactionDetails.summary?.tds_amount) > 0
              ? transactionDetails.summary?.tds_amount
              : ''
        },
        {
          name: 'tdsCoinName',
          value: transactionDetails.summary?.tds_currency_code
        }
      ];
      break;
    case TRANSACTION_TYPES.cost:
    case TRANSACTION_TYPES.realizedLoss:
    case TRANSACTION_TYPES.marginFee:
      defaultValues = [
        { name: 'transactionType', value: transactionDetails.type },
        {
          name: 'transactionSource',
          value: transactionDetails.exchange_id
        },
        {
          name: 'date',
          value: new Date(transactionDetails.transaction_timestamp)
        },
        {
          name: 'time',
          value: new Date(transactionDetails.transaction_timestamp)
        },
        { name: 'noOfCoins', value: transactionDetails.sent_coin_qty },
        { name: 'coinName', value: transactionDetails.sent_coin_code },
        {
          name: 'marketValueOfCoin',
          value: transactionDetails.sent_coin_fiat_value
        },
        { name: 'notes', value: transactionDetails.notes }
      ];
      break;
  }
  return defaultValues;
};

export const dateIsValid = (dateVal: Date) => {
  return !isNaN(new Date(dateVal).getTime());
};

export const getFormattedCoinList = (coinsList: CoinListType[]) => {
  return coinsList
    ?.map(coin => ({
      id: coin.coin_code,
      name: coin.coin_name,
      icon: coin.coin_icon
    }))
    ?.sort((a, b) => a?.name.localeCompare(b?.name));
};

export const getGainLossStyle = (
  gain_or_loss: number,
  customColor?: string
) => {
  if (!gain_or_loss) return customColor || 'text-davyGrey';
  if (gain_or_loss > 0) return 'text-greenHaze';
  return 'text-coralRed';
};

export const getExchangeIcon = (
  exchangeCode: string,
  type?: 'small' | 'icon'
) =>
  exchangeCode
    ? EXCHANGE_INFO[exchangeCode]?.[type === 'icon' ? 'icon' : 'smallIcon']
    : DefaultExchangeIcon;
