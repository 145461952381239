import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Tooltip, useMediaQuery } from '@mui/material';

import { Accordian, If } from '@components';
import {
  INDIAN_RUPEE,
  SORTING_ORDER,
  SORTING_ORDER_DECIMAL,
  SORTING_ORDER_STRING
} from '@constants/common';
import { translate } from '@utils/locale';
import { RootState } from '@store/reducers';
import {
  formatDecimalPecentage,
  getFormattedNumbering,
  getNumberInIndianFormat,
  sortArrayOfObjects
} from '@utils/generalUtils';
import { getExchangeIcon, getGainLossStyle } from '@utils/transactionUtils';
import { SourceSectionSkeleton } from '@containers/skeleton-loader';
import { useGetPortfolioSourceQuery } from '@containers/dashboard/api';
import {
  AscendingIcon,
  AscendingTransparentIcon,
  DefaultExchangeIcon,
  DescendingIcon,
  DescendingTransparentIcon
} from '@assets/icons';
import { SourceBalanceData, SourceSectionProps } from '../types';
import ImageFetcher from '@components/image-fetcher/ImageFetcher';
import DefaultCoinIcon from '@assets/icons/defaultCoinIcon.svg';
import SourcesMobileView from './sources-mobile-view/SourcesMobileView';
import NftSummary from '../nft-summary/NftSummary';

const accordianStyleConfig = {
  accordianStyle: 'bg-white px-0',
  accordianHeadingStyle: '',
  accordianHeadingWrapperStyle:
    'lg:sticky lg:top-[10px] lg:z-30 bg-white lg:pt-[10px]',
  accordianIconStyle: 'ml-4',
  accordianBodyHeightOffset: 50
};

const cryptoAssetTableHeading = [
  {
    title: 'exchangeOrWallet',
    style: 'w-[35%]',
    data: 'source_name',
    order: SORTING_ORDER_STRING
  },
  {
    title: 'investedAmount',
    style: 'w-[19.5%]',
    data: 'cost_basis',
    order: SORTING_ORDER_DECIMAL
  },
  {
    title: 'marketValue',
    style: 'w-[19.5%]',
    data: 'market_value',
    order: SORTING_ORDER_DECIMAL
  },
  {
    title: 'gainOrLoss',
    style: 'mr-10 w-1/6',
    data: 'gain_or_loss',
    order: SORTING_ORDER_DECIMAL
  }
];

const cryptoAssetRowHeading = [
  { title: 'mobileView.assets', style: 'w-[17%]' },
  { title: 'totalAssets', style: 'w-[17%]' },
  { title: 'investedAmount', style: 'w-1/5' },
  { title: 'marketValue', style: 'w-1/5' },
  { title: 'gainOrLoss', style: 'w-[19%]' }
];

const SourcesSection: FC<SourceSectionProps> = ({ topContentHeight }) => {
  const { clientId } = useSelector((state: RootState) => state.rootReducer.app);

  const { isFetching: isFetchingPortfolioSource, data: portfolioSource } =
    useGetPortfolioSourceQuery(clientId);

  const [openCryptoAssetKey, setOpenCryptoAssetKey] = useState();
  const [sortingKey, setSortingKey] = useState('');
  const [sourcePortfolioData, setSourcePortfolioData] = useState([]);
  const [sortingOrder, setSortingOrder] = useState('');

  const match = useMediaQuery('(min-width: 1192px)'); // TODO: to be updated when working on proper responsiveness

  useEffect(() => {
    if (!isFetchingPortfolioSource)
      setSourcePortfolioData(portfolioSource.source_balance_details);
  }, [isFetchingPortfolioSource]);

  useEffect(() => {
    const sortedSourceData = sortArrayOfObjects(
      portfolioSource?.source_balance_details,
      sortingKey,
      sortingOrder
    );
    setSourcePortfolioData(sortedSourceData);
  }, [sortingKey, sortingOrder]);

  //handler to open one accordian body at a time
  const handleCryptoAssetToggle = key => {
    setOpenCryptoAssetKey(openCryptoAssetKey !== key ? key : null);
  };

  const applySorting = (key: string, order) => {
    setOpenCryptoAssetKey(null);
    if (key === sortingKey)
      setSortingOrder(currentState => order[currentState]);
    else {
      setSortingOrder(order[SORTING_ORDER.DEFAULT]);
      setSortingKey(key);
    }
  };

  const getOrderIcon = (key: string) => {
    const DescIcon =
      key === sortingKey && sortingOrder === SORTING_ORDER.DESC
        ? DescendingIcon
        : DescendingTransparentIcon;
    const AscIcon =
      key === sortingKey && sortingOrder === SORTING_ORDER.ASC
        ? AscendingIcon
        : AscendingTransparentIcon;
    return (
      <span className="flex flex-col">
        <DescIcon className="mb-[2px]" />
        <AscIcon />
      </span>
    );
  };

  //crypto accordian head section
  const cryptoAssetHeading = (asset: SourceBalanceData) => {
    const {
      source_name,
      source_code,
      market_value,
      gain_or_loss,
      gain_or_loss_percent,
      source_icon,
      cost_basis
    } = asset;
    const ExchangeIcon =
      getExchangeIcon(source_code, 'small') || DefaultExchangeIcon;
    return (
      <div className="flex w-full">
        <div className="flex items-center w-[36%] text-base font-semibold text-davyGrey">
          <div
            className=" flex justify-center items-center mr-2 w-[25px] min-w-[25px]
                  h-[25px] rounded-[50%] border-[1px] md:w-[33px]  md:min-w-[33px] md:h-[33px]">
            {source_icon ? (
              <img className="w-8 h-8" src={source_icon} />
            ) : (
              <ExchangeIcon className="w-auto max-w-full h-auto max-h-full" />
            )}
          </div>
          <Tooltip title={source_name} arrow={true} enterTouchDelay={0}>
            <span className="w-fit truncate"> {source_name}</span>
          </Tooltip>
        </div>
        <div className="flex items-center w-[20%] text-base font-semibold text-davyGrey">
          <Tooltip
            title={getNumberInIndianFormat(
              Number(cost_basis),
              INDIAN_RUPEE.code,
              true
            )}
            arrow={true}
            enterTouchDelay={0}
            placement="bottom-start"
            PopperProps={{ disablePortal: true }}>
            <div className="w-fit">
              {getFormattedNumbering({
                value: cost_basis,
                showZeroValue: true
              })}
            </div>
          </Tooltip>
        </div>
        <div className="flex items-center w-[20%] text-base font-semibold text-davyGrey">
          <Tooltip
            title={getNumberInIndianFormat(
              Number(market_value),
              INDIAN_RUPEE.code,
              true
            )}
            arrow={true}
            enterTouchDelay={0}
            placement="bottom-start"
            PopperProps={{ disablePortal: true }}>
            <div className="w-fit">
              {getFormattedNumbering({ value: market_value })}
            </div>
          </Tooltip>
        </div>
        <div
          className={`${getGainLossStyle(
            Number(gain_or_loss)
          )} flex items-center flex-wrap ${
            !asset?.crypto_balance_details ? 'mr-8' : ''
          }
          w-[24%] text-base font-semibold text-davyGrey`}>
          <>
            <Tooltip
              title={getNumberInIndianFormat(
                Number(gain_or_loss),
                INDIAN_RUPEE.code,
                true
              )}
              arrow={true}
              enterTouchDelay={0}
              placement="bottom-start"
              PopperProps={{ disablePortal: true }}>
              <div className="w-fit">
                {getFormattedNumbering({ value: gain_or_loss })}
              </div>
            </Tooltip>
            &nbsp;
            <If condition={Number(gain_or_loss_percent)}>
              <Tooltip
                title={`${parseFloat(gain_or_loss_percent).toFixed(2)}%`}
                arrow={true}
                enterTouchDelay={0}
                placement="bottom-start"
                PopperProps={{ disablePortal: true }}>
                <div
                  className={`w-fit ${getGainLossStyle(Number(gain_or_loss))}`}>
                  {`(${
                    Number(gain_or_loss_percent) > 0 ? '+' : ''
                  }${formatDecimalPecentage(gain_or_loss_percent)})`}
                </div>
              </Tooltip>
            </If>
          </>
        </div>
      </div>
    );
  };

  const cryptoDetailTable = cryptoData => (
    <div className="p-4 bg-whiteSmoke rounded-md sm:p-5">
      <div className="flex pb-4 text-sm font-normal text-slateGrey bg-whiteSmoke lg:sticky lg:top-[106px] lg:pt-3">
        {cryptoAssetRowHeading.map((heading, key) => (
          <div key={key} className={heading.style}>
            {translate(`dashboard.${heading.title}`)}
          </div>
        ))}
      </div>
      {cryptoData?.crypto_balance_details?.map((cryptoAsset, key) => {
        const {
          quantity,
          market_value,
          gain_or_loss,
          currency_code,
          wallet_nickname,
          currency_icon,
          cost_basis
        } = cryptoAsset;
        return (
          <div key={key}>
            <div className="flex flex-col py-4 text-sm font-normal text-davyGrey sm:flex-row ">
              <div className="w-[17%]">
                {wallet_nickname ? (
                  <Tooltip
                    title={wallet_nickname}
                    arrow={true}
                    enterTouchDelay={0}>
                    <div className="w-fit truncate">{wallet_nickname}</div>
                  </Tooltip>
                ) : (
                  <>
                    <div className="flex flex-row items-center w-[17%]">
                      <ImageFetcher
                        imageUrl={currency_icon}
                        defaultImage={DefaultCoinIcon}
                        imageStyle="w-auto max-w-[20px] h-auto max-h-[20px]"
                      />
                      <span className="ml-2">{currency_code}</span>
                    </div>
                  </>
                )}
              </div>
              <div className="w-[17%]">
                <Tooltip
                  title={`${getNumberInIndianFormat(
                    Number(quantity),
                    '',
                    true
                  )} ${currency_code}`}
                  arrow={true}
                  enterTouchDelay={0}
                  placement="bottom-start"
                  PopperProps={{ disablePortal: true }}>
                  <div className="w-fit">
                    {`${getNumberInIndianFormat(Number(quantity), '')}`}
                  </div>
                </Tooltip>
              </div>
              <div className="w-1/5">
                <Tooltip
                  title={getNumberInIndianFormat(
                    Number(cost_basis),
                    INDIAN_RUPEE.code,
                    true
                  )}
                  arrow={true}
                  enterTouchDelay={0}
                  placement="bottom-start"
                  PopperProps={{ disablePortal: true }}>
                  <div className="w-fit">
                    {getFormattedNumbering({
                      value: cost_basis,
                      showZeroValue: true
                    })}
                  </div>
                </Tooltip>
              </div>
              <div className="w-1/5">
                <Tooltip
                  title={getNumberInIndianFormat(
                    Number(market_value),
                    INDIAN_RUPEE.code,
                    true
                  )}
                  arrow={true}
                  enterTouchDelay={0}
                  placement="bottom-start"
                  PopperProps={{ disablePortal: true }}>
                  <div className="w-fit">
                    {getFormattedNumbering({ value: market_value })}
                  </div>
                </Tooltip>
              </div>
              <div
                className={`${getGainLossStyle(Number(gain_or_loss))} w-[18%]`}>
                <Tooltip
                  title={getNumberInIndianFormat(
                    Number(gain_or_loss),
                    INDIAN_RUPEE.code,
                    true
                  )}
                  arrow={true}
                  enterTouchDelay={0}
                  placement="bottom-start"
                  PopperProps={{ disablePortal: true }}>
                  <div
                    className={`w-fit ${getGainLossStyle(
                      Number(gain_or_loss)
                    )}`}>
                    {getFormattedNumbering({ value: gain_or_loss })}
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
  //crypto accordian body section
  const cryptoAssetBody = cryptoData => (
    <>
      {cryptoData?.crypto_balance_details && (
        <>
          <div className="pt-8 pb-4 text-slateGrey bg-white lg:sticky lg:top-[36px] lg:z-10">
            {translate('dashboard.mobileView.assets')}
          </div>
          {cryptoDetailTable(cryptoData)}
        </>
      )}
      {cryptoData?.nft_balance_details && (
        <NftSummary
          nftData={cryptoData?.nft_balance_details}
          sortData={false}
          customStyle={'bg-whiteSmoke'}
          accordianTableHeadingStyle="bg-whiteSmoke lg:top-[108px] lg:pt-4"
          headingStyle="sticky top-[40px] z-20 pt-7"
        />
      )}
    </>
  );

  return isFetchingPortfolioSource ? (
    <SourceSectionSkeleton />
  ) : (
    <div
      className="p-5 w-full sm:p-8 lg:overflow-auto customNormalScroll"
      style={{
        maxHeight: match ? `calc(100vh - ${topContentHeight + 45}px)` : ''
      }}>
      {!isEmpty(portfolioSource?.source_balance_details) &&
        (match ? (
          <div>
            <div className="px-6 pt-3 pb-2 bg-white rounded-lg border border-harp">
              <div
                className="flex pb-3 text-sm font-normal text-slateGrey bg-white border-b
             lg:sticky lg:top-[-34px] lg:z-40 lg:pt-3">
                {cryptoAssetTableHeading.map((heading, key) => (
                  <div
                    key={key}
                    className={`flex items-center ${heading.style}`}>
                    <span
                      className="inline-block cursor-pointer"
                      onClick={() => applySorting(heading.data, heading.order)}>
                      <span className="flex flex-row items-center">
                        {translate(`dashboard.${heading.title}`)}
                        <span className="flex justify-center items-center pl-1">
                          {getOrderIcon(heading.data)}
                        </span>
                      </span>
                    </span>
                  </div>
                ))}
              </div>
              {sourcePortfolioData?.map((cryptoAsset, index) => (
                <div key={index}>
                  <Accordian
                    dataTestId="accordianHeading"
                    styleConfig={accordianStyleConfig}
                    contentConfig={{
                      accordianHeading: cryptoAssetHeading(cryptoAsset),
                      accordianBody:
                        !(
                          isEmpty(cryptoAsset?.crypto_balance_details) &&
                          isEmpty(cryptoAsset?.nft_balance_details)
                        ) && cryptoAssetBody(cryptoAsset),
                      handleOnClickHeading: () => handleCryptoAssetToggle(index)
                    }}
                    conditionalFlag={{
                      chevronDownPresent: !!cryptoAsset?.crypto_balance_details,
                      showDetails: openCryptoAssetKey === index
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <SourcesMobileView
            exchangeData={portfolioSource?.source_balance_details}
          />
        ))}
    </div>
  );
};

export default SourcesSection;
