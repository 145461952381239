import React, { FC, useCallback, useState } from 'react';

import { Button, Tooltip, StringToTSX } from '@components';
import { CoinCollection } from '@assets/images';
import {
  DISCOUNT_TYPE,
  DISCOUNT_TYPE_MAPPER,
  OFFER_TYPE,
  PROMOTION_TYPE
} from '@constants/common';
import { eventKeyMapper } from '@constants/gaEvents';
import { gAEventTracker, getFormattedNumbering, translate } from '@utils';
import { CopyIcon } from '@assets/icons';
import { OfferBadgeProps } from './types';

const OfferBadge: FC<OfferBadgeProps> = props => {
  const {
    discountDetails,
    isRedeemDisabled,
    onRedeemClick,
    isEligibleToRedeem
  } = props;
  const {
    coupon_code: couponCode,
    promotion_name: partnerName,
    promotion_type: promotionType,
    discount_value: discountValue,
    discount_type: discountType,
    offer_type: offerType
  } = discountDetails;

  const [showCopiedText, setShowCopiedText] = useState(false);

  const showCopiedTextPopUp = () => {
    setShowCopiedText(true);
    setTimeout(() => setShowCopiedText(false), 2000);
  };

  const onCopyClick = () => {
    gAEventTracker(eventKeyMapper.couponCodeCopied);
    navigator.clipboard.writeText(couponCode);
    showCopiedTextPopUp();
  };

  const getAvailOfferText = () => {
    const offerValue =
      discountType === DISCOUNT_TYPE.percentage
        ? discountValue + DISCOUNT_TYPE_MAPPER[discountType]
        : getFormattedNumbering({ value: discountValue });
    return offerType === OFFER_TYPE.cashback
      ? translate('subscription.availCashbackText', {
          cashback: offerValue,
          exchangeName: partnerName
        })
      : translate('subscription.availDiscountText', {
          discountValue: offerValue
        });
  };

  const getOfferText = useCallback(() => {
    if (couponCode) {
      return <StringToTSX domString={getAvailOfferText()} />;
    } else if (!isEligibleToRedeem) {
      return (
        <StringToTSX
          domString={translate(
            offerType === OFFER_TYPE.cashback
              ? 'subscription.cashbackText'
              : 'subscription.discountText',
            {
              exchangeName: partnerName
            }
          )}
        />
      );
    } else if (isEligibleToRedeem && !couponCode) {
      return (
        <StringToTSX
          domString={translate(
            offerType === OFFER_TYPE.cashback
              ? 'subscription.redeemCashbackText'
              : 'subscription.redeemCouponText',
            {
              exchangeName:
                promotionType === PROMOTION_TYPE.exchangePartner
                  ? partnerName + ' partner'
                  : partnerName
            }
          )}
        />
      );
    }
  }, [
    isEligibleToRedeem,
    couponCode,
    partnerName,
    discountType,
    discountValue
  ]);

  return (
    <div className="flex flex-row w-full">
      <div className="z-10 w-4 min-h-[83px] bg-white" />
      <div
        className="flex relative  flex-col items-center px-5 pb-5 w-full min-h-[83px] bg-lightOrange
        bg-offerBadgeBackground rounded-[5px] border-[1px] border-cloud border-dashed
        lg:flex-row lg:py-2 lg:pr-6 lg:pl-[15px]">
        <img src={CoinCollection} alt="" className="px-[7px]" />
        <div className="pr-0 pb-3 text-sm font-medium text-center text-blackGreen lg:py-0 lg:pr-[18px] lg:text-left">
          {getOfferText()}
        </div>
        <div
          className="absolute top-[50%] -left-2 w-4  h-4 bg-white rounded-full border-[1px]
           border-cloud border-dashed lg:top-[40%]"
        />
        <div
          className="absolute top-[50%] -right-2 w-4  h-4 bg-white rounded-full border-[1px]
        border-cloud border-dashed lg:top-[40%]"
        />
        {couponCode ? (
          <div
            className=" py-[7px] px-3 whitespace-nowrap bg-white
         rounded-[5px] border-[1px] border-paleSlate border-dashed opacity-70">
            <div className="inline-block text-sm font-medium text-davyGrey ">
              {translate('subscription.couponCode')}
            </div>
            <div className="inline-block ml-1 text-sm font-bold text-blackGreen">
              {couponCode}
            </div>

            {/* copy to clipboard section */}
            <div className="inline-block ml-[9px]">
              <Tooltip
                onHover={false}
                visibility={showCopiedText}
                onTop={false}
                body={
                  <div className="text-sm text-davyGrey">
                    {translate('copied')}
                  </div>
                }>
                <div
                  className="flex justify-center items-center w-6 h-6 bg-harp rounded-full cursor-pointer"
                  onClick={onCopyClick}>
                  <CopyIcon width={15} height={15} />
                </div>
              </Tooltip>
            </div>
          </div>
        ) : (
          <Button
            label={translate('subscription.redeemNow')}
            className="py-[9px] px-[17px] min-w-[125px] text-white bg-primaryColor
              disabled:bg-slateGrey rounded-md disabled:border-[1px] disabled:border-black
              disabled:opacity-50"
            labelClass="text-sm font-medium"
            onClick={onRedeemClick}
            disabled={isRedeemDisabled}
          />
        )}
      </div>
      <div className="z-10 w-4 min-h-[83px] bg-white" />
    </div>
  );
};

export default OfferBadge;
