import React, { FC, useState } from 'react';
import { Tooltip } from '@mui/material';

import { Accordian } from '@components';
import { INDIAN_RUPEE } from '@constants/common';
import { translate } from '@utils/locale';
import { getExchangeIcon, getGainLossStyle } from '@utils/transactionUtils';
import {
  formatDecimalPecentage,
  getFormattedNumbering,
  getNumberInIndianFormat
} from '@utils/generalUtils';
import { DefaultExchangeIcon } from '@assets/icons';
import { SourceBalanceData, SourcesMobileViewProps } from '../../types';
import SourceExchangeDetailsCard from './source-exchange-details-card/SourceExchangeDetailsCard';
import NftCard from '../../nft-summary/NftCard';

const accordianStyleConfig = {
  accordianStyle: 'bg-white px-0 w-full rounded-[10px] border',
  accordianHeadingWrapperStyle: 'flex-col',
  accordianIconStyle: 'sm:ml-6',
  accordianBodyHeightOffset: 50
};

const SourcesMobileView: FC<SourcesMobileViewProps> = props => {
  const { exchangeData } = props;
  const [openCryptoAssetKey, setOpenCryptoAssetKey] = useState();

  const handleCryptoAssetToggle = key => {
    setOpenCryptoAssetKey(openCryptoAssetKey !== key ? key : null);
  };

  const exchangeAssetHeading = (exchange: SourceBalanceData) => {
    const {
      source_name,
      source_code,
      market_value,
      gain_or_loss,
      gain_or_loss_percent,
      source_icon,
      cost_basis
    } = exchange;

    const ExchangeIcon = getExchangeIcon(source_code) || DefaultExchangeIcon;

    return (
      <div className="px-5 my-2 w-full text-xs font-normal text-slateGrey bg-white sm:px-6 sm:text-base">
        <div className="flex items-center">
          <div
            className=" flex shrink-0 justify-center items-center mr-2 w-[25px] min-w-[25px]
                  h-[25px] rounded-[50%] border-[1px] md:w-[33px]  md:min-w-[33px] md:h-[33px]">
            {source_icon ? (
              <img
                className="shrink-0 w-8 max-w-full h-8 max-h-full"
                src={source_icon}
              />
            ) : (
              <ExchangeIcon className="shrink-0 w-auto max-w-full h-auto max-h-full" />
            )}
          </div>
          <Tooltip title={source_name} arrow={true} enterTouchDelay={0}>
            <span className="truncate">{source_name}</span>
          </Tooltip>
        </div>
        <div className="flex justify-between my-1">
          <div>{translate('dashboard.mobileView.investedAmount')}</div>
          <div className="font-semibold text-davyGrey">
            <Tooltip
              title={getNumberInIndianFormat(
                Number(cost_basis),
                INDIAN_RUPEE.code,
                true
              )}
              arrow={true}
              enterTouchDelay={0}
              placement="bottom-start">
              <div className="w-fit">
                {getFormattedNumbering({
                  value: cost_basis,
                  showZeroValue: true
                })}
              </div>
            </Tooltip>
          </div>
        </div>
        <div className="flex justify-between mb-1">
          <div>{translate('dashboard.mobileView.marketValue')}</div>
          <div className="font-semibold text-davyGrey">
            <Tooltip
              title={getNumberInIndianFormat(
                Number(market_value),
                INDIAN_RUPEE.code,
                true
              )}
              arrow={true}
              enterTouchDelay={0}
              placement="bottom-start">
              <div className="w-fit">
                {getFormattedNumbering({ value: market_value })}
              </div>
            </Tooltip>
          </div>
        </div>
        <div className="flex justify-between mb-1">
          <div>{translate('dashboard.mobileView.gainOrLoss')}</div>
          <div
            className={`text-davyGrey font-semibold ${getGainLossStyle(
              Number(gain_or_loss)
            )}`}>
            <Tooltip
              title={getNumberInIndianFormat(
                Number(gain_or_loss),
                INDIAN_RUPEE.code,
                true
              )}
              arrow={true}
              enterTouchDelay={0}
              placement="bottom-start">
              <div
                className={`flex justify-end ${getGainLossStyle(
                  Number(gain_or_loss)
                )}`}>
                {getFormattedNumbering({ value: gain_or_loss })}
              </div>
            </Tooltip>
            <span>
              {Number(gain_or_loss_percent)
                ? formatDecimalPecentage(gain_or_loss_percent)
                : ''}
            </span>
          </div>
        </div>
        <div
          className={`${
            Number(exchange?.quantity) !== 0 ? 'mt-2.5 mb-3.5 border-b' : ''
          }`}
        />
      </div>
    );
  };

  const exchangeAssetBody = (exchange: SourceBalanceData) => {
    return (
      <>
        {exchange?.crypto_balance_details && (
          <>
            <div className="pt-8 pb-4 pl-5 text-sm sm:text-base">
              {translate('assets')}
            </div>
            <div className="flex overflow-x-auto ml-5 sm:w-[calc(100%-32px)]">
              {exchange?.crypto_balance_details?.map((cryptoData, index) => {
                const hasOnlyOneData =
                  exchange?.crypto_balance_details?.length === 1;
                return (
                  <SourceExchangeDetailsCard
                    key={index}
                    data={cryptoData}
                    hasOnlyOneData={hasOnlyOneData}
                  />
                );
              })}
            </div>
          </>
        )}
        {exchange?.nft_balance_details && (
          <>
            <div className="pt-8 pb-4 pl-5 text-sm sm:text-base">
              {translate('dashboard.nft')}
            </div>
            <div className="flex overflow-x-auto ml-5 sm:w-[calc(100%-32px)]">
              {exchange?.nft_balance_details?.map((nftData, index) => {
                return (
                  <NftCard
                    key={index}
                    nftCard={nftData}
                    customStyles={
                      'bg-whiteSmoke border mr-3 rounded-[10px] text-xs text-black'
                    }
                  />
                );
              })}
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <div className="mt-2">
      {exchangeData?.map((exchange, index) => (
        <div key={index} className="mb-2">
          <Accordian
            dataTestId="accordianHeading"
            styleConfig={accordianStyleConfig}
            contentConfig={{
              accordianHeading: exchangeAssetHeading(exchange),
              accordianBody: exchangeAssetBody(exchange),
              handleOnClickHeading: () => handleCryptoAssetToggle(index)
            }}
            conditionalFlag={{
              chevronDownPresent: !!Number(exchange?.quantity),
              showDetails: openCryptoAssetKey === index
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default SourcesMobileView;
