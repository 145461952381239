import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  ForwardRefRenderFunction
} from 'react';

import { ImperativeRefType, RippleProps } from './types';

const RippleEffect: ForwardRefRenderFunction<ImperativeRefType, RippleProps> = (
  props,
  ref
) => {
  const { rippleClass = 'bg-tealishBlue' } = props;

  const [coordinates, setCoordinates] = useState({ x: -1, y: -1 });
  const [isRippling, setIsRippling] = useState(false);

  useEffect(() => {
    if (coordinates.x !== -1 && coordinates.y !== -1) {
      setIsRippling(true);
      setTimeout(() => setIsRippling(false), 400);
    } else setIsRippling(false);
  }, [coordinates]);

  useImperativeHandle(ref, () => ({
    getMouseClickPosition: event => {
      const target = event.target as HTMLButtonElement;
      const rect = target.getBoundingClientRect();
      setCoordinates({
        x: event.clientX - rect.left,
        y: event.clientY - rect.top
      });
    }
  }));

  return (
    <>
      {isRippling && (
        <span
          className={`block absolute w-7 h-7 ${rippleClass} rounded-full opacity-100 
  animate-ripple-effect`}
          style={{ left: coordinates.x, top: coordinates.y }}
        />
      )}
    </>
  );
};

export default forwardRef(RippleEffect);
