import { NOTIFIER_MESSAGE_TYPES } from '@constants/common';
import {
  appendClientIdInEndpoints,
  restartJobStatusPolling,
  getNotifierMessage,
  translate
} from '@utils';
import {
  AddExchangePayload,
  BlockchainListType,
  ExchangeLinkingDetails,
  GetExchangesPayload,
  SourceListType,
  WalletListType
} from './types';
import baseApi from '@services/api';
import { BlockChainPayload, WalletsPayload } from '@containers/source/types';
import {
  GenericApiResponse,
  GenericPaginatedAPiResponse
} from 'types/generalTypes';
import { ConnectWalletPayload } from './components/types';

const ConnectExchangeBaseApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getExchanges: builder.query<SourceListType[], GetExchangesPayload>({
      query: ({ searchTerm }) =>
        searchTerm && searchTerm.length > 0
          ? `/exchange?search=${encodeURIComponent(searchTerm)}`
          : '/exchange',
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.getExchangesError')
      },
      transformResponse: (response: GenericApiResponse<SourceListType[]>) =>
        response.result
    }),
    getBlockchains: builder.query<BlockchainListType[], BlockChainPayload>({
      query: ({ searchTerm }) =>
        searchTerm && searchTerm.length > 0
          ? `/block-chain?search=${encodeURIComponent(searchTerm)}`
          : '/block-chain',
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.getBlockchainsError')
      },
      transformResponse: (response: GenericApiResponse<BlockchainListType[]>) =>
        response.result
    }),
    getBlockchainWallets: builder.query<
      GenericPaginatedAPiResponse<WalletListType[]>,
      WalletsPayload
    >({
      query: ({ searchTerm, page = 0, limit = 36, clientId }) =>
        searchTerm && searchTerm.length > 0
          ? appendClientIdInEndpoints(
              `/block-chain/wallet?search=${encodeURIComponent(
                searchTerm
              )}&page=${page}&limit=${limit}`,
              clientId
            )
          : appendClientIdInEndpoints(
              `/block-chain/wallet?page=${page}&limit=${limit}`,
              clientId
            ),
      extraOptions: {
        showNotifier: true,
        failure: translate('notifierMessage.getWalletsError')
      }
    }),
    addExchange: builder.mutation<
      GenericApiResponse<string>,
      AddExchangePayload
    >({
      query: ({ payload, clientId }) => ({
        url: appendClientIdInEndpoints('/user/exchange/connect', clientId),
        method: 'POST',
        body: payload
      }),
      extraOptions: {
        showNotifier: true,
        failure: getNotifierMessage(NOTIFIER_MESSAGE_TYPES.linkingFailed),
        showCustomMessage: true
      },
      onQueryStarted: (data, lifeCycleAPI) => {
        const invailidateTags = data.payload.disableStatusAPIInvalidate
          ? ['GetLinkedExchange']
          : ['GetLinkedExchange', 'GetSyncStatus'];
        restartJobStatusPolling(lifeCycleAPI, invailidateTags);
      }
    }),
    getConnectedExchangeLinkingDetails: builder.query<
      ExchangeLinkingDetails[],
      string | void
    >({
      query: clientId =>
        appendClientIdInEndpoints('/user/exchange/via', clientId),
      transformResponse: (
        response: GenericApiResponse<ExchangeLinkingDetails[]>
      ) => response.result
    }),
    connectWallet: builder.mutation<
      GenericApiResponse<string>,
      ConnectWalletPayload
    >({
      query: ({ payload, clientId }) => ({
        url: appendClientIdInEndpoints('/user/wallet/connect', clientId),
        method: 'POST',
        body: payload
      }),
      onQueryStarted: (_, lifeCycleAPI) =>
        restartJobStatusPolling(lifeCycleAPI, ['GetWallets'])
    })
  })
});

export const {
  useGetExchangesQuery,
  useGetBlockchainWalletsQuery,
  useGetBlockchainsQuery,
  useAddExchangeMutation,
  useGetConnectedExchangeLinkingDetailsQuery,
  useConnectWalletMutation
} = ConnectExchangeBaseApi;
