import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { lazyWithRetry } from '@utils';
import routesPath from './RoutesPath';

const ClientListingPage = lazyWithRetry(
  () => import('@pages/tax-professional-dashboard/ClientListingPage')
);

const CALayout = () => {
  return (
    <Routes>
      <Route path="clients" element={<ClientListingPage />} />
      <Route
        path={routesPath.ALL}
        element={<Navigate replace={true} to="clients" />}
      />
    </Routes>
  );
};

export default CALayout;
