import React, { FC } from 'react';
import { Tooltip } from '@mui/material';

import { getGainLossStyle } from '@utils/transactionUtils';
import { translate } from '@utils/locale';
import { INDIAN_RUPEE } from '@constants/common';
import {
  getFormattedNumbering,
  getNumberInIndianFormat
} from '@utils/generalUtils';
import { SourceExchangeDetailsCardProps } from '@containers/dashboard/components/types';
import ImageFetcher from '@components/image-fetcher/ImageFetcher';
import DefaultCoinIcon from '@assets/icons/defaultCoinIcon.svg';

const SourceExchangeDetailsCard: FC<SourceExchangeDetailsCardProps> = ({
  data,
  hasOnlyOneData
}) => {
  const { quantity, market_value, gain_or_loss, currency_code, cost_basis } =
    data;

  return (
    <div
      className={`
      py-2.5 px-3 mr-3 min-w-[280px] text-xs sm:text-base bg-whiteSmoke rounded-[10px] border mb-3
      ${hasOnlyOneData ? 'w-[95%]' : 'min-w-[280px]'}
      `}>
      <div className="flex justify-between items-center mb-1">
        <div>{translate('dashboard.asset')}</div>
        <div className="font-semibold text-davyGrey">
          <div className="flex flex-row items-center w-1/5 font-semibold text-davyGrey">
            <ImageFetcher
              imageUrl={data.currency_icon}
              defaultImage={DefaultCoinIcon}
              imageStyle="w-auto max-w-[20px] h-auto max-h-[20px]"
            />
            <span className="ml-2">{currency_code}</span>
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-1">
        <div>{translate('dashboard.totalAssets')}</div>
        <div className="font-semibold text-davyGrey">
          <Tooltip
            title={`${getNumberInIndianFormat(
              Number(quantity),
              '',
              true
            )} ${currency_code}`}
            arrow={true}
            enterTouchDelay={0}
            placement="bottom-start">
            <div className="w-fit">
              {`${getNumberInIndianFormat(Number(quantity), '')}`}
            </div>
          </Tooltip>
        </div>
      </div>
      <div className="flex justify-between mb-1">
        <div>{translate('dashboard.mobileView.investedAmount')}</div>
        <div className="font-semibold text-davyGrey">
          <Tooltip
            title={getNumberInIndianFormat(
              Number(cost_basis),
              INDIAN_RUPEE.code,
              true
            )}
            arrow={true}
            enterTouchDelay={0}
            placement="bottom-start">
            <div className="w-fit">
              {getFormattedNumbering({
                value: cost_basis,
                showZeroValue: true
              })}
            </div>
          </Tooltip>
        </div>
      </div>
      <div className="flex justify-between mb-1">
        <div>{translate('dashboard.mobileView.marketValue')}</div>
        <div className="font-semibold text-davyGrey">
          <Tooltip
            title={getNumberInIndianFormat(
              Number(market_value),
              INDIAN_RUPEE.code,
              true
            )}
            arrow={true}
            enterTouchDelay={0}
            placement="bottom-start">
            <div className="w-fit">
              {getFormattedNumbering({ value: market_value })}
            </div>
          </Tooltip>
        </div>
      </div>
      <div className="flex justify-between mb-1">
        <div>{translate('dashboard.mobileView.gainOrLoss')}</div>
        <div
          className={`text-davyGrey font-semibold ${getGainLossStyle(
            Number(gain_or_loss)
          )}`}>
          <Tooltip
            title={getNumberInIndianFormat(
              Number(gain_or_loss),
              INDIAN_RUPEE.code,
              true
            )}
            arrow={true}
            enterTouchDelay={0}
            placement="bottom-start">
            <div className={`w-fit ${getGainLossStyle(Number(gain_or_loss))}`}>
              {getFormattedNumbering({ value: gain_or_loss })}
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default SourceExchangeDetailsCard;
