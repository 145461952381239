import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip, useMediaQuery } from '@mui/material';
import { isNull } from 'lodash';

import { Accordian } from '@components';
import {
  INDIAN_RUPEE,
  SORTING_ORDER,
  SORTING_ORDER_DECIMAL,
  SORTING_ORDER_STRING
} from '@constants/common';
import {
  formatDecimalPecentage,
  getFormattedNumbering,
  getNumberInIndianFormat,
  limitDigits,
  sortArrayOfObjects
} from '@utils/generalUtils';
import { translate } from '@utils/locale';
import { RootState } from '@store/reducers';
import { EXCHANGE_INFO } from '@containers/source/constants';
import {
  AscendingIcon,
  AscendingTransparentIcon,
  DefaultExchangeIcon,
  DescendingIcon,
  DescendingTransparentIcon
} from '@assets/icons';
import { getGainLossStyle } from '@utils/transactionUtils';
import { useLazyGetPortfolioAssetDetailQuery } from '@containers/dashboard/api';
import { CryptoPortfolioSkeleton } from '@containers/skeleton-loader';
import {
  CryptoPortfolioSectionProps,
  PortfolioAssetData,
  PortfolioCryptoData
} from '../types';
import ImageFetcher from '@components/image-fetcher/ImageFetcher';
import DefaultCoinIcon from '@assets/icons/defaultCoinIcon.svg';
import CryptoPortfolioMobileView from '../crypto-portfolio-mobile-view/CryptoPortfolioMobileView';

const accordianStyleConfig = {
  accordianStyle: 'bg-white px-0',
  accordianIconStyle: 'ml-6',
  accordianBodyHeightOffset: 50
};

const CryptoPortfolioSection: FC<CryptoPortfolioSectionProps> = ({
  cryptoData
}) => {
  const [openCryptoAssetKey, setOpenCryptoAssetKey] = useState();
  const [cryptoAssetData, setCurrencyAssetData] =
    useState<PortfolioAssetData>();
  const [sortingKey, setSortingKey] = useState('');
  const [sortingOrder, setSortingOrder] = useState('');
  const [cryptoPortfolioData, setCryptoPortfolioData] = useState(cryptoData);

  useEffect(() => {
    const sortedPortfolioData = sortArrayOfObjects(
      cryptoData,
      sortingKey,
      sortingOrder
    );
    setCryptoPortfolioData(sortedPortfolioData);
  }, [sortingKey, sortingOrder]);

  const match = useMediaQuery('(min-width: 1192px)'); // TODO: to be updated when working on proper responsiveness

  const { clientId } = useSelector((state: RootState) => state.rootReducer.app);

  const [getPortfolioAssetDetail, portfolioAssetDetailResponse] =
    useLazyGetPortfolioAssetDetailQuery();

  const applySorting = (key: string, order) => {
    setOpenCryptoAssetKey(null);
    if (key === sortingKey)
      setSortingOrder(currentState => order[currentState]);
    else {
      setSortingOrder(order[SORTING_ORDER.DEFAULT]);
      setSortingKey(key);
    }
  };

  //handler to open one accordian body at a time
  const handleCryptoAssetToggle = async (key, currencyCode) => {
    setOpenCryptoAssetKey(openCryptoAssetKey !== key ? key : null);

    if (!isNull(key) && openCryptoAssetKey !== key) {
      const assetDetail = await getPortfolioAssetDetail({
        assetCode: currencyCode,
        clientId
      }).unwrap();
      setCurrencyAssetData({ currency_code: currencyCode, assetDetail });
    }
  };

  const cryptoAssetTableHeading = [
    {
      title: 'asset',
      style: 'w-1/5',
      data: 'currency_code',
      order: SORTING_ORDER_STRING
    },
    {
      title: 'totalAssets',
      style: 'w-1/5',
      data: 'quantity',
      order: SORTING_ORDER_DECIMAL
    },
    {
      title: 'investedAmount',
      style: 'w-1/5',
      data: 'cost_basis',
      order: SORTING_ORDER_DECIMAL
    },
    {
      title: 'marketValue',
      style: 'w-1/5',
      data: 'market_value',
      order: SORTING_ORDER_DECIMAL
    },
    {
      title: 'unrealisedGainOrLoss',
      style: 'mr-10 w-1/5',
      data: 'gain_or_loss',
      order: SORTING_ORDER_DECIMAL
    }
  ];

  const cryptoAssetRowHeading = [
    { title: 'exchangeOrWallet', style: 'w-[17%]' },
    { title: 'currencyBreakUp', style: 'w-1/5' },
    { title: 'investedAmount', style: 'w-1/5' },
    { title: 'marketValue', style: 'w-1/5' },
    { title: 'unrealisedGainOrLoss', style: 'w-1/5' }
  ];

  const getOrderIcon = (key: string) => {
    const DescIcon =
      key === sortingKey && sortingOrder === SORTING_ORDER.DESC
        ? DescendingIcon
        : DescendingTransparentIcon;
    const AscIcon =
      key === sortingKey && sortingOrder === SORTING_ORDER.ASC
        ? AscendingIcon
        : AscendingTransparentIcon;
    return (
      <span className="flex flex-col">
        <DescIcon className="mb-[2px]" />
        <AscIcon />
      </span>
    );
  };

  //crypto accordian head section
  const cryptoAssetHeading = (asset: PortfolioCryptoData) => {
    const {
      currency_code,
      currency_icon,
      quantity,
      market_value,
      gain_or_loss,
      gain_or_loss_percent,
      cost_basis
    } = asset;

    return (
      <div className="flex justify-between items-start w-full text-base">
        <div className="flex flex-row items-center w-1/5 font-semibold text-davyGrey">
          <ImageFetcher
            imageUrl={currency_icon}
            defaultImage={DefaultCoinIcon}
            imageStyle="w-auto max-w-[25px] h-auto max-h-[25px]"
          />
          <span className="ml-2">{currency_code}</span>
        </div>
        <div className="w-1/5 font-semibold text-davyGrey">
          <Tooltip
            title={getNumberInIndianFormat(Number(quantity), '', true)}
            arrow={true}
            enterTouchDelay={0}
            placement="bottom-start"
            PopperProps={{ disablePortal: true }}>
            <div className="w-fit">
              {getNumberInIndianFormat(Number(quantity), '')}
            </div>
          </Tooltip>
        </div>
        <div className="flex items-center w-1/5 font-semibold text-right text-davyGrey">
          <Tooltip
            title={getNumberInIndianFormat(
              Number(cost_basis),
              INDIAN_RUPEE.code,
              true
            )}
            arrow={true}
            enterTouchDelay={0}
            placement="bottom-start"
            PopperProps={{ disablePortal: true }}>
            <div className="w-fit">
              {getFormattedNumbering({
                value: cost_basis,
                showZeroValue: true
              })}
            </div>
          </Tooltip>
        </div>
        <div className="flex items-center w-1/5 font-semibold text-right text-davyGrey">
          <Tooltip
            title={getNumberInIndianFormat(
              Number(market_value),
              INDIAN_RUPEE.code,
              true
            )}
            arrow={true}
            enterTouchDelay={0}
            placement="bottom-start"
            PopperProps={{ disablePortal: true }}>
            <div className="w-fit">
              {getFormattedNumbering({ value: market_value })}
            </div>
          </Tooltip>
        </div>
        <div
          className={`flex items-center w-1/5 font-semibold text-right flex-wrap
          ${getGainLossStyle(Number(gain_or_loss))}`}>
          <Tooltip
            title={getNumberInIndianFormat(
              Number(gain_or_loss),
              INDIAN_RUPEE.code,
              true
            )}
            arrow={true}
            enterTouchDelay={0}
            placement="bottom-start"
            PopperProps={{ disablePortal: true }}>
            <div className="flex flex-nowrap w-fit">
              {getFormattedNumbering({ value: gain_or_loss })}
            </div>
          </Tooltip>
          <span className="flex flex-nowrap ml-2 break-all">
            <Tooltip
              title={`${parseFloat(gain_or_loss_percent).toFixed(2)}%`}
              arrow={true}
              enterTouchDelay={0}
              placement="bottom-start"
              PopperProps={{ disablePortal: true }}>
              <div className="w-fit">
                {`(${
                  Number(gain_or_loss_percent) > 0 ? '+' : ''
                }${formatDecimalPecentage(gain_or_loss_percent)})`}
              </div>
            </Tooltip>
          </span>
        </div>
      </div>
    );
  };

  //crypto accordian body section
  const cryptoAssetBody = (asset: PortfolioAssetData) => (
    <div className="p-4 mt-8 bg-whiteSmoke rounded-md sm:p-5">
      <div className="flex justify-between pb-4 text-sm font-normal text-slateGrey">
        {cryptoAssetRowHeading.map((heading, key) => (
          <div key={key} className={heading.style}>
            {translate(`dashboard.${heading.title}`)}
          </div>
        ))}
      </div>
      {portfolioAssetDetailResponse?.isFetching ? (
        <CryptoPortfolioSkeleton />
      ) : (
        <div data-testid="CryptoPortfolioDetails">
          {asset?.assetDetail?.map((exchange, index) => {
            const {
              quantity,
              source_name,
              source_icon,
              market_value,
              gain_or_loss,
              source_code,
              cost_basis
            } = exchange;
            const AssetExchangeIcon =
              EXCHANGE_INFO[source_code]?.smallIcon || DefaultExchangeIcon;
            return (
              <div
                key={index}
                className="flex flex-col justify-between py-4 text-sm font-normal text-davyGrey sm:flex-row">
                <div className="flex flex-row items-center w-[17%]">
                  <div
                    className="flex overflow-hidden shrink-0 justify-center items-center w-5 h-5
                   bg-white rounded-[50%] border">
                    {source_icon ? (
                      <img
                        className="shrink-0 w-auto max-w-full h-auto max-h-full"
                        alt="source_icon"
                        src={source_icon}
                      />
                    ) : (
                      <AssetExchangeIcon className="shrink-0 w-auto max-w-full h-auto max-h-full" />
                    )}
                  </div>
                  <Tooltip title={source_name} arrow={true} enterTouchDelay={0}>
                    <span className="ml-2 truncate">{source_name}</span>
                  </Tooltip>
                </div>
                <div className=" w-1/5">
                  <Tooltip
                    title={getNumberInIndianFormat(Number(quantity), '', true)}
                    arrow={true}
                    enterTouchDelay={0}
                    placement="bottom-start"
                    PopperProps={{ disablePortal: true }}>
                    <div className="w-fit">
                      {`${limitDigits(
                        getNumberInIndianFormat(Number(quantity)),
                        11
                      )}`}
                    </div>
                  </Tooltip>
                </div>
                <div className="w-1/5 text-davyGrey">
                  <Tooltip
                    title={getNumberInIndianFormat(
                      Number(cost_basis),
                      INDIAN_RUPEE.code,
                      true
                    )}
                    arrow={true}
                    enterTouchDelay={0}
                    placement="bottom-start"
                    PopperProps={{ disablePortal: true }}>
                    <div className="w-fit">
                      {getFormattedNumbering({
                        value: cost_basis,
                        showZeroValue: true
                      })}
                    </div>
                  </Tooltip>
                </div>
                <div className="w-1/5 text-davyGrey">
                  <Tooltip
                    title={getNumberInIndianFormat(
                      Number(market_value),
                      INDIAN_RUPEE.code,
                      true
                    )}
                    arrow={true}
                    enterTouchDelay={0}
                    placement="bottom-start"
                    PopperProps={{ disablePortal: true }}>
                    <div className="w-fit">
                      {getFormattedNumbering({ value: market_value })}
                    </div>
                  </Tooltip>
                </div>
                <div
                  className={`${getGainLossStyle(Number(gain_or_loss))} w-1/5`}>
                  <Tooltip
                    title={getNumberInIndianFormat(
                      Number(gain_or_loss),
                      INDIAN_RUPEE.code,
                      true
                    )}
                    arrow={true}
                    enterTouchDelay={0}
                    placement="bottom-start"
                    PopperProps={{ disablePortal: true }}>
                    <div
                      className={`w-fit ${getGainLossStyle(
                        Number(gain_or_loss)
                      )}`}>
                      {getFormattedNumbering({ value: gain_or_loss })}
                    </div>
                  </Tooltip>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );

  return (
    <div className="mb-3 text-base font-medium text-slateGrey">
      <div
        className={`pt-6 pb-1 w-full bg-white ${
          match ? 'sticky top-0 z-30' : ''
        }`}>
        {translate('dashboard.cryptoPortfolio')}
      </div>
      {match ? (
        <div className="px-4 pt-3 pb-2 mt-3 bg-white rounded-lg border border-harp sm:px-6">
          <div
            className="flex justify-between pb-3 text-sm font-normal text-slateGrey bg-white 
          border-b lg:sticky lg:top-[52px] lg:z-10 lg:pt-1">
            {cryptoAssetTableHeading.map((heading, key) => (
              <div key={key} className={`flex items-center ${heading.style}`}>
                <span
                  className="inline-block cursor-pointer"
                  onClick={() => applySorting(heading.data, heading.order)}>
                  <span className="flex flex-row items-center">
                    {translate(`dashboard.${heading.title}`)}
                    <span className="flex justify-center items-center pl-1">
                      {getOrderIcon(heading.data)}
                    </span>
                  </span>
                </span>
              </div>
            ))}
          </div>
          {cryptoPortfolioData?.map((cryptoAsset, index) => (
            <div key={index}>
              <Accordian
                dataTestId="accordianHeading"
                styleConfig={accordianStyleConfig}
                contentConfig={{
                  accordianHeading: cryptoAssetHeading(cryptoAsset),
                  accordianBody: cryptoAssetBody(cryptoAssetData),
                  handleOnClickHeading: () =>
                    handleCryptoAssetToggle(index, cryptoAsset.currency_code)
                }}
                conditionalFlag={{
                  showDetails: openCryptoAssetKey === index
                }}
              />
            </div>
          ))}
        </div>
      ) : (
        <CryptoPortfolioMobileView cryptoData={cryptoData} />
      )}
    </div>
  );
};

export default CryptoPortfolioSection;
