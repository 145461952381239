import { translate } from '@utils/locale';

export const ERR_UNAUTHORIZED_CLIENT_ACCESS = 'ERR_UNAUTHORIZED_CLIENT_ACCESS';
export const ERR_USER_ALREADY_REGISTERED_WITH_TAXCRYP =
  'ERR_USER_ALREADY_REGISTERED_WITH_TAXCRYP';
export const ERR_INACTIVE_SELECED_PLAN = 'ERR_INACTIVE_SELECED_PLAN';

export const TRANSACTION_SVC_ERROR_CODE_MAPPER = {
  ERR_FY_TO_TIMESTAMP_CONVERT: translate(
    'apiErrorMapper.unableToProcessRequest'
  ),
  ERR_EXCHANGE_DISCONN: translate('apiErrorMapper.unableToConnectExchange'),
  ERR_EXCHANGE_ALREADY_LINKED: translate(
    'apiErrorMapper.exchangeIsAlreadyLinked'
  ),
  ERR_CREATE_VALUT_FAILED: translate('apiErrorMapper.unableToConnectExchange'),
  ERR_TOO_MANY_REQUESTS: translate('apiErrorMapper.unableToProcessChargeBee'),
  ERR_TXN_NOT_FOUND: translate('apiErrorMapper.noTransactionFound'),
  ERR_COST_BASIS_MISSING: translate('apiErrorMapper.errorsInTransaction'),
  ERR_S3_FILE_UPLOAD: translate('apiErrorMapper.fileUploadFailed'),
  ERR_S3_SIGNED_URL: translate('apiErrorMapper.fileUploadFailed'),
  ERR_INVALID_FILE_UPLOAD_REQUEST: translate(
    'apiErrorMapper.incorrectFileFormat'
  ),
  [ERR_UNAUTHORIZED_CLIENT_ACCESS]: translate(
    'apiErrorMapper.unauthorizedClientAccess'
  ),
  [ERR_USER_ALREADY_REGISTERED_WITH_TAXCRYP]: translate(
    'apiErrorMapper.userAlreadyRegisteredWithTaxcryp'
  ),
  ERR_INVALID_PARTNER: translate('apiErrorMapper.invalidPartner'),
  [ERR_INACTIVE_SELECED_PLAN]: translate('apiErrorMapper.inactiveSelectedPlan')
};

export const INVALID_OTP_CODE = 'ERR_INVALID_OTP';
export const OTP_EXPIRED = 'ERR_OTP_EXPIRED';
export const WALLET_TXN_LIMIT_EXCEEDED =
  'ERR_USER_EXCEEDED_ALLOWED_AMOUNT_OF_TRANSACTIONS';

export const AUTH_SVC_ERROR_CODE_MAPPER = {
  ERR_SENDING_MAIL: translate('apiErrorMapper.unableToSendMail'),
  ERR_VERIFY_2FA: translate('apiErrorMapper.unableToVerify2FA'),
  ERR_USER_ALREADY_EXIST: translate('apiErrorMapper.userAlreadyExists'),
  ERR_USER_ALREADY_REGISTERED_AS_PROFESSIONAL: translate(
    'apiErrorMapper.userAlreadyExistAsTaxProfessional'
  ),
  ERR_USER_ALREADY_REGISTERED_AS_CLIENT: translate(
    'apiErrorMapper.userAlreadyExistAsClient'
  ),
  ERR_USER_NOT_FOUND: translate('apiErrorMapper.userNotFound'),
  ERR_GENERATE_OTP: translate('apiErrorMapper.unableToGenerateOTP'),
  ERR_SAME_OLD_AND_NEW_PASSWORDS: translate('apiErrorMapper.samePassword'),
  ERR_PASSWORD_MISMATCH: translate('apiErrorMapper.incorrectCurrentPassword'),
  ERR_ACCEPTING_TERMS: translate('apiErrorMapper.tncAcceptFailed'),
  ERR_FORGOT_PASSWORD_TOKEN_VERIFICATION_FAILED: translate(
    'apiErrorMapper.invalidForgotVerificationLink'
  ),
  ERR_RESETTING_PASSWORD: translate('apiErrorMapper.resetPasswordFailed'),
  ERR_EMAIL_VERIFICATION: translate(
    'apiErrorMapper.invalidEmailVerificationLink'
  ),
  ERR_INVALID_CREDENTIAL: translate('apiErrorMapper.invalidCredentials'),
  [INVALID_OTP_CODE]: translate('apiErrorMapper.invalidOtp'),
  [OTP_EXPIRED]: translate('apiErrorMapper.invalidOtp'),
  ERR_DUPLICATE_PHONE_NUMBER: translate(
    'apiErrorMapper.phoneNumberAlreadyLinked'
  ),
  ERR_INVALID_PHONE_FORMAT: translate(
    'apiErrorMapper.invalidPhoneNumberFormat'
  ),
  ERR_USER_TYPE_MISMATCH: translate('apiErrorMapper.userNotRegistered'),
  ERR_USER_CONNECTION_EXISTS: translate('apiErrorMapper.userConnectionExists'),
  ERR_USER_CONNECTION_LIMIT_EXCEEDED: translate(
    'apiErrorMapper.connectionLimitExceeded'
  ),
  ERR_CANNOT_INVITE_PROFESSIONAL: translate(
    'apiErrorMapper.cannotInviteTaxProfessional'
  ),
  ERR_MANAGED_ACCOUNT_EXISTS_FOR_EMAIL_OR_PHONE: translate(
    'apiErrorMapper.managedAccountEmailExist'
  ),
  ERR_MANAGED_ACCOUNT_IS_BEING_DELETED: translate(
    'apiErrorMapper.managedAccountBeingDeleted'
  )
};

export const ERR_ACCESS_DENIED = 'ERR_ACCESS_DENIED';
