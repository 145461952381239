import React, { FC } from 'react';

import { NftSectionProps } from '../types';
import NftCard from './NftCard';

const NftSummaryMobile: FC<NftSectionProps> = props => {
  const { nftData } = props;

  return (
    <div className="mt-2">
      {nftData?.map((nft, index) => (
        <div key={index} className="px-5 mb-2 rounded-md border">
          <NftCard nftCard={nft} customStyles={'text-davyGrey'} />
        </div>
      ))}
    </div>
  );
};

export default NftSummaryMobile;
