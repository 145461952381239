import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Checkbox } from '@mui/material';
import { isEmpty } from 'lodash';

import { Button } from '@components';
import { BetaLogoVertical } from '@assets/icons';
import { PartnerScreenSkeleton } from '@containers/skeleton-loader';
import { API_RESPONSE_STATUS, IS_AUTHENTICATED } from '@constants/common';
import { updateIsUserAuthenticated } from '@reducers/userAuth';
import { eventKeyMapper } from '@constants/gaEvents';
import { useAppDispatch } from '@store/store';
import { gAEventTracker, gAPageView, translate } from '@utils';
import { useQuery, useUpdateAppHeight } from '@hooks';
import { useLazyGetPartnerDetailsQuery, useOnboardUserMutation } from './api';
import routesPath from '@routes/RoutesPath';
import COLORS from '@constants/colors';

const checkBoxCustomStyle = {
  marginRight: '9px',
  width: '18px',
  height: '18px',
  color: COLORS.CLOUD,
  backgroundColor: COLORS.WHITE,
  '&:hover': {
    backgroundColor: COLORS.WHITE
  },
  '&.Mui-checked': {
    color: COLORS.PRIMARY_COLOR,
    backgroundColor: COLORS.WHITE
  }
};

const PartnerLandingScreen = () => {
  const [isTnCCheckboxChecked, setIsTnCCheckboxChecked] = useState(false);

  const query = useQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  useUpdateAppHeight();

  const [
    getPartnerDetails,
    { data: partnerDetails, isFetching: isFetchingPartnerDetails }
  ] = useLazyGetPartnerDetailsQuery();
  const [onboardUser, { isLoading: userOnboardingInProgress }] =
    useOnboardUserMutation();

  useEffect(() => {
    gAPageView(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    const sourceCode = query.get('source');
    (async () => {
      if (sourceCode) {
        try {
          await getPartnerDetails(sourceCode).unwrap();
        } catch (error) {
          navigate(routesPath.SIGNIN);
        }
      }
    })();
    setIsTnCCheckboxChecked(false);
  }, [query]);

  const handleContinue = async () => {
    const sourceCode = query.get('source');
    const userToken = query.get('user_token');
    const referralCode = query.get('referral_code');
    const payload = {
      user_token: userToken,
      partner_code: sourceCode,
      referral_code: referralCode
    };
    gAEventTracker(eventKeyMapper.continueBtnClicked);
    try {
      const response = await onboardUser({ payload }).unwrap();
      if (response && response.status === API_RESPONSE_STATUS.OK) {
        localStorage.setItem(IS_AUTHENTICATED, 'true');
        dispatch(updateIsUserAuthenticated('true'));
        navigate(routesPath.SOURCE);
        gAEventTracker(eventKeyMapper.userOnboardingSuccess);
      }
    } catch (error) {
      let additionalParams = '';
      if (userToken) additionalParams = `user_token=${userToken}`;
      if (sourceCode) additionalParams += `&source=${sourceCode}`;
      if (referralCode) additionalParams += `&referral_code=${referralCode}`;
      navigate(`${routesPath.SIGNIN}?${additionalParams}`);
    }
  };

  return (
    <div className="flex flex-col items-center p-6 w-full bg-white md:p-8 full-screen">
      <BetaLogoVertical className="shrink-0 mx-auto mt-4 w-[55%] sm:w-full" />
      {isFetchingPartnerDetails || isEmpty(partnerDetails) ? (
        <PartnerScreenSkeleton />
      ) : (
        <div className="flex justify-center items-center">
          <div className="flex flex-col w-full md:w-[50%]">
            <span className="pt-6 text-sm font-medium text-primaryColor md:pt-10 md:text-base">
              {translate('accountSetup.accountSetup')}
            </span>
            <span
              className="flex pt-3 text-xl font-semibold text-blackGreen
           whitespace-pre-line md:text-2xl md:whitespace-normal ">
              {partnerDetails?.partner_name}
              <span className="px-1 font-normal text-primaryColor">x</span>
              {translate('taxcryp')}
            </span>
            <span className="pt-4 pb-6 text-[15px] text-slateGrey md:text-lg">
              {translate('accountSetup.accountSetupSubHeader', {
                partnerName: partnerDetails?.partner_name
              })}
            </span>
            <div
              className="flex flex-row items-center mt-2 mb-[9px] text-xs font-medium leading-[15px]
                  text-grey sm:mt-11 sm:mb-3">
              <Checkbox
                sx={checkBoxCustomStyle}
                checked={isTnCCheckboxChecked}
                onChange={e => setIsTnCCheckboxChecked(e.target.checked)}
              />
              <span className="inline-block text-left">
                {translate('welcomePopup.tc')}
                <span
                  className="inline px-1 font-semibold text-blackCow cursor-pointer"
                  onClick={() =>
                    window.open(process.env.REACT_APP_TERMS_AND_CONDITIONS)
                  }>
                  {translate('welcomePopup.tandc')}
                </span>

                {translate('welcomePopup.and')}
                <span
                  className="inline pl-1 font-semibold text-blackCow cursor-pointer"
                  onClick={() =>
                    window.open(process.env.REACT_APP_PRIVACY_POLICY)
                  }>
                  {translate('welcomePopup.pp')}
                </span>
              </span>
            </div>
            <Button
              label={translate('resetSuccessfulPage.continue')}
              className="py-2 px-4 mt-8 w-[120px] text-sm text-white bg-primaryColor rounded-2xl"
              onClick={handleContinue}
              disabled={!isTnCCheckboxChecked || userOnboardingInProgress}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PartnerLandingScreen;
