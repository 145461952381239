import {
  CSRF_TOKEN_FOR_EXCHANGE_AUTH,
  IS_AUTHENTICATED,
  CLIENT_ID,
  USER_ROLE
} from '@constants/common';
import {
  clearClientProfile,
  updateClientId,
  updateNavigateTo
} from '@reducers/appReducer';
import { updateIsUserAuthenticated } from '@reducers/userAuth';
import { authBaseApi } from '@services/api';
import routesPath from '@routes/RoutesPath';
import store from '@store/store';

export const setUserLogout = () => {
  localStorage.setItem(IS_AUTHENTICATED, 'false');
  localStorage.removeItem(CSRF_TOKEN_FOR_EXCHANGE_AUTH);
  localStorage.setItem(CLIENT_ID, '');
  store.dispatch(updateIsUserAuthenticated('false'));
  if (
    store.getState().rootReducer.userDetails.userDetails.userType ===
    USER_ROLE.PROFESSIONAL
  ) {
    store.dispatch(updateNavigateTo(routesPath.TAX_PROFESSIONALS));
  }

  store.dispatch(updateClientId(''));
  store.dispatch(clearClientProfile());
};

export const invalidateClientDetails = () => {
  localStorage.setItem(CLIENT_ID, '');
  store.dispatch(updateClientId(''));
  store.dispatch(authBaseApi.util.invalidateTags(['GetSharedClientDetails']));
};

export const clearClientDetails = () => {
  localStorage.setItem(CLIENT_ID, '');
  store.dispatch(updateClientId(''));
  store.dispatch(clearClientProfile());
};
