import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { format } from 'date-fns';

import {
  GreenLoadingIcon,
  RightChevron,
  DangerIcon,
  DeleteIcon
} from '@assets/icons';
import {
  ExchangeLinkingStatus,
  SyncRequestStatus,
  TransactionLinkingStatus
} from '@constants/mySources';
import { DATE_TIME_NO_SEC_FORMAT } from '@constants/common';
import { translate } from '@utils/locale';
import { Tooltip } from '@components';
import COLORS from '@constants/colors';

export const getTableDisplayData = (
  exchangeId: string,
  passedStatus: SyncRequestStatus,
  lastSyncTime = '',
  // eslint-disable-next-line no-unused-vars
  handleTryAgain: (isByAuth: boolean, exchangeId?: string) => void = () => null,
  isTryAgainDisabled: boolean
) => {
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up('md'));

  let dateString: string = '';
  if (lastSyncTime) {
    const formattedDate = new Date(lastSyncTime);
    dateString = format(formattedDate, DATE_TIME_NO_SEC_FORMAT);
  }
  switch (passedStatus) {
    case SyncRequestStatus.LINKING:
      return (
        <div className="flex py-1.5 px-2 text-davyGrey bg-paleGreen rounded-md">
          <GreenLoadingIcon className="mr-2 animate-rotate" />
          {ExchangeLinkingStatus.LINKING_YOUR_ACCOUNT}
        </div>
      );
    case SyncRequestStatus.LINK_FAILED:
      return (
        <div className="flex items-center">
          <div className="flex py-1.5 px-2 mr-3 text-coralRed bg-lightOrange rounded-md">
            {ExchangeLinkingStatus.LINK_FAILED_WRONG_API}
          </div>
          <button
            className="flex items-center text-primaryColor disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={isTryAgainDisabled}
            onClick={() => handleTryAgain(false)}>
            {translate('tryAgain')}
            <RightChevron stroke={COLORS.PRIMARY_COLOR} className="ml-1.5" />
          </button>
        </div>
      );
    case SyncRequestStatus.PENDING:
    case SyncRequestStatus.PROCESSING:
      return (
        <div className="flex py-1.5 px-2 text-davyGrey bg-paleGreen rounded-md">
          <GreenLoadingIcon className="mr-2 animate-rotate" />
          {TransactionLinkingStatus.SYNCING_TRANSACTIONS}
        </div>
      );
    case SyncRequestStatus.FAILED:
    case SyncRequestStatus.DEAD:
      if (lastSyncTime) {
        return (
          <div className="flex items-center">
            {match && <span className="mr-4"> |</span>}
            {translate('sourcePage.lastSyncTime', { syncTime: dateString })}
            <span className="ml-2">
              <Tooltip
                body={
                  <div className="text-coralRed">
                    {translate('resyncFailed')}
                  </div>
                }
                onTop={true}>
                <DangerIcon width={15} height={15} />
              </Tooltip>
            </span>
          </div>
        );
      }
      return (
        <div className="flex py-1.5 px-2 mr-3 text-coralRed bg-lightOrange rounded-md">
          {TransactionLinkingStatus.TRANSACTION_SYNC_FAILED}
        </div>
      );
    case SyncRequestStatus.AUTH_EXPIRED:
      return (
        <div className="flex items-center">
          <div className="flex py-1.5 px-2 mr-3 text-coralRed bg-lightOrange rounded-md">
            {translate('sourcePage.loginExpired')}
          </div>
          <button
            className="flex items-center text-primaryColor disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={isTryAgainDisabled}
            onClick={() => handleTryAgain(true, exchangeId)}>
            {translate('reconnect')}
            <RightChevron stroke={COLORS.PRIMARY_COLOR} className="ml-1.5" />
          </button>
        </div>
      );
    case SyncRequestStatus.UNLINKED:
    case SyncRequestStatus.UNLINKING:
      return (
        <div className="flex items-center py-1.5 px-2 text-black bg-linen rounded-md">
          <DeleteIcon className="mr-2 animate-wiggle" height={12} width={12} />
          {TransactionLinkingStatus.DELETING}
        </div>
      );
    case SyncRequestStatus.PAUSED:
      return (
        <div className="flex items-center py-1.5 px-2 text-davyGrey bg-paleGreen rounded-md">
          <GreenLoadingIcon className="shrink-0 mr-2 animate-rotate" />
          {TransactionLinkingStatus.PAUSED}
        </div>
      );
    default:
      return match ? (
        <>
          <span className="mr-4"> |</span>
          {translate('sourcePage.lastSyncTime', { syncTime: dateString })}
        </>
      ) : (
        <div>
          {translate('sourcePage.lastSyncTime', { syncTime: dateString })}
        </div>
      );
  }
};
