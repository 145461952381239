import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TAB_BAR_CONSTANTS, TAB_BAR_ITEMS } from '@constants/tabBar';
import { CloseIcon } from '@assets/icons';
import { SideModal, TabBar } from '@components';
import { translate } from '@utils/locale';
import { RootState } from '@store/reducers';
import { useConnectWalletMutation } from '@containers/connect-exchange/api';
import { API_RESPONSE_STATUS } from '@constants/common';
import { DEFI_CONNECTION_TYPE } from '@containers/connect-exchange/constants';
import { PLAN_TYPES } from '@constants/subscriptionConstants';
import { ConnectWalletPopupProps } from '../types';
import COLORS from '@constants/colors';
import AutoSyncWallet from './AutoSyncWallet';

const ConnectWalletPopup: FC<ConnectWalletPopupProps> = props => {
  const { showModal, handleClose, data, blockchainList, subscriptionType } =
    props;

  const [selectedTab, setSelectedTab] = useState<string>(
    TAB_BAR_CONSTANTS.CONNECT_WALLET_AUTO_SYNC
  );
  const [tabBarItems] = useState(TAB_BAR_ITEMS.connectWalletPopup);

  const { clientId } = useSelector((state: RootState) => state.rootReducer.app);

  const navigate = useNavigate();

  const [connectWallet, connectWalletResponse] = useConnectWalletMutation();

  useEffect(() => {
    if (connectWalletResponse?.data?.status === API_RESPONSE_STATUS.OK) {
      handleClose();
      navigate('/source?tab=wallet');
    }
  }, [connectWalletResponse]);

  const handleTabSelect = (tabId: string) => {
    setSelectedTab(tabId);
  };

  const closeIcon = (
    <span
      onClick={handleClose}
      className="flex absolute top-3 right-3 z-10 justify-center items-center w-7
       h-7 bg-whiteSmoke rounded-full cursor-pointer">
      <CloseIcon fill={COLORS.SLATE_GREY} stroke={COLORS.SLATE_GREY} />
    </span>
  );
  const renderContent = () => {
    switch (selectedTab) {
      case TAB_BAR_CONSTANTS.CONNECT_WALLET_AUTO_SYNC:
        return (
          <AutoSyncWallet
            walletId={
              data?.type === DEFI_CONNECTION_TYPE.blockchain
                ? data?.blockchainId
                : data?.walletId
            }
            walletName={data?.name}
            walletType={data?.type}
            blockchainId={data?.blockchainId}
            blockchainList={blockchainList}
            connectWallet={connectWallet}
            connectWalletResponse={connectWalletResponse.data}
            isConnectWalletInprogress={connectWalletResponse.isLoading}
            isPlanUpgradeRequired={subscriptionType === PLAN_TYPES.FREE}
            clientId={clientId}
          />
        );
      case TAB_BAR_CONSTANTS.CONNECT_WALLET_FILE_UPLOAD:
        return (
          <div className="flex justify-center items-center">
            {translate('comingSoon')}
          </div>
        );
      default:
        return (
          <div className="flex justify-center items-center">
            {translate('comingSoon')}
          </div>
        );
    }
  };

  return (
    <SideModal isOpen={showModal} handleClose={handleClose}>
      <div
        className={`flex justify-center items-center self-center
								w-full h-full bg-transparent
									${showModal ? 'animate-zoom-to-front' : 'animate-zoom-to-back'}`}
        onClick={e => e.stopPropagation()}>
        <div
          className="flex overflow-auto relative flex-col  items-center w-[100%]
         		max-h-full bg-white outline-none sm:overflow-hidden sm:w-[80%] sm:h-auto sm:max-h-[650px]
          	sm:rounded-xl md:w-[65%] md:max-w-[930px]">
          <div className="flex relative flex-row justify-between px-6 mt-6 w-full">
            <span className="flex items-center">
              <img
                className="flex w-10 h-10 rounded-md border border-harp"
                src={data?.imageUrl}
                alt={`${data?.name}-icon`}
              />
              <div className="ml-4 font-medium text-davyGrey">{data?.name}</div>
            </span>
            {closeIcon}
          </div>
          <div className="flex flex-col mt-[14px] w-full h-full">
            <TabBar
              selectedTab={selectedTab}
              handleTabSelect={handleTabSelect}
              tabBarItems={tabBarItems}
              tabStyle="flex justify-start ml-6 mr-4 sm:mr-8"
              customHeight="h-[36px]"
            />
            <div className="border-t border-mercury">{renderContent()}</div>
          </div>
        </div>
      </div>
    </SideModal>
  );
};

export default ConnectWalletPopup;
